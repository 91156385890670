import { useState } from 'react';
import PropTypes from 'prop-types'

const SnoozeForm = props => {
  const { open, onClose, onSnooze } = props

  const [chosenSnoozeOption, setChosenSnoozeOption] = useState('')

  return (
    <div className={`modal ${open ? 'is-active' : ''}`}>
      <div
        className="modal-background"
        style={{ backgroundColor: '#FFFFFF' }}
      ></div>

      <div
        className="modal-content"
        style={{
          backgroundColor: 'lightgray',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h3>Select snooze period</h3>
        <div className="field">
          <div className="control">
            <div className="select is-info is-large">
              <select
                value={chosenSnoozeOption}
                onChange={e => setChosenSnoozeOption(e.target.value)}
                data-test="snooze-select"
              >
                <option value="" disabled>
                  Select a period
                </option>
                <option value="1 Day">1 Day</option>
                <option value="2 Days">2 Days</option>
                <option value="3 Days">3 Days</option>
                <option value="4 Days">4 Days</option>
                <option value="5 Days">5 Days</option>
                <option value="6 Days">6 Days</option>
                <option value="1 Week">1 Week</option>
                <option value="2 Weeks">2 Weeks</option>
              </select>
            </div>
          </div>
        </div>

        <button
          className="button"
          aria-label="confirm"
          data-test="confirm-button"
          style={{ margin: '1em 0 1em 0' }}
          onClick={() => onSnooze(chosenSnoozeOption)}
        >
          Confirm
        </button>

        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={onClose}
          style={{ backgroundColor: '#000000', color: '#FFFFFF' }}
        />
      </div>
    </div>
  )
}

SnoozeForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSnooze: PropTypes.func,
}

SnoozeForm.defaultProps = {
  open: false,
}

export default SnoozeForm
