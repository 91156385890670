/* eslint-disable no-unreachable */
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { useEffect, useState } from 'react'

import { showNotification } from 'constants/utils'
import { CREATE_TASK, DELETE_TASK, UPDATE_CARD_BY_ID } from 'graphql/mutations'
import {
  GET_COMPANY_SETTINGS,
  GET_PROJECT_NAMES,
  GET_TASK_BY_ID,
  GET_TASK_LIST,
} from 'graphql/queries'
import hooks from 'hooks'
import { useHistory, useParams } from 'react-router-dom'
import CreateCardFormView from './create-card-form-view'

function CreateCardFormContainer({ open, isTeam, cardsView, loggedUserId }) {
  const { canUseStickyCards } = hooks.useSubscription()

  const getTaskListQueryVariables = {
    uid: isTeam ? undefined : loggedUserId,
    limitDoneTasks: Boolean(cardsView),
  }

  const [createCardMutation] = useMutation(CREATE_TASK)
  const [updateCardMutation] = useMutation(UPDATE_CARD_BY_ID)
  const [deleteMutation] = useMutation(DELETE_TASK)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [fetchProjectList, { data: projectsData }] =
    useLazyQuery(GET_PROJECT_NAMES)
  const [fetchCompanySettings, { data: companySettings }] =
    useLazyQuery(GET_COMPANY_SETTINGS)

  const { id } = useParams()

  const { data: taskData } = useQuery(GET_TASK_BY_ID, {
    variables: { id },
    skip: !id,
  })

  const { taskById: task } = taskData ?? {}

  useEffect(() => {
    if (open) {
      fetchProjectList()
      fetchCompanySettings()
    }
    // eslint-disable-next-line
  }, [open])

  const projectsList = projectsData?.projects
  const customCardCategories = (
    companySettings?.settings?.create?.customCategories || []
  ).map(({ name, id }) => ({ label: name, value: id }))

  const history = useHistory()

  function onCloseTaskModal() {
    history.goBack()
  }

  async function onDelete() {
    try {
      await deleteMutation({
        variables: { id: task.id },
        refetchQueries: [
          {
            query: GET_TASK_LIST,
            variables: getTaskListQueryVariables,
          },
        ],
      })
    } catch (error) {
      showNotification(error, 'is-danger')
    }
  }

  function onSubmit(props) {
    const {
      title,
      projectId,
      priority,
      content,
      category,
      size,
      id,
      isUpdate,
      status,
      assigneeId,
      dueDate,
      estimatedDurationDateRange,
      duration,
    } = props

    setIsSubmitting(true)

    const newCard = {
      status,
      category,
      content,
      title,
      priority,
      size,
      assigneeId,
      projectId: projectId?.length ? projectId : null,
      dueDate,
      estimatedDurationDateRange,
      duration,
    }

    if (!isUpdate) {
      newCard.position = canUseStickyCards() ? 0 : null
    }

    isUpdate
      ? updateCardMutation({
          variables: { id: id, input: newCard },
          optimisticResponse: {
            __typename: 'Mutation',
            updateTaskById: {
              __typename: 'UpdateTaskResponse',
              task: {
                id,
                ...newCard,
                status: newCard.status,
                ...(task?.project && {
                  project: { ...task.project },
                }),
                assignee: newCard.assignee
                  ? { ...newCard.assignee, __typename: 'TeamMember' }
                  : null,
                estimatedDurationDateRange: estimatedDurationDateRange ?? null,
                __typename: 'Task',
              },
            },
          },
        })
          .catch(error => showNotification(error.message, 'is-danger'))
          .finally(() => {
            // setEditingCard(null)
            setIsSubmitting(false)
            onCloseTaskModal()
          })
      : createCardMutation({
          variables: { input: newCard },
          refetchQueries: [
            {
              query: GET_TASK_LIST,
              variables: getTaskListQueryVariables,
            },
          ],
        })
          .catch(error => {
            showNotification(error.message, 'is-danger')
          })
          .finally(() => {
            setIsSubmitting(false)
            onCloseTaskModal()
          })
  }

  return (
    <CreateCardFormView
      projectsList={projectsList}
      customCardCategories={customCardCategories}
      onDelete={onDelete}
      open={open}
      onClose={onCloseTaskModal}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      editingCard={task}
      fetchProjectList={fetchProjectList}
    />
  )
}

export default CreateCardFormContainer
