import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPen } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'

import { GET_ALL_VIRGIN_PROJECT_TEMPLATES } from 'graphql/queries'
import { DELETE_VIRGIN_PROJECT_TEMPLATE_BY_ID } from 'graphql/mutations'
import { VIRGIN_PROJECT_TEMPLATES } from 'constants/routes'

import { showNotification } from 'constants/utils'

import Table from 'components/table'
import Loader from 'components/loader'

import styles from './VirginProjectTemplatesTable.module.scss'

function VirginTemplateTable() {
  const history = useHistory()

  const [deleteProjectTemplateByIdMutation] = useMutation(
    DELETE_VIRGIN_PROJECT_TEMPLATE_BY_ID,
  )

  const [currentPageNumber, setCurrentPageNumber] = useState(0)

  async function deleteVirginProjectTemplate({ id }) {
    if (!window.confirm('Are you sure you want to delete this template?'))
      return

    try {
      await deleteProjectTemplateByIdMutation({
        variables: { id },
        refetchQueries: [
          {
            query: GET_ALL_VIRGIN_PROJECT_TEMPLATES,
          },
        ],
      })

      showNotification('Virgin template deleted correctly', 'success')
    } catch (error) {
      showNotification(
        `Couldn't delete the project template. Please try later.`,
        'is-danger',
      )
    }
  }

  const {
    data: getAllVirginProjectTemplatesResponse,
    loading: fetchingVirginProjectTemplates,
  } = useQuery(GET_ALL_VIRGIN_PROJECT_TEMPLATES)

  if (fetchingVirginProjectTemplates) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  if (
    getAllVirginProjectTemplatesResponse.virginProjectTemplates.length === 0
  ) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h3>No virgin project templates available</h3>
      </div>
    )
  }

  return (
    <div className={styles['table-container']}>
      <Table
        data={getAllVirginProjectTemplatesResponse.virginProjectTemplates}
        columns={createTableColumns({
          updateAction: item =>
            history.push(`${VIRGIN_PROJECT_TEMPLATES}/${item.id}`),
          deleteAction: item => deleteVirginProjectTemplate({ id: item.id }),
        })}
        pageSize={7}
        currentPageNumber={currentPageNumber}
        onChangePage={pageNumber => setCurrentPageNumber(pageNumber)}
      />
    </div>
  )
}

function createTableColumns({ updateAction, deleteAction }) {
  return [
    {
      Header: 'Project template name',
      accessor: 'name',
      sortType: 'basic',
    },
    {
      Header: ' ',
      Cell: props => {
        return (
          <div className="columns is-vcentered is-justify-content-flex-end">
            <div className="column is-1">
              <button
                className="button is-small is-light"
                onClick={() => updateAction(props.row.original)}
              >
                <span className="icon">
                  <FontAwesomeIcon icon={faPen} size="lg" />
                </span>
              </button>
            </div>

            <div className="column is-1">
              <button
                className="button is-small is-light"
                onClick={() => deleteAction(props.row.original)}
              >
                <span className="icon is-medium">
                  <FontAwesomeIcon icon={faTrash} size="lg" />
                </span>
              </button>
            </div>
          </div>
        )
      },
    },
  ]
}

export default VirginTemplateTable
