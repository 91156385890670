import gql from 'graphql-tag'
import {
  virginProjectTemplateFragment,
  virginEmailTemplateFragment,
  companyFragment,
} from './fragments'

export const GET_PROJECTS = gql`
  query GetProjects {
    projects {
      id
      name
      archived
      onHold
      snoozed {
        createdAt
        snoozedTo
      }
      snoozeType @client
      clients {
        id
        firstName
        lastName
        email
        role
      }
      assignees {
        role
        teamMember {
          id
          firstName
          lastName
          email
          role
        }
      }
      startDate
      endDate
      status
      progress
      createdAt
    }
  }
`

export const GET_PROJECTS_WITH_PHASES = gql`
  query {
    activeProjects {
      id
      name
      startDate
      endDate
      progress
      archived
      createdAt
      phases {
        id
        title
        dependsOn
        estimatedDurationDateRange {
          estimatedStartingDate
          estimatedCompletionDate
        }
        tasks {
          completed
        }
      }
    }
  }
`

export const GET_MY_FAVOURITE_PROJECTS = gql`
  query {
    myFavouriteProjects {
      id
    }
  }
`

export const GET_PROJECT_ONLY_PHASES = gql`
  query ProjectsOnlyPhases {
    projects {
      name
      id
      phases {
        id
        title
      }
    }
  }
`

export const GET_PROJECT_NAMES = gql`
  query ProjectsOnlyNames {
    projects {
      name
      id
      projectTemplateId
      clients {
        id
      }
      startDate
    }
  }
`

export const GET_PROJECTS_ADMIN = gql`
  query ProjectsForAdmin {
    projects {
      id
      name
      archived
      onHold
      clients {
        id
        firstName
        lastName
        email
        role
      }
      assignees {
        role
        teamMember {
          id
          firstName
          lastName
          email
          role
        }
      }
      startDate
      endDate
    }
  }
`

export const GET_PROJECTS_BY_PHASES = gql`
  query ($templateId: String!) {
    projectsByPhases(templateId: $templateId) {
      isCustom
      phaseTitle
      projects {
        name
        id
      }
    }
  }
`

export const GET_PROJECT_DETAILS = gql`
  query GetProjectDetails($id: ID!) {
    projectById(id: $id) {
      id
      name
      archived
      notifications
      update
      nextTaskToComplete {
        id
        title
      }
      phases {
        id
        title
        description
        dependsOn
        estimatedDurationDateRange {
          estimatedStartingDate
          estimatedCompletionDate
        }
        documents {
          name
          text
          uploadedBy
          createdAt
        }
        tasks {
          id
          title
          notification {
            emailTemplate {
              emailText
              name
              id
            }
            recipients
            extraRecipients
          }
          completed
        }
      }
      projectTemplateId
      emailTemplateId
      clients {
        id
        firstName
        lastName
        phoneNumber
        email
        address
        role
        organization {
          name
        }
      }
      engineers {
        id
        firstName
        lastName
        email
        phoneNumber
        address
        role
        organization {
          name
        }
      }
      consultants {
        id
        firstName
        lastName
        email
        phoneNumber
        address
        role
        organization {
          name
        }
      }
      contractors {
        id
        firstName
        lastName
        email
        phoneNumber
        address
        role
        organization {
          name
        }
      }
      surveyors {
        id
        firstName
        lastName
        email
        phoneNumber
        address
        role
        organization {
          name
        }
      }
      planners {
        id
        firstName
        lastName
        email
        phoneNumber
        address
        role
        organization {
          name
        }
      }
      warrantOfficers {
        id
        firstName
        lastName
        email
        phoneNumber
        address
        role
        organization {
          name
        }
      }
      buildingControlOfficers {
        id
        firstName
        lastName
        email
        phoneNumber
        address
        role
        organization {
          name
        }
      }
      ecologists {
        id
        firstName
        lastName
        email
        phoneNumber
        address
        role
        organization {
          name
        }
      }
      cdmPrincipalDesigners {
        id
        firstName
        lastName
        email
        phoneNumber
        address
        role
        organization {
          name
        }
      }
      bsaPrincipalDesigners {
        id
        firstName
        lastName
        email
        phoneNumber
        address
        role
        organization {
          name
        }
      }
      assignees {
        role
        teamMember {
          id
          firstName
          lastName
          email
          phoneNumber
          address
          role
        }
      }
      startDate
      endDate
      status
      privateStatus
      description
      value
      meta {
        projectDocsUrl
      }
    }
  }
`
export const GET_TEAM = gql`
  query GetTeam {
    team {
      id
      firstName
      lastName
      phoneNumber
      email
      address
      role
      projectsAssigned {
        project {
          id
          name
        }
      }
    }
  }
`

export const GET_CLIENTS_PARTNERS = gql`
  query GetTeamClientsPartners {
    clients {
      id
      firstName
      lastName
      phoneNumber
      email
      address
      role
      organization {
        name
      }
    }
    partners {
      id
      firstName
      lastName
      phoneNumber
      email
      address
      role
      organization {
        name
      }
    }
  }
`

export const GET_TEAM_CLIENTS_PARTNERS = gql`
  query GetClientsPartners {
    clients {
      id
      firstName
      lastName
      phoneNumber
      email
      address
      role
      organization {
        name
      }
    }
    partners {
      id
      firstName
      lastName
      phoneNumber
      email
      address
      role
      organization {
        name
      }
    }
    team {
      id
      firstName
      lastName
      phoneNumber
      email
      address
      role
      projectsAssigned {
        project {
          id
          name
        }
      }
    }
  }
`

export const GET_MY_PARTNERS = gql`
  query {
    partners {
      id
      firstName
      lastName
      phoneNumber
      email
      address
      role
    }
  }
`

export const GET_MY_TEAM = gql`
  query {
    team {
      id
      firstName
      lastName
      phoneNumber
      email
      address
      role
    }
  }
`

export const GET_ALL_USERS = gql`
  query {
    allCompaniesUsers {
      id
      firstName
      lastName
      phoneNumber
      email
      address
      company {
        name
      }
      role
    }
  }
`

export const GET_USER_BY_ID = gql`
  query ($id: ID!) {
    userById(id: $id) {
      id
      email
      role
      firstName
      lastName
      phoneNumber
      address
      role
      chatProviderId
      company {
        id
        name
      }
      ... on Client {
        organization {
          name
        }
      }
      ... on Partner {
        organization {
          name
        }
      }
      ... on TeamMember {
        favoriteProjectsIds
        defaultTemplateId
        subscribedToDailySummary
        subscribedToWeeklySummary
        subscribedToMonthlySummary
      }
    }
  }
`

export const GET_COMPANY_SETTINGS = gql`
  query getCompanySettings {
    settings {
      weeklyUpdate {
        day
        time
        enabled
      }
      subscription
      create {
        customCategories {
          name
          color
          id
        }
      }
      preferences {
        dateFormat
      }
      chatProvider {
        provider
      }
      notifications {
        slack {
          notificationsChannelId
        }
      }
    }
  }
`

export const GET_PROJECT_TEMPLATES_IDS = gql`
  query ProjectTemplateIds {
    projectTemplates {
      id
      name
    }
  }
`

export const GET_PROJECT_TEMPLATE_DETAILS = gql`
  query ($id: ID!) {
    projectTemplateById(id: $id) {
      id
      name
      phases {
        id
        title
        duration
        description
        dependsOn {
          id
        }
        tasks {
          id
          title
          private
          notification {
            emailTemplate {
              emailText
              name
              id
            }
            recipients
            extraRecipients
          }
        }
      }
      engineers {
        id
        firstName
        lastName
        email
        role
      }
      consultants {
        id
        firstName
        lastName
        email
        role
      }
      surveyors {
        id
        firstName
        lastName
        email
        role
      }
    }
  }
`

export const GET_EMAIL_TEMPLATES = gql`
  query {
    emailTemplates {
      id
      type
      name
    }
  }
`

export const GET_EMAIL_TEMPLATE_BY_ID = gql`
  query ($id: ID!) {
    emailTemplateById(id: $id) {
      id
      type
      name
      emailText
    }
  }
`

export const GET_TASK_LIST = gql`
  query ($uid: ID, $limitDoneTasks: Boolean) {
    tasks(uid: $uid, limitDoneTasks: $limitDoneTasks) {
      author {
        firstName
        lastName
      }
      category
      status
      content
      createdAt
      id
      assignee {
        id
        firstName
        lastName
      }
      priority
      project {
        id
        name
      }
      size
      title
      completedAt
      dueDate
      estimatedDurationDateRange {
        estimatedStartingDate
        estimatedCompletionDate
      }
      duration
      position
    }
  }
`

export const GET_TASK_BY_ID = gql`
  query GetTask($id: ID!) {
    taskById(id: $id) {
      author {
        firstName
        lastName
      }
      category
      status
      content
      createdAt
      id
      assignee {
        id
        firstName
        lastName
      }
      priority
      project {
        id
        name
      }
      size
      title
      completedAt
      estimatedDurationDateRange {
        estimatedStartingDate
        estimatedCompletionDate
      }
      dueDate
      duration
    }
  }
`

export const GET_TIMESHEET_BETWEEN_DATES_RANGE = gql`
  query ($datesRange: DatesRangeInput) {
    timesheetBetweenDatesRange(datesRange: $datesRange) {
      projectName
      projectId
      phaseId
      date
      time
      hidden
      createdAt
      id
      description
      userId
    }
  }
`

export const GET_TIMESHEET_BY_PROJECT_ID = gql`
  query ($projectId: ID!) {
    timesheetByProjectId(projectId: $projectId) {
      projectName
      projectId
      phaseId
      date
      time
      hidden
      createdAt
      id
      description
      userId
    }
  }
`

export const GENERATE_DATA_FOR_TIMESHEET_CSV = gql`
  query ($datesRange: DatesRangeInput, $filterUsers: [ID!]) {
    generateDataForTimesheetCsv(
      datesRange: $datesRange
      filterUsers: $filterUsers
    ) {
      id
      project
      phase
      date
      time
      description
      user
      invoiced
    }
  }
`

// VIRGIN PROJECT TEMPLATES QUERIES
export const GET_ALL_VIRGIN_PROJECT_TEMPLATES = gql`
  query {
    virginProjectTemplates {
      ...VirginProjectTemplateFragment
    }
  }
  ${virginProjectTemplateFragment}
`

export const GET_VIRGIN_PROJECT_TEMPLATE_BY_ID = gql`
  query ($id: ID!) {
    virginProjectTemplateById(id: $id) {
      ...VirginProjectTemplateFragment
    }
  }
  ${virginProjectTemplateFragment}
`

// VIRGIN EMAIL TEMPLATES QUERIES
export const GET_ALL_VIRGIN_EMAIL_TEMPLATES = gql`
  query {
    virginEmailTemplates {
      ...VirginEmailTemplateFragment
    }
  }
  ${virginEmailTemplateFragment}
`

export const GET_VIRGIN_EMAIL_TEMPLATE_BY_ID = gql`
  query ($id: ID!) {
    virginEmailTemplateById(id: $id) {
      ...VirginEmailTemplateFragment
    }
  }
  ${virginEmailTemplateFragment}
`

// COMPANIES QUERIES
export const GET_ALL_COMPANIES = gql`
  query {
    companies {
      ...CompanyFragment
    }
  }
  ${companyFragment}
`

export const GET_COMPANY_BY_ID = gql`
  query ($id: ID!) {
    companyById(id: $id) {
      ...CompanyFragment
    }
  }
  ${companyFragment}
`

export const GET_MY_COMPANY_PERMISSIONS = gql`
  query {
    myCompanyPermissions
  }
`

export const GET_METRICS = gql`
  query Metrics($period: String!) {
    metrics(period: $period) {
      newProjects {
        period
        value
      }
      completedProjects {
        period
        value
      }
      valueOfProjects {
        period
        value
      }
      createdCards {
        period
        value
      }
      completedCards {
        period
        value
      }
    }
  }
`
