import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLongArrowAltDown,
  faLongArrowAltUp,
} from '@fortawesome/free-solid-svg-icons'

export const TableView = ({
  headerGroups,
  onRowClick,
  page,
  prepareRow,
  getTableBodyProps,
  getTableProps,
  hightlightRowField,
}) => {
  return (
    <>
      <table
        border="0"
        cellSpacing="0"
        cellPadding="0"
        className={cx('table is-fullwidth')}
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  key={i}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon
                          icon={faLongArrowAltDown}
                          size="lg"
                          style={{ marginLeft: '5px' }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faLongArrowAltUp}
                          size="lg"
                          style={{ marginLeft: '5px' }}
                        />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                key={i}
                {...row.getRowProps()}
                onClick={e =>
                  onRowClick ? onRowClick(e, row.original) : undefined
                }
              >
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      key={i}
                      {...cell.getCellProps()}
                      className={
                        row.original[hightlightRowField] ? 'archived' : ''
                      }
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default TableView
