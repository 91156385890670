import { useMutation } from '@apollo/react-hooks'
import { showNotification } from '../../../constants/utils'

import {
  PUT_PROJECT_ON_HOLD_BY_ID,
  REMOVE_PROJECT_HOLD_BY_ID,
} from '../../../graphql/mutations'

const onHoldToString = onHold => (onHold ? 'resumed' : 'put on hold')

const makeMutationOptions = ({ id, onHold }) => {
  const optimisticResponseKey = onHold
    ? 'removeProjectHoldById'
    : 'putProjectOnHoldById'

  const optimisticResponseType = onHold
    ? 'RemoveProjectHoldResponse'
    : 'PutProjectOnHoldResponse'

  return {
    variables: { id },
    optimisticResponse: {
      __typename: 'Mutation',
      [optimisticResponseKey]: {
        successful: true,
        project: {
          id,
          onHold: onHold ? null : new Date().toISOString(),
          __typename: 'Project',
        },
        __typename: optimisticResponseType,
      },
    },
  }
}

const makeSuccessToastMessage = ({ name, onHold }) =>
  `Project "${name}" ${onHoldToString(onHold)}`

export const useOnHoldProjectMutation = () => {
  const [putProjectOnHoldMutation] = useMutation(PUT_PROJECT_ON_HOLD_BY_ID)
  const [removeProjectHoldMutation] = useMutation(REMOVE_PROJECT_HOLD_BY_ID)

  return project => {
    const mutation = project.onHold
      ? removeProjectHoldMutation
      : putProjectOnHoldMutation

    const options = makeMutationOptions(project)

    return mutation(options)
      .then(() =>
        showNotification(makeSuccessToastMessage(project), 'is-success'),
      )
      .catch(error => showNotification(error.message, 'is-danger'))
  }
}
