import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPen } from '@fortawesome/free-solid-svg-icons'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'

import { COMPANIES } from 'constants/routes'

import { GET_ALL_COMPANIES } from 'graphql/queries'
import { DELETE_COMPANY_BY_ID } from 'graphql/mutations'

import { showNotification } from 'constants/utils'

import Table from 'components/table'
import Loader from 'components/loader'

import styles from './CompaniesTable.module.scss'

function CompaniesTable() {
  const history = useHistory()

  const [deleteCompanyByIdMutation] = useMutation(DELETE_COMPANY_BY_ID)

  const [currentPageNumber, setCurrentPageNumber] = useState(0)

  async function deleteCompanyById({ id }) {
    if (!window.confirm('Are you sure you want to delete this company?')) return

    try {
      await deleteCompanyByIdMutation({
        variables: { id },
        refetchQueries: [
          {
            query: GET_ALL_COMPANIES,
          },
        ],
      })

      showNotification('Company deleted correctly', 'success')
    } catch (error) {
      showNotification(
        `Couldn't delete the company. Please try later.`,
        'is-danger',
      )
    }
  }

  const { data: getAllCompaniesResponse, loading: fetchingCompanies } =
    useQuery(GET_ALL_COMPANIES)

  if (fetchingCompanies) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  if (getAllCompaniesResponse.companies.length === 0) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h3>No companies available</h3>
      </div>
    )
  }

  return (
    <div className={styles['table-container']}>
      <Table
        data={getAllCompaniesResponse.companies}
        columns={createTableColumns({
          updateAction: item => history.push(`${COMPANIES}/${item.id}`),
          deleteAction: item => deleteCompanyById({ id: item.id }),
        })}
        pageSize={7}
        currentPageNumber={currentPageNumber}
        onChangePage={pageNumber => setCurrentPageNumber(pageNumber)}
      />
    </div>
  )
}

function createTableColumns({ updateAction, deleteAction }) {
  return [
    {
      Header: 'Company Name',
      accessor: 'name',
      sortType: 'basic',
    },
    {
      Header: 'Subscriber Email',
      accessor: 'subscriberEmail',
      sortType: 'basic',
    },
    {
      Header: 'Max Number Of Team Members',
      accessor: 'settings.subscription.allowedExtraUsers',
      sortType: 'basic',
    },
    {
      Header: 'Max Number Of Projects',
      accessor: 'settings.subscription.maxNumberOfProjects',
      sortType: 'basic',
    },
    {
      Header: 'Project Notifications',
      accessor: 'settings.enableProjectNotifications',
      sortType: 'basic',
      Cell: props => {
        return props.row.original.settings?.subscription
          ?.enableProjectNotifications
          ? 'yes'
          : 'no'
      },
    },
    {
      Header: 'Timesheet',
      accessor: 'settings.enableTimesheet',
      sortType: 'basic',
      Cell: props => {
        return props.row.original.settings?.subscription?.enableTimesheet
          ? 'yes'
          : 'no'
      },
    },
    {
      Header: 'Timeline',
      accessor: 'settings.enableTimeline',
      sortType: 'basic',
      Cell: props => {
        return props.row.original.settings?.subscription?.enableTimeline
          ? 'yes'
          : 'no'
      },
    },
    {
      Header: 'Cards Timeline',
      accessor: 'settings.enableCardsTimeline',
      sortType: 'basic',
      Cell: props => {
        return props.row.original.settings?.subscription?.enableCardsTimeline
          ? 'yes'
          : 'no'
      },
    },
    {
      Header: 'Sticky Cards',
      accessor: 'settings.enableStickyCards',
      sortType: 'basic',
      Cell: props => {
        return props.row.original.settings?.subscription?.enableStickyCards
          ? 'yes'
          : 'no'
      },
    },
    {
      Header: ' ',
      Cell: props => {
        return (
          <div className="columns">
            <div className="column is-2">
              <button
                className="button is-small is-light"
                onClick={() => updateAction(props.row.original)}
              >
                <span className="icon">
                  <FontAwesomeIcon icon={faPen} size="lg" />
                </span>
              </button>
            </div>

            <div className="column is-2">
              <button
                className="button is-small is-light"
                onClick={() => deleteAction(props.row.original)}
              >
                <span className="icon is-medium">
                  <FontAwesomeIcon icon={faTrash} size="lg" />
                </span>
              </button>
            </div>
          </div>
        )
      },
    },
  ]
}

export default CompaniesTable
