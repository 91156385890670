import { Route, Switch, useHistory } from 'react-router-dom'
import {
  VIRGIN_EMAIL_TEMPLATE_NEW,
  VIRGIN_EMAIL_TEMPLATE_DETAILS,
} from 'constants/routes'

import TabContainer from 'components/TabContainer'
import TemplateTable from './components/VirginEmailTemplatesTable'
import TemplateEditor from './components/VirginEmailTemplateEditor'

const Templates = () => {
  const history = useHistory()
  return (
    <TabContainer
      title="Virgin Email Templates"
      dataTest="bo-virgin-email-templates"
    >
      <button
        className="button is-info is-normal"
        onClick={() => history.push(VIRGIN_EMAIL_TEMPLATE_NEW)}
      >
        Add Template
      </button>

      <TemplateTable />

      <Switch>
        <Route exact path={VIRGIN_EMAIL_TEMPLATE_NEW}>
          <TemplateEditor />
        </Route>

        <Route exact path={VIRGIN_EMAIL_TEMPLATE_DETAILS}>
          <TemplateEditor />
        </Route>
      </Switch>
    </TabContainer>
  )
}

export default Templates
