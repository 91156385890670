import Title from '../../../components/title'

import styles from './subscription.module.scss'

const SubscriptionView = () => {
  return (
    <div
      className={styles['root']}
      data-test="administration-subscription-container"
    >
      <div className={styles['subscription-column']}>
        <div className={styles['subscription-title-wrapper']}>
          <Title title="Your YoopKnows Subscription" />
        </div>

        <div className={styles['subscription-wrapper']}>
          <table>
            <tbody>
              <tr>
                <th style={{ paddingRight: '30px', textAlign: 'left' }}>
                  Subscription Link
                </th>
                <td>
                  <a
                    href="https://yoopknows-test.chargebeeportal.com/portal/v2/home"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Manage Subscription
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionView
