export const usersPerPage = 7
export const projectsPerPage = 7

export const progressFilterOptions = [
  {
    label: 'Archived',
    value: 'archived',
  },
  {
    label: 'Completed',
    value: 'completed',
  },
  {
    label: 'Uncompleted',
    value: 'uncompleted',
  },
  {
    label: 'Grey',
    value: 'today',
  },
  {
    label: 'Green',
    value: 'ongoing',
  },
  {
    label: 'Amber',
    value: 'oneday',
  },
  {
    label: 'Red',
    value: 'expired',
  },
]

export const teamMembersRolesOptions = [
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Staff',
    value: 'staff',
  },
]

export const clientsAndPartnersFilterOptions = [
  {
    label: 'Client',
    pluralLabel: 'Clients',
    value: 'client',
    pluralValue: 'clients',
  },
  {
    label: 'Engineer',
    pluralLabel: 'Engineers',
    value: 'engineer',
    pluralValue: 'engineers',
  },
  {
    label: 'Surveyor',
    pluralLabel: 'Surveyors',
    value: 'surveyor',
    pluralValue: 'surveyors',
  },
  {
    label: 'Consultant',
    pluralLabel: 'Consultants',
    value: 'consultant',
    pluralValue: 'consultants',
  },
  {
    label: 'Contractor',
    pluralLabel: 'Contractors',
    value: 'contractor',
    pluralValue: 'contractors',
  },
  {
    label: 'Planner',
    pluralLabel: 'Planners',
    value: 'planner',
    pluralValue: 'planners',
  },
  {
    label: 'Warrant Officer',
    pluralLabel: 'Warrant Officers',
    value: 'warrantOfficer',
    pluralValue: 'warrantOfficers',
  },
  {
    label: 'Building Control Officer',
    pluralLabel: 'Building Control Officers',
    value: 'buildingControlOfficer',
    pluralValue: 'buildingControlOfficers',
  },
  {
    label: 'Ecologist',
    pluralLabel: 'Ecologists',
    value: 'ecologist',
    pluralValue: 'ecologists',
  },
  {
    label: 'CDM Principal Designer',
    pluralLabel: 'CDM Principal Designers',
    value: 'cdmPrincipalDesigner',
    pluralValue: 'cdmPrincipalDesigners',
  },
  {
    label: 'BSA Principal Designer',
    pluralLabel: 'BSA Principal Designers',
    value: 'bsaPrincipalDesigner',
    pluralValue: 'bsaPrincipalDesigners',
  },
]

export const dayOptions = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const emailTypes = [
  {
    value: 'TASK',
    label: 'Task email',
  },
  {
    value: 'WEEKLY',
    label: 'Weekly email',
  },
]

export const priorityOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
]

export const cardSizeOptions = [
  {
    label: 'S',
    value: 'S',
  },
  {
    label: 'M',
    value: 'M',
  },
  {
    label: 'L',
    value: 'L',
  },
  {
    label: 'XL',
    value: 'XL',
  },
]

export const createTableHeader = [
  { label: 'Title', id: 'title' },
  { label: 'Project', id: 'project' },
  { label: 'Category', id: 'category' },
  { label: 'Content', id: 'content' },
  { label: 'Priority', id: 'priority' },
  { label: 'Size', id: 'size' },
]

export const defaultPhaseDuration = /* 1 Week in seconds */ 7 * 24 * 60 * 60

export const supportCompanies = (
  process.env.REACT_APP_SUPPORT_COMPANIES_IDS ?? ''
).split(',')
