import { useState } from 'react';
import PasswordResetView from './password-reset-view'

const PasswordResetContainer = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const onSubmit = (password, code) => {
    setLoading(true)
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    const data = {
      code: code,
      newPassword: password,
    }

    const raw = JSON.stringify(data)

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    fetch(
      `${process.env.REACT_APP_GRAPHQL_HOST}/auth/resetPassword`,
      requestOptions,
    )
      .then(resp => {
        if (resp.status === 200) {
          setLoading(false)
          setSuccess(true)
        } else {
          setError(true)
        }
      })
      .catch(() => {
        setError(true)
      })
  }

  return (
    <PasswordResetView
      onSubmit={onSubmit}
      success={success}
      error={error}
      loading={loading}
    />
  )
}

export default PasswordResetContainer
