import { useEffect } from 'react';
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './sidebar.module.scss'

const Sidebar = ({ open, onClose, children }) => {
  useEffect(() => {
    if (open) {
      window.document.body.style.overflow = 'hidden'
    } else {
      window.document.body.style.overflow = 'auto'
    }

    return () => (window.document.body.style.overflow = 'auto')
  }, [open])

  return (
    <div className={`modal ${styles.sidebar} ${open ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={onClose}></div>

      {open && (
        <div className={cx('modal-content', styles['sidebar-content'])}>
          {children}
        </div>
      )}
    </div>
  )
}

Sidebar.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

Sidebar.defaultProps = {
  open: false,
}

export default Sidebar
