import { useMutation } from '@apollo/react-hooks'
import { SIGN_IN } from '../../graphql/mutations'
import hooks from '../../hooks'
import LoginView from './login-view'

const LoginContainer = () => {
  const auth = hooks.useAuth()

  const [login, { loading, error }] = useMutation(SIGN_IN)

  async function onLogin(email, password) {
    try {
      const { data } = await login({
        variables: {
          credentials: {
            email,
            password,
          },
        },
      })

      auth.signIn(data.signIn.authenticatedUser)
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  const tooManyAttemptsError =
    'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).'

  const errorMessage =
    error?.message === tooManyAttemptsError
      ? 'Too many login attempts. Reset your password.'
      : [
            'Request failed with status code 400',
            'Request failed with status code 500',
          ].includes(error?.message)
        ? 'Something went wrong, try again later. If the problem persists contact support'
        : error?.message

  return (
    <LoginView onLogin={onLogin} loading={loading} apiError={errorMessage} />
  )
}

export default LoginContainer
