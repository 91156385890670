import cx from 'classnames'

import Search from 'components/search'
import styles from './CreateSubToolbar.module.scss'
import SortingSelect from 'components/SortingSelect'

const sortingOptions = [{ value: 'dueDate', label: 'Sort by Due Date' }]

function CreateSubToolbar({
  searchQuery,
  hasSearchBar,
  setSearchQuery,
  sort,
  setSort,
  isTeam,
}) {
  return (
    <div
      className={cx(
        isTeam && styles['container-full-page'],
        'columns is-vcentered',
      )}
    >
      {hasSearchBar && (
        <Search
          globalFilter={searchQuery}
          setGlobalFilter={setSearchQuery}
          className={styles['search-bar']}
        />
      )}
      <SortingSelect
        sortingOptions={sortingOptions}
        sort={sort}
        setSort={setSort}
      />
    </div>
  )
}

export default CreateSubToolbar
