import { Fragment } from 'react';
import _ from 'lodash'

import { Switch, Route, useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'

import { DELETE_USER_BY_ID } from 'graphql/mutations'
import { GET_COMPANY_SETTINGS, GET_CLIENTS_PARTNERS } from 'graphql/queries'

import { showNotification } from 'constants/utils'

import UsersView from './users-view'
import CreateUserForm from './create-user-form'
import Loader from 'components/loader'
import {
  CLIENTS_PARTNERS,
  CLIENTS_PARTNERS_EDIT_USER,
  CLIENTS_PARTNERS_NEW_USER,
  createClientPartnerEditUserUrl,
  createTeamEditUserUrl,
  PROJECT_DETAILS,
  ROUTE_ENTITY_ID,
  TEAM,
  TEAM_EDIT_USER,
  TEAM_NEW_USER,
} from 'constants/routes'
import { clientsAndPartnersFilterOptions } from '../../../constants/constants'

export default function UsersContainer({
  title,
  isTeamPage,
  dataTest,
  usersQuery,
  role,
}) {
  const history = useHistory()

  const [deleteUserMutation] = useMutation(DELETE_USER_BY_ID)

  const { data: settingsResponse, loading: settingsLoading } = useQuery(
    GET_COMPANY_SETTINGS,
    { fetchPolicy: 'network-only' },
  )

  const { data, loading: fetchingUsers } = useQuery(usersQuery)

  if (fetchingUsers || settingsLoading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  const users =
    usersQuery === GET_CLIENTS_PARTNERS
      ? [...data.clients, ...data.partners]
      : data?.team

  const tableUsers = users?.map(user => {
    const projects = _.unionBy(
      user.projectsAssigned?.map(p => p.project) ?? [],
      'id',
    )

    return {
      ...user,
      role:
        clientsAndPartnersFilterOptions.find(u => u.value === user.role)
          ?.label ?? user.role,
      projects: (
        <div>
          {projects.map((project, index) => (
            <Fragment key={project.id}>
              <Link to={PROJECT_DETAILS.replace(ROUTE_ENTITY_ID, project.id)}>
                {project.name}
              </Link>
              {projects.length > 1 && !(index >= projects.length - 1)
                ? ', '
                : ''}
            </Fragment>
          ))}
        </div>
      ),
    }
  })

  function toggleCreateUserModal(open) {
    const newPath = open
      ? isTeamPage
        ? TEAM_NEW_USER
        : CLIENTS_PARTNERS_NEW_USER
      : isTeamPage
      ? TEAM
      : CLIENTS_PARTNERS

    history.push(newPath)
  }

  function openEditModal(user) {
    history.push(
      isTeamPage
        ? createTeamEditUserUrl(user.id)
        : createClientPartnerEditUserUrl(user.id),
    )
  }

  function closeModal() {
    history.push(isTeamPage ? TEAM : CLIENTS_PARTNERS)
  }

  function deleteUser(user) {
    return deleteUserMutation({
      variables: { id: user.id },
      update: store => {
        const data = store.readQuery({
          query: usersQuery,
        })

        if (user.role === 'client') {
          store.writeQuery({
            query: usersQuery,
            data: {
              ...data,
              clients: data.clients.filter(client => client.id !== user.id),
            },
          })
        } else if (
          ['engineer', 'surveyor', 'consultant', 'contractor'].includes(
            user.role,
          )
        ) {
          store.writeQuery({
            query: usersQuery,
            data: {
              ...data,
              partners: data.partners.filter(partner => partner.id !== user.id),
            },
          })
        } else {
          store.writeQuery({
            query: usersQuery,
            data: {
              ...data,
              team: data.team.filter(teammate => teammate.id !== user.id),
            },
          })
        }
      },
    })
      .then(() => showNotification('User deleted correctly', 'is-success'))
      .catch(error => showNotification(error.message, 'is-danger'))
  }

  function generateUsersCsv(role) {
    return users
      .filter(u => u.role === role)
      .map(u => {
        const { firstName, lastName, phoneNumber, email } = u

        return {
          'First Name': firstName,
          'Last Name': lastName,
          'Phone Number': phoneNumber,
          Email: email,
        }
      })
  }

  return (
    <>
      <UsersView
        title={title}
        role={role}
        users={tableUsers}
        deleteUser={deleteUser}
        onToggleCreateUserModal={toggleCreateUserModal}
        openEditModal={openEditModal}
        subscriptionInfo={settingsResponse.settings.subscription}
        isTeamPage={isTeamPage}
        dataTest={dataTest}
        generateUsersCsv={generateUsersCsv}
      />
      <Switch>
        <Route path={isTeamPage ? TEAM_NEW_USER : CLIENTS_PARTNERS_NEW_USER}>
          <CreateUserForm isTeamPage={isTeamPage} open onClose={closeModal} />
        </Route>
        <Route path={isTeamPage ? TEAM_EDIT_USER : CLIENTS_PARTNERS_EDIT_USER}>
          <CreateUserForm isTeamPage={isTeamPage} open onClose={closeModal} />
        </Route>
      </Switch>
    </>
  )
}
