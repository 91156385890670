import { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'

import {
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATE_BY_ID,
} from '../../../../graphql/queries'

import {
  CREATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE_BY_ID,
} from 'graphql/mutations'

import CreateEmailTemplateFormView from './create-email-template-form-view'

function CreateEmailTemplateFormContainer({ onClose }) {
  const { id } = useParams()
  const [getEmailTemplateById, { data }] = useLazyQuery(
    GET_EMAIL_TEMPLATE_BY_ID,
    { variables: { id } },
  )

  useEffect(() => {
    if (id) {
      getEmailTemplateById()
    }
  }, [id])

  const { emailTemplateById } = data || {}

  const [createEmailTemplateMutation] = useMutation(CREATE_EMAIL_TEMPLATE)
  const [updateEmailTemplateMutation] = useMutation(UPDATE_EMAIL_TEMPLATE_BY_ID)

  function createEmailTemplate(templateDto) {
    return createEmailTemplateMutation({
      variables: { input: templateDto },
      refetchQueries: [
        {
          query: GET_EMAIL_TEMPLATES,
        },
      ],
    })
  }

  function updateEmailTemplateById(templateDto) {
    return updateEmailTemplateMutation({
      variables: { id, input: templateDto },
    })
  }

  return (
    <CreateEmailTemplateFormView
      onClose={onClose}
      onCreateTemplate={createEmailTemplate}
      onUpdate={updateEmailTemplateById}
      selectedTemplate={emailTemplateById}
    />
  )
}

export default CreateEmailTemplateFormContainer
