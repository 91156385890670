import { useRef, useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import cx from 'classnames'
import isEqual from 'lodash.isequal'

import CreateFormModal from 'components/create-form-modal'
import SelectField from 'components/select'
import FormDatePicker from 'components/form-datepicker'
import CreateClientFormModal from './create-client-form-modal'

import {
  showNotification,
  formatDateFromCalendar,
  isValidUrl,
} from 'constants/utils'

import hooks from '../../../../hooks'

import styles from './project-form.module.scss'

function formatProjectForAPI(project, editingProject) {
  const {
    clients,
    engineers,
    consultants,
    contractors,
    surveyors,
    planners,
    warrantOfficers,
    buildingControlOfficers,
    ecologists,
    cdmPrincipalDesigners,
    bsaPrincipalDesigners,
    leads,
    supportMembers,
    value,
    ...restOfProject
  } = project

  const formattedLeads =
    leads?.map(user => ({
      teamMemberId: user.value,
      role: 'lead',
    })) || []

  const formattedSupportMembers =
    supportMembers?.map(user => ({
      teamMemberId: user.value,
      role: 'supportMember',
    })) || []

  const assignees = [...formattedLeads, ...formattedSupportMembers]

  return {
    ...restOfProject,
    ...(project.startDate
      ? {
          startDate: formatDateFromCalendar(project.startDate),
        }
      : {}),
    ...(project.endDate
      ? {
          endDate: formatDateFromCalendar(project.endDate),
        }
      : {}),
    ...(clients?.length > 0
      ? { clients: clients.map(({ value: clientId }) => clientId) }
      : {
          clients:
            clients && editingProject?.clients?.length > 0 ? [] : undefined,
        }),
    ...(engineers?.length > 0
      ? { engineers: engineers.map(({ value: engineerId }) => engineerId) }
      : {
          engineers:
            engineers && editingProject?.engineers?.length > 0 ? [] : undefined,
        }),
    ...(consultants?.length > 0
      ? {
          consultants: consultants.map(
            ({ value: consultantId }) => consultantId,
          ),
        }
      : {
          consultants:
            consultants && editingProject?.consultants?.length > 0
              ? []
              : undefined,
        }),
    ...(contractors?.length > 0
      ? {
          contractors: contractors.map(
            ({ value: consultantId }) => consultantId,
          ),
        }
      : {
          contractors:
            contractors && editingProject?.contractors?.length > 0
              ? []
              : undefined,
        }),
    ...(surveyors?.length > 0
      ? { surveyors: surveyors.map(({ value: surveyorId }) => surveyorId) }
      : {
          surveyors:
            surveyors && editingProject?.surveyors?.length > 0 ? [] : undefined,
        }),
    ...(planners?.length > 0
      ? { planners: planners.map(({ value: plannerId }) => plannerId) }
      : {
          planners:
            planners && editingProject?.planners?.length > 0 ? [] : undefined,
        }),
    ...(warrantOfficers?.length > 0
      ? {
          warrantOfficers: warrantOfficers.map(
            ({ value: warrantOfficerId }) => warrantOfficerId,
          ),
        }
      : {
          warrantOfficers:
            warrantOfficers && editingProject?.warrantOfficers?.length > 0
              ? []
              : undefined,
        }),
    ...(buildingControlOfficers?.length > 0
      ? {
          buildingControlOfficers: buildingControlOfficers.map(
            ({ value: buildingControlOfficerId }) => buildingControlOfficerId,
          ),
        }
      : {
          buildingControlOfficers:
            buildingControlOfficers &&
            editingProject?.buildingControlOfficers?.length > 0
              ? []
              : undefined,
        }),
    ...(ecologists?.length > 0
      ? {
          ecologists: ecologists.map(({ value: ecologistId }) => ecologistId),
        }
      : {
          ecologists:
            ecologists && editingProject?.ecologists?.length > 0
              ? []
              : undefined,
        }),
    ...(cdmPrincipalDesigners?.length > 0
      ? {
          cdmPrincipalDesigners: cdmPrincipalDesigners.map(
            ({ value: cdmPrincipalDesignerId }) => cdmPrincipalDesignerId,
          ),
        }
      : {
          cdmPrincipalDesigners:
            cdmPrincipalDesigners &&
            editingProject?.cdmPrincipalDesigners?.length > 0
              ? []
              : undefined,
        }),
    ...(bsaPrincipalDesigners?.length > 0
      ? {
          bsaPrincipalDesigners: bsaPrincipalDesigners.map(
            ({ value: bsaPrincipalDesignerId }) => bsaPrincipalDesignerId,
          ),
        }
      : {
          bsaPrincipalDesigners:
            bsaPrincipalDesigners &&
            editingProject?.bsaPrincipalDesigners?.length > 0
              ? []
              : undefined,
        }),
    ...(assignees?.length > 0
      ? { assignees }
      : {
          assignees:
            (leads || supportMembers) && editingProject?.assignees?.length > 0
              ? []
              : undefined,
        }),
    ...(project.projectTemplateId
      ? { projectTemplateId: project.projectTemplateId.value }
      : {}),
    ...(project.emailTemplateId
      ? { emailTemplateId: project.emailTemplateId.value }
      : {}),
    ...(project.value && Number(project.value)
      ? { value: Number(project.value) }
      : {}),
  }
}

function ProjectFormView(props) {
  const { user } = hooks.useAuth()
  const userIsAdmin = user?.role === 'admin'

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [openedModal, setOpenedModal] = useState(false)

  const {
    open,
    onClose,
    engineers,
    clients,
    consultants,
    contractors,
    surveyors,
    planners,
    warrantOfficers,
    buildingControlOfficers,
    ecologists,
    cdmPrincipalDesigners,
    bsaPrincipalDesigners,
    projectTemplates,
    weeklyEmailTemplates,
    onCreateProject,
    onUpdateProject,
    editingProject,
    createUser,
    createdClient,
    initialValues,
    team,
  } = props

  const formRef = useRef()

  function onCreate() {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  function onReset() {
    if (formRef.current) {
      const form = formRef.current
      form.resetForm()
    }
  }

  const editMode = !!initialValues.name

  useEffect(() => {
    if (createdClient.length && formRef.current) {
      formRef.current.setFieldValue('clients', createdClient)
    }
  }, [createdClient])

  const onSubmit = project => {
    setIsSubmitting(true)

    let submit

    if (editingProject) {
      const changes = Object.keys(project).reduce((acc, projectProp) => {
        if (!isEqual(project[projectProp], initialValues[projectProp])) {
          return {
            ...acc,
            ...{ [projectProp]: project[projectProp] },
          }
        }

        return acc
      }, {})

      // Need to send integral list of assignees otherwise, if one list is emptied all assignees get deleted
      const { leads, supportMembers } = project
      if (changes) {
        submit = () =>
          onUpdateProject(
            formatProjectForAPI(
              { ...changes, leads, supportMembers },
              editingProject,
            ),
          )
      }
    } else {
      submit = () => onCreateProject(formatProjectForAPI(project))
    }

    return submit()
      .then(() => {
        onReset()
        showNotification(
          `Project ${editMode ? 'update' : 'create'}d correctly`,
          'is-success',
        )
      })
      .catch(() => {
        showNotification(
          `It's not possible to create the project at the moment. Please try again in a few moments.`,
          'is-danger',
        )
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <CreateFormModal
      open={open}
      onClose={onClose}
      title={
        editMode
          ? `Update project ${initialValues.name}`
          : 'Create a new project'
      }
      submitButtonText={editMode ? 'Update' : 'Create'}
      resetButtonText={editMode ? 'Close' : 'Reset'}
      onCreate={onCreate}
      onReset={onReset}
      submitting={isSubmitting}
      dataTestSubmit="submit-create-project"
    >
      <Formik
        innerRef={formRef}
        validateOnBlur={false}
        enableReinitialize
        initialValues={initialValues}
        validate={values => {
          const errors = {}

          if (!values.name) {
            errors.name = 'Required'
          }

          if (!values.projectTemplateId) {
            errors.projectTemplateId = 'Required'
          }

          if (!values.emailTemplateId) {
            errors.emailTemplateId = 'Required'
          }

          if (!values.startDate) {
            errors.startDate = 'Required'
          }

          if (
            values?.meta?.projectDocsUrl &&
            !isValidUrl(values?.meta?.projectDocsUrl)
          ) {
            errors.meta = {
              projectDocsUrl: 'Not a valid url',
            }
          }

          if (values.value && isNaN(Number(values.value))) {
            errors.value = 'Must be a number'
          }

          return errors
        }}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Project's Name *</label>
              <div className="control">
                <Field className="input" type="text" name="name" />
                <ErrorMessage className="error" name="name" component="div" />
              </div>
            </div>

            <div className={styles['client-wrapper']}>
              <div
                className={cx(
                  'field',
                  styles['field-container'],
                  styles['client-field'],
                )}
              >
                <label className="label">Select Clients</label>
                <div className="control is-expanded">
                  <Field
                    name="clients"
                    component={SelectField}
                    options={clients}
                    multiple
                  />
                  <ErrorMessage
                    className="error"
                    name="clients"
                    component="div"
                  />
                </div>
              </div>
              <button
                className={cx('button', 'is-info', styles['new-client-button'])}
                onClick={() => setOpenedModal(true)}
                type="button"
                data-test="create-client-button"
              >
                Create new client
              </button>
            </div>
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Select Engineers</label>
              <div className="control is-expanded">
                <Field
                  name="engineers"
                  component={SelectField}
                  options={engineers}
                  multiple
                />
                <ErrorMessage name="engineers" component="div" />
              </div>
            </div>
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Select Consultants</label>
              <div className="control is-expanded">
                <Field
                  name="consultants"
                  component={SelectField}
                  options={consultants}
                  multiple
                />
                <ErrorMessage
                  className="error"
                  name="consultants"
                  component="div"
                />
              </div>
            </div>
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Select Contractors</label>
              <div className="control is-expanded">
                <Field
                  name="contractors"
                  component={SelectField}
                  options={contractors}
                  multiple
                />
                <ErrorMessage
                  className="error"
                  name="contractors"
                  component="div"
                />
              </div>
            </div>
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Select Surveyors</label>
              <div className="control is-expanded">
                <Field
                  name="surveyors"
                  component={SelectField}
                  options={surveyors}
                  multiple
                />
                <ErrorMessage
                  className="error"
                  name="surveyors"
                  component="div"
                />
              </div>
            </div>
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Select Planners</label>
              <div className="control is-expanded">
                <Field
                  name="planners"
                  component={SelectField}
                  options={planners}
                  multiple
                />
                <ErrorMessage
                  className="error"
                  name="planners"
                  component="div"
                />
              </div>
            </div>
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Select Warrant Officers</label>
              <div className="control is-expanded">
                <Field
                  name="warrantOfficers"
                  component={SelectField}
                  options={warrantOfficers}
                  multiple
                />
                <ErrorMessage
                  className="error"
                  name="warrantOfficers"
                  component="div"
                />
              </div>
            </div>
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Select Building Control Officers</label>
              <div className="control is-expanded">
                <Field
                  name="buildingControlOfficers"
                  component={SelectField}
                  options={buildingControlOfficers}
                  multiple
                />
                <ErrorMessage
                  className="error"
                  name="buildingControlOfficers"
                  component="div"
                />
              </div>
            </div>
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Select Ecologists</label>
              <div className="control is-expanded">
                <Field
                  name="ecologists"
                  component={SelectField}
                  options={ecologists}
                  multiple
                />
                <ErrorMessage
                  className="error"
                  name="ecologists"
                  component="div"
                />
              </div>
            </div>
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Select CDM Principal Designers</label>
              <div className="control is-expanded">
                <Field
                  name="cdmPrincipalDesigners"
                  component={SelectField}
                  options={cdmPrincipalDesigners}
                  multiple
                />
                <ErrorMessage
                  className="error"
                  name="cdmPrincipalDesigners"
                  component="div"
                />
              </div>
            </div>
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Select BSA Principal Designers</label>
              <div className="control is-expanded">
                <Field
                  name="bsaPrincipalDesigners"
                  component={SelectField}
                  options={bsaPrincipalDesigners}
                  multiple
                />
                <ErrorMessage
                  className="error"
                  name="bsaPrincipalDesigners"
                  component="div"
                />
              </div>
            </div>
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Select Leads</label>
              <div className="control is-expanded">
                <Field
                  name="leads"
                  component={SelectField}
                  options={team}
                  multiple
                />
                <ErrorMessage className="error" name="leads" component="div" />
              </div>
            </div>
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Select Support Members</label>
              <div className="control is-expanded">
                <Field
                  name="supportMembers"
                  component={SelectField}
                  options={team}
                  multiple
                />
                <ErrorMessage
                  className="error"
                  name="supportMembers"
                  component="div"
                />
              </div>
            </div>
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Project Template *</label>
              <div className="control is-expanded">
                <Field
                  name="projectTemplateId"
                  component={SelectField}
                  options={projectTemplates}
                  disabled={editMode}
                />
                <ErrorMessage
                  className="error"
                  name="projectTemplateId"
                  component="div"
                />
              </div>
            </div>
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Email Template *</label>
              <div className="control is-expanded">
                <Field
                  name="emailTemplateId"
                  component={SelectField}
                  options={weeklyEmailTemplates}
                />
                <ErrorMessage
                  className="error"
                  name="emailTemplateId"
                  component="div"
                />
              </div>
            </div>
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Description</label>
              <div className="control">
                <Field className="textarea" as="textarea" name="description" />
                <ErrorMessage
                  className="error"
                  name="description"
                  component="div"
                />
              </div>
            </div>

            <div className={cx('field', styles['field-container'])}>
              <label className="label">Project Docs</label>
              <div className="control">
                <Field
                  className="input"
                  type="text"
                  name="meta.projectDocsUrl"
                />
                <ErrorMessage
                  className="error"
                  name="meta.projectDocsUrl"
                  component="div"
                />
              </div>
            </div>

            {userIsAdmin && (
              <div className={cx('field', styles['field-container'])}>
                <label className="label">Project Value (&pound;)</label>
                <div className="control">
                  <Field className="input" type="text" name="value" />
                  <ErrorMessage
                    className="error"
                    name="value"
                    component="div"
                  />
                </div>
              </div>
            )}

            <div className="level-left">
              <div className="level-left">
                <div className={cx('field', styles['field-container'])}>
                  <label className="label">Start Date *</label>
                  <div className="control">
                    <FormDatePicker name="startDate" top />
                    <ErrorMessage
                      className="error"
                      name="startDate"
                      component="div"
                    />
                  </div>
                </div>
              </div>
              <div className="level-right" style={{ marginLeft: '8rem' }}>
                <div className={cx('field', styles['field-container'])}>
                  <label className="label">Planning Determination</label>
                  <div className="control">
                    <FormDatePicker name="endDate" top />
                    <ErrorMessage
                      className="error"
                      name="endDate"
                      component="div"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <CreateClientFormModal
        open={openedModal}
        createUser={createUser}
        onClose={() => setOpenedModal(false)}
      />
    </CreateFormModal>
  )
}

export default ProjectFormView
