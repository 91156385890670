import { useState, useEffect } from 'react';
import cx from 'classnames'
import { Container, Draggable } from 'react-smooth-dnd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faCaretSquareDown,
  faArrowsAltH,
} from '@fortawesome/free-solid-svg-icons'

import styles from './PhasesForm.module.scss'

import ExpansionPanel from 'components/expansion-panel'
import TasksForm from '../TasksForm/TasksForm'
import PhaseDurationInput from 'routes/administration/templates-page/create-project-template-form/components/PhasesForm/components/PhaseDurationInput'
import DeleteModal from '../../../../../../../shared/project/project-phases/delete-modal'

import { reorder } from 'constants/utils'
import { defaultPhaseDuration } from 'constants/constants'

function PhasesForm({ field: formikField, form }) {
  const [phasesCache, setPhasesCache] = useState([
    {
      title: '',
      duration: defaultPhaseDuration,
      description: '',
      tasks: [createEmptyTask()],
    },
  ])

  const [selectedPhase, setSelectedPhase] = useState()
  const [selectedPhaseIndex, setSelectedPhaseIndex] = useState()

  useEffect(() => {
    if (formikField.value.length > 0) {
      setPhasesCache(formikField.value)
    }
  }, [formikField])

  function updatePhases(newPhases) {
    setPhasesCache(newPhases)
    form.setFieldValue(formikField.name, newPhases)
  }

  function onReorderPhases(phases) {
    return ({ removedIndex: from, addedIndex: to }) => {
      if (from === to) return

      const currentPhases = [...phases]

      const newPhases = reorder(currentPhases, from, to)

      return updatePhases(newPhases)
    }
  }

  function onAddPhase() {
    updatePhases([
      ...phasesCache,
      {
        title: `Phase ${phasesCache.length + 1}`,
        description: '',
        duration: defaultPhaseDuration,
        tasks: [
          {
            title: `Task 1`,
            notification: null,
            private: false,
          },
        ],
      },
    ])
  }

  function toggleModal(phase, phaseIndex) {
    setSelectedPhase(phase)
    setSelectedPhaseIndex(phaseIndex)
  }

  function closeModal() {
    setSelectedPhase(null)
    setSelectedPhaseIndex(null)
  }

  function onRemovePhase(phaseIndex) {
    updatePhases(phasesCache.filter((_, i) => i !== phaseIndex))
  }

  function onSplitPhase(phaseIndex) {
    updatePhases(splitPhases(phasesCache, phaseIndex))
  }

  function onPhaseUpdate(i, field, value) {
    const newPhases = [...phasesCache]
    const newPhase = { ...newPhases[i], [field]: value }
    newPhases[i] = newPhase

    updatePhases(newPhases)
  }

  return (
    <div>
      <button type="button" className="button is-info" onClick={onAddPhase}>
        Add phase
      </button>

      <Container
        onDrop={onReorderPhases(phasesCache)}
        nonDragAreaSelector=".no-drag"
      >
        {phasesCache.map((phase, i) => {
          return (
            <Draggable key={`form-phase-${i}`} style={{ overflow: 'visible' }}>
              <div>
                <ExpansionPanel
                  title={renderPhaseHeading(
                    phase,
                    toggleModal,
                    onSplitPhase,
                    i,
                  )}
                  className="no-outline"
                >
                  <div className="no-drag" style={{ padding: '14px' }}>
                    <div className="field">
                      <label className="label">Title*</label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          value={phasesCache[i].title}
                          onChange={e =>
                            onPhaseUpdate(i, 'title', e.target.value)
                          }
                        />
                      </div>
                    </div>

                    <PhaseDurationInput
                      value={phasesCache[i].duration}
                      setValue={input => onPhaseUpdate(i, 'duration', input)}
                    />

                    <div className="field">
                      <label className="label">Description</label>
                      <div className="control">
                        <textarea
                          style={{ width: '100%', boxSizing: 'border-box' }}
                          value={phasesCache[i].description}
                          onChange={e =>
                            onPhaseUpdate(i, 'description', e.target.value)
                          }
                        ></textarea>
                      </div>
                    </div>

                    <TasksForm
                      tasks={phasesCache[i].tasks}
                      onPhaseUpdate={onPhaseUpdate}
                      phaseIndex={i}
                    />
                  </div>
                </ExpansionPanel>
              </div>
            </Draggable>
          )
        })}
      </Container>
      {selectedPhase && (
        <DeleteModal
          title={selectedPhase.title}
          onClose={closeModal}
          deleteAction={() => onRemovePhase(selectedPhaseIndex)}
          elemType="phase"
          open={selectedPhase}
        />
      )}
    </div>
  )
}

function createEmptyTask() {
  return {
    title: '',
    notification: null,
    private: false,
  }
}

function splitPhases(phases, phaseIndex) {
  const phase = phases[phaseIndex]

  const tasksToSplit = phase.tasks
  const { id, title } = phase

  if (tasksToSplit.length === 1) {
    tasksToSplit.push(createEmptyTask())
  }

  const newPhases = [
    {
      ...phase,
      id,
      title: title && `${title} (1)`,
      tasks: tasksToSplit.slice(0, -1),
    },
    {
      ...phase,
      id: undefined,
      title: title && `${title} (2)`,
      tasks: tasksToSplit.slice(-1),
    },
  ]

  return [
    ...phases.slice(0, phaseIndex),
    ...newPhases,
    ...phases.slice(phaseIndex + 1),
  ]
}

function renderPhaseHeading(phase, toggleModal, onSplitPhase, i) {
  return (
    <div className={cx(styles['wrapper'], 'level')}>
      <div className={cx(styles['container'], 'level-left')}>
        <div className={cx(styles['icons'], 'level-item')}>
          <button
            type="button"
            className="button is-small is-light"
            onClick={event => {
              event.stopPropagation()
              toggleModal(phase, i)
            }}
            style={{ marginRight: '12px' }}
          >
            <span className="icon is-medium">
              <FontAwesomeIcon icon={faTrash} size="lg" />
            </span>
          </button>

          <button
            type="button"
            className="button is-small is-light"
            onClick={() => onSplitPhase(i)}
          >
            <span className="icon is-medium">
              <FontAwesomeIcon icon={faArrowsAltH} size="lg" />
            </span>
          </button>
        </div>
        <div>{phase.title || `Phase ${i + 1}`}</div>
      </div>

      <div className={cx(styles['drop-down-icon'], 'level-right')}>
        <div className="level-item" style={{ marginLeft: '14px' }}>
          <FontAwesomeIcon icon={faCaretSquareDown} size="2x" />
        </div>
      </div>
    </div>
  )
}

export default PhasesForm
