import { useState, useEffect } from 'react';

import { useApolloClient } from '@apollo/react-hooks'

import { GET_EMAIL_TEMPLATES } from '../../../../../../graphql/queries'

import SmartNotificationsView from './smart-notifications-view'

function SmartNotificationsContainer(props) {
  const client = useApolloClient()

  const [emailTemplates, setEmailTemplates] = useState([])

  useEffect(() => {
    if (!props.open) return

    client
      .query({
        query: GET_EMAIL_TEMPLATES,
      })
      .then(emailTemplatesResponse => {
        setEmailTemplates(emailTemplatesResponse.data.emailTemplates)
      })
    // eslint-disable-next-line
  }, [props.open])

  const emailTemplatesOptions = emailTemplates
    .filter(template => template.type === 'TASK')
    .map(template => ({ value: template.id, label: template.name }))

  const initialValues = props.currentConfiguration
    ? {
        engineers: props.currentConfiguration.recipients.includes('engineers'),
        consultants:
          props.currentConfiguration.recipients.includes('consultants'),
        surveyors: props.currentConfiguration.recipients.includes('surveyors'),
        client: props.currentConfiguration.recipients.includes('client'),
        emailTemplateId: {
          value:
            props.currentConfiguration.emailTemplate?.id ??
            props.currentConfiguration.emailTemplateId,
          label:
            props.currentConfiguration.emailTemplate?.name ??
            emailTemplatesOptions.find(o => {
              return (
                o.value ===
                (props.currentConfiguration.emailTemplate?.id ??
                  props.currentConfiguration.emailTemplateId)
              )
            })?.label,
        },
        extraRecipients: props.currentConfiguration.extraRecipients,
      }
    : {
        engineers: false,
        consultants: false,
        surveyors: false,
        client: false,
        emailTemplateId: '',
        extraRecipients: '',
      }

  return (
    <SmartNotificationsView
      {...props}
      emailTemplatesOptions={emailTemplatesOptions}
      initialValues={initialValues}
    />
  )
}

export default SmartNotificationsContainer
