import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks'

import { GET_PROJECT_ONLY_PHASES } from 'graphql/queries'
import AddHoursFormView from './add-hours-form-view'

function AddHoursFormContainer(props) {
  const [fetchProjectList, { data: projectsData }] = useLazyQuery(
    GET_PROJECT_ONLY_PHASES,
  )

  useEffect(() => {
    if (open) {
      fetchProjectList()
    }
    // eslint-disable-next-line
  }, [open])

  const projectList = projectsData?.projects

  return (
    <AddHoursFormView
      open={props.open}
      onClose={props.onClose}
      onAddHours={props.onAddRecordToTimesheet}
      onDeleteRecord={props.onDeleteRecord}
      selectedRecord={props.selectedRecord}
      projectList={projectList}
    />
  )
}

export default AddHoursFormContainer
