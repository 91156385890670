import cx from 'classnames'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import styles from './expansion-panel.module.scss'

import 'react-accessible-accordion/dist/fancy-example.css'

function ExpansionPanel({
  title,
  children,
  className,
  preExpanded,
  uuid,
  onChange,
}) {
  return (
    <Accordion
      allowZeroExpanded
      preExpanded={preExpanded}
      className={styles['accordion-root']}
      onChange={onChange}
    >
      <AccordionItem uuid={uuid}>
        <AccordionItemHeading>
          <AccordionItemButton
            className={cx(className, styles['accordion-item-button'])}
          >
            {title}
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className={styles['accordion-item-panel']}>
          {children}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default ExpansionPanel
