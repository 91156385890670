import { useField } from 'formik'

import DatePicker from '../datepicker'

const FormDatePickerView = ({
  top,
  className,
  containerClassName,
  minDate,
  maxDate,
  name,
}) => {
  const [field, , helpers] = useField(name)
  return (
    <DatePicker
      onChange={helpers.setValue}
      value={field.value}
      top={top}
      className={className}
      containerClassName={containerClassName}
      minDate={minDate}
      maxDate={maxDate}
    />
  )
}

export default FormDatePickerView
