import { useMutation } from '@apollo/react-hooks'
import { showNotification } from '../../../constants/utils'

import { GET_COMPANY_SETTINGS } from '../../../graphql/queries'
import { CREATE_CARD_CATEGORY } from '../../../graphql/mutations'

const makeMutationOptions = category => {
  return {
    variables: { category },
    refetchQueries: [
      {
        query: GET_COMPANY_SETTINGS,
      },
    ],
  }
}

export const useConfigureCreateCardCategoryMutation = () => {
  const [createCardCategory] = useMutation(CREATE_CARD_CATEGORY)

  return newConfig => {
    const options = makeMutationOptions(newConfig)

    return createCardCategory(options)
      .then(res => {
        const {
          data: {
            createCardCategory: { success, error = '' },
          },
        } = res

        if (!success)
          showNotification(
            error || "It's not possible to create the category",
            'is-danger',
          )
      })
      .catch(error => showNotification(error.message, 'is-danger'))
  }
}
