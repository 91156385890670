import { useHistory } from 'react-router-dom'

import styles from './not-found.module.scss'

export const NotFound = () => {
  let history = useHistory()

  return (
    <div className={styles['wrapper']}>
      <h1>404 Not Found</h1>
      <h2>Ups... seems like the page you were looking for doesn't exist :(</h2>
      <button className="button is-info" onClick={() => history.push('/')}>
        Take me Home
      </button>
    </div>
  )
}

export default NotFound
