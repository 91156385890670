import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileAlt,
  faCalendarPlus,
  faCalendarCheck,
  faBell,
} from '@fortawesome/free-solid-svg-icons'

import styles from './statistics.module.scss'

function StatisticsView({ statistics }) {
  return (
    <div>
      <div className={styles['statistic-container']}>
        <div
          className={`${styles['statistic-icon-green']} ${styles['statistic-icon']}`}
        >
          <FontAwesomeIcon icon={faFileAlt} size="lg" />
        </div>

        <div className="statistic-text">
          <div>Live projects</div>
          <div className={styles['statistic-number']}>
            {statistics.currentLiveProjects}
          </div>
        </div>
      </div>

      <div className={styles['statistic-container']}>
        <div
          className={`${styles['statistic-icon-orange']} ${styles['statistic-icon']}`}
        >
          <FontAwesomeIcon icon={faFileAlt} size="lg" />
        </div>

        <div className="statistic-text">
          <div>On hold projects</div>
          <div className={styles['statistic-number']}>
            {statistics.onHoldProjects}
          </div>
        </div>
      </div>

      <div className={styles['statistic-container']}>
        <div
          className={`${styles['statistic-icon-green']} ${styles['statistic-icon']}`}
        >
          <FontAwesomeIcon icon={faCalendarPlus} size="lg" />
        </div>

        <div className="statistic-text">
          <div>New projects this month</div>
          <div
            className={styles['statistic-number']}
            data-test="new-projects-counter"
          >
            {statistics.thisMonthNewProjects}
          </div>
        </div>
      </div>

      <div className={styles['statistic-container']}>
        <div
          className={`${styles['statistic-icon-green']} ${styles['statistic-icon']}`}
        >
          <FontAwesomeIcon icon={faCalendarCheck} size="lg" />
        </div>

        <div className="statistic-text">
          <div>Finished projects this month</div>
          <div
            className={styles['statistic-number']}
            data-test="finished-this-month-counter"
          >
            {statistics.thisMonthFinishedProjects}
          </div>
        </div>
      </div>

      <div className={styles['statistic-container']}>
        <div
          className={`${styles['statistic-icon-red']} ${styles['statistic-icon']}`}
        >
          <FontAwesomeIcon icon={faBell} size="lg" />
        </div>

        <div className="statistic-text">
          <div>Touch point required</div>
          <div className={styles['statistic-number']}>
            {statistics.actionRequired}
          </div>
        </div>
      </div>

      <div className={styles['statistic-container']}>
        <div
          className={`${styles['statistic-icon-orange']} ${styles['statistic-icon']}`}
        >
          <FontAwesomeIcon icon={faBell} size="lg" />
        </div>

        <div className="statistic-text">
          <div>Snoozed projects today</div>
          <div className={styles['statistic-number']}>
            {statistics.snoozedProjectsToday}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatisticsView
