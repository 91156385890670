import { useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { addDays, subDays } from 'date-fns'

import { GET_TASK_LIST, GET_TEAM } from 'graphql/queries'
import { UPDATE_CARD_BY_ID } from 'graphql/mutations'
import { toast } from 'bulma-toast'
import { calculateHighlights } from '../utils/calculateHighlights'
import calculateUserDates from './utils/calculateUserDates'

export default function useCardsTimelineData() {
  const { data, loading: loadingCards } = useQuery(GET_TASK_LIST)
  const { data: teamMembersData, loading: loadingTeamMembers } =
    useQuery(GET_TEAM)

  const loading = loadingCards || loadingTeamMembers
  const { team: teamMembers } = teamMembersData ?? {}

  const [openedUserIds, setOpenedUserIds] = useState([])

  function handleExpanderClick(userId) {
    if (openedUserIds.includes(userId)) {
      setOpenedUserIds(openedUserIds.filter(id => id !== userId))
    } else {
      setOpenedUserIds([...openedUserIds, userId])
    }
  }

  const { tasks } = data ?? {}

  // TODO
  // Refactor this using a useReducer to better handle the expansion
  // of a single user instead of iterating ovear all tasks each time
  const rows = useMemo(() => {
    let mappedRows = []

    teamMembers?.forEach(teamMember => {
      // Get only tasks belonging to this user
      const userTasks =
        tasks
          ?.filter(t => t.assignee?.id === teamMember.id)
          ?.filter(t => t.status !== 'Done')
          .map(t => ({
            ...t,
            estimatedDurationDateRange: {
              estimatedStartingDate: t.estimatedDurationDateRange
                ?.estimatedStartingDate
                ? new Date(t.estimatedDurationDateRange?.estimatedStartingDate)
                : new Date(),
              estimatedCompletionDate: t.estimatedDurationDateRange
                ?.estimatedCompletionDate
                ? new Date(
                    t.estimatedDurationDateRange?.estimatedCompletionDate,
                  )
                : addDays(new Date(), 1),
            },
          }))
          .sort((a, b) =>
            a.estimatedDurationDateRange?.estimatedStartingDate >
            b.estimatedDurationDateRange?.estimatedStartingDate
              ? 1
              : -1,
          ) ?? []

      const tasksForHighlights = userTasks.filter(
        t => t.estimatedDurationDateRange.estimatedEndingDate > new Date(),
      )

      const highlights = calculateHighlights({ tasks: tasksForHighlights })

      // Calculate start and end date of user
      const { start, end } = calculateUserDates(userTasks)

      mappedRows.push({
        id: teamMember.id,
        name: `${teamMember.firstName} ${teamMember.lastName}`,
        start,
        end,
        hideChildren: !openedUserIds.includes(teamMember.id),
        type: 'project',
        progress: 0,
        highlights,
      })

      userTasks.forEach(userTask => {
        const { id, title, project, estimatedDurationDateRange } = userTask
        const { estimatedStartingDate, estimatedCompletionDate } =
          estimatedDurationDateRange ?? {}

        // If start or due date are missing set the other one,
        // if both are missing then just set today as the date
        let taskStart = estimatedStartingDate
          ? new Date(estimatedStartingDate)
          : estimatedCompletionDate
          ? subDays(new Date(estimatedCompletionDate), 1)
          : subDays(new Date(), 1)

        let taskEnd = estimatedCompletionDate
          ? new Date(estimatedCompletionDate)
          : estimatedStartingDate
          ? addDays(new Date(estimatedStartingDate), 1)
          : addDays(new Date(), 1)

        // Should not have dates in the past
        if (taskStart < new Date()) {
          taskStart = new Date()

          if (taskEnd < new Date()) taskEnd = new Date()
        }

        const highlights = []

        const { dueDate } = userTask

        const dueDateHighlight =
          !!dueDate &&
          (new Date(dueDate) < taskStart
            ? {
                start: new Date(),
                end: new Date(),
                color: 'red',
              }
            : {
                start: new Date(dueDate),
                end: new Date(dueDate),
                color: 'red',
              })

        if (dueDateHighlight) highlights.push(dueDateHighlight)

        mappedRows.push({
          id,
          name: `${title} ${project?.name ? ` - ${project?.name}` : ''}`,
          start: taskStart,
          end: taskEnd,
          type: 'task',
          progress: 0,
          dependencies: [],
          project: teamMember.id,
          highlights,
        })
      })
    })

    return mappedRows
  }, [openedUserIds, tasks, teamMembers])

  const [updateCard] = useMutation(UPDATE_CARD_BY_ID)

  const handleTaskChange = async ({ id, name, start, end }) => {
    const task = tasks.find(t => t.id === id)

    const { dueDate } = task

    if (!!dueDate && new Date(dueDate) < end) {
      toast({
        message: `Cannot set end date after the due date`,
        type: 'is-danger',
        dismissible: true,
        position: 'bottom-right',
        duration: 3000,
        closeOnClick: true,
      })

      return null
    }

    try {
      return await updateCard({
        variables: {
          id,
          input: {
            estimatedDurationDateRange: {
              estimatedStartingDate: start,
              estimatedCompletionDate: end,
            },
          },
        },
        optimisticResponse: {
          typeName: 'Mutation',
          updateTaskById: {
            successful: true,
            task: {
              ...task,
              estimatedDurationDateRange: {
                estimatedStartingDate: start,
                estimatedCompletionDate: end,
              },
              __typename: 'Task',
            },
            __typename: 'UpdateTaskResponse',
          },
        },
      })
    } catch {
      toast({
        message: `Could not update card ${name}`,
        type: 'is-danger',
        dismissible: true,
        position: 'bottom-right',
        duration: 3000,
        closeOnClick: true,
      })
    }
  }

  return { rows, loading, handleExpanderClick, handleTaskChange }
}
