import sortBy from 'lodash.sortby'

export function calculateHighlights({ tasks = [] }) {
  let highlights = []

  if (tasks.length <= 1) return highlights

  const newTasks = [...tasks]
  const sortedByStartPhases = sortBy(
    newTasks,
    task => task.estimatedDurationDateRange.estimatedStartingDate,
  )

  const allStartTimestamps = sortedByStartPhases.map(task =>
    task.estimatedDurationDateRange.estimatedStartingDate.getTime(),
  )

  const allEndTimestamps = sortedByStartPhases.map(task =>
    task.estimatedDurationDateRange.estimatedCompletionDate.getTime(),
  )

  tasks.forEach(task => {
    const phaseEndTimestamp =
      task.estimatedDurationDateRange.estimatedCompletionDate.getTime()

    // Find closest next task start
    const allFutureStartTimestamps = allStartTimestamps.filter(
      timestamp => timestamp >= phaseEndTimestamp,
    )

    const nextStartingTimestamp = allFutureStartTimestamps[0]

    if (!nextStartingTimestamp || nextStartingTimestamp === phaseEndTimestamp)
      return

    // Check if the task is under another task that lasts longer (see example)
    // 1: ========================================
    // 2: =========
    // 3:                         ===============
    //
    // We want to avoid marking a break between task 2 and 3

    const isPhaseUnderLongerPhase = tasks.some(p => {
      return (
        p.estimatedDurationDateRange.estimatedStartingDate.getTime() <
          phaseEndTimestamp &&
        p.estimatedDurationDateRange.estimatedCompletionDate.getTime() >
          phaseEndTimestamp
      )
    })

    if (isPhaseUnderLongerPhase) return

    const newHighlight = {
      start: task.estimatedDurationDateRange.estimatedCompletionDate,
      end: new Date(nextStartingTimestamp),
      color: 'white',
    }

    // If there's already an identical highlight don't add another one
    if (
      highlights.some(
        h =>
          h.start.toISOString() === newHighlight.start.toISOString() &&
          h.end.toISOString() === newHighlight.end.toISOString(),
      )
    )
      return

    // Check that there's no other task in the range of the highlight
    const phasesEndingInHighlightRange = allEndTimestamps.filter(t => {
      return t > newHighlight.start && t <= newHighlight.end
    })

    if (phasesEndingInHighlightRange.length) return

    highlights = [...highlights, newHighlight]
  })

  return highlights
}
