import { useMutation } from '@apollo/react-hooks'

import { showNotification } from 'constants/utils'
import hooks from '../../../../hooks'

import { COMPLETE_PROJECT_TASK, SNOOZE_PROJECT_BY_ID } from 'graphql/mutations'
import { GET_PROJECT_DETAILS } from 'graphql/queries'

import ProjectDescriptionUsers from './components/ProjectDescriptionUsers'
import ProjectDescriptionDeterminationDate from './project-description-determination-date'
import ProjectDescriptionDocs from './project-description-docs'
import ProjectDescriptionField from './project-description-field'
import ProjectDescriptionStatus from './project-description-status'
import ProjectProgress from './project-progress'
// import ProjectLog from './project-log'
import { clientsAndPartnersFilterOptions } from '../../../../constants/constants'
import NextTaskForm from './next-task-form'

function generateStatistics(phases) {
  const statsPerPhase = phases.map(phase => {
    const numberOfTasks = phase.tasks.length
    const numberOfCompletedTasks = phase.tasks.filter(
      task => task.completed,
    ).length

    return {
      numberOfTasks,
      numberOfCompletedTasks,
      completed: numberOfTasks === numberOfCompletedTasks,
    }
  })

  const numberOfCompletedPhases = statsPerPhase.filter(
    stat => stat.completed,
  ).length
  const numberOfPhases = phases.length
  const totalNumberOfCompletedTasks = statsPerPhase.reduce(
    (acc, stat) => acc + stat.numberOfCompletedTasks,
    0,
  )
  const totalNumberOfTasks = statsPerPhase.reduce(
    (acc, stat) => acc + stat.numberOfTasks,
    0,
  )
  const progress =
    Math.floor((totalNumberOfCompletedTasks / totalNumberOfTasks) * 100) || 0

  return {
    numberOfCompletedPhases,
    numberOfPhases,
    totalNumberOfCompletedTasks,
    totalNumberOfTasks,
    progress,
  }
}

function ProjectDescription({
  project,
  clientView = false,
  readOnly = false,
  onUpdate,
}) {
  const statisticsData = generateStatistics(project.phases)

  const auth = hooks.useAuth()

  const [completeProjectTaskMutation] = useMutation(COMPLETE_PROJECT_TASK)
  const [snoozeProjectById] = useMutation(SNOOZE_PROJECT_BY_ID)

  async function completeProjectTask() {
    await completeProjectTaskMutation({
      variables: {
        taskId: project.nextTaskToComplete.id,
      },
      refetchQueries: [
        {
          query: GET_PROJECT_DETAILS,
          variables: {
            id: project.id,
          },
        },
      ],
    })

    showNotification(
      `Task "${project.nextTaskToComplete.title}" completed`,
      'is-success',
    )
  }

  async function snoozeProject(period) {
    await snoozeProjectById({
      variables: { id: project.id, period },
    })

    showNotification(
      `Project snoozed correctly for ${period.toLowerCase()}`,
      'is-success',
    )
  }

  const leads = project?.assignees
    ?.filter(assignee => assignee.role === 'lead')
    .map(assignee => assignee.teamMember)

  const supportMembers = project?.assignees
    ?.filter(assignee => assignee.role === 'supportMember')
    .map(assignee => assignee.teamMember)

  return (
    <div data-test="project-description-container">
      <ProjectProgress
        selectedProject={project}
        statisticsData={statisticsData}
      />

      <ProjectDescriptionDocs projectDocsUrl={project.meta?.projectDocsUrl} />

      <ProjectDescriptionUsers type="Leads" readOnly={readOnly} users={leads} />
      <ProjectDescriptionUsers
        type="Support Members"
        readOnly={readOnly}
        users={supportMembers}
      />

      {clientsAndPartnersFilterOptions.map(p => (
        <ProjectDescriptionUsers
          key={p.value}
          type={p.pluralLabel}
          readOnly={readOnly}
          users={project[p.pluralValue]}
          redactPhoneNumber={clientView && p.value !== 'client'}
        />
      ))}

      <ProjectDescriptionStatus
        isAnonymous={auth.isAnonymous}
        readOnly={readOnly}
        value={project.status}
        onUpdate={onUpdate}
        title="Project Activity"
        fieldName="status"
      />
      {!readOnly && (
        <ProjectDescriptionStatus
          isAnonymous={auth.isAnonymous}
          readOnly={readOnly}
          value={project.privateStatus}
          onUpdate={onUpdate}
          title="Private Notes"
          fieldName="privateStatus"
        />
      )}

      {!readOnly && project.nextTaskToComplete && (
        <NextTaskForm
          nextTaskToComplete={project.nextTaskToComplete}
          onTaskCompleted={completeProjectTask}
          onProjectSnoozed={snoozeProject}
        />
      )}

      <ProjectDescriptionDeterminationDate
        isAnonymous={auth.isAnonymous}
        readOnly={readOnly}
        value={project.endDate}
        onUpdate={onUpdate}
      />

      <ProjectDescriptionField
        isAnonymous={auth.isAnonymous}
        readOnly={readOnly}
        value={project.description}
        onUpdate={onUpdate}
      />
    </div>
  )
}

export default ProjectDescription
