import { useHistory } from 'react-router-dom'

import { useQuery, useMutation } from '@apollo/react-hooks'

import { GET_ALL_USERS } from 'graphql/queries'
import { IMPERSONATE_USER } from 'graphql/mutations'

import hooks from 'hooks'

import Loader from 'components/loader'
import AllUsersView from './support-view'

export default function AllUsersContainer() {
  const auth = hooks.useAuth()
  const history = useHistory()

  const { data: allUsers, loading: fetchingAllUsers } = useQuery(
    GET_ALL_USERS,
    { fetchPolicy: 'network-only' },
  )

  const [impersonateUserMutation] = useMutation(IMPERSONATE_USER)

  function impersonateUser(user) {
    impersonateUserMutation({
      variables: { impersonateEmail: user.email },
    }).then(res => {
      const newSession = {
        ...res.data.impersonate.authenticatedUser,
        ...{ impersonated: true },
      }
      let user = JSON.parse(window.localStorage.getItem('session'))
      window.localStorage.setItem('old-session', JSON.stringify(user))
      window.localStorage.setItem('session', JSON.stringify(newSession))
      auth.setNewUser(newSession)
      history.push('/')
      location.reload()
    })
  }

  if (fetchingAllUsers) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  return (
    <AllUsersView
      users={allUsers.allCompaniesUsers}
      impersonateUser={impersonateUser}
    />
  )
}
