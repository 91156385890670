import { useMutation } from '@apollo/react-hooks'
import {
  faArrowsAltH,
  faCaretSquareDown,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import { useState } from 'react'
import Select from 'react-select'
import { Container, Draggable } from 'react-smooth-dnd'
import { v4 as uuid } from 'uuid'

import styles from './PhasesForm.module.scss'

import ExpansionPanel from '../../../../../../components/expansion-panel'
import DeleteModal from '../../../../../shared/project/project-phases/delete-modal'
import TasksForm from '../TasksForm/TasksForm'

import { toast as notify } from 'bulma-toast'
import { defaultPhaseDuration } from 'constants/constants'
import {
  CREATE_PROJECT_TEMPLATE_PHASE,
  CREATE_PROJECT_TEMPLATE_PHASE_TASK,
  DELETE_PROJECT_TEMPLATE_PHASE,
  DELETE_PROJECT_TEMPLATE_PHASE_TASK_BY_ID,
  MOVE_PROJECT_TEMPLATE_PHASE_BY_ID,
  UPDATE_PROJECT_TEMPLATE_PHASE_BY_ID,
  UPDATE_PROJECT_TEMPLATE_PHASE_TASK_BY_ID,
} from 'graphql/mutations'
import { useSubscription } from 'hooks/use-subscription'
import PhaseDurationInput from 'routes/administration/templates-page/create-project-template-form/components/PhasesForm/components/PhaseDurationInput'
import { reorder } from '../../../../../../constants/utils'

function createEmptyTask() {
  return {
    title: 'Task',
    notification: null,
    private: false,
  }
}

function renderPhaseHeading(phase, toggleModal, onSplitPhase, i) {
  return (
    <div className={cx(styles['wrapper'], 'level')}>
      <div className={cx(styles['container'], 'level-left')}>
        <div className={cx(styles['icons'], 'level-item')}>
          <button
            type="button"
            className="button is-small is-light"
            onClick={() => toggleModal(phase, i)}
          >
            <span className="icon is-medium">
              <FontAwesomeIcon icon={faTrash} size="lg" />
            </span>
          </button>

          <button
            type="button"
            className="button is-small is-light"
            onClick={() => onSplitPhase(i)}
          >
            <span className="icon is-medium">
              <FontAwesomeIcon icon={faArrowsAltH} size="lg" />
            </span>
          </button>
        </div>
        <div>{phase.title || `Phase ${i + 1}`}</div>
      </div>

      <div className={cx(styles['drop-down-icon'], 'level-right')}>
        <div className="level-item" style={{ marginLeft: '14px' }}>
          <FontAwesomeIcon icon={faCaretSquareDown} size="2x" />
        </div>
      </div>
    </div>
  )
}

function PhasesForm({ field: formikField, form }) {
  const { canUseTimeline } = useSubscription()

  const isEditing = !!form.values.id

  const phasesCache = formikField.value

  const dependsOnOptions = phasesCache?.map(phase => {
    return {
      label: phase.title,
      value: phase.id,
    }
  })

  // Should not be able to chose itself as 'dependsOn'
  const getPhaseDependsOnOptions = phase => {
    const phaseDependsOnOptions = dependsOnOptions
      .filter(o => o.value !== phase.id)
      .filter(o => {
        const fullPhaseFromOption = phasesCache.find(p => p.id === o.value)

        return !fullPhaseFromOption?.dependsOn !== phase.id
      })

    return phaseDependsOnOptions
  }

  const [selectedPhase, setSelectedPhase] = useState()

  function updatePhases(newPhases) {
    form.setFieldValue(formikField.name, newPhases)
  }

  const [movePhase] = useMutation(MOVE_PROJECT_TEMPLATE_PHASE_BY_ID)

  function onReorderPhases(phases) {
    return async ({ removedIndex: from, addedIndex: to }) => {
      if (from === to) return

      if (isEditing) {
        const movedPhase = phasesCache[from]
        const { data } = await movePhase({
          variables: { id: movedPhase.id, newPosition: to },
        })

        if (data.moveProjectTemplatePhaseById.successful) {
          const currentPhases = [...phases]

          const newPhases = reorder(currentPhases, from, to)

          return updatePhases(newPhases)
        }
      } else {
        const currentPhases = [...phases]

        const newPhases = reorder(currentPhases, from, to)

        return updatePhases(newPhases)
      }
    }
  }

  const [addPhase] = useMutation(CREATE_PROJECT_TEMPLATE_PHASE)

  async function onAddPhase() {
    if (isEditing) {
      const { data } = await addPhase({
        variables: {
          input: {
            title: `Phase ${phasesCache.length + 1}`,
            description: '',
            duration: defaultPhaseDuration,
            dependsOn: null,
          },
          projectTemplateId: form.values.id,
        },
      })

      const { projectTemplatePhase: newPhase } =
        data?.createProjectTemplatePhase ?? {}

      newPhase && updatePhases([...phasesCache, newPhase])
    } else {
      updatePhases([
        ...phasesCache,
        {
          id: uuid(),
          title: `Phase ${phasesCache.length + 1}`,
          description: '',
          duration: defaultPhaseDuration,
          dependsOn: null,
          tasks: [
            {
              title: 'Task 1',
              notification: null,
              private: false,
            },
          ],
        },
      ])
    }
  }

  function toggleModal(phase) {
    setSelectedPhase(phase)
  }

  function closeModal() {
    setSelectedPhase(null)
  }

  const [deletePhase] = useMutation(DELETE_PROJECT_TEMPLATE_PHASE)

  async function onRemovePhase(phaseToDelete) {
    const phaseIndex = phasesCache.findIndex(p => p.id === phaseToDelete.id)
    if (isEditing) {
      try {
        const { data } = await deletePhase({
          variables: { id: phasesCache[phaseIndex].id },
        })

        if (data.deleteProjectTemplatePhaseById.successful)
          updatePhases(phasesCache.filter((_, i) => i !== phaseIndex))
      } catch (e) {
        notify({
          message: e.message,
          type: 'is-danger',
          dismissible: true,
          position: 'bottom-right',
          duration: 3000,
          closeOnClick: true,
        })
      }
    } else {
      updatePhases(phasesCache.filter((_, i) => i !== phaseIndex))
    }
  }

  const [addPhaseTask] = useMutation(CREATE_PROJECT_TEMPLATE_PHASE_TASK)

  function splitPhases(phases, phaseIndex, isEditing) {
    const phase = phases[phaseIndex]

    let tasksToSplit = [...phase.tasks]
    const { id, title } = phase

    if (tasksToSplit.length === 1) {
      tasksToSplit.push(createEmptyTask())
    }

    const newPhaseId = uuid()

    const newPhases = [
      {
        ...phase,
        id,
        title: title && `${title} (1)`,
        tasks: tasksToSplit.slice(0, -1),
      },
      {
        ...phase,
        id: newPhaseId,
        title: title && `${title} (2)`,
        tasks: tasksToSplit.slice(-1),
      },
    ]

    if (isEditing) {
      updatePhasesAfterSplit(newPhases)
    }

    return [
      ...phases.slice(0, phaseIndex),
      ...newPhases,
      ...phases.slice(phaseIndex + 1),
    ]
  }

  const [deleteTask] = useMutation(DELETE_PROJECT_TEMPLATE_PHASE_TASK_BY_ID)
  const [updateTask] = useMutation(UPDATE_PROJECT_TEMPLATE_PHASE_TASK_BY_ID)

  async function updatePhasesAfterSplit(newPhases) {
    if (isEditing) {
      const { tasks: newPhaseTasks, ...newPhase } = newPhases[1]
      // eslint-disable-next-line no-unused-vars
      const { tasks: originalPhaseTasks, ...originalPhase } = newPhases[0]

      await addPhase({
        variables: {
          input: newPhase,
          projectTemplateId: form.values.id,
        },
      })

      // Update title of first phase

      await updatePhase({
        variables: {
          id: originalPhase.id,
          input: { title: originalPhase.title },
        },
      })

      let tasksToRemoveIds = []
      let taskNotificationSettings = {}

      const tasksToCreate = newPhaseTasks.map(({ id, notification, ...t }) => {
        if (id) {
          tasksToRemoveIds.push(id)
        }

        const newId = uuid()

        if (notification) {
          taskNotificationSettings[newId] = notification
        }

        return { id: newId, ...t }
      })

      // Remove second half of tasks from original phase
      const removeTasksPromises = tasksToRemoveIds.map(id =>
        deleteTask({
          variables: {
            id,
          },
        }),
      )

      let addPhaseTasksPromises = []

      tasksToCreate?.forEach(t => {
        const addTaskPromise = addPhaseTask({
          variables: {
            input: t,
            phaseId: newPhase.id,
          },
        })

        addPhaseTasksPromises.push(addTaskPromise)
      })

      const createdTasks = await Promise.all(addPhaseTasksPromises)

      const updateTasksNotificationsPromises = createdTasks.map(t => {
        const { id } =
          t.data.createProjectTemplatePhaseTask.projectTemplatePhaseTask

        const notification = taskNotificationSettings[id]

        if (!notification) return

        const { emailTemplate, ...notificationSettings } = notification

        return updateTask({
          variables: {
            id,
            input: {
              notification: {
                emailTemplateId: emailTemplate.id,
                ...notificationSettings,
              },
            },
          },
        })
      })

      await Promise.all(updateTasksNotificationsPromises)
      await Promise.all(removeTasksPromises)
    }
  }

  function onSplitPhase(phaseIndex) {
    updatePhases(splitPhases(phasesCache, phaseIndex, isEditing))
  }

  const [updatePhase] = useMutation(UPDATE_PROJECT_TEMPLATE_PHASE_BY_ID)

  async function onPhaseUpdate(i, field, value) {
    const newPhases = [...phasesCache]

    newPhases[i][field] = value

    updatePhases(newPhases)

    if (isEditing) {
      setTimeout(async () => {
        // eslint-disable-next-line no-unused-vars
        const { tasks, id, ...newPhase } = {
          ...newPhases[i],
          [field]: value,
        }

        const { __typename, ...phaseToSave } = newPhase

        try {
          await updatePhase({
            variables: {
              id,
              input: phaseToSave,
            },
          })
        } catch (e) {
          notify({
            message: 'Could not set phase dependency',
            type: 'is-danger',
            dismissible: true,
            position: 'bottom-right',
            duration: 3000,
            closeOnClick: true,
          })
        }
      }, 1000)
    }
  }

  function onTasksUpdated({ phaseIndex, newTasks }) {
    onPhaseUpdate(phaseIndex, 'tasks', newTasks)
  }

  return (
    <div>
      <button type="button" className="button is-info" onClick={onAddPhase}>
        Add phase
      </button>

      <Container
        onDrop={onReorderPhases(phasesCache)}
        nonDragAreaSelector=".no-drag"
      >
        {phasesCache.map((phase, i) => {
          return (
            <Draggable key={`form-phase-${i}`} style={{ overflow: 'visible' }}>
              <div>
                <ExpansionPanel
                  title={renderPhaseHeading(
                    phase,
                    toggleModal,
                    onSplitPhase,
                    i,
                  )}
                  className="no-outline"
                >
                  <div className="no-drag" style={{ padding: '14px' }}>
                    <div className="field">
                      <label className="label">Title*</label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          value={phasesCache[i].title}
                          onChange={e =>
                            onPhaseUpdate(i, 'title', e.target.value)
                          }
                        />
                      </div>
                    </div>

                    {canUseTimeline() && (
                      <PhaseDurationInput
                        value={phasesCache[i].duration}
                        setValue={input => onPhaseUpdate(i, 'duration', input)}
                      />
                    )}

                    {canUseTimeline() && (
                      <div className="field">
                        <label className="label">Depends on</label>
                        <div className="control">
                          <Select
                            defaultValue={{ label: 'Select...', value: null }}
                            options={[
                              { label: 'Select...', value: null },
                              ...getPhaseDependsOnOptions(phase),
                            ]}
                            onChange={o =>
                              onPhaseUpdate(i, 'dependsOn', o.value)
                            }
                            value={dependsOnOptions.find(
                              o => o.value === phasesCache[i].dependsOn,
                            )}
                          />
                        </div>
                      </div>
                    )}

                    <div className="field">
                      <label className="label">Description</label>
                      <div className="control">
                        <textarea
                          style={{ width: '100%', boxSizing: 'border-box' }}
                          value={phasesCache[i].description}
                          onChange={e =>
                            onPhaseUpdate(i, 'description', e.target.value)
                          }
                        ></textarea>
                      </div>
                    </div>

                    <TasksForm
                      tasks={phasesCache[i].tasks}
                      onTasksUpdated={newTasks =>
                        onTasksUpdated({ phaseIndex: i, newTasks })
                      }
                      isEditing={isEditing}
                      phaseId={phasesCache[i].id}
                    />
                  </div>
                </ExpansionPanel>
              </div>
            </Draggable>
          )
        })}
      </Container>
      {selectedPhase && (
        <DeleteModal
          onClose={closeModal}
          deleteAction={() => onRemovePhase(selectedPhase)}
          elemType={'Phase'}
          title={selectedPhase.title}
          open={selectedPhase}
        />
      )}
    </div>
  )
}

export default PhasesForm
