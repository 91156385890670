import ReactPaginate from 'react-paginate'
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useFilters,
  useSortBy,
} from 'react-table'

import styles from './table.module.scss'

import TableHeader from './table-header'
import TableView from './table-view'

export default function TableContainer({
  data,
  columns,
  onToggleCreateModal,
  buttonText,
  pageSize,
  filterOptions,
  onRowClick,
  favoriteFilter,
  buttonDisabled,
  noHeader,
  currentPageNumber = 0,
  hightlightRowField = 'archived',
  onChangePage = () => {},
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    state,
    setGlobalFilter,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPageNumber, pageSize: pageSize },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  return (
    <>
      {noHeader || (
        <TableHeader
          onToggleCreateModal={onToggleCreateModal}
          buttonText={buttonText}
          setGlobalFilter={setGlobalFilter}
          globalFilter={state.globalFilter}
          filterOptions={filterOptions}
          favoriteFilter={favoriteFilter}
          search={true}
          buttonDisabled={buttonDisabled}
        />
      )}

      <TableView
        headerGroups={headerGroups}
        onRowClick={onRowClick}
        pageSize={pageSize}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        page={page}
        prepareRow={prepareRow}
        hightlightRowField={hightlightRowField}
      />

      {pageCount > 1 && (
        <ReactPaginate
          initialPage={0}
          containerClassName="pagination is-small is-centered"
          pageLinkClassName="pagination-link"
          previousLinkClassName="pagination-link"
          nextLinkClassName="pagination-link"
          disabledClassName={styles['pagination-disabled']}
          previousLabel="<"
          nextLabel=">"
          pageCount={pageCount}
          onPageChange={data => {
            gotoPage(data.selected)
            onChangePage(data.selected)
          }}
        />
      )}
    </>
  )
}
