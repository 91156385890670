import { useState } from 'react';

import hooks from '../hooks'

import Header from './header'
import ProjectForm from '../routes/shared/project/project-form'
import ImpersonatingBanner from 'components/ImpersonatingBanner'
import styles from './Layout.module.scss'

function MainLayout({ children }) {
  const { user, isImpersonating } = hooks.useAuth()
  const [openCreateProjectModal, setOpenCreateProjectModal] = useState(false)

  function toggleCreateProjectModal(open) {
    setOpenCreateProjectModal(open)
  }

  return (
    <main className={styles['container']}>
      {isImpersonating() && <ImpersonatingBanner />}
      {user.role === 'ghost' ? (
        <></>
      ) : (
        <Header onToggleCreateProjectModal={toggleCreateProjectModal} />
      )}

      {children}
      <ProjectForm
        open={openCreateProjectModal}
        onClose={() => toggleCreateProjectModal(false)}
      />
    </main>
  )
}

export default MainLayout
