import cx from 'classnames'

import styles from './title.module.scss'

const TitleView = ({ title, dataTest, fitTitle, children }) => {
  return (
    <h3
      className={cx(
        styles['header-title'],
        fitTitle && styles['header-fit-title'],
      )}
      data-test={dataTest}
    >
      {children || title}
    </h3>
  )
}

export default TitleView
