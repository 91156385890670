import { useRef, useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import cx from 'classnames'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'

import CreateFormModal from '../../../../components/create-form-modal'
import SelectField from '../../../../components/select'

import { showNotification } from '../../../../constants/utils'
import { emailTypes } from '../../../../constants/constants'

import styles from './create-user-form.module.scss'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

function CreateEmailTemplateFormView({
  onClose,
  onCreateTemplate,
  onUpdate,
  selectedTemplate,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [emailText, setEmailText] = useState(EditorState.createEmpty())

  let initialValues

  useEffect(() => {
    if (selectedTemplate) {
      const contentState = stateFromHTML(selectedTemplate.emailText)
      setEmailText(EditorState.createWithContent(contentState))
    } else {
      setEmailText(EditorState.createEmpty())
    }
  }, [selectedTemplate])

  if (selectedTemplate) {
    initialValues = {
      name: selectedTemplate.name,
      templateType: {
        value: selectedTemplate.type,
        label: selectedTemplate.type,
      },
    }
  } else {
    initialValues = {
      name: '',
      templateType: [],
    }
  }

  const formRef = useRef()

  const onCreate = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  const onReset = () => {
    if (formRef.current) {
      const form = formRef.current
      form.resetForm()
    }
    setEmailText(EditorState.createEmpty())
  }

  const submitForm = templateData => {
    let postBody
    setIsSubmitting(true)

    const emailTextHtml = emailText.getCurrentContent().hasText()
      ? stateToHTML(emailText.getCurrentContent())
      : ''

    if (selectedTemplate) {
      postBody = {
        emailText: emailTextHtml,
        name: templateData.name,
        type: templateData.templateType.value,
      }

      return onUpdate(postBody)
        .then(() => {
          setIsSubmitting(false)
        })
        .then(() =>
          showNotification('Email template updated correctly', 'is-success'),
        )
        .catch(error => showNotification(error.message, 'is-danger'))
        .finally(() => setIsSubmitting(false))
    } else {
      postBody = {
        emailText: emailTextHtml,
        name: templateData.name,
        type: templateData.templateType.value,
      }

      return onCreateTemplate(postBody)
        .then(() => {
          setIsSubmitting(false)
          showNotification('Email template created correctly', 'is-success')
          onReset()
        })
        .catch(error => showNotification(error.message, 'is-danger'))
        .finally(() => {
          setIsSubmitting(false)
          onClose()
        })
    }
  }

  return (
    <CreateFormModal
      open
      onClose={onClose}
      title={
        selectedTemplate
          ? 'Update email template'
          : 'Create a new email template'
      }
      submitButtonText={selectedTemplate ? 'Update' : 'Create'}
      resetButtonText={selectedTemplate ? 'Cancel' : 'Reset'}
      onCreate={onCreate}
      onReset={onReset}
      submitting={isSubmitting}
    >
      <Formik
        enableReinitialize
        innerRef={formRef}
        validateOnBlur={false}
        initialValues={initialValues}
        validate={values => {
          const errors = {}

          if (!values.name) {
            errors.name = 'Required'
          }

          return errors
        }}
        onSubmit={template => submitForm(template)}
      >
        {props => (
          <Form>
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Name *</label>
              <div className="control">
                <Field
                  className="input"
                  type="text"
                  name="name"
                  disabled={!!selectedTemplate}
                />
                <ErrorMessage className="error" name="name" component="div" />
              </div>
            </div>

            <div className={cx('field', styles['field-container'])}>
              <label className="label">Type </label>
              <div className="control is-expanded">
                <Field
                  name="templateType"
                  component={SelectField}
                  options={emailTypes}
                  placeholder={selectedTemplate && selectedTemplate.type}
                />
                <ErrorMessage
                  className="error"
                  name="templateType"
                  component="div"
                />
              </div>
            </div>

            {props.values.templateType === 'WEEKLY' && (
              <p>
                Use the [link] placeholder to position the project's link in the
                text
              </p>
            )}

            <div>
              <Editor
                editorState={emailText}
                editorStyle={{
                  border: '1px solid #70757d',
                  backgroundColor: '#f9fafd',
                  height: '200px',
                  overflow: 'auto',
                }}
                onEditorStateChange={value => {
                  return setEmailText(value)
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </CreateFormModal>
  )
}

export default CreateEmailTemplateFormView
