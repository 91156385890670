import cx from 'classnames'

import SummarySubscribeForm from './summary-subscribe-form'

import Title from '../../../../components/title'
import styles from '../settings.module.scss'

export const UserSettings = ({
  user,
  projectTemplates,
  updateUserSettings,
}) => {
  function onProjectSelectChange(e) {
    updateUserSettings({
      defaultTemplateId: e.target.value,
    })
  }

  return (
    <div className={styles['settings-column']}>
      <div className={styles['settings-title-wrapper']}>
        <Title title="User Settings" />
      </div>

      <div className={styles['settings']}>
        <div className={styles['form-wrapper']}>
          <div className={cx(styles['field-container'], 'field')}>
            <div className="field-label is-normal">
              <label className="label">Default project template id</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <span className="select">
                    <select
                      onChange={onProjectSelectChange}
                      defaultValue={
                        user.defaultTemplateId !== ''
                          ? user.defaultTemplateId
                          : 'Select default project template id'
                      }
                    >
                      <option value="" hidden>
                        Select default project template id
                      </option>
                      {projectTemplates.map(projectTemplate => (
                        <option
                          key={projectTemplate.id}
                          value={projectTemplate.id}
                        >
                          {projectTemplate.name}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles['settings-container']}>
          <SummarySubscribeForm
            subscribedToDailySummary={user.subscribedToDailySummary}
            subscribedToWeeklySummary={user.subscribedToWeeklySummary}
            subscribedToMonthlySummary={user.subscribedToMonthlySummary}
            updateUserSettings={updateUserSettings}
          />
        </div>
      </div>
    </div>
  )
}

export default UserSettings
