import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from 'routes/shared/tools/timesheet/add-hours-form-modal/timesheet-phases'

function TimesheetPhasesTotalTime(props) {
  return Object.keys(props.data).length === 0 ? (
    <div
      className="is-flex is-justify-content-center mt-5"
      data-test="hours-tab"
    >
      No hours logged yet
    </div>
  ) : (
    <table
      className={cx('table is-fullwidth', styles['timesheet-phases-table'])}
      data-test="hours-tab"
    >
      <thead>
        <tr>
          <th>Phase</th>
          <th>Hours</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(props.data).map(phaseName => {
          return (
            <tr key={phaseName}>
              <td>{phaseName}</td>
              <td>{props.data[phaseName]}</td>
            </tr>
          )
        })}
        <tr></tr>
      </tbody>
    </table>
  )
}

TimesheetPhasesTotalTime.propTypes = {
  data: PropTypes.object,
}

export default TimesheetPhasesTotalTime
