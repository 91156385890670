import { useHistory, useParams } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/react-hooks'

import { DELETE_PROJECT_BY_ID } from 'graphql/mutations'
import { GET_PROJECTS_ADMIN } from 'graphql/queries'
import { useArchiveProjectMutation } from './archive-mutation'
import { useOnHoldProjectMutation } from './on-hold-mutation'
import {
  PROJECT_EDIT,
  ROUTE_ENTITY_ID,
  PROJECTS_ADMINISTRATION,
} from 'constants/routes'

import Loader from 'components/loader'
import { showNotification } from 'constants/utils'

import ProjectsView from './projects-view'
import ProjectForm from '../../shared/project/project-form'

export default function ProjectsContainer() {
  const { data: projectsResponse, loading: fetchingProjects } = useQuery(
    GET_PROJECTS_ADMIN,
    { fetchPolicy: 'network-only', nextFetchPolicy: 'cache-first' },
  )

  const { projects } = projectsResponse ?? {}

  const params = useParams()

  const { id: editingProjectId } = params
  const editingProject = projects?.find(p => p.id === editingProjectId)

  const [deleteProjectMutation] = useMutation(DELETE_PROJECT_BY_ID)
  const toggleArchivedProject = useArchiveProjectMutation()
  const toggleProjectOnHold = useOnHoldProjectMutation()

  const history = useHistory()

  function closeModal() {
    if (history.length > 2) {
      history.goBack()
    } else {
      history.push(PROJECTS_ADMINISTRATION)
    }
  }

  function openEditModal(project) {
    history.push(PROJECT_EDIT.replace(ROUTE_ENTITY_ID, project.id))
  }

  function deleteProject(project) {
    return deleteProjectMutation({
      variables: { id: project.id },
      update: store => {
        const { projects } = store.readQuery({
          query: GET_PROJECTS_ADMIN,
        })

        store.writeQuery({
          query: GET_PROJECTS_ADMIN,
          data: {
            projects: projects.filter(({ id }) => id !== project.id),
          },
        })
      },

      optimisticResponse: {
        __typename: 'Mutation',
        deleteProjectById: {
          project: { id: project.id, __typename: 'Project' },
          successful: true,
          message: 'Project deleted successfully',
          __typename: 'DeleteProjectResponse',
        },
      },
    })
      .then(() => showNotification('Project deleted correctly', 'is-success'))
      .catch(error => showNotification(error.message, 'is-danger'))
  }

  const data = projects?.map(project => {
    // In case a user is selected both as support and lead we'll show their name only once
    const allAssignees = project?.assignees?.map(({ teamMember }) => teamMember)

    const uniqueProjectAssegnees =
      allAssignees?.reduce((prevAssegnees, currAssegnee) => {
        const isAssegneeDuplicate = !!prevAssegnees?.find(
          assegnee => assegnee.id === currAssegnee.id,
        )

        return isAssegneeDuplicate
          ? prevAssegnees
          : [...prevAssegnees, currAssegnee]
      }, []) ?? []

    const projectAssegnees =
      uniqueProjectAssegnees
        .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
        ?.join(', ') ?? [].join(', ')

    return { ...project, assegnees: projectAssegnees }
  })

  if (fetchingProjects) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  return (
    <>
      <ProjectsView
        projects={data}
        deleteProject={deleteProject}
        archiveProject={toggleArchivedProject}
        projectOnHold={toggleProjectOnHold}
        openEditModal={openEditModal}
      />
      {editingProject ? (
        <ProjectForm
          editingProjectId={editingProject ? editingProject.id : ''}
          open={!!editingProject}
          onClose={closeModal}
        />
      ) : (
        <></>
      )}
    </>
  )
}
