import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'

import styles from './filter.module.scss'

const FilterView = ({ onChange, options }) => {
  return (
    <div
      className="control has-icons-left has-icons-right"
      style={{ marginBottom: '5px' }}
    >
      <div className="select is-fullwidth">
        <select
          className={cx(styles['filter'], styles['option'], 'is-borderless')}
          defaultValue="Filter by"
          onChange={e => {
            onChange(e.target.value || undefined)
          }}
        >
          <option className={styles['option']} value="">
            Filter by
          </option>
          {options.map(({ label, value }) => (
            <option className={styles['option']} key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>

      <span className="icon is-small is-left">
        <FontAwesomeIcon icon={faFilter} />
      </span>
    </div>
  )
}

export default FilterView
