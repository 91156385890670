import { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'

import {
  CREATE_USER,
  CREATE_TEAM_MEMBER,
  UPDATE_USER_BY_ID,
} from 'graphql/mutations'

import { GET_TEAM, GET_CLIENTS_PARTNERS, GET_USER_BY_ID } from 'graphql/queries'

import CreateUserFormView from './create-user-form-view'
import Loader from 'components/loader'

function CreateUserFormContainer({ open, onClose, isTeamPage }) {
  const { id } = useParams()

  const [createUserMutation] = useMutation(CREATE_USER)
  const [createTeamMemberMutation] = useMutation(CREATE_TEAM_MEMBER)
  const [updateUserMutation] = useMutation(UPDATE_USER_BY_ID)
  const [getUserDetails, { loading, data }] = useLazyQuery(GET_USER_BY_ID, {
    variables: { id },
  })

  useEffect(() => {
    if (id) {
      getUserDetails()
    }
  }, [])

  const user = data?.userById

  function createUser(user) {
    // eslint-disable-next-line no-unused-vars
    const { createAccount, password, ...input } = user

    return createUserMutation({
      variables: { input },
      update: (store, result) => {
        const data = store.readQuery({
          query: isTeamPage ? GET_TEAM : GET_CLIENTS_PARTNERS,
        })

        const createdUser = result.data.createUser.user

        if (createdUser.role === 'client') {
          store.writeQuery({
            query: isTeamPage ? GET_TEAM : GET_CLIENTS_PARTNERS,
            data: {
              ...data,
              clients: [createdUser, ...data.clients],
            },
          })
        } else {
          store.writeQuery({
            query: isTeamPage ? GET_TEAM : GET_CLIENTS_PARTNERS,
            data: {
              ...data,
              partners: [createdUser, ...data.partners],
            },
          })
        }
      },
    })
  }

  function createTeamMember(teamMember) {
    // eslint-disable-next-line no-unused-vars
    const { createAccount, password, ...input } = teamMember
    return createTeamMemberMutation({
      variables: {
        input,
        account: { email: teamMember.email, password: teamMember.password },
      },
      update: (store, result) => {
        const data = store.readQuery({
          query: isTeamPage ? GET_TEAM : GET_CLIENTS_PARTNERS,
        })

        const createdTeammate = result.data.createTeamMember.user

        store.writeQuery({
          query: isTeamPage ? GET_TEAM : GET_CLIENTS_PARTNERS,
          data: {
            ...data,
            team: [createdTeammate, ...data.team],
          },
        })
      },
    })
  }

  function updateUser(updatedUser) {
    const { id, ...userUpdate } = updatedUser
    return updateUserMutation({
      variables: { id, input: userUpdate },
    })
  }

  return loading ? (
    <Loader />
  ) : (
    <CreateUserFormView
      open={open}
      onClose={onClose}
      onCreateUser={createUser}
      onCreateTeamMember={createTeamMember}
      onUpdate={updateUser}
      selectedUser={user}
      isTeamPage={isTeamPage}
    />
  )
}

export default CreateUserFormContainer
