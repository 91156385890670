import { useState, useMemo } from 'react'
import { format } from 'date-fns'
import useCompanySettings from '../../../hooks/useCompanySettings'

export default function useDatepicker({
  maxRange,
  startValue,
  endValue,
  isRange,
  value,
  onChangeStartValue,
  onChangeEndValue,
  onChange,
  singleOnChange,
}) {
  const { preferences } = useCompanySettings()

  const formatDate = date => format(date, preferences?.dateFormat ?? 'd/M/yyyy')

  const maxRangeMillis = (maxRange - 1) * 24 * 60 * 60 * 1000

  const [calendarVisible, setCalendarVisible] = useState(false)
  const [lastClickedDate, setLastClickedDate] = useState(null)

  const minRangeDate =
    !maxRange || !lastClickedDate
      ? null
      : new Date(lastClickedDate?.getTime() - maxRangeMillis)

  const maxRangeDate =
    !maxRange || !lastClickedDate
      ? null
      : new Date(lastClickedDate?.getTime() + maxRangeMillis)

  const label = useMemo(() => {
    if (isRange) {
      if (!startValue || !endValue) return 'Select a range'
      return `${formatDate(startValue)} - ${formatDate(endValue)}`
    }

    return value ? formatDate(value) : 'Select a date'
  }, [value, startValue, endValue])

  const handleChange = value => {
    if (isRange && !singleOnChange) {
      onChangeStartValue(value[0])
      onChangeEndValue(value[1])
      setCalendarVisible(false)
    } else {
      onChange(value)
      setCalendarVisible(false)
    }
  }

  const handleClickDay = value => {
    setLastClickedDate(value)
  }

  return {
    calendarVisible,
    setCalendarVisible,
    minRangeDate,
    maxRangeDate,
    label,
    handleChange,
    handleClickDay,
  }
}
