import Switch from 'react-switch'
import cx from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './project-modal.module.scss'

function Toggle({
  icon,
  text,
  isActive,
  setIsActive,
  disabled,
  dataTest,
  textContainerClass = '',
}) {
  return (
    <div
      data-test={dataTest ? `toggle-${dataTest}` : ''}
      className="is-flex is-align-items-center"
    >
      <div className={textContainerClass}>
        <label>
          {icon ? (
            <span style={{ marginRight: '5px' }}>
              <FontAwesomeIcon icon={icon} size="lg" />
            </span>
          ) : (
            <></>
          )}
          <span className={cx(styles['button-text'], 'text')}>{text}</span>
        </label>
      </div>

      <div>
        <Switch
          checkedIcon={false}
          uncheckedIcon={false}
          height={20}
          width={35}
          onColor="#76c066"
          checked={isActive}
          onChange={() => {
            setIsActive(!isActive)
          }}
          disabled={disabled}
          className="is-block"
        />
      </div>
    </div>
  )
}

export default Toggle
