import { useQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/browser'

import { supportCompanies } from 'constants/constants'
import { GET_USER_BY_ID } from 'graphql/queries'
import { createContext, useContext, useEffect, useState } from 'react'

const authContext = createContext()

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext)
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null)
  const [authenticating, setAuthenticating] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)
  const [isAnonymous, setIsAnonymous] = useState(false)

  const { data: fetchedUser } = useQuery(GET_USER_BY_ID, {
    variables: { id: user?.uid },
    skip: !user?.uid,
  })

  useEffect(() => {
    if (!fetchedUser) return
    setUser(u => ({ ...u, companyId: fetchedUser.userById.company.id }))
  }, [fetchedUser])

  useEffect(() => {
    Sentry.setUser(user)
  }, [user])

  useEffect(() => {
    const signedInUser = JSON.parse(window.localStorage.getItem('session'))
    if (signedInUser) {
      if (!user) {
        setUser(signedInUser)
      }
      setAuthenticated(true)
    }
  }, [])

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  function signIn(user) {
    setAuthenticating(true)

    // don't invert the order of the next lines
    window.localStorage.setItem('session', JSON.stringify(user))

    setUser(user)
    setAuthenticating(false)
    setAuthenticated(true)
  }

  function signOut() {
    setUser(null)
    window.localStorage.removeItem('session')
    setAuthenticated(false)
  }

  function setAnonymousUser() {
    setIsAnonymous(true)
    setUser({ name: 'Client' })
  }

  function setNewUser(newUser) {
    setUser(newUser)
  }

  function updateSession(dto) {
    const currentSession = JSON.parse(window.localStorage.getItem('session'))
    const newSession = { ...currentSession, ...dto }
    window.localStorage.setItem('session', JSON.stringify(newSession))
    setUser(newSession)
  }

  function isImpersonating() {
    return !!window.localStorage.getItem('old-session')
  }

  // Return the user object and auth methods
  return {
    user,
    authenticated,
    authenticating,
    isAnonymous,
    signIn,
    signOut,
    setAnonymousUser,
    setNewUser,
    updateSession,
    isSupport: supportCompanies.includes(fetchedUser?.userById?.company?.id),
    isImpersonating,
    companyId: fetchedUser?.userById?.company?.id,
  }
}
