import { useEffect, useRef } from 'react';
import cx from 'classnames'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import { useHistory, useParams } from 'react-router-dom'

import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import { Editor } from 'react-draft-wysiwyg'

import { Formik, Form, Field, ErrorMessage } from 'formik'

import {
  CREATE_VIRGIN_EMAIL_TEMPLATE,
  UPDATE_VIRGIN_EMAIL_TEMPLATE_BY_ID,
} from 'graphql/mutations'
import {
  GET_ALL_VIRGIN_EMAIL_TEMPLATES,
  GET_VIRGIN_EMAIL_TEMPLATE_BY_ID,
} from 'graphql/queries'

import { showNotification } from 'constants/utils'

import Loader from 'components/loader'
import CreateFormModal from 'components/create-form-modal'

import { VIRGIN_EMAIL_TEMPLATES } from 'constants/routes'

import styles from './VirginEmailTemplateEditor.module.scss'

// eslint-disable-next-line no-unused-vars
const EmailTextarea = ({ field, form, name, ...props }) => {
  return (
    <div>
      <Editor
        editorState={field.value}
        editorStyle={{
          border: '1px solid #70757d',
          backgroundColor: '#f9fafd',
          height: '500px',
          overflow: 'auto',
        }}
        onEditorStateChange={value => {
          form.setFieldValue(field.name, value)
        }}
      />
    </div>
  )
}

function VirginEmailTemplateEditor() {
  const formRef = useRef()

  const history = useHistory()

  const { id: virginEmailTemplateId } = useParams()

  const [
    getVirginEmailTemplateById,
    {
      loading: fetchingVirginEmailTemplateById,
      data: getVirginEmailTemplateByIdResponse,
    },
  ] = useLazyQuery(GET_VIRGIN_EMAIL_TEMPLATE_BY_ID)

  useEffect(() => {
    if (virginEmailTemplateId) {
      getVirginEmailTemplateById({
        variables: { id: virginEmailTemplateId },
      })
    }
  }, [virginEmailTemplateId])

  const [
    createVirginEmailTemplateMutation,
    { loading: creatingVirginEmailTemplate },
  ] = useMutation(CREATE_VIRGIN_EMAIL_TEMPLATE)

  const [
    updateVirginEmailTemplateByIdMutation,
    { loading: updatingVirginEmailTemplateById },
  ] = useMutation(UPDATE_VIRGIN_EMAIL_TEMPLATE_BY_ID)

  function onClose() {
    history.push(VIRGIN_EMAIL_TEMPLATES)
  }

  function onCreate() {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  function onReset() {
    if (formRef.current) {
      const form = formRef.current
      form.resetForm()
    }
  }

  // eslint-disable-next-line no-unused-vars
  async function onSubmit({ id, emailText, ...virginEmailTemplateValues }) {
    const emailTextHtml = emailText.getCurrentContent().hasText()
      ? stateToHTML(emailText.getCurrentContent())
      : ''

    const virginEmailTemplateDto = {
      ...virginEmailTemplateValues,
      emailText: emailTextHtml,
    }

    try {
      if (virginEmailTemplateId) {
        await updateVirginEmailTemplateByIdMutation({
          variables: {
            id: virginEmailTemplateId,
            input: virginEmailTemplateDto,
          },
        })

        showNotification(
          'Virgin email template updated correctly',
          'is-success',
        )
      } else {
        await createVirginEmailTemplateMutation({
          variables: { input: virginEmailTemplateDto },
          refetchQueries: [
            {
              query: GET_ALL_VIRGIN_EMAIL_TEMPLATES,
            },
          ],
        })

        showNotification(
          'Virgin email template created correctly',
          'is-success',
        )
      }
    } catch (error) {
      showNotification(
        `Couldn't ${
          virginEmailTemplateId ? 'update' : 'create'
        } the virgin email template. Please try later`,
        'is-danger',
      )
    }

    onClose()
  }

  if (fetchingVirginEmailTemplateById) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  function createInitialValues() {
    if (!virginEmailTemplateId) {
      return {
        name: '',
        emailText: EditorState.createEmpty(),
      }
    }

    const serializedEmailText =
      getVirginEmailTemplateByIdResponse?.virginEmailTemplateById.emailText

    const deserializedEmailText = getVirginEmailTemplateByIdResponse
      ?.virginEmailTemplateById.emailText
      ? EditorState.createWithContent(stateFromHTML(serializedEmailText))
      : EditorState.createEmpty()

    return {
      ...getVirginEmailTemplateByIdResponse?.virginEmailTemplateById,
      emailText: deserializedEmailText,
    }
  }

  return (
    <CreateFormModal
      open={open}
      onClose={onClose}
      title={`${virginEmailTemplateId ? 'Update' : 'Create'} virgin template`}
      submitButtonText={virginEmailTemplateId ? 'Update' : 'Create'}
      resetButtonText={virginEmailTemplateId ? 'Cancel' : 'Reset'}
      onCreate={onCreate}
      onReset={onReset}
      noPadding
      submitting={
        creatingVirginEmailTemplate || updatingVirginEmailTemplateById
      }
    >
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={createInitialValues()}
        validate={values => {
          const errors = {}
          if (!values.name) {
            errors.name = 'Required'
          }

          if (!values.emailText) {
            errors.name = 'Required'
          }

          return errors
        }}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <div className={styles['form']}>
              <div className={cx('field', styles['field-container'])}>
                <label className="label">Template's name *</label>
                <div className="control">
                  <Field className="input" type="text" name="name" />
                  <ErrorMessage name="name" component="div" />
                </div>
              </div>

              <div className={cx('field', styles['field-container'])}>
                <label className="label">Template's Email Text *</label>
                <div className="control">
                  <Field
                    className="textarea"
                    component={EmailTextarea}
                    name="emailText"
                  />
                  <ErrorMessage name="emailText" component="div" />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CreateFormModal>
  )
}

export default VirginEmailTemplateEditor
