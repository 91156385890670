import { useState } from 'react';
import cx from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faCheck, faTrash } from '@fortawesome/free-solid-svg-icons'

import styles from './tasks.module.scss'

function Task({
  task,
  onCompleteTask,
  onUndoTask,
  onUpdateTask,
  openSmartNotificationForm,
  openDeleteModal,
  readOnly,
  canBeDeleted,
}) {
  const [taskTitle, setTaskTitle] = useState(task.title)

  return (
    <div
      className={cx(
        'is-flex is-justify-content-space-between is-align-items-center',
        styles['task-item'],
      )}
      key={task.id}
      style={{ width: 'auto' }}
    >
      <div
        className="is-flex is-justify-content-space-between is-align-items-center"
        style={{ width: '100%' }}
      >
        {readOnly ? (
          <></>
        ) : (
          <button
            title={!canBeDeleted ? 'A phase must have at least one task' : ''}
            type="button"
            disabled={!canBeDeleted}
            className="button is-ghost mr-4 p-0"
            style={{ color: '#555c72' }}
            onClick={openDeleteModal}
          >
            <FontAwesomeIcon icon={faTrash} size="1x" />
          </button>
        )}
        <div style={{ width: '100%' }}>
          <input
            disabled={readOnly}
            className={cx('glowing-border', styles['task-input-field'])}
            value={taskTitle}
            onChange={e => {
              setTaskTitle(e.target.value)
              onUpdateTask(task.id, { title: e.target.value })
            }}
          />
        </div>
      </div>

      <div className="is-flex is-justify-content-space-between is-align-items-center">
        <div className="mr-1">
          <button
            className={cx(
              'button',
              'is-small',
              task.completed ? styles['task-completed'] : 'is-light',
            )}
            disabled={readOnly}
            onClick={() =>
              !task.completed ? onCompleteTask(task) : onUndoTask(task)
            }
          >
            <span className="icon is-medium">
              <FontAwesomeIcon icon={faCheck} size="lg" />
            </span>
          </button>
        </div>
        <div>
          <button
            className="button is-small is-light"
            style={readOnly ? { display: 'none' } : {}}
            onClick={openSmartNotificationForm}
          >
            <span className="icon is-medium">
              <FontAwesomeIcon icon={faBell} size="lg" />
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Task
