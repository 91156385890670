import { useEffect, useRef, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import { useHistory, useParams } from 'react-router-dom'

import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import {
  CREATE_VIRGIN_PROJECT_TEMPLATE,
  UPDATE_VIRGIN_PROJECT_TEMPLATE_BY_ID,
} from 'graphql/mutations'
import {
  GET_ALL_VIRGIN_PROJECT_TEMPLATES,
  GET_VIRGIN_PROJECT_TEMPLATE_BY_ID,
} from 'graphql/queries'

import { showNotification } from 'constants/utils'

import Loader from 'components/loader'
import CreateFormModal from 'components/create-form-modal'
import PhasesForm from './components/PhasesForm'
import TemplateDetailsForm from './components/TemplateDetailsForm/TemplateDetailsForm'

import { VIRGIN_PROJECT_TEMPLATES } from 'constants/routes'

import styles from './VirginProjectTemplateEditor.module.scss'
import { defaultPhaseDuration } from 'constants/constants'

function VirginProjectTemplateEditor() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const formRef = useRef()

  const history = useHistory()

  const { id: virginProjectTemplateId } = useParams()

  const [
    getVirginProjectTemplateById,
    {
      loading: fetchingVirginProjectTemplateById,
      data: getVirginProjectTemplateByIdResponse,
    },
  ] = useLazyQuery(GET_VIRGIN_PROJECT_TEMPLATE_BY_ID)

  useEffect(() => {
    if (virginProjectTemplateId) {
      getVirginProjectTemplateById({
        variables: { id: virginProjectTemplateId },
      })
    }
  }, [virginProjectTemplateId])

  const [
    createVirginProjectTemplateMutation,
    { loading: creatingVirginProjectTemplate },
  ] = useMutation(CREATE_VIRGIN_PROJECT_TEMPLATE)

  const [
    updateVirginProjectTemplateByIdMutation,
    { loading: updatingVirginProjectTemplateById },
  ] = useMutation(UPDATE_VIRGIN_PROJECT_TEMPLATE_BY_ID)

  function onClose() {
    history.push(VIRGIN_PROJECT_TEMPLATES)
  }

  function onCreate() {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  function onReset() {
    if (formRef.current) {
      const form = formRef.current
      form.resetForm()
    }
  }

  // eslint-disable-next-line no-unused-vars
  async function onSubmit({ id, ...virginProjectTemplateDto }) {
    try {
      if (virginProjectTemplateId) {
        await updateVirginProjectTemplateByIdMutation({
          variables: {
            id: virginProjectTemplateId,
            input: {
              ...virginProjectTemplateDto,
              phases: virginProjectTemplateDto.phases.map(phase => ({
                ...phase,
                tasks: phase.tasks.map(
                  // eslint-disable-next-line no-unused-vars
                  ({ notification, private: isPrivate, ...otherTaskProps }) =>
                    otherTaskProps,
                ),
              })),
            },
          },
        })

        showNotification(
          'Virgin email template updated correctly',
          'is-success',
        )
      } else {
        await createVirginProjectTemplateMutation({
          variables: { input: virginProjectTemplateDto },
          refetchQueries: [
            {
              query: GET_ALL_VIRGIN_PROJECT_TEMPLATES,
            },
          ],
        })

        showNotification(
          'Virgin email template created correctly',
          'is-success',
        )
      }
    } catch (error) {
      showNotification(
        `Couldn't ${
          virginProjectTemplateId ? 'update' : 'create'
        } the virgin email template. Please try later`,
        'is-danger',
      )
    }

    onClose()
  }

  if (fetchingVirginProjectTemplateById) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  return (
    <CreateFormModal
      open={open}
      onClose={onClose}
      title={`${virginProjectTemplateId ? 'Update' : 'Create'} virgin template`}
      submitButtonText={virginProjectTemplateId ? 'Update' : 'Create'}
      resetButtonText={virginProjectTemplateId ? 'Cancel' : 'Reset'}
      onCreate={onCreate}
      onReset={onReset}
      noPadding
      submitting={
        creatingVirginProjectTemplate || updatingVirginProjectTemplateById
      }
    >
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={
          getVirginProjectTemplateByIdResponse?.virginProjectTemplateById ||
          createInitialValues()
        }
        validate={values => {
          const errors = {}
          if (!values.name) {
            errors.name = 'Required'
          }

          for (const [i, phase] of values.phases.entries()) {
            if (!phase.title) {
              errors.phases = `Missing title in phase ${i}`
              break
            }

            for (const [j, task] of phase.tasks.entries()) {
              if (!task.title) {
                errors.phases = `Missing title in task ${j} of phase ${0}`
                break
              }
            }
          }

          return errors
        }}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <Tabs
              selectedTabClassName="selected-tab"
              selectedIndex={selectedTabIndex}
              onSelect={tabIndex => setSelectedTabIndex(tabIndex)}
            >
              <div className={styles['tabs']}>
                <TabList className={styles['tabs-items']}>
                  <Tab>Template details</Tab>
                  <Tab>Phases</Tab>
                </TabList>
              </div>

              <TabPanel>
                <div className={styles['form']}>
                  <TemplateDetailsForm editMode={!!virginProjectTemplateId} />
                </div>
              </TabPanel>

              <TabPanel>
                <div className={styles['form']}>
                  <Field component={PhasesForm} name="phases" />
                </div>
              </TabPanel>
            </Tabs>

            <ErrorMessage
              name="phases"
              component={({ children }) => {
                return (
                  <article className="message is-danger">
                    <div className="message-body">{children}</div>
                  </article>
                )
              }}
            />
          </Form>
        )}
      </Formik>
    </CreateFormModal>
  )
}

function createInitialValues(editingProjectTemplate) {
  if (!editingProjectTemplate) {
    return {
      name: '',
      phases: [
        {
          title: 'Phase 1',
          description: '',
          duration: defaultPhaseDuration,
          tasks: [
            {
              title: 'Task 1',
            },
          ],
        },
      ],
    }
  }
}

export default VirginProjectTemplateEditor
