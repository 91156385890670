import { addHours, subHours, addSeconds } from 'date-fns'

import DatePicker from 'components/datepicker'
import { useField } from 'formik'

function CardDatesRange() {
  const [field, , helpers] = useField('estimatedDurationDateRange')

  const { estimatedStartingDate, estimatedCompletionDate } = field.value ?? {}

  async function handleSelectRange([start, end]) {
    const startMidday = addHours(start, 12)
    const endMidday = addSeconds(subHours(end, 12), 1)

    helpers.setValue({
      estimatedStartingDate: startMidday,
      estimatedCompletionDate: endMidday,
    })
  }

  return (
    <div>
      <h5>Estimated dates</h5>
      <DatePicker
        isRange
        singleOnChange
        startValue={estimatedStartingDate && new Date(estimatedStartingDate)}
        endValue={estimatedCompletionDate && new Date(estimatedCompletionDate)}
        onChange={handleSelectRange}
      />
    </div>
  )
}

export default CardDatesRange
