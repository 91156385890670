import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'

import hooks from './hooks'

// Routes
import {
  ADMINISTRATION,
  AUTH,
  BACKOFFICE,
  CLIENT_VIEW,
  DASHBOARD,
  HOURS_ADMINISTRATION,
  NOT_FOUND,
  PASSWORD_RECOVERY,
  PROJECTS,
  PROJECT_EDIT,
  SIGN_UP,
  TOOLS,
} from 'constants/routes'

import Loader from 'components/loader'
import Layout from './layout/main'
import routes from './routes'

import useCompanyPermissions from 'hooks/useCompanyPermissions'
import './App.scss'
import CompanySettingsContextProvider from './context/CompanySettingsContext'

function App({ client }) {
  const { user, isSupport } = hooks.useAuth()

  const { gettingMyCompanyPermissions } = useCompanyPermissions(client)

  function adminAndStaffView(Route) {
    if (user && ['admin', 'staff'].includes(user.role)) {
      return Route
    }

    return <routes.LoginRoute />
  }

  function backofficeView(Route) {
    if (user && user.support) {
      return Route
    }

    return <routes.LoginRoute />
  }

  function signUpView(Route) {
    if (user && ['admin', 'staff'].includes(user.role)) {
      return <routes.DashboardRoute />
    }

    if (user && user.role === 'ghost') {
      return Route
    }

    return <Redirect to="/" />
  }

  function passwordRequestView(Route) {
    if (user) {
      return <routes.PasswordRequestRoute />
    }

    return Route
  }

  function passwordResetView(Route) {
    if (user) {
      return <routes.PasswordResetRoute />
    }

    return Route
  }

  function renderLogin() {
    if (user && ['admin', 'staff'].includes(user.role)) {
      return (
        <Layout>
          <routes.DashboardRoute />
        </Layout>
      )
    }

    if (user && user.role === 'ghost') {
      return (
        <Layout>
          <routes.SignUpRoute />
        </Layout>
      )
    }

    return <routes.LoginRoute />
  }

  if (gettingMyCompanyPermissions) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  return (
    <CompanySettingsContextProvider>
      <BrowserRouter>
        <CompatRouter>
          <div className="App">
            <Switch>
              <Route path={NOT_FOUND}>
                <routes.NotFound />
              </Route>

              <Route path={CLIENT_VIEW}>
                <routes.ClientRoute />
              </Route>

              <Route path={PROJECT_EDIT}>
                {adminAndStaffView(
                  <Layout>
                    <routes.AdministrationRoute />
                  </Layout>,
                )}
              </Route>

              <Route path={ADMINISTRATION}>
                {adminAndStaffView(
                  <Layout>
                    <routes.AdministrationRoute />
                  </Layout>,
                )}
              </Route>

              {isSupport && (
                <Route path={BACKOFFICE}>
                  {backofficeView(
                    <Layout>
                      <routes.BackOffice />
                    </Layout>,
                  )}
                </Route>
              )}

              <Route path={TOOLS}>
                {adminAndStaffView(
                  <Layout>
                    <routes.ToolsRoute />
                  </Layout>,
                )}
              </Route>

              <Route path={DASHBOARD}>
                {adminAndStaffView(
                  <Layout>
                    <routes.DashboardRoute />
                  </Layout>,
                )}
              </Route>

              <Route path={HOURS_ADMINISTRATION}>
                {adminAndStaffView(
                  <Layout>
                    <routes.TimesheetAdministration />
                  </Layout>,
                )}
              </Route>

              <Route path={SIGN_UP}>{signUpView(<routes.SignUpRoute />)}</Route>

              <Route path={PASSWORD_RECOVERY}>
                {passwordRequestView(<routes.PasswordRequestRoute />)}
              </Route>

              <Route path={AUTH}>
                {passwordResetView(<routes.PasswordResetRoute />)}
              </Route>

              <Route path={PROJECTS}>{renderLogin()}</Route>

              <Route path="/">{renderLogin()}</Route>
            </Switch>
          </div>
        </CompatRouter>
      </BrowserRouter>
    </CompanySettingsContextProvider>
  )
}

export default App
