import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import styles from './TaskTableItem.module.scss'

function TaskTableItem({
  name,
  type,
  id,
  start: startDate,
  onProjectClick,
  isSelected,
  hideParentLink,
  url,
}) {
  const hideLink = type === 'project' && hideParentLink

  const maxLength = 40

  return (
    <div
      style={{ width: '100%' }}
      className={classNames(
        `is-flex is-justify-content-space-between is-align-items-center is-full-width`,
        isSelected ? styles['is-selected'] : '',
      )}
    >
      <button
        onClick={() => onProjectClick({ id, startDate })}
        className={styles['name-button']}
      >
        {name.substring(0, maxLength)}
        {name.length > maxLength && '...'}
      </button>
      {!hideLink && (
        <Link to={url} className="mr-2">
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            style={{ color: '#658aff' }}
          />
        </Link>
      )}
    </div>
  )
}

export default TaskTableItem
