import cx from 'classnames'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import IconButton from '../icon-button'

import styles from './create-form-modal.module.scss'

function HeaderView({ title, onClose }) {
  return (
    <header className={styles.header}>
      <div className="is-flex is-justify-content-space-between is-align-items-center">
        <div>
          <div>
            <h2 className={styles['title']}>{title}</h2>
          </div>
        </div>

        <div>
          <div>
            <IconButton
              icon={faTimes}
              action={onClose}
              className={cx(
                'level-item',
                'button is-white',
                styles['close-button'],
              )}
              dataTest={`close-${title}-modal-button`}
            />
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeaderView
