import { useState, useEffect, useMemo } from 'react';
import cx from 'classnames'
import { useTable, useGlobalFilter, useFilters, useSortBy } from 'react-table'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

import styles from './projects-table.module.scss'
import { set } from 'lodash'

const ProjectsTableView = ({ columns, data, onOpenProjectDescription }) => {
  const [sortBy, setSortBy] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isInitialRender, setIsInitialRender] = useState(true)

  useEffect(() => {
    try {
      const savedSortBy = JSON.parse(
        window.localStorage.getItem('dashboardProjectsSortBy'),
      )
      if (savedSortBy) {
        setSortBy(savedSortBy)
      }
    } catch (error) {
      // This feature is not essential — ignore any errors
    } finally {
      setIsLoading(false)
    }
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy,
      },
      autoResetSortBy: false,
      useControlledState: state => {
        return useMemo(() => {
          const newState = { ...state }

          if (isInitialRender && sortBy?.length) {
            newState.sortBy = sortBy
            setIsInitialRender(false)
          }

          return newState
        }, [state, sortBy])
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
  )

  useEffect(() => {
    try {
      if (
        (state.sortBy?.length || sortBy?.length) &&
        (state.sortBy[0]?.id !== sortBy[0]?.id ||
          state.sortBy[0]?.desc !== sortBy[0]?.desc)
      ) {
        window.localStorage.setItem(
          'dashboardProjectsSortBy',
          JSON.stringify(state.sortBy),
        )
      }
    } catch (error) {
      // This feature is not essential — ignore any errors
    }
  }, [state.sortBy])

  if (isLoading) {
    return <></>
  }

  return (
    <table
      border="0"
      cellSpacing="0"
      cellPadding="0"
      className={cx('table is-fullwidth', styles['projects-table'])}
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                key={i}
                {...column.getHeaderProps(column.getSortByToggleProps())}
                width={column.width}
                className={cx(
                  styles['projects-table-header'],
                  styles[
                    `${column.Header.toLowerCase().replace(' ', '-')}-header`
                  ],
                )}
              >
                {column.render('Header')}
                <span className={styles['sort-icon']}>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        size="lg"
                        style={{ marginLeft: '5px' }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        size="lg"
                        style={{ marginLeft: '5px' }}
                      />
                    )
                  ) : (
                    ''
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr
              key={i}
              {...row.getRowProps()}
              onClick={e => onOpenProjectDescription(e, row.original)}
              data-test="project-row"
            >
              {row.cells.map(cell => {
                return (
                  <td
                    key={i}
                    {...cell.getCellProps()}
                    className={row.original.archived ? 'archived' : ''}
                  >
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default ProjectsTableView
