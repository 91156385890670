import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'
import styles from '../password-recovery.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import cx from 'classnames'

const SuccessMessage = () => {
  return (
    <div className={cx('container', styles['success-container'])}>
      <h1>Check your email!</h1>
      <h2>We sent you a link to reset your password</h2>
      <Link to="/sign-in" className={cx('button', 'is-info', 'link-button')}>
        Go to Login
      </Link>
    </div>
  )
}

const PasswordRequestView = ({ onSubmit, loading, error, success }) => {
  const initialValues = {
    email: '',
  }

  if (success) {
    return <SuccessMessage />
  }

  return (
    <div className={styles['password-recovery-page']}>
      <div className={styles['password-recovery-wrapper']}>
        <div className={styles['password-recovery-header']}>
          <span className="icon is-medium is-left">
            <FontAwesomeIcon size="lg" icon={faSignInAlt} />
          </span>
          <span>Recover Your Password</span>
        </div>

        <div className={styles['password-recovery-form-wrapper']}>
          <Formik
            enableReinitialize
            validateOnBlur={false}
            initialValues={initialValues}
            validate={values => {
              const errors = {}

              if (!values.email) {
                errors.email = 'Required'
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address'
              }

              return errors
            }}
            onSubmit={({ email }) => onSubmit(email)}
          >
            {() => (
              <Form>
                <div className={styles['align-center']}>
                  <div className={cx('field', styles['field-container'])}>
                    <label className="label">Email *</label>
                    <div className="control">
                      <Field className="input" type="email" name="email" />
                      <ErrorMessage
                        className="error"
                        name="email"
                        component="div"
                      />
                    </div>
                  </div>
                </div>

                {error &&
                  error.message ===
                    'GraphQL error: There is no user record corresponding to this identifier. The user may have been deleted.' && (
                    <span className="error">
                      There is no user associated to that email in our database
                    </span>
                  )}

                <div
                  className={cx(
                    styles['footer-wrapper'],
                    styles['align-center'],
                  )}
                >
                  <button
                    className={cx(
                      'button',
                      'is-info',
                      styles['action-button'],
                      loading ? 'is-loading' : '',
                    )}
                    type="submit"
                  >
                    Send Recovery Link
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default PasswordRequestView
