import { useApolloClient, useMutation } from '@apollo/react-hooks'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import {
  CREATE_PROJECT_TEMPLATE,
  UPDATE_PROJECT_TEMPLATE,
} from 'graphql/mutations'
import {
  GET_MY_PARTNERS,
  GET_PROJECT_TEMPLATES_IDS,
  GET_PROJECT_TEMPLATE_DETAILS,
} from 'graphql/queries'

import { toast } from 'bulma-toast'
import { defaultPhaseDuration } from 'constants/constants'
import CreateProjectTemplateFormView from './CreateProjectTemplateFormView'

function formatUsersTypeForView(users, type) {
  if (!users) {
    return []
  }
  return users
    .filter(user => user.role === type)
    .map(user => ({
      value: user,
      label: `${user.firstName} ${user.lastName}`,
    }))
}

function createInitialValues({ editingProjectTemplate, cloning } = {}) {
  if (!editingProjectTemplate) {
    return {
      name: '',
      engineers: [],
      surveyors: [],
      consultants: [],
      phases: [
        {
          id: uuid(),
          title: 'Phase 1',
          duration: defaultPhaseDuration,
          description: '',
          dependsOn: null,
          tasks: [
            {
              title: 'Task 1',
              notification: null,
              private: false,
            },
          ],
        },
      ],
    }
  }

  const { phases: originalPhases } = editingProjectTemplate

  const phases = originalPhases?.map(phase => {
    const { id: dependsOn } = phase.dependsOn ?? {}

    return {
      ...phase,
      dependsOn,
    }
  })

  if (cloning) {
    const newIds = Array(phases.length)
      .fill()
      .map(() => uuid())

    const phasesInput = phases.map((p, index) => {
      const { tasks, dependsOn } = p

      const dependsOnPhaseIndex = phases.findIndex(p => p.id === dependsOn)

      const newDependsOn = newIds[dependsOnPhaseIndex]

      const remappedTasks =
        tasks?.map(t => {
          const { emailTemplate, ...notification } = t.notification ?? {}

          const newNotification = t.notification
            ? {
                ...notification,
                emailTemplateId: emailTemplate?.id,
              }
            : null

          return {
            ...t,
            notification: newNotification,
          }
        }) ?? []

      // eslint-disable-next-line no-unused-vars
      return {
        ...p,
        id: newIds[index],
        tasks: remappedTasks,
        dependsOn: newDependsOn,
      }
    })

    return {
      name: `${editingProjectTemplate.name} (cloned)`,
      engineers: formatUsersTypeForView(
        editingProjectTemplate.engineers,
        'engineer',
      ),
      surveyors: formatUsersTypeForView(
        editingProjectTemplate.surveyors,
        'surveyors',
      ),
      consultants: formatUsersTypeForView(
        editingProjectTemplate.consultants,
        'consultant',
      ),
      phases: phasesInput,
    }
  }

  return {
    id: editingProjectTemplate.id,
    name: editingProjectTemplate.name,
    engineers: formatUsersTypeForView(
      editingProjectTemplate.engineers,
      'engineer',
    ),
    surveyors: formatUsersTypeForView(
      editingProjectTemplate.surveyors,
      'surveyor',
    ),
    consultants: formatUsersTypeForView(
      editingProjectTemplate.consultants,
      'consultant',
    ),
    phases,
  }
}

function getUserIds(users) {
  return users?.map(u => u.id) ?? []
}

function CreateProjectTemplateFormContainer({
  onClose,
  cloning,
  projectTemplates = [],
}) {
  const { id } = useParams()

  const client = useApolloClient()

  const [fetchedData, setFetchedData] = useState({
    users: [],
    fetching: true,
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [createProjectTemplateMutation] = useMutation(CREATE_PROJECT_TEMPLATE)
  const [updateProjectTemplateMutation] = useMutation(UPDATE_PROJECT_TEMPLATE)

  function createProjectTemplate(template) {
    setIsSubmitting(true)

    const { consultants, engineers, surveyors, ...restOfTemplate } = template

    const defaultPartners = getUserIds([
      ...consultants,
      ...engineers,
      ...surveyors,
    ])

    const phasesInput = restOfTemplate.phases?.map(p => {
      const tasks = p.tasks?.map(t => {
        const { notification, ...restOfTask } = t ?? {}

        return {
          ...restOfTask,
          notification: notification ?? null,
        }
      })

      return {
        ...p,
        tasks,
      }
    })

    const templateInput = {
      ...restOfTemplate,
      phases: phasesInput,
    }

    return createProjectTemplateMutation({
      variables: { input: { ...templateInput, defaultPartners } },
      refetchQueries: [
        {
          query: GET_PROJECT_TEMPLATES_IDS,
        },
      ],
    }).finally(() => setIsSubmitting(false))
  }

  async function updateProjectTemplate(template) {
    setIsSubmitting(true)

    // The following comment is for when the database will be migrated and we'll only pass an array of defaultPartners IDs instead of separate keys for each kind of partner
    // eslint-disable-next-line no-unused-vars
    // const { consultants, engineers, surveyors, id, ...templateInput } = template

    // const defaultPartners = getUserIds([
    //   ...consultants,
    //   ...engineers,
    //   ...surveyors,
    // ])

    const phasesWithClientCreatedId = template.phases

    const phases = phasesWithClientCreatedId.map(({ __typename, ...p }) => {
      // eslint-disable-next-line no-unused-vars

      const tasks = p.tasks?.map(({ __typename, ...task }) => {
        const { notification } = task

        if (notification == null) return task

        // eslint-disable-next-line no-unused-vars

        const emailTemplateId =
          notification.emailTemplateId || notification.emailTemplate.id

        const notificationInput = {
          emailTemplateId,
          recipients: notification.recipients,
          extraRecipients: notification.extraRecipients,
        }

        return {
          ...task,
          notification: notificationInput,
        }
      })

      return {
        ...p,
        tasks,
      }
    })

    const engineers = template.engineers.map(({ __typename, ...rest }) => rest)
    const consultants = template.consultants.map(
      ({ __typename, ...rest }) => rest,
    )
    const surveyors = template.surveyors.map(({ __typename, ...rest }) => rest)

    try {
      const updatedProjectResponse = await updateProjectTemplateMutation({
        variables: {
          id: template.id,
          input: { ...template, engineers, consultants, surveyors, phases },
        },
      })

      return updatedProjectResponse
    } catch (e) {
      toast({
        message: 'Could not update project template',
        type: 'is-danger',
        dismissible: true,
        position: 'bottom-right',
        duration: 3000,
        closeOnClick: true,
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (!open) return

    const promises = [
      client.query({
        query: GET_MY_PARTNERS,
      }),
    ]

    if (id) {
      promises.push(
        client.query({
          query: GET_PROJECT_TEMPLATE_DETAILS,
          variables: {
            id,
          },
          fetchPolicy: 'network-only',
        }),
      )
    }

    Promise.all(promises).then(([usersResponse, projectTemplateResponse]) => {
      let newState = {
        users: usersResponse.data.partners,
        fetching: false,
      }

      if (projectTemplateResponse) {
        newState = {
          ...newState,
          projectTemplateById: createInitialValues({
            editingProjectTemplate:
              projectTemplateResponse.data.projectTemplateById,
            cloning,
          }),
        }
      }

      setFetchedData(newState)
    })
  }, [open])

  const [engineers, consultants, surveyors] = [
    'engineer',
    'consultant',
    'surveyor',
  ].map(type => formatUsersTypeForView(fetchedData.users, type))

  return (
    <CreateProjectTemplateFormView
      onClose={onClose}
      engineers={engineers}
      consultants={consultants}
      surveyors={surveyors}
      initialValues={fetchedData?.projectTemplateById || createInitialValues()}
      onCreate={createProjectTemplate}
      onUpdate={updateProjectTemplate}
      isSubmitting={isSubmitting}
      cloning={cloning}
      projectTemplates={projectTemplates}
    />
  )
}

export default CreateProjectTemplateFormContainer
