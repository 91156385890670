import { useQuery, useMutation, useApolloClient } from '@apollo/react-hooks'
import cloneDeep from 'lodash.clonedeep'

import { GET_MY_TEAM, GET_TASK_LIST } from 'graphql/queries'
import { MOVE_CARDS } from 'graphql/mutations'
import hooks from 'hooks'

import { getColumnAssignee, getColumnLabel } from 'constants/utils'

import CardsBoardView from './cards-board-view'
import Loader from 'components/loader'
import { update } from 'lodash'

function sortByPosition(a, b) {
  if (a.position === null) {
    return 1
  } else if (b.position === null) {
    return -1
  }

  return a.position - b.position
}

function handleCardMove({
  client,
  variables,
  cardId,
  source,
  destination,
  sourceColumnStatus,
  destinationColumnStatus,
  destinationColumnAssignee,
  cardHasMovedColumn,
}) {
  const queryCache = client.readQuery({
    query: GET_TASK_LIST,
    variables,
  })

  if (!queryCache) {
    return
  }

  const tasks = queryCache.tasks
  const updatedTasks = cloneDeep(tasks)

  const columnPositions = {}
  const positions = {}

  // Normalise card positions
  for (let task of updatedTasks) {
    const previousPosition = task.position

    if (task.status === 'Done') {
      task.position = 0
    } else {
      let key = task.status
      if (
        task.status !== 'Incoming' &&
        task.status !== 'Overflow' &&
        task.assignee?.id
      ) {
        key += `.${task.assignee.id}`
      }

      if (columnPositions[key] === undefined) {
        columnPositions[key] = 0
      }

      task.position = columnPositions[key]++
    }

    if (previousPosition !== task.position) {
      positions[task.id] = task.position
    }
  }

  for (let index = 0; index < updatedTasks.length; index++) {
    const task = updatedTasks[index]
    const isMovedCard = task.id === cardId
    const previousPosition = task.position

    if (!isMovedCard && task.status === 'Done') {
      continue
    }

    if (isMovedCard) {
      task.position = destinationColumnStatus === 'Done' ? 0 : destination.index

      if (cardHasMovedColumn) {
        task.status = destinationColumnStatus

        if (destinationColumnAssignee) {
          task.assignee = {
            id: destinationColumnAssignee,
            firstName: null,
            lastName: null,
            __typename: 'TeamMember',
          }
        }
      }
      // Change position of all cards in the source column
    } else if (task.status === sourceColumnStatus) {
      // Card has moved column and task was originally below the active task
      // = Move the task up 1
      if (cardHasMovedColumn && task.position > source.index) {
        task.position -= 1

        // Task is in the position the active task has been moved to
        // And it was orignally above the active task
        // = Move the task down 1
      } else if (
        !cardHasMovedColumn &&
        task.position === destination.index &&
        task.position < source.index
      ) {
        task.position += 1

        // Task is in the position the active task has been moved to
        // And it was originally below the active task
        // = Move the task up 1
      } else if (
        !cardHasMovedColumn &&
        task.position === destination.index &&
        task.position > source.index
      ) {
        task.position -= 1

        // Task was originally above the active task
        // And is now below the active task
        // = Move the task down 1
      } else if (
        !cardHasMovedColumn &&
        task.position < source.index &&
        task.position > destination.index
      ) {
        task.position += 1

        // Task was originally below the active task
        // And is now above the active task
        // = Move the task up 1
      } else if (
        !cardHasMovedColumn &&
        task.position > source.index &&
        task.position < destination.index
      ) {
        task.position -= 1
      }
      // Change position of all cards in the destination column
    } else if (cardHasMovedColumn && task.status === destinationColumnStatus) {
      if (task.position < destination.index) {
        continue
      }

      if (task.position === destination.index) {
        task.position += 1
      } else if (task.position > destination.index) {
        task.position += 1
      }
    }

    if (previousPosition !== task.position) {
      positions[task.id] = task.position
    }
  }

  client.writeQuery({
    query: GET_TASK_LIST,
    variables,
    data: {
      tasks: updatedTasks,
    },
  })

  return positions
}

export default function CardsBoardContainer(props) {
  const { canUseStickyCards } = hooks.useSubscription()

  const loggedUser = hooks.useAuth().user
  const client = useApolloClient()

  const getTaskListQueryVariables = {
    uid: props.isTeam ? undefined : loggedUser.uid,
    limitDoneTasks: true,
  }

  const { data, loading, error } = useQuery(GET_MY_TEAM, {
    fetchPolicy: 'network-only',
  })
  const [moveCards] = useMutation(MOVE_CARDS)

  function onCardDropped(result) {
    const { destination, source, draggableId: cardId } = result

    if (!destination) {
      return
    }

    const cardHasMovedColumn = source.droppableId !== destination.droppableId
    const cardHasChangedPosition = source.index !== destination.index
    const cardHasMoved = cardHasMovedColumn || cardHasChangedPosition

    if (!cardHasMoved) {
      return
    }

    // Don't allow cards to be moved in their current column if Sticky Cards is disabled
    if (!canUseStickyCards() && !cardHasMovedColumn) {
      return
    }

    const sourceColumnStatus = getColumnLabel(source.droppableId)
    const destinationColumnStatus = getColumnLabel(destination.droppableId)
    const destinationColumnAssignee = getColumnAssignee(destination.droppableId)

    const movedInDoneColumn =
      sourceColumnStatus === 'Done' && destinationColumnStatus === 'Done'
    if (movedInDoneColumn) {
      return
    }

    let positions = []
    if (canUseStickyCards()) {
      positions = handleCardMove({
        client,
        variables: getTaskListQueryVariables,
        cardId,
        source,
        destination,
        sourceColumnStatus,
        destinationColumnStatus,
        destinationColumnAssignee,
        cardHasMovedColumn,
      })
    }

    positions = Object.entries(positions).map(([id, position]) => {
      return { id, position }
    })

    let newCard = {
      status: destinationColumnStatus,
      assigneeId: destinationColumnAssignee || null,
    }

    if (destinationColumnStatus === 'Done') {
      newCard = {
        ...newCard,
        completedAt: new Date().toISOString(),
        position: 0,
      }
    }

    let optimisticResponse

    if (!canUseStickyCards()) {
      const existingCard = props.cards.find(card => card.id === cardId)

      optimisticResponse = {
        __typename: 'Mutation',
        updateTaskById: {
          task: {
            ...existingCard,
            project: existingCard.project
              ? { ...existingCard.project, __typename: 'TaskProject' }
              : null,
            id: cardId,
            status: newCard.status,
            assignee: newCard.assigneeId
              ? { id: newCard.assigneeId, __typename: 'TeamMember' }
              : null,
            position: newCard.position,
            __typename: 'Task',
          },
          __typename: 'UpdateTaskResponse',
        },
        updateTaskPositions: {
          positions: [],
          __typename: 'UpdateTaskPositionsResponse',
        },
      }
    }

    moveCards({
      variables: {
        taskId: cardId,
        task: newCard,
        positions,
      },
      optimisticResponse,
      refetchQueries: canUseStickyCards()
        ? [
            {
              query: GET_TASK_LIST,
              variables: getTaskListQueryVariables,
            },
          ]
        : [],
    })
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return <p>Error fetching cards...</p>
  }

  return (
    <CardsBoardView
      {...props}
      onCardDropped={onCardDropped}
      teamMembers={data.team}
      isTeam={props.isTeam}
    />
  )
}
