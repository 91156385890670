import { useState } from 'react'

import Datepicker from '../../../../components/datepicker'
import ProjectSectionTitle from './components/project-section-title'

import { formatDateFromCalendar } from 'constants/utils'

import styles from './project-description.module.scss'

function ProjectDescriptionDeterminationDate({
  readOnly = false,
  value,
  onUpdate,
}) {
  const [endDate, setEndDate] = useState(value)

  function onUpdateEndDate(newEndDate) {
    setEndDate(newEndDate)

    onUpdate({
      target: {
        name: 'endDate',
        value: formatDateFromCalendar(newEndDate),
      },
    })
  }

  function showInput() {
    return (
      <div className="field">
        <div className="control">
          <Datepicker
            value={endDate ? new Date(endDate) : null}
            onChange={onUpdateEndDate}
            disabled={readOnly}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={styles['project-description-status']}>
      <div style={{ marginBottom: '10px' }}>
        <ProjectSectionTitle
          title="Planning Determination"
          icon="icon-exclamation"
        />
      </div>
      {showInput()}
    </div>
  )
}

export default ProjectDescriptionDeterminationDate
