import { useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import { useSubscription } from 'hooks/use-subscription'

import styles from './CreateProjectTemplateForm.module.scss'

import { showNotification } from '../../../../constants/utils'

import CreateFormModal from '../../../../components/create-form-modal'
import TemplateDetailsForm from './components/TemplateDetailsForm/TemplateDetailsForm'
import PhasesForm from './components/PhasesForm'

function CreateProjectTemplateFormView(props) {
  const {
    onClose,
    engineers,
    consultants,
    surveyors,
    initialValues,
    isSubmitting,
    cloning,
    projectTemplates,
  } = props

  const formRef = useRef()

  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const { canUseTimeline } = useSubscription()

  function onCreate() {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  function onCloseSlider() {
    onReset()
    onClose()
    setSelectedTabIndex(0)
  }

  function onReset() {
    if (formRef.current) {
      const form = formRef.current
      form.resetForm()
    }
  }

  async function onSubmit(projectTemplate) {
    delete projectTemplate.clonedProjectTemplateId
    const projectTemplateToSave = { ...projectTemplate }

    projectTemplateToSave.consultants = projectTemplateToSave.consultants
      ? projectTemplateToSave.consultants.map(({ value }) => {
          delete value.interaction
          return value
        })
      : []
    projectTemplateToSave.engineers = projectTemplateToSave.engineers
      ? projectTemplateToSave.engineers.map(({ value }) => {
          delete value.interaction
          return value
        })
      : []
    projectTemplateToSave.surveyors = projectTemplateToSave.surveyors
      ? projectTemplateToSave.surveyors.map(({ value }) => {
          delete value.interaction
          return value
        })
      : []

    if (initialValues.id) {
      return props
        .onUpdate(projectTemplateToSave)
        .then(() =>
          showNotification('Project template updated correctly', 'is-success'),
        )
        .catch(() => {
          showNotification(
            'There was an error while updating project template',
            'is-danger',
          )
        })
    }

    try {
      await props.onCreate(projectTemplateToSave)
      onCloseSlider()
      showNotification(
        cloning
          ? 'Project template was cloned correctly'
          : 'Project template was created correctly',
        'is-success',
      )
      onReset()
    } catch (e) {
      showNotification(
        cloning
          ? 'There was an error while cloning the project template'
          : 'There was an error while creating project template',
        'is-danger',
      )
    }
  }

  function showText() {
    let titleText
    let buttonText

    if (cloning) {
      titleText = `Clone project template ${initialValues.name}`
      buttonText = 'Clone'
    } else if (initialValues.id) {
      titleText = `Update template ${initialValues.name}`
      buttonText = 'Update'
    } else {
      titleText = 'Create a new project template'
      buttonText = 'Create'
    }

    return { titleText, buttonText }
  }

  return (
    <CreateFormModal
      open
      onClose={onCloseSlider}
      title={showText().titleText}
      submitButtonText={showText().buttonText}
      resetButtonText={initialValues.id ? 'Cancel' : 'Reset'}
      onCreate={onCreate}
      onReset={onReset}
      submitting={isSubmitting}
      noPadding
    >
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={initialValues}
        validate={values => {
          const errors = {}
          if (!values.name) {
            errors.name = 'Required'
          }

          if (projectTemplates.some(t => t.name === values.name)) {
            errors.name = 'This name is already in use'
          }

          for (const [i, phase] of values.phases.entries()) {
            if (!phase.title) {
              errors.phases = `Missing title in phase ${i + 1}`
              break
            }

            if (canUseTimeline() && !phase.duration) {
              errors.phases = `Duration for phase '${values.phases[i].title}' needs to be at least 1 day`
              break
            }

            if (phase.tasks) {
              for (const [j, task] of phase.tasks.entries()) {
                if (!task.title) {
                  errors.phases = `Missing title in task ${j} of phase ${0}`
                  break
                }
              }
            }
          }
          return errors
        }}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <Tabs
              selectedTabClassName="selected-tab"
              selectedIndex={selectedTabIndex}
              onSelect={tabIndex => setSelectedTabIndex(tabIndex)}
            >
              <div className={styles['tabs']}>
                <TabList className={styles['tabs-items']}>
                  <Tab>Template details</Tab>
                  <Tab>Phases</Tab>
                </TabList>
              </div>

              <TabPanel>
                <div className={styles['form']}>
                  <TemplateDetailsForm
                    editMode={!!initialValues.id}
                    engineers={engineers}
                    consultants={consultants}
                    surveyors={surveyors}
                  />
                </div>
              </TabPanel>

              <TabPanel>
                <div className={styles['form']}>
                  <Field component={PhasesForm} name="phases" />
                </div>
              </TabPanel>
            </Tabs>
            <ErrorMessage
              name="phases"
              component={({ children }) => {
                return (
                  <article className="message is-danger">
                    <div className="message-body">{children}</div>
                  </article>
                )
              }}
            />
          </Form>
        )}
      </Formik>
    </CreateFormModal>
  )
}

export default CreateProjectTemplateFormView
