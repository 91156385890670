import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatDate, formatDateFromCalendar } from 'constants/utils'
import {
  faTimes,
  faUserFriends,
  faCalendar,
  faStream,
} from '@fortawesome/free-solid-svg-icons'

import hooks from 'hooks'
import { priorityOptions, cardSizeOptions } from 'constants/constants'

import DropDown from 'components/drop-down'
import Sidebar from 'components/sidebar'
import IconButton from 'components/icon-button'
import DeleteCard from '../delete-card'

import FormDatepicker from 'components/form-datepicker'
import DurationField from './components/DurationField'

import styles from './create-card-form.module.scss'
import CardDatesRange from './components/CardDatesRange'
import { useSubscription } from 'hooks/use-subscription'

function CreateTaskFormView({
  open,
  onClose,
  projectsList,
  customCardCategories,
  isSubmitting,
  onSubmit,
  onDelete,
  editingCard,
}) {
  const [selectedCard, setSelectedCard] = useState('')
  const { canUseCardsTimeline } = useSubscription()
  const auth = hooks.useAuth()

  const assignee = editingCard?.assignee

  const author = editingCard?.author ?? auth.user

  const authorName = `${author.firstName} ${author.lastName}`

  const iconSelectStyles = {
    valueContainer: base => ({
      ...base,
      display: 'flex',
      flexWrap: 'noWrap',
      padding: '4px 23px',
      fontSize: 14,
    }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none',
    }),
    placeholder: () => ({
      position: 'relative',
      whiteSpace: 'nowrap',
    }),
    singleValue: () => ({
      paddingLeft: 0,
    }),
    menuPortal: base => ({
      ...base,
      zIndex: 9999,
      backgroundColor: 'white',
    }),
  }

  const selectStyles = {
    valueContainer: base => ({
      ...base,
      display: 'flex',
      flexWrap: 'noWrap',
      padding: '4px 10px',
      fontSize: 14,
    }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none',
    }),
    placeholder: () => ({
      position: 'relative',
      whiteSpace: 'nowrap',
    }),
    singleValue: () => ({
      paddingLeft: 0,
    }),
  }

  const initialValues = {
    title: editingCard?.title ?? '',
    projectId: editingCard?.project?.id ?? null,
    priority: editingCard?.priority ?? '',
    content: editingCard?.content ?? '',
    category:
      editingCard?.category && editingCard?.category !== ''
        ? editingCard.category
        : '',
    size: editingCard?.size ?? '',
    estimatedDurationDateRange: editingCard?.estimatedDurationDateRange,
    dueDate: editingCard?.dueDate && new Date(editingCard?.dueDate),
    duration: editingCard?.duration ?? 0,
  }

  let projectsListOptions =
    projectsList?.map(pj => {
      return { label: pj.name, value: pj.id }
    }) || []

  projectsListOptions = [
    ...projectsListOptions,
    { label: 'Unassigned', value: null },
  ]

  const handleProjectInputChange = newValue => {
    const inputValue = newValue.replace(/\W/g, '')
    return inputValue
  }

  const getCategoryName = chosenCategory => {
    if (!chosenCategory) return 'Category'
    const customCategory = customCardCategories.find(
      category => category.value === chosenCategory,
    )

    if (!customCategory) return 'Category'

    return customCategory.label
  }

  return (
    <Sidebar open={open} onClose={onClose}>
      <Formik
        validateOnBlur={false}
        enableReinitialize
        initialValues={initialValues}
        validate={values => {
          const errors = {}

          if (!values.title) {
            errors.title = 'Required'
          }

          return errors
        }}
        onSubmit={({
          title,
          projectId,
          priority,
          content,
          category,
          size,
          dueDate,
          estimatedDurationDateRange,
          duration,
        }) => {
          onSubmit({
            status: editingCard?.status ?? 'Incoming',
            category,
            title,
            projectId,
            priority,
            content: content ?? '',
            assigneeId: assignee?.id,
            size,
            duration,
            id: editingCard?.id ?? null,
            isUpdate: editingCard,
            dueDate: dueDate ? formatDateFromCalendar(dueDate) : null,
            ...(!!estimatedDurationDateRange && {
              estimatedDurationDateRange: {
                estimatedStartingDate:
                  estimatedDurationDateRange.estimatedStartingDate,
                estimatedCompletionDate:
                  estimatedDurationDateRange.estimatedCompletionDate,
              },
            }),
          })
        }}
      >
        {form => {
          return (
            <Form className={styles['form-wrap']}>
              <div className={styles['form-header']} data-test="form-header">
                <div className={styles['form-header-top']}>
                  <h2 className={styles['card-title']}>
                    {form?.values.title || 'New Card'}
                  </h2>
                  <div className="level-right">
                    <IconButton
                      icon={faTimes}
                      action={onClose}
                      className={cx(
                        'level-item',
                        'button is-white',
                        styles['close-button'],
                      )}
                    />
                  </div>
                </div>

                <div className={styles['card-options']}>
                  <div className={styles['project-option-wrapper']}>
                    <div
                      className={cx(
                        'field',
                        styles['field-container'],
                        styles['card-option'],
                        styles['project-option'],
                      )}
                    >
                      <Field
                        name="projectId"
                        defaultOptions={[{ label: 'Unassigned', value: null }]}
                        options={projectsListOptions}
                        onInputChange={handleProjectInputChange}
                        iid="projectId"
                        styles={iconSelectStyles}
                        placeholder={editingCard?.project?.name ?? 'Project'}
                        icon={faStream}
                        component={DropDown}
                        menuPortalTarget={document.body}
                      />
                      <ErrorMessage
                        className="error"
                        name="projectId"
                        component="div"
                      />
                    </div>
                  </div>

                  <div className={styles['card-secondary-options']}>
                    <div
                      className={cx(
                        'field',
                        styles['field-container'],
                        styles['card-option'],
                        styles['category'],
                      )}
                    >
                      <Field
                        name="category"
                        options={customCardCategories}
                        iid="category"
                        styles={iconSelectStyles}
                        placeholder={
                          editingCard
                            ? getCategoryName(editingCard.category)
                            : 'Category'
                        }
                        defaultValue={editingCard?.category}
                        icon={faStream}
                        component={DropDown}
                      />
                      <ErrorMessage
                        component="div"
                        className={cx('error', styles['project-error'])}
                        name="category"
                      />
                    </div>

                    <div
                      className={cx(
                        'field',
                        styles['field-container'],
                        styles['card-option'],
                        styles['priority'],
                      )}
                    >
                      <Field
                        name="priority"
                        options={priorityOptions}
                        iid="priority"
                        styles={selectStyles}
                        placeholder="Priority"
                        defaultValue={
                          editingCard?.priority
                            ? {
                                label: editingCard.priority,
                                name: editingCard.priority,
                              }
                            : null
                        }
                        component={DropDown}
                      />
                    </div>

                    <div
                      className={cx(
                        'field',
                        styles['field-container'],
                        styles['card-option'],
                        styles['size'],
                      )}
                    >
                      <Field
                        name="size"
                        options={cardSizeOptions}
                        iid="size"
                        styles={selectStyles}
                        placeholder="Size"
                        defaultValue={
                          editingCard?.size
                            ? {
                                label: editingCard.size,
                                name: editingCard.size,
                              }
                            : null
                        }
                        component={DropDown}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles['form-content']}>
                <div className={cx('field', styles['field-container'])}>
                  <label className="label">Card Title *</label>
                  <div
                    className="control"
                    style={{ marginLeft: '10px', width: '96%' }}
                  >
                    <Field
                      className="input"
                      type="text"
                      name="title"
                      placeholder="Card title..."
                    />
                    <ErrorMessage
                      className="error"
                      name="title"
                      component="div"
                    />
                  </div>
                </div>

                {canUseCardsTimeline() && (
                  <CardDatesRange
                    id={editingCard?.id}
                    startDate={
                      form.values.estimatedDurationDateRange
                        ?.estimatedStartingDate
                    }
                    endDate={
                      form.values.estimatedDurationDateRange
                        ?.estimatedCompletionDate
                    }
                  />
                )}

                <div
                  className={cx(
                    'field',
                    styles['field-container'],
                    styles['card-option'],
                  )}
                >
                  <label className="label">Due Date</label>
                  <div style={{ marginLeft: '10px', width: '96%' }}>
                    <FormDatepicker
                      name="dueDate"
                      minDate={new Date()}
                      className={cx(styles['datepicker'], 'input')}
                    />
                    <ErrorMessage
                      className="error"
                      name="dueDate"
                      component="div"
                    />
                  </div>
                </div>

                <div className={cx('field', styles['field-container'])}>
                  <label className="label">Duration</label>
                  <div
                    className="control"
                    style={{ marginLeft: '10px', width: '96%' }}
                  >
                    <Field name="duration" component={DurationField} />
                    <ErrorMessage
                      className="error"
                      name="duration"
                      component="div"
                    />
                  </div>
                </div>

                <div className={cx('field', styles['field-container'])}>
                  <label className="label" htmlFor="content">
                    Card Content
                  </label>
                  <Field name="content">
                    {({ field }) => (
                      <textarea
                        className={cx(
                          'text-area',
                          'is-borderless',
                          styles['description'],
                        )}
                        rows="10"
                        name="content"
                        placeholder="Task description..."
                        {...field}
                      />
                    )}
                  </Field>
                </div>
                <div className={styles['stamps']}>
                  <div className={styles['stamp-wrap']}>
                    <span className={styles['stamp-title']}>
                      <FontAwesomeIcon
                        className={styles['title-icon']}
                        icon={faUserFriends}
                      />
                      Card created by
                    </span>
                    <br />
                    <span
                      className={styles['stamp-content']}
                      data-test="card-author"
                    >
                      {authorName}
                    </span>
                  </div>

                  {editingCard && (
                    <div className={styles['stamp-wrap']}>
                      <span className={styles['stamp-title']}>
                        <FontAwesomeIcon
                          className={styles['title-icon']}
                          icon={faCalendar}
                        />
                        Card created on
                      </span>
                      <br />
                      <span className={styles['stamp-content']}>
                        {formatDate(editingCard.createdAt)}
                      </span>
                    </div>
                  )}
                </div>
                <div className={styles['buttons']}>
                  <button
                    data-test={`card-${
                      editingCard ? 'delete' : 'reset'
                    }-button`}
                    className={cx(
                      'button',
                      'is-light',
                      styles['delete-button'],
                    )}
                    onClick={e => {
                      if (editingCard) {
                        e.preventDefault()
                        setSelectedCard(editingCard)
                      } else {
                        e.preventDefault()
                        form.resetForm()
                      }
                    }}
                  >
                    {editingCard ? 'Delete' : 'Reset'}
                  </button>

                  <button
                    className={cx(
                      'button',
                      'is-info',
                      styles['submit-button'],
                      isSubmitting ? 'is-loading' : '',
                    )}
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
      {selectedCard && (
        <DeleteCard
          open={selectedCard}
          onDelete={onDelete}
          selectedCard={selectedCard}
          onClose={() => {
            setSelectedCard('')
            onClose()
          }}
        />
      )}
    </Sidebar>
  )
}

export default CreateTaskFormView
