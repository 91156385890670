import cx from 'classnames'

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ProjectSectionTitle from '../components/project-section-title'

import styles from './NextTaskForm.module.scss'

const snoozingOptions = [
  {
    label: '1d',
    value: '1 Day',
  },
  {
    label: '2d',
    value: '2 Days',
  },
  {
    label: '3d',
    value: '3 Days',
  },
  {
    label: '4d',
    value: '4 Days',
  },
  {
    label: '5d',
    value: '5 Days',
  },
  {
    label: '6d',
    value: '6 Days',
  },
  {
    label: '1w',
    value: '1 Week',
  },
  {
    label: '2w',
    value: '2 Weeks',
  },
]

export default function NextTaskForm({
  onTaskCompleted = () => {},
  onProjectSnoozed = () => {},
  nextTaskToComplete = {},
} = {}) {
  return (
    <div className={styles.wrapper}>
      <ProjectSectionTitle title="Project next task" />

      <div
        className={cx(
          'mt-2',
          styles['task-form-wrapper'],
          'is-flex is-justify-content-space-between is-align-items-center',
        )}
      >
        <div className={styles['task-title']}>{nextTaskToComplete.title}</div>

        <div>
          <button
            className={cx('button', 'is-small', 'is-light')}
            onClick={onTaskCompleted}
          >
            <span className="icon is-medium">
              <FontAwesomeIcon icon={faCheck} size="lg" />
            </span>
          </button>
        </div>
      </div>

      <div className="mt-3">
        <div className="mt-2 is-flex is-justify-content-space-between">
          <div className="has-text-weight-bold	is-size-6">Snooze</div>
          {snoozingOptions.map(({ label, value }) => {
            return (
              <span
                onClick={() => onProjectSnoozed(value)}
                key={label}
                className="tag is-info is-light is-clickable"
              >
                {label}
              </span>
            )
          })}
        </div>
      </div>
    </div>
  )
}
