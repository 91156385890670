import { useMemo } from 'react';
import PropTypes from 'prop-types'
import cx from 'classnames'
import format from 'date-fns/format'
import { useTable, useSortBy } from 'react-table'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons/faLongArrowAltDown'
import { faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons/faLongArrowAltUp'

import styles from './timesheet.module.scss'
import { formatMinutes, getDatesBetween } from './timesheet-utils'

/**
 * This table will only work with dates headers
 */
function TimesheetTable(props) {
  const dates = getDatesBetween(props.datesRange.start, props.datesRange.end)

  const data = useMemo(() => {
    return props.data
  }, [props.data])

  const columns = useMemo(
    () => [
      ...props.startHeaders,
      ...dates.map(date => ({
        Header: format(date, 'EEEEEE, MMM d'),
        accessor: format(date, 'yyyy-MM-dd'),
        Footer: info => {
          const total = useMemo(
            () =>
              info.rows.reduce(
                (sum, row) =>
                  row.values[format(date, 'yyyy-MM-dd')]
                    ? row.values[format(date, 'yyyy-MM-dd')] + sum
                    : sum,
                0,
              ),
            [info.rows],
          )

          return (
            <div className={styles['time-record-box']}>
              {formatMinutes(total)}
            </div>
          )
        },
      })),
      {
        Header: 'Total',
        accessor: 'totalWeekTime',
        Footer: info => {
          const total = useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => row.values['totalWeekTime'] + sum,
                0,
              ),
            [info.rows],
          )

          return (
            <div className={styles['time-record-box']}>
              {formatMinutes(total)}
            </div>
          )
        },
      },
      ...props.endHeaders,
    ],
    [props.records],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'projectName',
            asc: true,
          },
        ],
      },
    },
    useSortBy,
  )
  return (
    <table
      className={cx(styles['timesheets-table'], 'table is-fullwidth')}
      {...getTableProps()}
    >
      <thead className={styles['timesheets-table-thead']}>
        {headerGroups.map(headerGroup => {
          const { key, ...restHeaderGroupProps } =
            headerGroup.getHeaderGroupProps()

          return (
            <tr key={key} {...restHeaderGroupProps}>
              {headerGroup.headers.map(column => {
                const { key, ...restHeaderProps } = column.getHeaderProps(
                  column.getSortByToggleProps(),
                )
                return (
                  <th
                    key={key}
                    {...restHeaderProps}
                    {...(column.id === 'deleteRecord'
                      ? { onClick: e => e.preventDefault() }
                      : {})}
                  >
                    {column.render('Header')}
                    <span className={styles['sort-icon']}>
                      {column.isSorted && column.id !== 'deleteRecord' ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon
                            icon={faLongArrowAltDown}
                            size="sm"
                            style={{ marginLeft: '5px', color: 'black' }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faLongArrowAltUp}
                            size="sm"
                            style={{ marginLeft: '5px', color: 'black' }}
                          />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                )
              })}
            </tr>
          )
        })}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr key={i} {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td
                    key={i}
                    {...cell.getCellProps()}
                    onClick={() => {
                      return props.onCellSelected({
                        cellId: cell.column.id,
                        rowData: cell.row.original,
                      })
                    }}
                  >
                    {cell.render(props.renderCell)}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>

      <tfoot>
        {footerGroups.map(footerGroup => {
          const { key, ...restFooterGroupProps } =
            footerGroup.getFooterGroupProps()
          return (
            <tr key={key} {...restFooterGroupProps}>
              {footerGroup.headers.map(column => {
                const { key, ...restFooterProps } = column.getFooterProps()

                return (
                  <td key={key} {...restFooterProps}>
                    {column.render('Footer')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tfoot>
    </table>
  )
}

TimesheetTable.propTypes = {
  renderCell: PropTypes.func,
  onCellSelected: PropTypes.func,
  datesRange: PropTypes.object,
  data: PropTypes.array,
  columns: PropTypes.array,
  startHeaders: PropTypes.array,
  endHeaders: PropTypes.array,
}

export default TimesheetTable
