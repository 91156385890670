import cx from 'classnames'
import Switch from 'react-switch'
import { Link, useHistory, useLocation } from 'react-router-dom'

import styles from './create.module.scss'
import { CARDS_NEW } from 'constants/routes'

export default function CreateToolbar({ settings, setSetting }) {
  const isTeam = useLocation().pathname.includes('/tools')

  const history = useHistory()

  return (
    <div className="level">
      <div className={cx(styles['toolbar-item'])}>
        <div className="level-left">
          <label title="including all Done cards" className="level">
            <span className="level-left" style={{ marginRight: 8 }}>
              List all {isTeam ? 'team' : 'my'} cards
            </span>
            <div className="level-right">
              <Switch
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={35}
                onColor="#76c066"
                onChange={() => setSetting('cardsView', !settings.cardsView)}
                checked={!settings.cardsView}
              />
            </div>
          </label>
        </div>
      </div>

      <button className="button is-normal">
        {isTeam ? (
          <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
            {'< My view'}
          </Link>
        ) : (
          <Link
            to="/tools/create"
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            {'Team view >'}
          </Link>
        )}
      </button>

      <button
        className="button is-info is-normal"
        style={{ marginLeft: 12 }}
        onClick={() => history.push(CARDS_NEW)}
        data-test="add-card"
      >
        Add Card
      </button>
    </div>
  )
}
