import { Draggable } from 'react-beautiful-dnd'
import cx from 'classnames'
import { format } from 'date-fns'

import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglass } from '@fortawesome/free-solid-svg-icons'

import Avatar from '../../../../../components/avatar'
import styles from '../create.module.scss'
import { useHistory } from 'react-router-dom'
import { createCardDetailsUrl } from 'constants/routes'
import useCompanySettings from '../../../../../hooks/useCompanySettings'
import {
  convertSecondsToTimeComponents,
  getNumberWithOrdinal,
} from 'constants/utils'

const Card = ({ card, colors, index, allowStickyCards }) => {
  const projectName = card?.project?.name

  const authorFullName = `${card.author.firstName} ${card.author.lastName}`

  const shortenProjectName = () => {
    const maxLength = 30
    if (projectName?.length >= maxLength) {
      const shorterName = projectName?.substring(0, maxLength) + '...'
      return shorterName
    } else {
      return projectName
    }
  }

  const { preferences } = useCompanySettings()

  const { dateFormat } = preferences ?? {}

  const dueDate =
    card.dueDate && format(new Date(card.dueDate), dateFormat ?? 'd.MM.yyyy')

  const overDueBy =
    !!card.dueDate &&
    Math.floor(
      (new Date().getTime() - new Date(card.dueDate).getTime()) /
        1000 /
        60 /
        60 /
        24,
    )

  const history = useHistory()

  return (
    <Draggable
      isDragDisabled={!allowStickyCards}
      draggableId={card.id}
      index={index}
    >
      {(provided, snapshot) => {
        const isDragging = snapshot.isDragging
        return (
          <div
            data-test={card.title}
            onClick={() => history.push(createCardDetailsUrl(card.id))}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            className={cx(
              styles['card-container'],
              isDragging ? styles['dragging'] : '',
            )}
          >
            <div
              data-test="card-header"
              style={{
                backgroundColor:
                  card.category && colors[card.category]
                    ? colors[card.category]
                    : '#565656',
              }}
              className={styles['card-header']}
            >
              <div className={styles['title-wrap']}>
                <div data-test="project-name" className={styles['subtitle']}>
                  {shortenProjectName()}
                </div>
                <div className={styles['title']}>{card.title}</div>
              </div>

              <div className={styles['card-stats']}>
                <div
                  className={cx(styles['size-wrap'], !card?.size ? 'hide' : '')}
                >
                  <div data-test="card-size" className={styles['size']}>
                    {card?.size}
                  </div>
                </div>

                <div
                  data-test="card-priority"
                  className={cx(
                    styles['priority-wrap'],
                    !card?.priority ? 'hide' : '',
                  )}
                >
                  <div className={styles['priority']}>
                    {getNumberWithOrdinal(card?.priority)}
                  </div>
                </div>

                <div
                  data-test="card-duration"
                  className={cx(
                    styles['duration-wrap'],
                    card?.duration === 0 ? 'hide' : '',
                  )}
                >
                  <div className={styles['duration']}>
                    {convertDurationObjectToString(card?.duration)}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles['card-footer']}>
              <div className={styles['card-footer-item']}>
                <Avatar isSmall user={card?.author} />

                <span className={cx(styles['name'], 'is-size-7')}>
                  By{' '}
                  <strong data-test="card-author">
                    {authorFullName ?? 'Nobody'}
                  </strong>
                </span>
              </div>
              {!!dueDate && (
                <div className={styles['card-footer-item']}>
                  <div className={styles['icon-container']}>
                    <FontAwesomeIcon icon={faHourglass} />
                  </div>
                  <span className={cx(styles['name'], 'is-size-7')}>
                    Due by <strong data-test="card-due-date">{dueDate}</strong>
                    <span className={styles['overdue-by']}>
                      {overDueBy > 0 && `+${overDueBy}`}
                    </span>
                  </span>
                </div>
              )}
            </div>
          </div>
        )
      }}
    </Draggable>
  )
}

function convertDurationObjectToString(duration) {
  const timeComponents = convertSecondsToTimeComponents(duration)
  const days = timeComponents.days

  let durationString = ''

  if (days > 0) {
    durationString += `${days}d `
  }

  if (timeComponents.hours > 0) {
    durationString += `${timeComponents.hours}h `
  }

  return durationString
}

export default Card

Card.propTypes = {
  index: PropTypes.number.isRequired,
  card: PropTypes.object.isRequired,
}
