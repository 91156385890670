import { useHistory } from 'react-router-dom'

import SignUpView from './sign-up-view'
import { useMutation } from '@apollo/react-hooks'
import { SIGN_UP } from '../../graphql/mutations'

import hooks from '../../hooks'

const SignUpContainer = () => {
  const auth = hooks.useAuth()
  const history = useHistory()

  const [signUp, { loading, error }] = useMutation(SIGN_UP)

  const submitSignUp = async password => {
    try {
      signUp({
        variables: {
          signUpPassword: password,
        },
      })
      auth.signOut()
      history.push('/')
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  return <SignUpView onSignUp={submitSignUp} loading={loading} error={error} />
}

export default SignUpContainer
