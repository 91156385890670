const { useAuth } = require('./use-auth')

export function useSubscription() {
  const auth = useAuth()

  function getMaxNumberOfProjects() {
    return auth.user?.subscription?.canCreateProjects?.meta.maxNumberOfProjects
  }

  function getNumberOfActiveProjects() {
    return auth.user?.subscription?.canCreateProjects?.meta
      .numberOfActiveProjects
  }

  function getNumberOfRemainingProjects() {
    return auth.user?.subscription?.canCreateProjects?.meta.remainingProjects
  }

  function canAddNewProjects() {
    return auth.user?.subscription?.canCreateProjects?.allowed || false
  }

  function getNumberOfRemainingTeamMembers() {
    return auth.user?.subscription?.canCreateTeamMembers?.meta
      .remainingTeamMembers
  }

  function canAddNewTeamMembers() {
    return auth.user?.subscription?.canCreateTeamMembers?.allowed || false
  }

  function canUseTimeline() {
    return auth.user?.subscription?.canUseTimeline?.allowed || false
  }

  function canUseCardsTimeline() {
    return auth.user?.subscription?.canUseCardsTimeline?.allowed || false
  }

  function canUseTimesheet() {
    return auth.user?.subscription?.canUseTimesheet?.allowed || false
  }

  function canUseStickyCards() {
    return true
  }

  return {
    getMaxNumberOfProjects,
    getNumberOfActiveProjects,
    getNumberOfRemainingProjects,
    canAddNewProjects,
    getNumberOfRemainingTeamMembers,
    canAddNewTeamMembers,
    canUseTimeline,
    canUseTimesheet,
    canUseCardsTimeline,
    canUseStickyCards,
  }
}
