import { useState } from 'react';
import cx from 'classnames'

import ProjectSectionTitle from './components/project-section-title'

import styles from './project-description.module.scss'

const ProjectDescriptionStatus = ({
  isAnonymous,
  readOnly,
  value,
  onUpdate,
  title,
  fieldName,
}) => {
  const [status, setStatus] = useState(value ?? '')
  const [disabled, setDisabled] = useState(true)

  function onUpdateStatus(e) {
    const newStatus = e.target.value

    setStatus(newStatus)
    onUpdate({ target: { name: fieldName, value: newStatus } })
  }

  const iOS = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent)

  function showInput() {
    return (
      <div className="field">
        <div className="control">
          <textarea
            id="project-text-area"
            className={cx(
              styles['textarea-input'],
              'textarea is-borderless with-shadow',
            )}
            placeholder="Project status..."
            name={fieldName}
            value={status}
            readOnly={iOS && !isAnonymous ? false : disabled}
            onChange={onUpdateStatus}
            onClick={() => (!readOnly ? setDisabled(false) : '')}
            onBlur={() => (!readOnly ? setDisabled(true) : '')}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={styles['project-description-status']}>
      <ProjectSectionTitle
        title={title}
        icon="icon-exclamation"
        subtitle={
          fieldName === 'privateStatus' &&
          'Clients will not see this in email updates'
        }
      />
      {showInput()}
    </div>
  )
}

export default ProjectDescriptionStatus
