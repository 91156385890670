import cx from 'classnames'
import { Link } from 'react-router-dom'

import Loader from 'components/loader'

import styles from './overview.module.scss'

let colors = [
  '#50b649',
  '#1d863c',
  '#084b6e',
  '#068b8f',
  '#0f9ab4',
  '#4c1a63',
  '#b649b6',
  '#c33939',
  '#ec7241',
  '#f0b919',
  '#95d152',
]

colors = [...colors, ...colors, ...colors, ...colors]

function renderProjectsGroupedByPhase(projectsByPhases, isCondensed) {
  return (
    <div className="is-flex is-flex-wrap-wrap is-justify-content-space-between">
      {projectsByPhases.map(({ phaseTitle, projects, isCustom }, i) => {
        const projectsToRender = isCondensed ? projects.slice(0, 4) : projects

        return (
          <div className="is-flex mb-4" key={`${phaseTitle}-kanban-card`}>
            <div className={styles['card-container']}>
              <div
                className={styles['card-header']}
                style={{ backgroundColor: colors[i] }}
              >
                <div>{phaseTitle}</div>
                {isCustom && (
                  <div className="mt-2">
                    <span className="tag">Custom phase</span>
                  </div>
                )}
              </div>

              <div className={styles['card-body']}>
                {projectsToRender.map(project => {
                  return (
                    <div
                      className={cx(
                        styles['card-container-item'],
                        styles['card-body-item'],
                      )}
                      key={project.name}
                    >
                      <Link to={`/projects/${project.id}`}>{project.name}</Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const OverviewView = ({
  projectsByPhases,
  isCondensed = true,
  loading = false,
  defaultTemplateId,
}) => {
  return (
    <div data-test="kanban-container">
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <Loader />
        </div>
      ) : projectsByPhases.length > 0 ? (
        renderProjectsGroupedByPhase(projectsByPhases, isCondensed)
      ) : (
        <div className="container" id={styles['no-cards-container']}>
          {defaultTemplateId
            ? 'The are no projects using the selected project id'
            : 'Select a template ID to start'}
        </div>
      )}
    </div>
  )
}

export default OverviewView
