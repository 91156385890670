import { useState } from 'react';
import { toast as notify } from 'bulma-toast'
import { useMutation } from '@apollo/react-hooks'
import { addHours, subHours, addSeconds } from 'date-fns'

import { UPDATE_PROJECT_PHASE_BY_ID } from 'graphql/mutations'
import styles from './PhaseDateRange.module.scss'

import DatePicker from 'components/datepicker'
import { GET_PROJECTS_WITH_PHASES } from 'graphql/queries'

function PhaseDateRange({ id, startDate, endDate, minDate }) {
  const [localStartDate, setLocalStartDate] = useState(startDate)
  const [localEndDate, setLocalEndDate] = useState(endDate)

  const [updateProjectPhase] = useMutation(UPDATE_PROJECT_PHASE_BY_ID, {
    refetchQueries: [{ query: GET_PROJECTS_WITH_PHASES }],
  })

  async function handleSelectRange([start, end]) {
    setLocalStartDate(start)
    setLocalEndDate(end)

    const startMidday = addHours(start, 12)
    const endMidday = addSeconds(subHours(end, 12), 1)

    const input = {
      estimatedDurationDateRange: {
        estimatedStartingDate: startMidday,
        estimatedCompletionDate: endMidday,
      },
    }

    try {
      await updateProjectPhase({
        variables: { id, input },
      })

      notify({
        message: `Phase dates updated successfully`,
        type: 'is-success',
        dismissible: true,
        position: 'bottom-right',
        duration: 3000,
        closeOnClick: true,
      })
    } catch {
      notify({
        message: `Could not update phase dates`,
        type: 'is-danger',
        dismissible: true,
        position: 'bottom-right',
        duration: 3000,
        closeOnClick: true,
      })
      setLocalStartDate(startDate)
      setLocalEndDate(endDate)
    }
  }

  return (
    <div className={styles.container}>
      <h4>Estimated dates</h4>
      <DatePicker
        isRange
        singleOnChange
        startValue={localStartDate && new Date(localStartDate)}
        endValue={localEndDate && new Date(localEndDate)}
        onChange={handleSelectRange}
        minDate={minDate}
      />
    </div>
  )
}

export default PhaseDateRange
