import { useMemo, useState } from 'react'

import { useMutation, useQuery } from '@apollo/react-hooks'
import addDays from 'date-fns/addDays'

import { GET_PROJECTS_WITH_PHASES } from 'graphql/queries'
import { UPDATE_PROJECT_PHASE_BY_ID } from 'graphql/mutations'
import { toast as notify } from 'bulma-toast'
import { calculateHighlights } from '../utils/calculateHighlights'

function populatePhasesEstimatedDurationDateRange({
  phases = [],
  projectStartDate,
}) {
  return phases.map(phase => {
    const { estimatedStartingDate, estimatedCompletionDate } =
      phase.estimatedDurationDateRange ?? {}

    // Add dates if estimated dates are not there for old phases
    const start = estimatedStartingDate
      ? new Date(estimatedStartingDate)
      : new Date(projectStartDate)

    const end = estimatedCompletionDate
      ? new Date(estimatedCompletionDate)
      : new Date(addDays(new Date(projectStartDate), 7))

    return {
      ...phase,
      estimatedDurationDateRange: {
        estimatedStartingDate: start,
        estimatedCompletionDate: end,
      },
    }
  })
}

export function createProjectsAndPhases(data, openedProjectIds, searchQuery) {
  const projectsAndPhases = []

  const searchedProjects = searchQuery
    ? data.activeProjects.filter(p =>
        p.name.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    : data.activeProjects

  searchedProjects.forEach(project => {
    const projectPhases = populatePhasesEstimatedDurationDateRange({
      phases: project.phases,
      projectStartDate: project.startDate,
    })

    const highlights = calculateHighlights({ tasks: projectPhases })

    const sortedByEndPhases = [...projectPhases].sort((a, b) =>
      a.estimatedDurationDateRange.estimatedCompletionDate >
      b.estimatedDurationDateRange.estimatedCompletionDate
        ? 1
        : -1,
    )

    projectsAndPhases.push({
      id: project.id,
      name: project.name,
      start: new Date(project.startDate),
      end: sortedByEndPhases[sortedByEndPhases.length - 1]
        .estimatedDurationDateRange.estimatedCompletionDate,
      hideChildren: !openedProjectIds.includes(project.id),
      type: 'project',
      progress: project.progress,
      highlights,
    })

    projectPhases.forEach(phase => {
      const totalTasks = phase.tasks.length
      const completedTasks = phase.tasks.filter(t => t.completed).length
      const progress = 100 / (totalTasks / completedTasks)

      projectsAndPhases.push({
        id: phase.id,
        project: project.id,
        name: phase.title,
        start: phase.estimatedDurationDateRange.estimatedStartingDate,
        end: phase.estimatedDurationDateRange.estimatedCompletionDate,
        type: 'task',
        progress,
        dependencies: phase.dependsOn ? [phase.dependsOn] : [],
      })
    })
  })

  return projectsAndPhases
}

export default function useProjectsTimelineData() {
  const [searchQuery, setSearchQuery] = useState()

  const { data, loading } = useQuery(GET_PROJECTS_WITH_PHASES)
  const [updatePhaseMutation] = useMutation(UPDATE_PROJECT_PHASE_BY_ID)

  const [openedProjectIds, setOpenedProjectIds] = useState([])

  function handleExpanderClick(project) {
    if (openedProjectIds.includes(project.id)) {
      setOpenedProjectIds(openedProjectIds.filter(id => id !== project.id))
    } else {
      setOpenedProjectIds([...openedProjectIds, project.id])
    }
  }

  async function handlePhaseChange(phase) {
    const { id: phaseId, project: projectId } = phase
    const project = data.activeProjects.find(p => p.id === projectId)

    const { start, end } = phase

    const estimatedDurationDateRange = {
      estimatedStartingDate: start,
      estimatedCompletionDate: end,
      __typename: 'EstimatedDurationDateRange',
    }

    if (project.startDate > start) {
      notify({
        message: `Can't start a phase before the start of a project`,
        type: 'is-danger',
        dismissible: true,
        position: 'bottom-right',
        duration: 3000,
        closeOnClick: true,
      })

      return null
    } else {
      try {
        return await updatePhaseMutation({
          variables: {
            id: phaseId,
            input: {
              estimatedDurationDateRange,
            },
          },
          optimisticResponse: {
            typeName: 'Mutation',
            updateProjectPhaseById: {
              successful: true,
              phase: {
                id: phaseId,
                title: phase.name,
                estimatedDurationDateRange,
                __typename: 'ProjectPhase',
              },
              __typename: 'UpdateProjectPhaseById',
            },
          },
        })
      } catch {
        notify({
          message: `Could not update phase ${phase.name}`,
          type: 'is-danger',
          dismissible: true,
          position: 'bottom-right',
          duration: 3000,
          closeOnClick: true,
        })
      }
    }
  }

  const rows = useMemo(() => {
    if (loading) return []

    return createProjectsAndPhases(data, openedProjectIds, searchQuery)
  }, [data, openedProjectIds, searchQuery])

  return {
    rows,
    handleExpanderClick,
    handlePhaseChange,
    loading,
    data,
    searchQuery,
    setSearchQuery,
  }
}
