import cx from 'classnames'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'

import styles from './DatePicker.module.scss'

export default function DatePickerView({
  value,
  startValue,
  endValue,
  minDate,
  maxDate,
  isRange,
  calendarVisible,
  setCalendarVisible,
  label,
  handleChange,
  handleClickDay,
  disabled,
  top = false,
  className,
  containerClassName,
}) {
  return (
    <div className={cx(styles['container'], containerClassName)}>
      <input
        type="text"
        onClick={() => (disabled ? null : setCalendarVisible(!calendarVisible))}
        value={label || 'Select a date'}
        className={cx(
          styles['input'],
          isRange && styles['range-input'],
          className,
        )}
        readOnly
        data-test="date-picker"
      />
      {calendarVisible && (
        <div
          className={cx(
            styles['calendar-container'],
            top ? styles['top'] : styles['bottom'],
          )}
        >
          <Calendar
            onChange={handleChange}
            value={isRange ? [startValue, endValue] : value}
            minDate={minDate}
            maxDate={maxDate}
            selectRange={isRange}
            onClickDay={handleClickDay}
          />
        </div>
      )}
    </div>
  )
}
