import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faPen,
  faFileUpload,
  faFileDownload,
  faCirclePause,
  faCirclePlay,
} from '@fortawesome/free-solid-svg-icons'

import hooks from 'hooks'

import Title from 'components/title'
import Table from 'components/table'

import DeleteProjectModal from './delete-project'

import { formatDate, createClientsNames } from 'constants/utils'

import styles from './projects.module.scss'

function ProjectsView({
  projects,
  deleteProject,
  openEditModal,
  archiveProject,
  projectOnHold,
}) {
  const subscription = hooks.useSubscription()
  const [selectedProject, setSelectedProject] = useState('')
  const [currentPageNumber, setCurrentPageNumber] = useState(0)

  function onOpenDeleteProjectModal(project) {
    setSelectedProject(project)
  }

  function onCloseDeleteProjectModal() {
    setSelectedProject(null)
  }

  const columns = [
    {
      Header: 'Project',
      accessor: 'name',
      sortType: 'basic',
      Cell: props => (
        <span data-test={props.row.original.name}>
          {props.row.original.name}
        </span>
      ),
    },
    {
      Header: 'Clients',
      accessor: row => createClientsNames(row.clients),
      sortType: 'basic',
      Cell: props => createClientsNames(props.row.original.clients),
    },
    {
      Header: 'Started',
      accessor: row => formatDate(row.startDate),
      sortType: 'basic',
      Cell: props => (
        <span data-test="start-date">
          {formatDate(props.row.original.startDate)}
        </span>
      ),
    },
    {
      Header: 'Determination',
      accessor: row => formatDate(row.endDate),
      sortType: 'basic',
      Cell: props => (
        <span data-test="end-date">
          {formatDate(props.row.original.endDate)}
        </span>
      ),
    },
    {
      Header: 'Assigned To',
      accessor: 'assegnees',
      sortType: 'basic',
    },
    {
      Header: ' ',
      Cell: props => {
        return (
          <div className="columns is-vcentered">
            <div className="column is-2">
              <button
                className="button is-small is-light"
                onClick={() => openEditModal(props.row.original)}
              >
                <span className="icon">
                  <FontAwesomeIcon icon={faPen} size="lg" />
                </span>
              </button>
            </div>

            <div className="column is-2">
              <button
                title={
                  props.row.original.onHold
                    ? 'Resume Project'
                    : 'Put Project On Hold'
                }
                className="button is-small is-light"
                onClick={() => projectOnHold(props.row.original)}
                data-test={`${props.row.original.name}-hold-button`}
                disabled={props.row.original.archived}
              >
                <span className="icon">
                  {props.row.original.onHold ? (
                    <FontAwesomeIcon icon={faCirclePlay} size="lg" />
                  ) : (
                    <FontAwesomeIcon icon={faCirclePause} size="lg" />
                  )}
                </span>
              </button>
            </div>

            <div className="column is-2">
              <button
                title={
                  props.row.original.archived
                    ? 'Restore Project'
                    : 'Archive Project'
                }
                className="button is-small is-light"
                onClick={() => archiveProject(props.row.original)}
                data-test={`${props.row.original.name}-archive-button`}
                disabled={
                  props.row.original.archived &&
                  !subscription.canAddNewProjects()
                }
              >
                <span className="icon">
                  {props.row.original.archived ? (
                    <FontAwesomeIcon icon={faFileUpload} size="lg" />
                  ) : (
                    <FontAwesomeIcon icon={faFileDownload} size="lg" />
                  )}
                </span>
              </button>
            </div>

            <div className="column is-2">
              <button
                className="button is-small is-light"
                onClick={() => onOpenDeleteProjectModal(props.row.original)}
                data-test={`${props.row.original.name}-delete-button`}
              >
                <span className="icon is-medium">
                  <FontAwesomeIcon icon={faTrash} size="lg" />
                </span>
              </button>
            </div>
          </div>
        )
      },
    },
  ]

  return (
    <div
      className={styles['projects-wrapper']}
      data-test="administration-projects-container"
    >
      <div className={styles['projects-title-wrapper']}>
        <Title title="Projects" />
        <p>
          You can create{' '}
          <strong>{subscription.getNumberOfRemainingProjects()}</strong> more{' '}
          live projects (
          <strong>{subscription.getNumberOfActiveProjects()}</strong> used of{' '}
          <strong>{subscription.getMaxNumberOfProjects()}</strong> available).
        </p>
      </div>
      <Table
        data={projects}
        columns={columns}
        pageSize={7}
        currentPageNumber={currentPageNumber}
        onChangePage={pageNumber => setCurrentPageNumber(pageNumber)}
      />
      {selectedProject && (
        <DeleteProjectModal
          onClose={onCloseDeleteProjectModal}
          deleteProject={deleteProject}
          selectedProject={selectedProject}
        />
      )}
    </div>
  )
}

export default ProjectsView
