import { useQuery } from '@apollo/react-hooks'

import { GET_PROJECT_TEMPLATES_IDS, GET_EMAIL_TEMPLATES } from 'graphql/queries'

import Loader from 'components/loader'

import TemplatesView from './templates-view'

export default function TemplatesContainer() {
  const { data: projectTemplatesResponse, loading: projectTemplatesLoading } =
    useQuery(GET_PROJECT_TEMPLATES_IDS, { fetchPolicy: 'network-only' })
  const { data: emailTemplatesResponse, loading: emailTemplatesLoading } =
    useQuery(GET_EMAIL_TEMPLATES, { fetchPolicy: 'network-only' })

  return (
    <div data-test="administration-templates-container">
      {projectTemplatesLoading || emailTemplatesLoading ? (
        <div style={{ textAlign: 'center' }}>
          <Loader />
        </div>
      ) : (
        <TemplatesView
          projectTemplates={projectTemplatesResponse.projectTemplates}
          emailTemplates={emailTemplatesResponse.emailTemplates}
        />
      )}
    </div>
  )
}
