import { useMutation } from '@apollo/react-hooks'

import { RECOVER_PASSWORD } from '../../../graphql/mutations'
import PasswordRequestView from './password-request-view'

const PasswordRequestContainer = () => {
  const [
    recoverPassword,
    { loading: mutationLoading, data: mutationData, error: mutationError },
  ] = useMutation(RECOVER_PASSWORD)

  const onSubmit = email => {
    recoverPassword({
      variables: { recoverPasswordEmail: email },
    })
  }

  return (
    <PasswordRequestView
      onSubmit={onSubmit}
      loading={mutationLoading}
      error={mutationError}
      success={mutationData}
    />
  )
}

export default PasswordRequestContainer
