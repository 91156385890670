import styles from '../project-description.module.scss'

const ProjectSectionTitle = ({ title, subtitle }) => {
  return (
    <div className={styles['project-description-section-title']}>
      <p className={styles['title']}>{title}</p>
      <p className={styles['subtitle']}>{subtitle}</p>
    </div>
  )
}

export default ProjectSectionTitle
