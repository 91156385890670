import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import cx from 'classnames'

import { formatDate } from 'constants/utils'

import ProjectSectionTitle from './components/project-section-title'

import styles from './project-description.module.scss'
import 'react-circular-progressbar/dist/styles.css'

const ProjectProgress = ({
  selectedProject,
  statisticsData: {
    numberOfCompletedPhases,
    numberOfPhases,
    totalNumberOfCompletedTasks,
    totalNumberOfTasks,
    progress,
  },
}) => {
  function calculateProgressCircleValue(nrOfItems, nrOfTotalItems) {
    return Math.round((nrOfItems / nrOfTotalItems) * 100)
  }

  return (
    <section className={styles['project-description-progress-wrapper']}>
      <ProjectSectionTitle title="Project progress" icon="icon-flash" />

      <div className={styles['progress-circles']}>
        <div className={cx(styles['progress-circle-box'])}>
          <CircularProgressbar
            className={styles['circle-progress']}
            value={calculateProgressCircleValue(
              numberOfCompletedPhases,
              numberOfPhases,
            )}
            text={`${calculateProgressCircleValue(
              numberOfCompletedPhases,
              numberOfPhases,
            )}%`}
            styles={buildStyles({
              textColor: '#658aff',
              pathColor: '#658aff',
              trailColor: 'rgba(101, 138, 255, 0.2)',
            })}
          />
          <div>
            <p className={styles['completed-phases-text']}>Completed phases</p>
            <p
              className={styles['completed-phases-value']}
            >{`${numberOfCompletedPhases}/${numberOfPhases}`}</p>
          </div>
        </div>
        <div className={cx(styles['progress-circle-box'])}>
          <CircularProgressbar
            className={styles['circle-progress']}
            value={calculateProgressCircleValue(
              totalNumberOfCompletedTasks,
              totalNumberOfTasks,
            )}
            text={`${calculateProgressCircleValue(
              totalNumberOfCompletedTasks,
              totalNumberOfTasks,
            )}%`}
            styles={buildStyles({
              textColor: '#658aff',
              pathColor: '#658aff',
              trailColor: 'rgba(101, 138, 255, 0.2)',
            })}
          />
          <div>
            <p className={styles['completed-phases-text']}>Completed tasks</p>
            <p
              className={styles['completed-phases-value']}
            >{`${totalNumberOfCompletedTasks}/${totalNumberOfTasks}`}</p>
          </div>
        </div>
      </div>

      <div className={styles['line-progress-wrapper']}>
        <div>
          <p className={styles['line-progress-title']}>Start date</p>
          <p
            className={styles['line-progress-subtitle']}
            data-test="start-date"
          >
            {formatDate(selectedProject.startDate)}
          </p>
        </div>
        <div>
          <p className={styles['line-progress-title']}>
            Planning determination
          </p>
          <p className={styles['line-progress-subtitle']} data-test="end-date">
            {formatDate(selectedProject.endDate)}
          </p>
        </div>
      </div>
      <progress
        className={cx(styles['line-progress'], 'progress is-small')}
        value={progress}
        max="100"
      >
        {progress}%
      </progress>
      <p
        className={cx(styles['progress-text'], 'progress-value has-text-white')}
      >
        {progress}%
      </p>
    </section>
  )
}

export default ProjectProgress
