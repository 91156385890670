import { useEffect, useState } from 'react'
import { useAuth } from './use-auth'

import { GET_MY_COMPANY_PERMISSIONS } from 'graphql/queries'
import { COMPANY_PERMISSIONS_CHANGED } from 'graphql/subscriptions'

export default function useCompanyPermissions(client) {
  const auth = useAuth()

  const [gettingMyCompanyPermissions, setGettingMyCompanyPermissions] =
    useState(false)

  useEffect(() => {
    function getMyCompanyPermissionsChanged() {
      if (!auth.authenticated) return
      getMyCompanyPermissions()
      // return subscribeToMyCompanyPermissionsChanged()
    }

    getMyCompanyPermissionsChanged()
  }, [auth.authenticated])

  async function getMyCompanyPermissions() {
    setGettingMyCompanyPermissions(true)

    try {
      const {
        data: { myCompanyPermissions },
      } = await client.query({
        query: GET_MY_COMPANY_PERMISSIONS,
        fetchPolicy: 'network-only',
      })

      auth.updateSession({
        subscription: myCompanyPermissions,
      })
    } finally {
      setGettingMyCompanyPermissions(false)
    }
  }

  function subscribeToMyCompanyPermissionsChanged() {
    return client
      .subscribe({
        query: COMPANY_PERMISSIONS_CHANGED,
      })
      .subscribe({
        next({ data }) {
          auth.updateSession({
            subscription: data.companyPermissionsChanged,
          })
        },
        error(err) {
          // eslint-disable-next-line no-console
          console.error('err', err)
        },
      })
  }

  return { gettingMyCompanyPermissions }
}
