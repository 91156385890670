import gql from 'graphql-tag'

export const TASK_ADDED = gql`
  subscription {
    taskAdded {
      id
      author {
        firstName
        lastName
      }
      category
      status
      content
      createdAt
      id
      assignee {
        id
        firstName
        lastName
      }
      priority
      project {
        id
        name
      }
      size
      title
    }
  }
`

export const TASK_UPDATED = gql`
  subscription {
    taskUpdated {
      id
      author {
        firstName
        lastName
      }
      category
      status
      content
      createdAt
      id
      assignee {
        id
        firstName
        lastName
      }
      priority
      project {
        id
        name
      }
      size
      title
    }
  }
`

export const TASK_DELETED = gql`
  subscription {
    taskDeleted
  }
`

export const TASK_POSITIONS_UPDATED = gql`
  subscription {
    taskPositionsUpdated {
      id
      position
    }
  }
`

export const COMPANY_PERMISSIONS_CHANGED = gql`
  subscription {
    companyPermissionsChanged
  }
`
