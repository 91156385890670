import { useEffect, useState } from 'react';
import Select from 'react-select'
import { useMutation } from '@apollo/client'
import { UPDATE_COMPANY_BY_ID } from '../../../../graphql/mutations'
import { useAuth } from '../../../../hooks/use-auth'
import { showNotification } from '../../../../constants/utils'
import useCompanyPermissions from '../../../../hooks/useCompanySettings'
import { GET_COMPANY_SETTINGS } from '../../../../graphql/queries'

const options = [
  {
    label: 'd/M/yyyy',
    value: 'd/M/yyyy',
  },
  {
    label: 'M/d/yyyy',
    value: 'M/d/yyyy',
  },
]
const Preferences = () => {
  const { preferences } = useCompanyPermissions()
  const [value, setValue] = useState()

  useEffect(
    () => setValue(options.find(o => o.value === preferences?.dateFormat)),
    [preferences?.dateFormat],
  )

  const { companyId } = useAuth()

  const [updateCompanyMutation] = useMutation(UPDATE_COMPANY_BY_ID, {
    refetchQueries: [GET_COMPANY_SETTINGS],
  })

  const updateCompanyPreferences = async (preferences = {}) => {
    try {
      await updateCompanyMutation({
        variables: { id: companyId, input: { settings: { preferences } } },
      })

      showNotification('Company settings update correctly!', 'is-success')
    } catch (e) {
      showNotification(
        'Company settings could not be updated! Please try again in a few moments.',
        'is-danger',
      )
    }
  }

  const onChange = option =>
    updateCompanyPreferences({ dateFormat: option.value })

  return (
    <div>
      <h3>Preferences</h3>

      <section>
        <h5>Date Format</h5>
        <Select
          value={value}
          onChange={onChange}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: 'rgba(101, 138, 255, 0.35)',
              primary: '#658aff',
            },
          })}
          options={options}
        />
      </section>
    </div>
  )
}

export default Preferences
