import cx from 'classnames'
import Switch from 'react-switch'

import { useField, ErrorMessage } from 'formik'
import styles from './FormToggle.module.scss'

function FormToggle({ name, label, mandatory, style }) {
  const [field, , helpers] = useField(name)

  return (
    <div className={cx('field', styles['field-container'])} style={style}>
      <label className="label">
        {label} {mandatory && '*'}
      </label>
      <Switch
        checkedIcon={false}
        uncheckedIcon={false}
        height={20}
        width={35}
        onColor="#76c066"
        checked={field.value}
        onChange={() => {
          helpers.setValue(!field.value)
        }}
      />
      <ErrorMessage className="error" name={name} component="div" />
    </div>
  )
}

export default FormToggle
