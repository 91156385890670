import { useState } from 'react';
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker'

import Toggle from '../../../dashboard/project-modal/toggle'

import { dayOptions } from '../../../../constants/constants'

import styles from '../settings.module.scss'

const WeeklyUpdatesForm = ({
  settings,
  setWeeklyUpdates,
  onConfigureWeeklyUpdates,
  user,
}) => {
  const dayList = ['', ...dayOptions]
  const [day, setDay] = useState(settings && settings.day)
  const [time, setTime] = useState(settings && settings.time)

  function onReset() {
    setDay()
    setTime()
  }

  function onTimeChange(date) {
    const formatedDate = date.toISOString()
    setTime(formatedDate)
  }

  function onSelectChange(e) {
    setDay(e.target.value)
  }

  return (
    <>
      <div className="subtitle is-5">Configure weekly clients' updates</div>
      <Toggle
        text="Enable weekly client updates globally"
        isActive={settings ? !!settings.enabled : false}
        disabled={
          user.role !== 'admin' || !settings || !settings.time || !settings.day
        }
        setIsActive={() => setWeeklyUpdates()}
      />

      <div className={styles['form-wrapper']}>
        <div className={cx(styles['field-container'], 'field day')}>
          <div className="field-label is-normal">
            <label className="label">Day*</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <span className="select">
                  <select
                    disabled={user.role !== 'admin'}
                    onChange={onSelectChange}
                    value={day ? day : 0}
                  >
                    {dayList.map((dayOption, index) => (
                      <option key={dayOption} value={index}>
                        {dayOption}
                      </option>
                    ))}
                  </select>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={cx(styles['field-container'], 'field time')}>
          <div className="field-label is-normal">
            <label className="label">Time*</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control has-icons-left">
                <span className="select">
                  <DatePicker
                    selected={time && new Date(time)}
                    onChange={date => onTimeChange(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    className={cx(
                      'form-control',
                      user.role !== 'admin' ? styles['disabled'] : '',
                      styles['select'],
                    )}
                    timeIntervals={15}
                    timeCaption="Time"
                    disabled={user.role !== 'admin'}
                    dateFormat="h:mm aa"
                  />
                </span>
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faCalendar} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {user.role === 'admin' && (
        <div className={cx(styles['form-button'], 'buttons is-right')}>
          <button
            // eslint-disable-next-line
            disabled={!day || (day === '0' && !time)}
            className={cx('button', 'is-light', styles['action-button'])}
            onClick={onReset}
          >
            Reset
          </button>

          <button
            disabled={!day || day === '0' || !time}
            className={cx('button', 'is-info', styles['action-button'])}
            onClick={() => {
              if (!day && !time) return

              onConfigureWeeklyUpdates({
                day: day,
                time: time,
              })
            }}
          >
            Save
          </button>
        </div>
      )}
    </>
  )
}

export default WeeklyUpdatesForm
