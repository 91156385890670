import isToday from 'date-fns/isToday'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'

export function mapProjectToSnooze(project) {
  /*
			white(today) indicating it has been looked at that day.
			Next day(tomorrow) it goes green, then goes amber(oneday) with one day time to go?
			then goes red(expired) once the number of days has passed
	*/

  if (project.snoozed) {
    const snoozedTo = new Date(project.snoozed.snoozedTo)
    const today = new Date()

    const diffInDays = differenceInCalendarDays(snoozedTo, today)

    const isOneDayToGo = () => {
      if (diffInDays <= 0) {
        return false
      }

      return diffInDays === 1
    }

    if (isToday(snoozedTo) || snoozedTo < today) {
      return { ...project, snoozeType: 'expired' }
    }

    if (isOneDayToGo(snoozedTo)) {
      return { ...project, snoozeType: 'oneday' }
    }

    if (isToday(snoozedTo)) {
      return { ...project, snoozeType: 'today' }
    }

    return { ...project, snoozeType: 'ongoing' }
  }

  return project
}

export default {
  Project: {
    snoozeType: obj => {
      return mapProjectToSnooze(obj).snoozeType || ''
    },
  },
}
