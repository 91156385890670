import cx from 'classnames'
import { ErrorMessage, Field } from 'formik'

import styles from './FormInput.module.scss'

const FormInput = ({ name, label, mandatory, type = 'text' }) => {
  return (
    <div className={cx('field', styles['field-container'])}>
      <label className="label">
        {label} {mandatory && '*'}
      </label>
      <div className="control">
        <Field className="input" type={type} name={name} />
        <ErrorMessage className="error" name={name} component="div" />
      </div>
    </div>
  )
}

export default FormInput
