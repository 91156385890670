import cx from 'classnames'

import ConfirmationModal from '../../../../components/confirmation-modal'

import styles from './project-phases.module.scss'

const DeleteModal = ({
  title,
  onClose,
  deleteAction,
  submitting,
  elemType,
  open = false,
}) => {
  return (
    <ConfirmationModal open={open} onClose={onClose}>
      <div style={{ padding: '24px' }}>
        <p>
          Are you sure you want to delete {elemType}{' '}
          <span style={{ fontWeight: 'bold' }}>{title}</span> ?
        </p>
        <div className="buttons is-right">
          <button
            className={cx('button', 'is-light', styles['action-button'])}
            onClick={onClose}
            disabled={submitting}
          >
            Cancel
          </button>

          <button
            className={cx(
              'button',
              'is-info',
              styles['action-button'],
              submitting ? 'is-loading' : '',
            )}
            onClick={() => {
              deleteAction()
              onClose()
            }}
            type="submit"
            data-test="confirm-button"
          >
            Delete
          </button>
        </div>
      </div>
    </ConfirmationModal>
  )
}

export default DeleteModal
