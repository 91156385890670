import { useMutation } from '@apollo/react-hooks'
import { showNotification } from '../../../constants/utils'

import { GET_COMPANY_SETTINGS } from '../../../graphql/queries'
import { DELETE_CARD_CATEGORY } from '../../../graphql/mutations'

const makeMutationOptions = categoryId => {
  return {
    variables: { categoryId },
    refetchQueries: [
      {
        query: GET_COMPANY_SETTINGS,
      },
    ],
  }
}

export const useConfigureDeleteCardCategoryMutation = () => {
  const [deleteCardCategory] = useMutation(DELETE_CARD_CATEGORY)

  return newConfig => {
    const options = makeMutationOptions(newConfig)

    return deleteCardCategory(options)
      .then(res => {
        const {
          data: {
            deleteCardCategory: { success, error = '' },
          },
        } = res

        if (!success)
          showNotification(
            error || "It's not possible to delete the category",
            'is-danger',
          )
      })
      .catch(error => showNotification(error.message, 'is-danger'))
  }
}
