import { toast as notify } from 'bulma-toast'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

export const showNotification = (message, type) => {
  notify({
    message: message,
    type: type,
    dismissible: true,
    position: 'bottom-right',
    duration: 3000,
    closeOnClick: true,
  })
}

export const formatDate = date => {
  return date ? format(parseISO(date), 'LLLL d, y') : ''
}

export const createClientsNames = projectClients => {
  return projectClients
    .map(
      client =>
        `${(client && client.firstName) || ''} ${
          (client && client.lastName) || ''
        }`,
    )
    .join(', ')
}

export const calculateProjectProgress = phases => {
  const numberOfTasks = phases.reduce(
    (acc, phase) => acc + phase.tasks.length,
    0,
  )
  const numberOfCompletedTasks = phases.reduce((acc, phase) => {
    const { tasks } = phase

    const completedTasks = tasks.filter(task => task.completed)

    return acc + completedTasks.length
  }, 0)

  return Math.floor((numberOfCompletedTasks / numberOfTasks) * 100) || 0
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export function getColumnLabel(droppableId) {
  if (droppableId) {
    let columnLabel = droppableId.substring(0, droppableId.indexOf('-'))
    return columnLabel
  }
  return 'Incoming'
}

export function getColumnAssignee(droppableId) {
  const columnAssignee = droppableId.substring(droppableId.indexOf('-') + 1)

  if (columnAssignee === 'company') {
    return null
  }

  return columnAssignee
}

export function validEmail(email) {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email,
  )
}

export function isValidUrl(url) {
  const regex =
    /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi

  return regex.test(url)
}

export function formatDateFromCalendar(date) {
  return format(date, 'yyyy-MM-dd')
}

export function convertSecondsToTimeComponents(seconds) {
  // 1 day = 8 hours
  let days = Math.floor(seconds / (60 * 60 * 8))
  let hours = Math.floor((seconds / (60 * 60)) % 8)

  return {
    days,
    hours,
  }
}

export function convertTimeComponentsToSeconds(duration) {
  const { days, hours } = duration
  // 1 day = 8 hours
  return days * 28800 + hours * 3600
}

export function getNumberWithOrdinal(n) {
  var s = ['th', 'st', 'nd', 'rd'],
    v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}
