import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
function OverviewToolbar({ setSetting }) {
  const isTeam = useLocation().pathname.includes('/tools')

  return (
    <div className="level">
      <button
        className="button is-info is-normal"
        onClick={() => setSetting('addHoursModalOpen', true)}
      >
        Add Hours
      </button>

      {!isTeam ? (
        <div style={{ marginLeft: 10 }}>
          <button className="button is-normal">
            <Link
              to="/tools/hours"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              {'View full page >'}
            </Link>
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

OverviewToolbar.propTypes = {
  settings: PropTypes.object,
  setSetting: PropTypes.func,
}

export default OverviewToolbar
