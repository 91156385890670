import { useQuery } from '@apollo/client'
import { useAuth } from 'hooks/use-auth'
import { createContext } from 'react'
import { GET_COMPANY_SETTINGS } from '../graphql/queries'

export const CompanySettingsContext = createContext()

const CompanySettingsContextProvider = ({ children }) => {
  const { authenticated } = useAuth()
  const { data: companySettings } = useQuery(GET_COMPANY_SETTINGS, {
    skip: !authenticated,
  })

  return (
    <CompanySettingsContext.Provider value={{ ...companySettings?.settings }}>
      {children}
    </CompanySettingsContext.Provider>
  )
}

export default CompanySettingsContextProvider
