import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

import styles from './ImpersonatingBanner.module.scss'
import { useAuth } from 'hooks/use-auth'

const ImpersonatingBanner = () => {
  const { user } = useAuth()

  return (
    <div className={styles['banner']}>
      <FontAwesomeIcon icon={faTriangleExclamation} />
      <span className={styles['message']}>
        Careful you are impersonating{' '}
        <strong className={styles['userInfo']}>
          {user.firstName} {user.lastName}
        </strong>{' '}
        from <strong className={styles['userInfo']}>{user.company}</strong>
      </span>
      <FontAwesomeIcon icon={faTriangleExclamation} />
    </div>
  )
}

export default ImpersonatingBanner
