import cx from 'classnames'
import { Route, Switch, useLocation } from 'react-router-dom'

import tabStyles from '../dashboard/project-modal/project-modal.module.scss'
import styles from './BackOffice.module.scss'

import Companies from './components/Companies'
import VirginProjectTemplates from './components/VirginProjectTemplates'
import VirginEmailTemplates from './components/VirginEmailTemplates'
import Support from './components/Support'

import {
  VIRGIN_PROJECT_TEMPLATES,
  VIRGIN_EMAIL_TEMPLATES,
  COMPANIES,
  SUPPORT,
  BACKOFFICE,
} from 'constants/routes'
import { TabLink, TabsContainer } from 'components/Tabs'

const BackOffice = () => {
  const { pathname } = useLocation()

  return (
    <>
      <div
        className={cx(tabStyles['tabs'], styles['project-tabs'])}
        data-test="back-office-container"
      >
        <TabsContainer className={tabStyles['tabs-items']}>
          <TabLink
            to={COMPANIES}
            externalIsActive={
              pathname === COMPANIES || pathname === '/back-office'
            }
            dataTest="tab-companies"
          >
            Companies
          </TabLink>
          <TabLink
            to={VIRGIN_PROJECT_TEMPLATES}
            dataTest="tab-virgin-project-templates"
          >
            Virgin Project Templates
          </TabLink>
          <TabLink
            to={VIRGIN_EMAIL_TEMPLATES}
            dataTest="tab-virgin-email-templates"
          >
            Virgin Email Templates
          </TabLink>
          <TabLink to={SUPPORT} dataTest="tab-support">
            Support
          </TabLink>
        </TabsContainer>
      </div>
      <Switch>
        <Route path={VIRGIN_PROJECT_TEMPLATES}>
          <VirginProjectTemplates />
        </Route>

        <Route path={VIRGIN_EMAIL_TEMPLATES}>
          <VirginEmailTemplates />
        </Route>

        <Route path={SUPPORT}>
          <Support />
        </Route>

        {/* Also matches COMPANIES route */}
        <Route path={BACKOFFICE}>
          <Companies />
        </Route>
      </Switch>
    </>
  )
}

export default BackOffice
