import Title from '../../../../components/title'

import TemplatesTable from './templates-table-view'

import styles from '../templates.module.scss'

const TemplateView = ({
  type,
  toggleCreateTemplateModal,
  templates,
  openEditModal,
  openCloneModal,
}) => {
  return (
    <>
      <div className={styles['templates-title-wrapper']}>
        <Title
          title={type === 'project' ? 'Project templates' : 'Email templates'}
        />
      </div>

      <TemplatesTable
        type={type}
        templates={templates}
        openEditModal={openEditModal}
        openCloneModal={openCloneModal}
        onToggleCreateModal={toggleCreateTemplateModal}
      />
    </>
  )
}

export default TemplateView
