import { useState, useRef } from 'react';
import cx from 'classnames'
import { Formik, Form, Field, ErrorMessage } from 'formik'

import HeaderView from 'components/create-form-modal/header'
import FooterView from 'components/create-form-modal/footer'
import ConfirmationModal from 'components/confirmation-modal'

import { showNotification } from 'constants/utils'

import styles from './project-form.module.scss'

function CreateClientFormModal({ open, onClose, createUser }) {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const formRef = useRef()

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    phoneNumber: '',
  }

  function onCreate() {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  function onReset() {
    if (formRef.current) {
      const form = formRef.current
      form.resetForm()
    }
  }

  function onSubmit(clientData) {
    clientData.role = 'client'

    createUser(clientData)
      .then(() => {
        setIsSubmitting(false)
        showNotification('Client created correctly', 'is-success')
        onReset()
        onClose()
      })
      .catch(error => showNotification(error.message, 'is-danger'))
      .finally(() => setIsSubmitting(false))
  }

  return (
    <ConfirmationModal open={open} onClose={onClose}>
      <HeaderView title="Create new client" onClose={onClose} />
      <div className={styles['form-wrapper']}>
        <Formik
          enableReinitialize
          innerRef={formRef}
          validateOnBlur={false}
          initialValues={initialValues}
          validate={values => {
            const errors = {}

            if (!values.firstName) {
              errors.firstName = 'Required'
            }

            if (!values.lastName) {
              errors.lastName = 'Required'
            }

            if (!values.email) {
              errors.email = 'Required'
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address'
            }

            return errors
          }}
          onSubmit={onSubmit}
        >
          {() => (
            <Form>
              <div className={cx('field', styles['field-container'])}>
                <label className="label">First Name *</label>
                <div className="control">
                  <Field className="input" type="text" name="firstName" />
                  <ErrorMessage
                    className="error"
                    name="firstName"
                    component="div"
                  />
                </div>
              </div>

              <div className={cx('field', styles['field-container'])}>
                <label className="label">Last Name *</label>
                <div className="control">
                  <Field className="input" type="text" name="lastName" />
                  <ErrorMessage
                    className="error"
                    name="lastName"
                    component="div"
                  />
                </div>
              </div>

              <div className={cx('field', styles['field-container'])}>
                <label className="label">Email *</label>
                <div className="control">
                  <Field className="input" type="email" name="email" />
                  <ErrorMessage
                    className="error"
                    name="email"
                    component="div"
                  />
                </div>
              </div>

              <div className={cx('field', styles['field-container'])}>
                <label className="label">Company</label>
                <div className="control">
                  <Field
                    className="input"
                    type="text"
                    name="organization.name"
                  />
                  <ErrorMessage
                    className="error"
                    name="organization.name"
                    component="div"
                  />
                </div>
              </div>

              <div className={cx('field', styles['field-container'])}>
                <label className="label">Address</label>
                <div className="control">
                  <Field className="input" type="text" name="address" />
                  <ErrorMessage
                    className="error"
                    name="address"
                    component="div"
                  />
                </div>
              </div>

              <div className={cx('field', styles['field-container'])}>
                <label className="label">Phone Number *</label>
                <div className="control">
                  <Field
                    className="input"
                    type="phoneNumber"
                    name="phoneNumber"
                  />
                  <ErrorMessage
                    className="error"
                    name="phoneNumber"
                    component="div"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <FooterView
        onReset={onReset}
        onCreate={onCreate}
        submitting={isSubmitting}
        submitButtonText="Create"
        resetButtonText="Reset"
        dataTestSubmitButton="submit-create-client"
      />
    </ConfirmationModal>
  )
}

export default CreateClientFormModal
