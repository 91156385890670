import Sidebar from '../sidebar'
import HeaderView from './header'
import FooterView from './footer'

import styles from './create-form-modal.module.scss'

const CreateFormModal = ({
  children,
  open,
  onClose,
  title,
  onCreate,
  onReset,
  submitting,
  submitButtonText = 'Create',
  resetButtonText = 'Reset',
  noPadding = false,
  hideFooter = false,
  dataTestSubmit,
}) => {
  return (
    <Sidebar open={open} onClose={onClose}>
      <HeaderView title={title} onClose={onClose} />

      <div className={styles.content} style={noPadding ? { padding: 0 } : {}}>
        {children}
      </div>

      {hideFooter ? (
        <></>
      ) : (
        <FooterView
          onReset={resetButtonText === 'Close' ? onClose : onReset}
          onCreate={onCreate}
          submitting={submitting}
          submitButtonText={submitButtonText}
          resetButtonText={resetButtonText}
          dataTestSubmitButton={dataTestSubmit}
        />
      )}
    </Sidebar>
  )
}

export default CreateFormModal
