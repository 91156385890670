import { Route, Switch, useHistory } from 'react-router-dom'
import { COMPANY_NEW, COMPANIES_DETAILS } from 'constants/routes'

import TabContainer from 'components/TabContainer'
import CompanyEditor from './components/CompanyEditor'
import CompaniesTable from './components/CompaniesTable'

const Companies = () => {
  const history = useHistory()
  return (
    <TabContainer title="Companies" dataTest="bo-companies-container">
      <button
        className="button is-info is-normal"
        onClick={() => history.push(COMPANY_NEW)}
      >
        Add Company
      </button>

      <CompaniesTable />

      <Switch>
        <Route exact path={COMPANY_NEW}>
          <CompanyEditor />
        </Route>

        <Route exact path={COMPANIES_DETAILS}>
          <CompanyEditor />
        </Route>
      </Switch>
    </TabContainer>
  )
}

export default Companies
