import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom'

import { Container, Draggable } from 'react-smooth-dnd'
import cx from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckSquare,
  faCheckCircle,
  faFile,
  faCommentDots,
  faCaretSquareDown,
  faAlignLeft,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

import ExpansionPanel from 'components/expansion-panel'
// import ChatCenter from './chat-center'
import Documents from './documents'
import Tasks from './tasks'
import DeleteModal from './delete-modal'

import styles from './project-phases.module.scss'
import PhaseDateRange from './components/PhaseDateRange'
import PhaseDependsOnSelect from './components/PhaseDependsOnSelect'
import { useSubscription } from 'hooks/use-subscription'
import { toast } from 'bulma-toast'

function ProjectPhasesView(props) {
  const {
    phases,
    onAddPhase,
    onUpdatePhase,
    onUpdatePhaseNotDebounced,
    onMovePhase,
    onDeletePhase,
    readOnly = false,
    onPostDocument,
    projectId,
    projectStartDate,
  } = props

  const ref = useRef()

  useEffect(() => {
    ref?.current?.scrollIntoView()
  }, [ref])

  const { phaseId: selectedPhaseId } = useParams()

  const { canUseTimeline } = useSubscription()
  const showDuration = canUseTimeline()

  const [selectedPhase, setSelectedPhase] = useState()
  const [selectedPhaseTitle, setSelectedPhaseTitle] = useState()
  const [selectedPhaseDescription, setSelectedPhaseDescription] = useState()

  function createDependsOnOptions(thisPhase) {
    return [
      { label: 'Select...', value: null },
      ...phases
        // Should not be able to chose itself as 'dependsOn'
        .filter(phase => phase.id !== thisPhase.id)
        .map(phase => ({
          value: phase.id,
          label: phase.title,
        })),
    ]
  }

  function onPhaseMoved({ removedIndex: from, addedIndex: to }) {
    if (from === to) return

    const movedPhase = phases[from]
    return onMovePhase(movedPhase, to)
  }

  function closeModal() {
    setSelectedPhase(null)
  }

  function renderPhaseHeading(phase, phaseStatistics, i) {
    function handleDeletePhase(e) {
      e.stopPropagation()

      const isPhaseDependencyOf = phases.some(p => p.dependsOn === phase.id)

      if (isPhaseDependencyOf) {
        toast({
          message: `${phase.title} is dependency of other phases. Clear the dependencies before deleting`,
          type: 'is-danger',
          dismissible: true,
          position: 'bottom-right',
          duration: 3000,
          closeOnClick: true,
        })
      }

      if (!isPhaseDependencyOf) {
        setSelectedPhase(phase)
      }
    }

    return (
      <div className={cx('level', styles['phase-heading-wrapper'])}>
        <div className={cx('level-left', styles['phase-heading-container'])}>
          {readOnly ? (
            <></>
          ) : (
            <div
              className={cx('level-item', styles['trash-icon'])}
              onClick={handleDeletePhase}
            >
              <FontAwesomeIcon icon={faTrash} size="1x" />
            </div>
          )}

          {phaseStatistics.completed ? (
            <div className="level-item">
              <FontAwesomeIcon icon={faCheckSquare} size="lg" />
            </div>
          ) : (
            ''
          )}

          <div className={styles['phase-heading']}>
            {phase.title || `Phase ${i + 1}`}
          </div>
        </div>

        <div className={cx('level-right', styles['phase-icons'])}>
          <div className={cx('level-item', styles['info-icon'])}>
            <span className={styles['phase-statistics']}>
              {phaseStatistics.numberOfCompletedTasks}/
              {phaseStatistics.numberOfTasks}
            </span>
            <FontAwesomeIcon
              icon={faCheckCircle}
              size="lg"
              style={{ marginLeft: '4px' }}
            />
          </div>
          <div className={cx('level-item', styles['info-icon'])}>
            <span className={styles['phase-statistics']}>
              {phase.documents ? phase.documents.length : 0}
            </span>
            <FontAwesomeIcon
              icon={faFile}
              size="lg"
              style={{ marginLeft: '4px' }}
            />
          </div>
          <div className={cx('level-item', styles['info-icon'])}>
            <span className={styles['phase-statistics']}>
              {phase.comments ? phase.comments.length : 0}
            </span>
            <FontAwesomeIcon
              icon={faCommentDots}
              size="lg"
              style={{ marginLeft: '4px' }}
            />
          </div>
          <div className={cx('level-item', styles['info-icon'])}>
            <FontAwesomeIcon icon={faCaretSquareDown} size="lg" />
          </div>
        </div>
      </div>
    )
  }

  function renderCard(title, icon, content) {
    return (
      <div className={styles['phase-inner-card']}>
        <div className={styles['phase-inner-header']}>
          <div className={styles['phase-inner-title']}>
            <div className={styles['phase-inner-title-icon']}>
              <FontAwesomeIcon icon={icon} size="lg" />
            </div>
            <div>{title}</div>
          </div>
        </div>

        <div>{content}</div>
      </div>
    )
  }

  function calculatePhaseStatistics(phase) {
    const numberOfTasks = phase.tasks.length
    const numberOfCompletedTasks = phase.tasks.filter(
      task => task.completed,
    ).length
    return {
      numberOfTasks,
      numberOfCompletedTasks,
      completed: numberOfTasks === numberOfCompletedTasks,
      title: phase.title,
      id: phase.id,
    }
  }

  function onSelectedPhaseTitleUpdate(phase, e) {
    const newStatus = e.target.value

    setSelectedPhaseTitle(newStatus)
    onUpdatePhase(phase, {
      title: newStatus,
    })
  }

  async function onDependsOnChange(phase, newValue) {
    await onUpdatePhaseNotDebounced(phase, { dependsOn: newValue })
  }

  return (
    <div className={styles.root} data-test="phases-tab">
      {readOnly ? (
        <></>
      ) : (
        <button
          type="button"
          className="button is-info"
          onClick={() => {
            onAddPhase({
              title: `Phase ${props.phases.length + 1}`,
              description: '',
            })
          }}
        >
          Add phase
        </button>
      )}
      <Container
        onDrop={onPhaseMoved}
        shouldAcceptDrop={() => !readOnly}
        nonDragAreaSelector=".no-drag"
      >
        {phases.map((phase, phaseIndex) => {
          const phaseStatistics = calculatePhaseStatistics(phase)

          const isPhaseCompleted =
            phaseStatistics.numberOfCompletedTasks ===
            phaseStatistics.numberOfTasks

          const { estimatedStartingDate, estimatedCompletionDate } =
            phase.estimatedDurationDateRange ?? {}

          const dependsOnValue = phase.dependsOn
            ? {
                value: phase.dependsOn,
                label: phases.find(p => p.id === phase.dependsOn).title,
              }
            : { label: 'Select...', value: null }

          return (
            <Draggable key={phase.id} style={{ overflow: 'visible' }}>
              <div
                ref={element => {
                  if (phase.id === selectedPhaseId) {
                    ref.current = element
                  }
                }}
                className={styles.phase}
              >
                <ExpansionPanel
                  title={renderPhaseHeading(
                    phase,
                    phaseStatistics,
                    phaseIndex,
                    false,
                  )}
                  className={
                    isPhaseCompleted
                      ? styles['completed-phase']
                      : styles['phase-wrapper']
                  }
                  preExpanded={[selectedPhaseId]}
                  uuid={phase.id}
                  onChange={() => {
                    setSelectedPhaseTitle(phase.title)
                    setSelectedPhaseDescription(phase.description)
                  }}
                >
                  <div className="no-drag">
                    {showDuration && (
                      <>
                        <PhaseDateRange
                          id={phase.id}
                          projectId={projectId}
                          startDate={estimatedStartingDate}
                          endDate={estimatedCompletionDate}
                          minDate={projectStartDate}
                        />

                        <PhaseDependsOnSelect
                          options={createDependsOnOptions(phase)}
                          onChange={({ value }) =>
                            onDependsOnChange(phase, value)
                          }
                          value={dependsOnValue}
                        />
                      </>
                    )}

                    <div className="no-drag" style={{ padding: '14px' }}>
                      <div className={styles['phase-inner-description']}>
                        <div className={styles['phase-inner-title']}>
                          <div className={styles['phase-inner-title-icon']}>
                            <FontAwesomeIcon icon={faAlignLeft} size="sm" />
                          </div>
                          Phase Title
                        </div>

                        <div className="mt-3">
                          <input
                            disabled={readOnly}
                            type="text"
                            className={cx(
                              styles['phase-input-field'],
                              'glowing-border',
                            )}
                            style={{ width: '100%', boxSizing: 'border-box' }}
                            value={selectedPhaseTitle}
                            onChange={e => onSelectedPhaseTitleUpdate(phase, e)}
                          ></input>
                        </div>
                      </div>

                      <div className={styles['phase-inner-description']}>
                        <div className={styles['phase-inner-title']}>
                          <div className={styles['phase-inner-title-icon']}>
                            <FontAwesomeIcon icon={faAlignLeft} size="sm" />
                          </div>
                          Phase description
                        </div>

                        <div className="mt-3">
                          <textarea
                            disabled={readOnly}
                            className={cx(
                              styles['phase-input-field'],
                              styles['phase-description-field'],
                              'glowing-border',
                            )}
                            style={{ width: '100%', boxSizing: 'border-box' }}
                            value={selectedPhaseDescription}
                            onChange={e => {
                              setSelectedPhaseDescription(e.target.value)

                              onUpdatePhase(phase, {
                                description: e.target.value,
                              })
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    {renderCard(
                      'Project tasks',
                      faCheckCircle,
                      <Tasks
                        tasks={phase.tasks}
                        readOnly={readOnly}
                        projectId={projectId}
                        phaseId={phase.id}
                      />,
                    )}

                    {renderCard(
                      'Documents',
                      faFile,
                      <Documents
                        documents={phase.documents || []}
                        onPostDocument={document =>
                          onPostDocument(phaseIndex, document)
                        }
                      />,
                    )}
                  </div>
                </ExpansionPanel>
              </div>
            </Draggable>
          )
        })}
      </Container>

      {selectedPhase && (
        <DeleteModal
          open={!!selectedPhase}
          title={selectedPhase.title}
          deleteAction={() => onDeletePhase(selectedPhase)}
          onClose={closeModal}
        />
      )}
    </div>
  )
}

export default ProjectPhasesView
