import { useMutation } from '@apollo/react-hooks'
import { showNotification } from '../../../constants/utils'

import {
  ARCHIVE_PROJECT_BY_ID,
  UNARCHIVE_PROJECT_BY_ID,
} from '../../../graphql/mutations'

const archivedToString = archived => (archived ? 'restored' : 'archived')

const makeMutationOptions = ({ id, archived }) => {
  const optimisticResponseKey = archived
    ? 'unarchiveProjectById'
    : 'archiveProjectById'

  const optimisticResponseType = archived
    ? 'UnarchiveProjectResponse'
    : 'ArchiveProjectResponse'

  return {
    variables: { id },
    optimisticResponse: {
      __typename: 'Mutation',
      [optimisticResponseKey]: {
        successful: true,
        project: {
          id,
          archived: archived ? null : new Date().toISOString(),
          __typename: 'Project',
        },
        __typename: optimisticResponseType,
      },
    },
  }
}

const makeSuccessToastMessage = ({ name, archived }) =>
  `Project "${name}" ${archivedToString(archived)} correctly`

export const useArchiveProjectMutation = () => {
  const [archiveProjectMutation] = useMutation(ARCHIVE_PROJECT_BY_ID)
  const [unarchiveProjectMutation] = useMutation(UNARCHIVE_PROJECT_BY_ID)

  return project => {
    const mutation = project.archived
      ? unarchiveProjectMutation
      : archiveProjectMutation

    const options = makeMutationOptions(project)

    return mutation(options)
      .then(() =>
        showNotification(makeSuccessToastMessage(project), 'is-success'),
      )
      .catch(error => showNotification(error.message, 'is-danger'))
  }
}
