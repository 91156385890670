import { createPortal } from 'react-dom'

import styles from './Modal.module.scss'

const Modal = ({ isOpen, children }) => {
  return isOpen
    ? createPortal(
        <div className={styles['wrapper']}>
          <div className={styles['container']}>{children}</div>
        </div>,
        document.getElementById('root'),
      )
    : null
}

export default Modal
