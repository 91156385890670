import { useState, useEffect } from 'react';
import PropTypes from 'prop-types'

import { useApolloClient, useMutation } from '@apollo/react-hooks'
import { toast as notify } from 'bulma-toast'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'

import AddHoursFormModal from './add-hours-form-modal'
import TimesheetView from './timesheet-view'
import { formatDateFromCalendar, showNotification } from 'constants/utils'

import { GET_TIMESHEET_BETWEEN_DATES_RANGE } from 'graphql/queries'
import {
  ADD_RECORD_TO_TIMESHEET,
  HIDE_PROJECT_RECORDS,
  SHOW_HIDDEN_PROJECT_RECORDS,
  DELETE_TIME_RECORD_MUTATION,
} from 'graphql/mutations'

const defaultDatesRange = {
  start: startOfWeek(new Date(), { weekStartsOn: 0 }),
  end: endOfWeek(new Date(), { weekStartsOn: 0 }),
}
function TimesheetContainer(props) {
  const [timesheet, setTimesheet] = useState([])
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showHidden, setShowHidden] = useState(false)
  const [selectedDatesRange, setSelectedDatesRange] =
    useState(defaultDatesRange)

  const apolloClient = useApolloClient()
  const [addRecordToTimesheetMutation] = useMutation(ADD_RECORD_TO_TIMESHEET)
  const [hideProjectRecordsMutation] = useMutation(HIDE_PROJECT_RECORDS)
  const [showHiddenProjectRecordsMutation] = useMutation(
    SHOW_HIDDEN_PROJECT_RECORDS,
  )
  const [deleteTimeRecordMutation] = useMutation(DELETE_TIME_RECORD_MUTATION)

  useEffect(() => {
    fetchTimesheet(selectedDatesRange)
    // eslint-disable-next-line
  }, [selectedDatesRange])

  useEffect(() => {
    if (selectedRecord) {
      const newRecords = timesheet.filter(
        record => selectedRecord.projectId === record.projectId,
      )
      const newSelectedRecord = { ...selectedRecord }
      newSelectedRecord.records = newRecords
      setSelectedRecord(newSelectedRecord)
    }
  }, [timesheet])

  function onRecordSelected(record) {
    setSelectedRecord(record)
    props.openAddHouseModal()
  }

  function fetchTimesheet(datesRange) {
    setIsLoading(true)

    return apolloClient
      .query({
        query: GET_TIMESHEET_BETWEEN_DATES_RANGE,
        variables: {
          datesRange: {
            start: formatDateFromCalendar(datesRange.start),
            end: formatDateFromCalendar(datesRange.end),
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data: { timesheetBetweenDatesRange: timesheetResponse } }) => {
        setTimesheet(timesheetResponse)
      })
      .catch(() => {
        notify({
          message: 'There was an error while fetching your timesheet',
          type: 'is-danger',
          dismissible: true,
          position: 'bottom-right',
          duration: 3000,
          closeOnClick: true,
        })
      })
      .finally(() => setIsLoading(false))
  }

  function addRecordToTimesheet({ hours = 0, minutes = 0, ...rest }) {
    setIsLoading(true)

    // the date and time are sent at GMT timezone at 22.00 of previous day
    const newRecord = {
      time: hours * 60 + minutes,
      ...rest,
    }

    return addRecordToTimesheetMutation({
      variables: {
        input: newRecord,
      },
    })
      .then(() => {
        if (!selectedRecord) props.closeAddHoursModal()
        fetchTimesheet(selectedDatesRange)
      })
      .catch(error => {
        showNotification(error, 'is-danger')
      })
      .finally(() => setIsLoading(false))
  }

  function deleteTimeRecord(recordId) {
    setIsLoading(true)

    return deleteTimeRecordMutation({
      variables: { id: recordId },
    })
      .then(() => {
        fetchTimesheet(selectedDatesRange)
      })
      .catch(error => {
        showNotification(error, 'is-danger')
      })
      .finally(() => setIsLoading(false))
  }

  function hideProjectRecords({ projectId }) {
    setIsLoading(true)

    return hideProjectRecordsMutation({
      variables: { projectId },
    })
      .then(() => {
        fetchTimesheet(selectedDatesRange)
      })
      .catch(error => {
        showNotification(error, 'is-danger')
      })
      .finally(() => setIsLoading(false))
  }

  function showHiddenProjectRecords({ projectId }) {
    setIsLoading(true)

    return showHiddenProjectRecordsMutation({
      variables: { projectId },
    })
      .then(() => {
        fetchTimesheet(selectedDatesRange)
      })
      .catch(error => {
        showNotification(error, 'is-danger')
      })
      .finally(() => setIsLoading(false))
  }

  function onStartDateSelected(startValue) {
    setSelectedDatesRange(currentlySelected => ({
      ...currentlySelected,
      start: startValue,
    }))
  }

  function onEndDateSelected(endValue) {
    setSelectedDatesRange(currentlySelected => ({
      ...currentlySelected,
      end: endValue,
    }))
  }

  function onCloseAddHoursModal() {
    setSelectedRecord(null)
    props.closeAddHoursModal()
  }

  return (
    <>
      <TimesheetView
        records={timesheet}
        loading={isLoading}
        onRecordSelected={onRecordSelected}
        onHideProjectRecords={hideProjectRecords}
        onShowHiddenProjectRecords={showHiddenProjectRecords}
        showHidden={showHidden}
        setShowHidden={setShowHidden}
        selectedDatesRange={selectedDatesRange}
        onStartDateSelected={onStartDateSelected}
        onEndDateSelected={onEndDateSelected}
        defaultDatesRange={defaultDatesRange}
      />

      <AddHoursFormModal
        open={props.addHoursModalOpen}
        onClose={onCloseAddHoursModal}
        onAddRecordToTimesheet={addRecordToTimesheet}
        onDeleteRecord={deleteTimeRecord}
        selectedRecord={selectedRecord}
      />
    </>
  )
}

TimesheetContainer.propTypes = {
  selectedDatesRange: PropTypes.object,
  addHoursModalOpen: PropTypes.bool,
  closeAddHoursModal: PropTypes.func,
  openAddHouseModal: PropTypes.func,
}

export default TimesheetContainer
