import { useState } from 'react';
import PropTypes from 'prop-types'
import Switch from 'react-switch'
import cx from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'

import TimesheetTable from './timesheet-table'
import SearchBar from 'components/search'
import Loader from 'components/loader'

import styles from './timesheet.module.scss'
import { formatMinutes } from './timesheet-utils'
import { useLocation } from 'react-router-dom'

import { groupTimesheetDataByProjectAndDate } from './timesheet-utils'
import Datepicker from 'components/datepicker'

function TimesheetView(props) {
  const areThereRecords = props.records.length !== 0

  function renderTimeRecord(rowProps) {
    const isHidden = rowProps.row.original.hidden

    if (rowProps.column.id === 'totalWeekTime') {
      return (
        <div
          className={styles['time-record-box']}
          onClick={e => e.stopPropagation()}
        >
          {formatMinutes(rowProps.value)}
        </div>
      )
    }

    if (rowProps.column.id === 'projectName') {
      return <div onClick={e => e.stopPropagation()}>{rowProps.value}</div>
    }

    if (rowProps.column.id === 'deleteRecord') {
      return (
        <div
          className="is-clickable"
          onClick={e => e.stopPropagation()}
          title="Archive records"
        >
          {isHidden ? (
            <FontAwesomeIcon
              title="Show records"
              icon={faEye}
              size="1x"
              onClick={() =>
                props.onShowHiddenProjectRecords({
                  projectId: rowProps.row.original.projectId,
                })
              }
            />
          ) : (
            <FontAwesomeIcon
              title="Hide records"
              icon={faEyeSlash}
              size="1x"
              onClick={() =>
                props.onHideProjectRecords({
                  projectId: rowProps.row.original.projectId,
                })
              }
            />
          )}
        </div>
      )
    }

    return (
      <div className={styles['time-record-box-day']}>
        {formatMinutes(rowProps.value) || ''}
      </div>
    )
  }

  function onRecordSelected({ cellId, rowData }) {
    if (
      cellId.date === 'deleteRecord' ||
      cellId.date === 'projectName' ||
      cellId.date === 'totalWeekTime'
    )
      return

    const selectedRecord = {
      date: cellId,
      projectName: rowData.projectName,
      projectId: rowData.projectId,
      records: props.records
        .filter(
          record =>
            record.date === cellId && record.projectId === rowData.projectId,
        )
        .map(record => {
          return {
            phaseId: record.phaseId,
            time: record.time,
            createdAt: +record.createdAt,
            id: record.id,
            description: record.description,
          }
        }),
    }

    return props.onRecordSelected(selectedRecord)
  }

  if (props.loading) {
    return (
      <div className="has-text-centered">
        <Loader />
      </div>
    )
  }

  const [searchValue, setSearchValue] = useState('')

  const isFullpage = useLocation().pathname.includes('/tools')

  let tableData = groupTimesheetDataByProjectAndDate(
    searchValue
      ? props.records.filter(record =>
          record.projectName.toLowerCase().includes(searchValue.toLowerCase()),
        )
      : props.records,
  )

  tableData = props.showHidden
    ? tableData
    : tableData.filter(row => !row.hidden)

  return (
    <>
      <div className="level" data-test="timesheet-container">
        <div className="level-left">
          <div className={cx(styles['search-input'])}>
            <SearchBar
              globalFilter={searchValue}
              setGlobalFilter={setSearchValue}
              disabled={!areThereRecords}
            />
          </div>
          <div className="level-item">
            <div className="is-flex is-align-items-center">
              <div style={{ marginRight: 8 }}>Show hidden</div>
              <Switch
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={35}
                onColor="#76c066"
                onChange={() => props.setShowHidden(!props.showHidden)}
                checked={props.showHidden}
                disabled={!areThereRecords}
              />
              <div className="level-right"></div>
            </div>
          </div>
        </div>

        <div className="level-right">
          <div className="level-item">
            <Datepicker
              isRange
              startValue={props.selectedDatesRange.start}
              endValue={props.selectedDatesRange.end}
              onChangeStartValue={props.onStartDateSelected}
              onChangeEndValue={props.onEndDateSelected}
              maxRange={7}
            />
            <button
              className="button is-light is-normal"
              style={{ marginLeft: '1rem' }}
              onClick={() => {
                props.onStartDateSelected(props.defaultDatesRange.start)
                props.onEndDateSelected(props.defaultDatesRange.end)
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>

      {areThereRecords ? (
        <div className={isFullpage ? '' : styles['timesheets-table-wrapper']}>
          <TimesheetTable
            renderCell={renderTimeRecord}
            onCellSelected={onRecordSelected}
            searchValue={searchValue}
            datesRange={props.selectedDatesRange}
            data={tableData}
            startHeaders={[
              {
                Header: 'Projects',
                Footer: 'Total',
                accessor: 'projectName',
              },
            ]}
            endHeaders={[
              {
                Header: '',
                accessor: 'deleteRecord',
              },
            ]}
          />
        </div>
      ) : (
        <div className="has-text-centered">
          There are no records for the current week
        </div>
      )}
    </>
  )
}

TimesheetView.propTypes = {
  records: PropTypes.array,
  loading: PropTypes.bool,
  selectedDatesRange: PropTypes.object,
  onDateRangeSelected: PropTypes.func,
  onRecordSelected: PropTypes.func,
  onHideProjectRecords: PropTypes.func,
  onShowHiddenProjectRecords: PropTypes.func,
  showHidden: PropTypes.bool,
  setShowHidden: PropTypes.func,
}

export default TimesheetView
