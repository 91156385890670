import { useQuery } from '@apollo/react-hooks'

import hooks from 'hooks'

import Loader from 'components/loader'
import Tools from '../shared/tools'

import { GET_USER_BY_ID } from 'graphql/queries'

function ToolsRoute() {
  const auth = hooks.useAuth()

  const {
    data: userResponse,
    loading: fetchingUserDetails,
    error: fetchingUserDetailsError,
  } = useQuery(GET_USER_BY_ID, {
    variables: { id: auth.user.uid },
    fetchPolicy: 'network-only',
  })

  //Improve how we show errors
  if (fetchingUserDetailsError) {
    // eslint-disable-next-line no-console
    console.error(fetchingUserDetailsError)
    return <p>ERROR</p>
  }

  if (fetchingUserDetails) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  return <Tools fullPage user={userResponse.userById} />
}

export default ToolsRoute
