import { Gantt, ViewMode } from '@yoopknows/timeline'

const GanttTimeline = ({
  tasks,
  viewMode,
  onDateChange,
  onExpanderClick,
  listCellWidth,
  nameCellComponent,
  hasScrollbars,
  initialScrollXDate,
}) => {
  let columnWidth = 65
  if (viewMode === ViewMode.Month) {
    columnWidth = 300
  } else if (viewMode === ViewMode.Week) {
    columnWidth = 250
  }

  return (
    <Gantt
      tasks={tasks}
      viewMode={viewMode}
      onDateChange={onDateChange}
      onExpanderClick={onExpanderClick}
      listCellWidth={listCellWidth}
      columnWidth={columnWidth}
      nameCellComponent={nameCellComponent}
      hasScrollbars={hasScrollbars}
      initialScrollXDate={initialScrollXDate}
    />
  )
}

export default GanttTimeline
