import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const style = {
  maxHeight: '40px',
  cursor: 'pointer',
  marginRight: '8px',
  backgroundColor: 'transparent',
}

const IconButton = ({
  icon,
  color = '#555c72',
  action,
  dataTooltip,
  className,
  dataTest,
  disabled,
}) => {
  return (
    <button
      className={className || 'button is-medium'}
      onClick={() => action()}
      data-tooltip={dataTooltip}
      title={dataTooltip}
      style={color ? { color: color, ...style } : style}
      data-test={dataTest}
      disabled={disabled}
    >
      <span className="icon">
        <FontAwesomeIcon icon={icon} size="lg" />
      </span>
    </button>
  )
}

export default IconButton
