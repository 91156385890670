import gql from 'graphql-tag'
import {
  companyFragment,
  projectPhaseFragment,
  virginEmailTemplateFragment,
  virginProjectTemplateFragment,
} from './fragments'

export const SIGN_IN = gql`
  mutation ($credentials: SignInInput!) {
    signIn(credentials: $credentials) {
      authenticatedUser {
        role
        email
        firstName
        lastName
        uid
        company
        support
      }
    }
  }
`

export const SIGN_OUT = gql`
  mutation {
    signOut
  }
`

export const SNOOZE_PROJECT_BY_ID = gql`
  mutation ($id: ID!, $period: String!) {
    snoozeProjectById(id: $id, period: $period) {
      successful
      project {
        id
        name
        snoozed {
          createdAt
          snoozedTo
        }
        snoozeType @client
      }
    }
  }
`

export const SAVE_NOTIFICATION_CONFIGURATION = gql`
  mutation ($projectId: String!, $value: Boolean!) {
    saveNotificationConfiguration(projectId: $projectId, value: $value) {
      id
      notifications
      __typename
    }
  }
`

export const SAVE_UPDATES_CONFIGURATION = gql`
  mutation ($projectId: String!, $value: Boolean!) {
    saveUpdatesConfiguration(projectId: $projectId, value: $value) {
      id
      update
      __typename
    }
  }
`

export const CREATE_SHAREABLE_LINK = gql`
  mutation ($id: ID!) {
    createProjectShareableLinkById(id: $id) {
      successful
      link
    }
  }
`

export const COMPLETE_PROJECT_TASK = gql`
  mutation ($taskId: ID!, $taskPostScript: String) {
    completeProjectTask(taskId: $taskId, taskPostScript: $taskPostScript) {
      successful
      task {
        id
        completed
      }
    }
  }
`

export const UNDO_PROJECT_TASK = gql`
  mutation ($taskId: ID!) {
    undoProjectTask(taskId: $taskId) {
      task {
        id
        completed
      }
      successful
    }
  }
`

export const POST_PHASE_COMMENT = gql`
  mutation ($projectId: ID!, $phaseIndex: Int!, $comment: CommentInput!) {
    postPhaseComment(
      projectId: $projectId
      phaseIndex: $phaseIndex
      comment: $comment
    ) {
      successful
      project {
        id
      }
    }
  }
`

export const POST_DOCUMENT = gql`
  mutation ($projectId: ID!, $phaseIndex: Int!, $document: DocumentInput!) {
    postPhaseDocument(
      projectId: $projectId
      phaseIndex: $phaseIndex
      document: $document
    ) {
      successful
    }
  }
`
export const SET_SETTINGS = gql`
  mutation ($configuration: WeeklyUpdateConfigurationInput!) {
    configureWeeklyUpdates(configuration: $configuration) {
      success
    }
  }
`
export const ENABLE_UPDATES = gql`
  mutation {
    enableWeeklyUpdates {
      success
    }
  }
`

export const CREATE_CARD_CATEGORY = gql`
  mutation ($category: CreateCategoryInput!) {
    createCardCategory(category: $category) {
      success
      error
    }
  }
`

export const DELETE_CARD_CATEGORY = gql`
  mutation ($categoryId: String!) {
    deleteCardCategory(id: $categoryId) {
      success
    }
  }
`

export const UPDATE_CARD_CATEGORY = gql`
  mutation ($categoryId: String!, $category: CreateCategoryInput!) {
    updateCardCategory(id: $categoryId, category: $category) {
      success
      error
    }
  }
`

export const CREATE_PROJECT = gql`
  mutation ($input: CreateProjectInput!) {
    createProject(input: $input) {
      successful
      project {
        id
      }
    }
  }
`

export const UPDATE_PROJECT_BY_ID = gql`
  mutation ($id: ID!, $input: UpdateProjectInput!) {
    updateProjectById(id: $id, input: $input) {
      successful
      project {
        id
        name
        archived
        status
        privateStatus
        description
        meta {
          projectDocsUrl
        }
        clients {
          id
          firstName
          lastName
          email
          role
          organization {
            name
          }
        }
        engineers {
          id
          firstName
          lastName
          email
          address
          role
          organization {
            name
          }
        }
        consultants {
          id
          firstName
          lastName
          email
          address
          role
          organization {
            name
          }
        }
        surveyors {
          id
          firstName
          lastName
          email
          address
          role
          organization {
            name
          }
        }
        planners {
          id
          firstName
          lastName
          email
          address
          role
          organization {
            name
          }
        }
        warrantOfficers {
          id
          firstName
          lastName
          email
          address
          role
          organization {
            name
          }
        }
        buildingControlOfficers {
          id
          firstName
          lastName
          email
          address
          role
          organization {
            name
          }
        }
        ecologists {
          id
          firstName
          lastName
          email
          address
          role
          organization {
            name
          }
        }
        cdmPrincipalDesigners {
          id
          firstName
          lastName
          email
          address
          role
          organization {
            name
          }
        }
        bsaPrincipalDesigners {
          id
          firstName
          lastName
          email
          address
          role
          organization {
            name
          }
        }
        assignees {
          role
          teamMember {
            id
            firstName
            lastName
            email
            role
          }
        }
        value
        startDate
        endDate
      }
    }
  }
`

export const DELETE_PROJECT_BY_ID = gql`
  mutation ($id: ID!) {
    deleteProjectById(id: $id) {
      successful
      project {
        id
      }
    }
  }
`

// Project phases mutations
export const ADD_PROJECT_PHASE = gql`
  mutation AddProjectPhaseById($projectId: ID!, $input: AddProjectPhaseInput!) {
    addProjectPhase(projectId: $projectId, input: $input) {
      phase {
        ...ProjectPhaseFragment
      }
      successful
      userErrors {
        field
        message
        code
      }
    }
  }
  ${projectPhaseFragment}
`

export const MOVE_PROJECT_PHASE_BY_ID = gql`
  mutation MoveProjectPhaseById($id: ID!, $newPosition: PhasePointer!) {
    moveProjectPhaseById(id: $id, newPosition: $newPosition) {
      phase {
        id
      }
      successful
      userErrors {
        field
        message
        code
      }
    }
  }
`

export const UPDATE_PROJECT_PHASE_BY_ID = gql`
  mutation UpdateProjectPhaseById($id: ID!, $input: UpdateProjectPhaseInput!) {
    updateProjectPhaseById(id: $id, input: $input) {
      successful
      phase {
        ...ProjectPhaseFragment
      }
    }
  }
  ${projectPhaseFragment}
`

export const DELETE_PROJECT_PHASE_BY_ID = gql`
  mutation DeleteProjectPhaseById($id: ID!) {
    deleteProjectPhaseById(id: $id) {
      phase {
        id
      }
      successful
      userErrors {
        message
        code
      }
    }
  }
`

// Project phase tasks mutations
export const ADD_PROJECT_PHASE_TASK = gql`
  mutation AddProjectPhaseTaskById(
    $phaseId: ID!
    $input: AddProjectPhaseTaskInput!
  ) {
    addProjectPhaseTask(phaseId: $phaseId, input: $input) {
      task {
        id
        completed
        title
      }
      successful
      userErrors {
        field
        message
        code
      }
    }
  }
`

export const MOVE_PROJECT_PHASE_TASK_BY_ID = gql`
  mutation MoveProjectPhaseTaskById($id: ID!, $newPosition: PhaseTaskPointer!) {
    moveProjectPhaseTaskById(id: $id, newPosition: $newPosition) {
      task {
        id
      }
      successful
      userErrors {
        field
        message
        code
      }
    }
  }
`

export const UPDATE_PROJECT_PHASE_TASK_BY_ID = gql`
  mutation UpdateProjectPhaseTaskById(
    $id: ID!
    $input: UpdateProjectPhaseTaskByIdInput!
  ) {
    updateProjectPhaseTaskById(id: $id, input: $input) {
      successful
      task {
        id
        title
        notification {
          emailTemplate {
            name
            id
          }
          recipients
          extraRecipients
        }
      }
    }
  }
`

export const DELETE_PROJECT_PHASE_TASK_BY_ID = gql`
  mutation DeleteProjectPhaseTaskById($id: ID!) {
    deleteProjectPhaseTaskById(id: $id) {
      task {
        id
      }
      successful
      userErrors {
        message
        code
      }
    }
  }
`

// Users mutations
export const CREATE_USER = gql`
  mutation ($input: UserInput!) {
    createUser(input: $input) {
      user {
        id
        firstName
        lastName
        email
        address
        phoneNumber
        role
        chatProviderId
      }
      successful
    }
  }
`

export const CREATE_TEAM_MEMBER = gql`
  mutation ($input: UserInput!, $account: UserAccountInput!) {
    createTeamMember(input: $input, account: $account) {
      user {
        id
        firstName
        lastName
        email
        address
        phoneNumber
        role
        chatProviderId
      }
      successful
    }
  }
`

export const UPDATE_USER_BY_ID = gql`
  mutation ($id: ID!, $input: UpdateUserInput!) {
    updateUserById(id: $id, input: $input) {
      successful
      user {
        id
        firstName
        lastName
        email
        address
        role
        chatProviderId
        ... on Client {
          organization {
            name
          }
        }
        ... on Partner {
          organization {
            name
          }
        }
      }
    }
  }
`

export const DELETE_USER_BY_ID = gql`
  mutation ($id: ID!) {
    deleteUserById(id: $id) {
      user {
        id
      }
      successful
    }
  }
`

export const CREATE_PROJECT_TEMPLATE = gql`
  mutation ($input: CreateProjectTemplateInput!) {
    createProjectTemplate(input: $input) {
      projectTemplate {
        id
      }
      successful
    }
  }
`

export const DELETE_PROJECT_TEMPLATE = gql`
  mutation ($id: ID!) {
    deleteProjectTemplateById(id: $id) {
      projectTemplate {
        id
      }
      successful
    }
  }
`

export const UPDATE_PROJECT_TEMPLATE = gql`
  mutation ($id: ID!, $input: UpdateProjectTemplateInput!) {
    updateProjectTemplateById(id: $id, input: $input) {
      projectTemplate {
        id
        name
        phases {
          id
          title
          duration
          description
          tasks {
            id
            title
            private
            notification {
              emailTemplate {
                emailText
                id
              }
              recipients
              extraRecipients
            }
          }
          dependsOn {
            id
          }
        }
      }
      successful
    }
  }
`

export const CREATE_PROJECT_TEMPLATE_PHASE = gql`
  mutation ($projectTemplateId: ID!, $input: CreateProjectTemplatePhaseInput!) {
    createProjectTemplatePhase(
      projectTemplateId: $projectTemplateId
      input: $input
    ) {
      projectTemplatePhase {
        id
        title
        description
        duration
        tasks {
          title
          private
        }
      }
      successful
    }
  }
`

export const DELETE_PROJECT_TEMPLATE_PHASE = gql`
  mutation ($id: ID!) {
    deleteProjectTemplatePhaseById(id: $id) {
      projectTemplatePhase {
        id
      }
      successful
    }
  }
`

export const MOVE_PROJECT_TEMPLATE_PHASE_BY_ID = gql`
  mutation ($id: ID!, $newPosition: Int!) {
    moveProjectTemplatePhaseById(id: $id, newPosition: $newPosition) {
      projectTemplatePhase {
        title
        description
        duration
        tasks {
          title
          private
        }
      }
      successful
    }
  }
`

export const UPDATE_PROJECT_TEMPLATE_PHASE_BY_ID = gql`
  mutation ($id: ID!, $input: UpdateProjectTemplatePhaseInput!) {
    updateProjectTemplatePhaseById(id: $id, input: $input) {
      projectTemplatePhase {
        title
        description
        duration
        tasks {
          title
          private
        }
      }
      successful
    }
  }
`

export const UPDATE_PROJECT_TEMPLATE_PHASE_TASK_BY_ID = gql`
  mutation ($id: ID!, $input: UpdateProjectTemplatePhaseTaskInput!) {
    updateProjectTemplatePhaseTaskById(id: $id, input: $input) {
      projectTemplatePhaseTask {
        id
        title
        private
        notification {
          emailTemplate {
            id
            name
            emailText
            type
          }
          recipients
          extraRecipients
        }
      }
      successful
    }
  }
`

export const CREATE_PROJECT_TEMPLATE_PHASE_TASK = gql`
  mutation ($phaseId: ID!, $input: CreateProjectTemplatePhaseTaskInput!) {
    createProjectTemplatePhaseTask(phaseId: $phaseId, input: $input) {
      projectTemplatePhaseTask {
        id
        title
        private
        notification {
          emailTemplate {
            emailText
            id
          }
          recipients
          extraRecipients
        }
      }
      successful
    }
  }
`

export const MOVE_PROJECT_TEMPLATE_PHASE_TASK_BY_ID = gql`
  mutation ($id: ID!, $newPosition: Int!) {
    moveProjectTemplatePhaseTask(id: $id, newPosition: $newPosition) {
      projectTemplatePhaseTask {
        id
        title
        private
        notification {
          emailTemplate {
            emailText
            id
          }
          recipients
          extraRecipients
        }
      }
      successful
    }
  }
`

export const DELETE_PROJECT_TEMPLATE_PHASE_TASK_BY_ID = gql`
  mutation ($id: ID!) {
    deleteProjectTemplatePhaseTask(id: $id) {
      projectTemplatePhaseTask {
        id
      }
      successful
    }
  }
`

export const CREATE_EMAIL_TEMPLATE = gql`
  mutation ($input: EmailTemplateInput!) {
    createEmailTemplate(input: $input) {
      emailTemplate {
        id
        name
        type
        emailText
      }
      successful
    }
  }
`

export const UPDATE_EMAIL_TEMPLATE_BY_ID = gql`
  mutation ($id: ID!, $input: UpdateEmailTemplateInput!) {
    updateEmailTemplateById(id: $id, input: $input) {
      emailTemplate {
        id
        name
        type
        emailText
      }
      successful
    }
  }
`

export const DELETE_EMAIL_TEMPLATE_BY_ID = gql`
  mutation ($id: ID!) {
    deleteEmailTemplateById(id: $id) {
      emailTemplate {
        id
      }
      successful
    }
  }
`

export const ARCHIVE_PROJECT_BY_ID = gql`
  mutation ($id: ID!) {
    archiveProjectById(id: $id) {
      successful
      project {
        id
        archived
      }
    }
  }
`

export const UNARCHIVE_PROJECT_BY_ID = gql`
  mutation ($id: ID!) {
    unarchiveProjectById(id: $id) {
      successful
      project {
        id
        archived
      }
    }
  }
`

export const PUT_PROJECT_ON_HOLD_BY_ID = gql`
  mutation ($id: ID!) {
    putProjectOnHoldById(id: $id) {
      successful
      project {
        id
        onHold
      }
    }
  }
`

export const REMOVE_PROJECT_HOLD_BY_ID = gql`
  mutation ($id: ID!) {
    removeProjectHoldById(id: $id) {
      successful
      project {
        id
        onHold
      }
    }
  }
`

export const ADD_PROJECT_TO_FAVOURITES = gql`
  mutation ($projectId: ID!) {
    addProjectToFavouritesById(projectId: $projectId) {
      successful
    }
  }
`

export const REMOVE_PROJECT_FROM_FAVOURITES = gql`
  mutation ($projectId: ID!) {
    removeProjectFromFavouritesById(projectId: $projectId) {
      successful
    }
  }
`

export const UPDATE_USER_SETTINGS = gql`
  mutation ($settings: UserSettings!) {
    updateUserSettings(settings: $settings) {
      successful
    }
  }
`

export const SIGN_UP = gql`
  mutation ($signUpPassword: String!) {
    signUp(password: $signUpPassword)
  }
`

export const RECOVER_PASSWORD = gql`
  mutation ($recoverPasswordEmail: EmailAddress!) {
    recoverPassword(email: $recoverPasswordEmail)
  }
`

export const IMPERSONATE_USER = gql`
  mutation ($impersonateEmail: EmailAddress!) {
    impersonate(email: $impersonateEmail) {
      authenticatedUser {
        company
        email
        firstName
        lastName
        role
        uid
      }
    }
  }
`

export const CREATE_TASK = gql`
  mutation ($input: CreateTaskInput!) {
    createTask(input: $input) {
      task {
        completedAt
        id
        category
        priority
        title
        size
        duration
        author {
          firstName
          lastName
        }
        status
        project {
          id
          name
        }
        dueDate
        estimatedDurationDateRange {
          estimatedStartingDate
          estimatedCompletionDate
        }
        position
      }
    }
  }
`

export const UPDATE_CARD_BY_ID = gql`
  mutation ($id: ID!, $input: UpdateTaskInput!) {
    updateTaskById(id: $id, input: $input) {
      task {
        id
        category
        status
        title
        size
        priority
        dueDate
        duration
        estimatedDurationDateRange {
          estimatedStartingDate
          estimatedCompletionDate
        }
        project {
          id
          name
        }
        assignee {
          id
        }
        position
      }
    }
  }
`

export const MOVE_CARDS = gql`
  mutation (
    $taskId: ID!
    $task: UpdateTaskInput!
    $positions: [TaskPositionInput]!
  ) {
    updateTaskById(id: $taskId, input: $task) {
      task {
        id
        category
        status
        title
        size
        priority
        dueDate
        duration
        estimatedDurationDateRange {
          estimatedStartingDate
          estimatedCompletionDate
        }
        project {
          id
          name
        }
        assignee {
          id
        }
        position
      }
    }

    updateTaskPositions(input: $positions) {
      positions {
        id
        position
      }
    }
  }
`

export const DELETE_TASK = gql`
  mutation ($id: ID!) {
    deleteTaskById(id: $id) {
      task {
        id
      }
    }
  }
`

export const ADD_RECORD_TO_TIMESHEET = gql`
  mutation ($input: AddRecordInput!) {
    addRecordToTimesheet(input: $input) {
      record {
        projectName
        projectId
        phaseId
        date
        time
        hidden
        createdAt
        id
        description
        userId
      }
    }
  }
`

export const TOGGLE_INVOICE_STATUS_BY_RECORD_ID = gql`
  mutation ($recordId: ID!) {
    toggleInvoiceStatusByRecordId(recordId: $recordId) {
      successful
    }
  }
`

export const HIDE_PROJECT_RECORDS = gql`
  mutation ($projectId: ID!) {
    hideProjectRecords(projectId: $projectId) {
      successful
    }
  }
`

export const SHOW_HIDDEN_PROJECT_RECORDS = gql`
  mutation ($projectId: ID!) {
    showHiddenProjectRecords(projectId: $projectId) {
      successful
    }
  }
`

export const DELETE_TIME_RECORD_MUTATION = gql`
  mutation ($id: ID!) {
    deleteTimesheetRecordById(id: $id) {
      record {
        id
      }
    }
  }
`

// VIRGIN PROJECT TEMPLATES MUTATIONS
export const CREATE_VIRGIN_PROJECT_TEMPLATE = gql`
  mutation ($input: CreateVirginProjectTemplateInput!) {
    createVirginProjectTemplate(input: $input) {
      virginProjectTemplate {
        ...VirginProjectTemplateFragment
      }
      successful
    }
  }
  ${virginProjectTemplateFragment}
`

export const UPDATE_VIRGIN_PROJECT_TEMPLATE_BY_ID = gql`
  mutation ($id: ID!, $input: UpdateVirginProjectTemplateInput!) {
    updateVirginProjectTemplateById(id: $id, input: $input) {
      virginProjectTemplate {
        ...VirginProjectTemplateFragment
      }
      successful
    }
  }
  ${virginProjectTemplateFragment}
`

export const DELETE_VIRGIN_PROJECT_TEMPLATE_BY_ID = gql`
  mutation ($id: ID!) {
    deleteVirginProjectTemplateById(id: $id) {
      virginProjectTemplate {
        ...VirginProjectTemplateFragment
      }
      successful
    }
  }
  ${virginProjectTemplateFragment}
`

// VIRGIN EMAIL TEMPLATES MUTATIONS
export const CREATE_VIRGIN_EMAIL_TEMPLATE = gql`
  mutation ($input: CreateVirginEmailTemplateInput!) {
    createVirginEmailTemplate(input: $input) {
      virginEmailTemplate {
        ...VirginEmailTemplateFragment
      }
      successful
    }
  }
  ${virginEmailTemplateFragment}
`

export const UPDATE_VIRGIN_EMAIL_TEMPLATE_BY_ID = gql`
  mutation ($id: ID!, $input: UpdateVirginEmailTemplateInput!) {
    updateVirginEmailTemplateById(id: $id, input: $input) {
      virginEmailTemplate {
        ...VirginEmailTemplateFragment
      }
      successful
    }
  }
  ${virginEmailTemplateFragment}
`

export const DELETE_VIRGIN_EMAIL_TEMPLATE_BY_ID = gql`
  mutation ($id: ID!) {
    deleteVirginEmailTemplateById(id: $id) {
      virginEmailTemplate {
        ...VirginEmailTemplateFragment
      }
      successful
    }
  }
  ${virginEmailTemplateFragment}
`

// COMPANIES MUTATIONS
export const CREATE_COMPANY = gql`
  mutation ($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      company {
        ...CompanyFragment
      }
      successful
    }
  }
  ${companyFragment}
`

export const UPDATE_COMPANY_BY_ID = gql`
  mutation ($id: ID!, $input: UpdateCompanyInput!) {
    updateCompanyById(id: $id, input: $input) {
      company {
        ...CompanyFragment
      }
      successful
    }
  }
  ${companyFragment}
`

export const DELETE_COMPANY_BY_ID = gql`
  mutation ($id: ID!) {
    deleteCompanyById(id: $id) {
      company {
        ...CompanyFragment
      }
      successful
    }
  }
  ${companyFragment}
`
