import PropTypes from 'prop-types'
import cx from 'classnames'
import { useLocation } from 'react-router-dom'

import { useQuery } from '@apollo/react-hooks'

import { GET_PROJECT_TEMPLATES_IDS } from '../../../../graphql/queries'

import { Link } from 'react-router-dom'

import Switch from 'react-switch'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup, faFilter } from '@fortawesome/free-solid-svg-icons'

import styles from './overview.module.scss'

function OverviewToolbar({ settings, setSetting }) {
  const path = useLocation().pathname
  const full = path === '/tools'

  const {
    data: projectTemplatesResponse,
    loading: projectTemplatesLoading,
    error: projectTemplatesError,
  } = useQuery(GET_PROJECT_TEMPLATES_IDS, { fetchPolicy: 'network-only' })

  const projectTemplates = projectTemplatesResponse?.projectTemplates || []

  return (
    <div className="level">
      <div className="level-left">
        <div className={cx(styles['toolbar-item'])}>
          <div className="control has-icons-left">
            <div className="select is-fullwidth">
              <select
                className="is-borderless"
                value={settings?.defaultTemplateId || 'Select template id'}
                onChange={e => setSetting('defaultTemplateId', e.target.value)}
                disabled={projectTemplatesLoading || projectTemplatesError}
              >
                <option value="" hidden>
                  Select template id
                </option>
                {projectTemplates.map(option => (
                  <option key={`${option.id}-kanban-toolbar`} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faFilter} />
            </span>
          </div>
        </div>
      </div>

      <div className={cx(styles['toolbar-item'], styles['toolbar-button'])}>
        <div className="level-left">
          <label className="level">
            <span className="icon is-left">
              <FontAwesomeIcon icon={faLayerGroup} />
            </span>
            <span className="level-left" style={{ marginRight: 8 }}>
              Condensed
            </span>
            <div className="level-right">
              <Switch
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={35}
                onColor="#76c066"
                onChange={() =>
                  setSetting('isCondensed', !settings.isCondensed)
                }
                checked={settings.isCondensed}
              />
            </div>
          </label>
        </div>
      </div>

      <div
        className={cx(styles['toolbar-item'], styles['toolbar-button'])}
        style={full ? { display: 'none', marginRight: 0 } : { marginRight: 0 }}
      >
        <div className="level-right">
          <Link
            to="/tools"
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            {'View full board >'}
          </Link>
        </div>
      </div>
    </div>
  )
}

OverviewToolbar.propTypes = {
  filterOptions: PropTypes.array,
  onOptionSelect: PropTypes.func,
  condensedValue: PropTypes.bool,
  onCondenseChange: PropTypes.func,
  full: PropTypes.bool,
}

OverviewToolbar.defaultProps = {
  filterOptions: [],
  onOptionSelect: () => {},
  onCondenseChange: () => {},
}

export default OverviewToolbar
