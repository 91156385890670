import styles from './TabContainer.module.scss'
import Title from '../title'

const TabContainer = ({ title, children, dataTest }) => {
  return (
    <div className={styles['projects-wrapper']} data-test={dataTest}>
      <div className={styles['projects-title-wrapper']}>
        <Title title={title} />
      </div>
      {children}
    </div>
  )
}

export default TabContainer
