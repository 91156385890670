import { useState, useEffect } from 'react';

import { useApolloClient } from '@apollo/react-hooks'
import { toast as notify } from 'bulma-toast'

import { GET_PROJECTS_BY_PHASES } from '../../../../graphql/queries'

import OverviewView from './overview-view'

const OverviewContainer = ({ defaultTemplateId, isCondensed }) => {
  const [projectsByPhases, setProjectsByPhases] = useState([])
  const [fetchingProjectsByPhases, setFetchingProjectsByPhases] =
    useState(false)

  const apolloClient = useApolloClient()

  function onSelectTemplateId(templateId) {
    setFetchingProjectsByPhases(true)

    return apolloClient
      .query({
        query: GET_PROJECTS_BY_PHASES,
        variables: { templateId },
        fetchPolicy: 'network-only',
      })
      .then(({ data: { projectsByPhases: projectsByPhasesResponse } }) =>
        setProjectsByPhases(projectsByPhasesResponse),
      )
      .catch(() => {
        notify({
          message:
            'There was an error while fetching projects for the kanban board',
          type: 'is-danger',
          dismissible: true,
          position: 'bottom-right',
          duration: 3000,
          closeOnClick: true,
        })
      })
      .finally(() => setFetchingProjectsByPhases(false))
  }

  useEffect(() => {
    if (defaultTemplateId) {
      onSelectTemplateId(defaultTemplateId)
    }
    // eslint-disable-next-line
  }, [defaultTemplateId])

  return (
    <>
      <OverviewView
        isCondensed={isCondensed}
        loading={fetchingProjectsByPhases}
        projectsByPhases={projectsByPhases}
        defaultTemplateId={defaultTemplateId}
      />
    </>
  )
}

export default OverviewContainer
