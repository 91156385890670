import { useState } from 'react';
import cx from 'classnames'

import ConfirmationModal from '../../../../components/confirmation-modal'

import styles from './delete-user.module.scss'

const DeleteUserModal = ({ onClose, deleteUser, submitting, selectedUser }) => {
  const [deleting, setDeleting] = useState(false)

  return (
    <ConfirmationModal open onClose={onClose}>
      <div style={{ padding: '24px' }}>
        <p>
          Are you sure you want to delete user
          <span style={{ fontWeight: 'bold' }}>
            {selectedUser.firstName} {selectedUser.lastName}
          </span>
          ?
        </p>
        <div className="buttons is-right">
          <button
            className={cx('button', 'is-light', styles['action-button'])}
            onClick={onClose}
            disabled={submitting}
          >
            Cancel
          </button>

          <button
            className={cx(
              'button',
              'is-info',
              styles['action-button'],
              submitting ? 'is-loading' : '',
            )}
            onClick={() => {
              setDeleting(true)
              deleteUser(selectedUser).then(() => {
                setDeleting(false)
                onClose()
              })
            }}
            disabled={deleting}
            type="submit"
          >
            {deleting ? 'Deleting...' : 'Delete'}
          </button>
        </div>
      </div>
    </ConfirmationModal>
  )
}

export default DeleteUserModal
