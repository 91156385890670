import { useState } from 'react';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { TwitterPicker } from 'react-color'

export default function CategoriesForm({
  companyCategories = [],
  onDeleteCategory,
  onSaveCategory,
  onUpdateCardCategory,
}) {
  const [name, setName] = useState('')
  const [color, setColor] = useState('')
  const [updatingCardId, setUpdatingCardId] = useState('')
  const [loading, setLoading] = useState(false)

  function resetForm() {
    setName('')
    setColor('')
    setUpdatingCardId('')
  }

  const isSaveBtnDisabled = !(name && color)

  return (
    <>
      <div className="subtitle is-5">Cards categories (Create)</div>
      <div className="columns" style={{ height: '300px' }}>
        <fieldset disabled={loading} style={{ borderColor: 'transparent' }}>
          <div className="column">
            <div className="field">
              <label className="label">Name</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </div>
            </div>

            <div className="field">
              <label className="label">Color</label>
              <div className="control">
                <TwitterPicker
                  color={color}
                  onChangeComplete={newColor => setColor(newColor.hex)}
                />
              </div>
            </div>

            <div className="field is-grouped">
              <div className="control">
                <button
                  className="button is-link"
                  disabled={isSaveBtnDisabled}
                  onClick={() => {
                    setLoading(true)

                    if (updatingCardId) {
                      return onUpdateCardCategory({
                        categoryId: updatingCardId,
                        category: { name, color },
                      })
                        .then(() => resetForm())
                        .finally(() => setLoading(false))
                    } else {
                      return onSaveCategory({ name, color })
                        .then(() => resetForm())
                        .finally(() => setLoading(false))
                    }
                  }}
                >
                  {updatingCardId ? 'Update' : 'Create'}
                </button>
              </div>

              <div className="control">
                <button className="button is-link is-light" onClick={resetForm}>
                  Reset
                </button>
              </div>
            </div>
          </div>
        </fieldset>

        <div
          className="column"
          style={{
            overflowY: 'scroll',
            height: '100%',
            borderLeft: '1px solid #cecece',
          }}
        >
          <ul>
            {companyCategories.length > 0 ? (
              companyCategories.map(category => {
                return (
                  <li
                    className="is-flex is-flex-wrap-nowrap is-justify-content-space-between	is-align-content-center"
                    key={category.id}
                  >
                    <div className="mb-3 is-flex is-flex-wrap-nowrap">
                      <div
                        style={{
                          backgroundColor: category.color,
                          width: '20px',
                          height: '20px',
                        }}
                        className="mr-2"
                      ></div>
                      <div>{category.name}</div>
                    </div>

                    <div className="is-flex is-flex-wrap-nowrap">
                      <div className="mr-2">
                        <FontAwesomeIcon
                          style={{ cursor: 'pointer' }}
                          icon={faEdit}
                          size="sm"
                          onClick={() => {
                            setName(category.name)
                            setColor(category.color)
                            setUpdatingCardId(category.id)
                          }}
                        />
                      </div>

                      <div>
                        <FontAwesomeIcon
                          style={{ cursor: 'pointer', color: 'red' }}
                          icon={faTrash}
                          size="sm"
                          onClick={() => {
                            setLoading(true)
                            return onDeleteCategory(category.id).finally(() =>
                              setLoading(false),
                            )
                          }}
                        />
                      </div>
                    </div>
                  </li>
                )
              })
            ) : (
              <div style={{ textAlign: 'center' }}>
                No categories created yet
              </div>
            )}
          </ul>
        </div>
      </div>
    </>
  )
}
