import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import styles from './search.module.scss'

const SearchView = ({ globalFilter, setGlobalFilter, disabled, className }) => {
  return (
    <div className={className}>
      <div className="control has-icons-right">
        <input
          className={cx(styles['search'], 'input is-small is-borderless')}
          type="text"
          value={globalFilter || ''}
          placeholder="Search..."
          onChange={e => {
            setGlobalFilter(e.target.value || undefined)
          }}
          disabled={disabled}
          data-test="search-box"
        />
        <span className="icon is-right">
          <FontAwesomeIcon icon={faSearch} size="lg" />
        </span>
      </div>
    </div>
  )
}

export default SearchView
