import { useState, useEffect } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import { useLocation } from 'react-router-dom'

import {
  GET_TEAM_CLIENTS_PARTNERS,
  GET_PROJECT_TEMPLATES_IDS,
  GET_EMAIL_TEMPLATES,
  GET_PROJECT_DETAILS,
  GET_PROJECTS,
  GET_PROJECT_NAMES,
  GET_PROJECTS_WITH_PHASES,
} from 'graphql/queries'

import {
  CREATE_PROJECT,
  UPDATE_PROJECT_BY_ID,
  CREATE_USER,
} from 'graphql/mutations'

import ProjectFormView from './project-form-view'

function formatUsersTypeForView(users, type) {
  if (!users) return []

  return users
    .filter(user => user?.role === type)
    .map(user => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    }))
}

function formatProjectTemplatesForView(projectTemplates) {
  return projectTemplates.map(({ id, name }) => ({ value: id, label: name }))
}

function formatEmailTemplatesForView(emailTemplates) {
  return emailTemplates
    .filter(template => template.type === 'WEEKLY')
    .map(template => ({ value: template.id, label: template.name }))
}

function createInitialValues(fetchedData) {
  const editingProject = fetchedData.editingProject

  if (!editingProject) {
    return {
      name: '',
      clients: [],
      engineers: [],
      consultants: [],
      contractors: [],
      surveyors: [],
      planners: [],
      warrantOfficers: [],
      buildingControlOfficers: [],
      ecologists: [],
      cdmPrincipalDesigners: [],
      bsaPrincipalDesigners: [],
      projectTemplateId: '',
      emailTemplateId: '',
      description: '',
      startDate: '',
      endDate: '',
      value: '',
      meta: {
        projectDocsUrl: '',
      },
    }
  }

  function getTemplateName() {
    const projectTemplateId = editingProject.projectTemplateId
    const templateObject = fetchedData.projectTemplates.filter(
      p => p.id === projectTemplateId,
    )

    const templateName = templateObject[0].name

    return templateName
  }

  const leads = editingProject.assignees
    ?.filter(assignee => assignee.role === 'lead')
    ?.map(({ teamMember }) => ({
      value: teamMember?.id,
      label: `${teamMember?.firstName} ${teamMember?.lastName}`,
    }))

  const supportMembers = editingProject.assignees
    ?.filter(assignee => assignee.role === 'supportMember')
    ?.map(({ teamMember }) => ({
      value: teamMember?.id,
      label: `${teamMember?.firstName} ${teamMember?.lastName}`,
    }))

  const emailTemplateName = fetchedData.emailTemplates.find(
    template => template.id === editingProject.emailTemplateId,
  ).name

  return {
    name: editingProject.name,
    clients: formatUsersTypeForView(editingProject.clients, 'client'),
    engineers: formatUsersTypeForView(editingProject.engineers, 'engineer'),
    consultants: formatUsersTypeForView(
      editingProject.consultants,
      'consultant',
    ),
    contractors: formatUsersTypeForView(
      editingProject.contractors,
      'contractor',
    ),
    surveyors: formatUsersTypeForView(editingProject.surveyors, 'surveyor'),
    planners: formatUsersTypeForView(editingProject.planners, 'planner'),
    warrantOfficers: formatUsersTypeForView(
      editingProject.warrantOfficers,
      'warrantOfficer',
    ),
    buildingControlOfficers: formatUsersTypeForView(
      editingProject.buildingControlOfficers,
      'buildingControlOfficer',
    ),
    ecologists: formatUsersTypeForView(editingProject.ecologists, 'ecologist'),
    cdmPrincipalDesigners: formatUsersTypeForView(
      editingProject.cdmPrincipalDesigners,
      'cdmPrincipalDesigner',
    ),
    bsaPrincipalDesigners: formatUsersTypeForView(
      editingProject.bsaPrincipalDesigners,
      'bsaPrincipalDesigner',
    ),
    leads,
    supportMembers,
    projectTemplateId: {
      value: editingProject.projectTemplateId,
      label: getTemplateName(),
    },
    emailTemplateId: {
      value: editingProject.emailTemplateId,
      label: emailTemplateName,
    },
    description: editingProject.description,
    startDate: new Date(editingProject.startDate),
    endDate: editingProject.endDate ? new Date(editingProject.endDate) : '',
    value: editingProject.value ?? '',
    meta: editingProject.meta ?? {},
  }
}

function ProjectFormContainer({ open, onClose, editingProjectId }) {
  const client = useApolloClient()
  const location = useLocation()

  const [fetchedData, setFetchedData] = useState({
    users: [],
    projectTemplates: [],
    emailTemplates: [],
    editingProject: null,
    fetching: true,
  })

  const [createProjectMutation] = useMutation(CREATE_PROJECT)
  const [updateProjectMutation] = useMutation(UPDATE_PROJECT_BY_ID)

  const [createUserMutation] = useMutation(CREATE_USER)

  const [createdClient, setCreatedClient] = useState([])

  function createUser(user) {
    return createUserMutation({
      variables: { input: user },
    }).then(res => {
      setFetchedData({
        ...fetchedData,
        users: [...fetchedData.users, res.data.createUser.user],
      })
      setCreatedClient(res.data.createUser.user)
    })
  }

  function createProject(projectDto) {
    const refetchQueries =
      location.pathname === '/administration'
        ? [{ query: GET_PROJECT_NAMES }, { query: GET_PROJECTS_WITH_PHASES }]
        : [
            { query: GET_PROJECTS },
            { query: GET_PROJECT_NAMES },
            { query: GET_PROJECTS_WITH_PHASES },
          ]

    return createProjectMutation({
      variables: { input: projectDto },
      refetchQueries,
    })
      .then(() => {
        onClose()
      })
      .finally(() => setCreatedClient([]))
  }

  function updateProject(projectDto) {
    return updateProjectMutation({
      variables: { id: editingProjectId, input: projectDto },
      refetchQueries: [
        { query: GET_PROJECT_DETAILS, variables: { id: editingProjectId } },
      ],
    })
  }

  useEffect(() => {
    if (!open) return

    const promises = [
      client.query({
        query: GET_TEAM_CLIENTS_PARTNERS,
      }),
      client.query({
        query: GET_PROJECT_TEMPLATES_IDS,
      }),
      client.query({
        query: GET_EMAIL_TEMPLATES,
      }),
    ]

    if (editingProjectId) {
      promises.push(
        client.query({
          query: GET_PROJECT_DETAILS,
          variables: { id: editingProjectId },
          fetchPolicy: 'network-only',
        }),
      )
    }
    // eslint-disable-next-line no-undef
    Promise.all(promises).then(
      ([
        usersResponse,
        projectTemplatesIdsResponse,
        emailTemplatesResponse,
        projectResponse,
      ]) => {
        let newState = {
          users: [
            ...usersResponse.data.team,
            ...usersResponse.data.clients,
            ...usersResponse.data.partners,
          ],
          projectTemplates: projectTemplatesIdsResponse.data.projectTemplates,
          emailTemplates: emailTemplatesResponse.data.emailTemplates,
          fetching: false,
        }

        if (projectResponse) {
          newState = {
            ...newState,
            editingProject: projectResponse.data.projectById,
          }
        }
        setFetchedData(newState)
      },
    )
  }, [open, client, editingProjectId])

  if (fetchedData.fetching) {
    return <></>
  }

  const [
    engineers,
    clients,
    consultants,
    contractors,
    surveyors,
    planners,
    warrantOfficers,
    buildingControlOfficers,
    ecologists,
    cdmPrincipalDesigners,
    bsaPrincipalDesigners,
    staff,
    admins,
  ] = [
    'engineer',
    'client',
    'consultant',
    'contractor',
    'surveyor',
    'planner',
    'warrantOfficer',
    'buildingControlOfficer',
    'ecologist',
    'cdmPrincipalDesigner',
    'bsaPrincipalDesigner',
    'staff',
    'admin',
  ].map(type => formatUsersTypeForView(fetchedData.users, type))

  const team = [...staff, ...admins]

  const projectTemplatesIds = formatProjectTemplatesForView(
    fetchedData.projectTemplates,
  )

  const weeklyEmailTemplates = formatEmailTemplatesForView(
    fetchedData.emailTemplates,
  )

  const initialValues = createInitialValues(fetchedData)

  return (
    <ProjectFormView
      open={open}
      onClose={onClose}
      createUser={createUser}
      createdClient={
        createdClient ? formatUsersTypeForView([createdClient], 'client') : []
      }
      clients={clients}
      engineers={engineers}
      consultants={consultants}
      contractors={contractors}
      surveyors={surveyors}
      planners={planners}
      warrantOfficers={warrantOfficers}
      buildingControlOfficers={buildingControlOfficers}
      ecologists={ecologists}
      cdmPrincipalDesigners={cdmPrincipalDesigners}
      bsaPrincipalDesigners={bsaPrincipalDesigners}
      projectTemplates={projectTemplatesIds}
      weeklyEmailTemplates={weeklyEmailTemplates}
      onCreateProject={createProject}
      onUpdateProject={updateProject}
      editingProject={fetchedData.editingProject}
      initialValues={initialValues}
      team={team}
    />
  )
}

export default ProjectFormContainer
