import { useMutation } from '@apollo/react-hooks'
import debounce from 'lodash.debounce'

import {
  ADD_PROJECT_PHASE_TASK,
  UPDATE_PROJECT_PHASE_TASK_BY_ID,
  DELETE_PROJECT_PHASE_TASK_BY_ID,
  MOVE_PROJECT_PHASE_TASK_BY_ID,
  COMPLETE_PROJECT_TASK,
  UNDO_PROJECT_TASK,
} from 'graphql/mutations'

import { GET_PROJECT_DETAILS } from 'graphql/queries'

import { showNotification } from 'constants/utils'

import TasksView from './tasks-view'
import { useParams } from 'react-router-dom'

function TasksContainer({ tasks, readOnly, projectId, phaseId }) {
  const [completeTaskMutation] = useMutation(COMPLETE_PROJECT_TASK)
  const [undoTaskMutation] = useMutation(UNDO_PROJECT_TASK)
  const [addTaskMutation] = useMutation(ADD_PROJECT_PHASE_TASK)
  const [updateTaskMutation] = useMutation(UPDATE_PROJECT_PHASE_TASK_BY_ID)
  const [moveTaskMutation] = useMutation(MOVE_PROJECT_PHASE_TASK_BY_ID)
  const [deleteTaskMutation] = useMutation(DELETE_PROJECT_PHASE_TASK_BY_ID)

  async function onCompleteTask(task) {
    try {
      await completeTaskMutation({
        variables: {
          taskId: task.id,
          taskPostScript: task.taskPostScript || '',
        },
        refetchQueries: [
          {
            query: GET_PROJECT_DETAILS,
            variables: {
              id: projectId,
            },
          },
        ],
        optimisticResponse: {
          __typename: 'Mutation',
          completeProjectTask: {
            successful: true,
            task: {
              id: task.id,
              completed: true,
              __typename: 'ProjectPhaseTask',
            },
            __typename: 'CompleteTaskResponse',
          },
        },
      })
    } catch (error) {
      showNotification(
        'The task cannot be completed at the moment. Please try again in a few moments.',
        'is-danger',
      )
    }
  }

  async function onUndoTask(task) {
    try {
      await undoTaskMutation({
        variables: {
          taskId: task.id,
        },
        refetchQueries: [
          {
            query: GET_PROJECT_DETAILS,
            variables: {
              id: projectId,
            },
          },
        ],
        optimisticResponse: {
          __typename: 'Mutation',
          completeProjectTask: {
            successful: true,
            task: {
              id: task.id,
              completed: false,
              __typename: 'ProjectPhaseTask',
            },
            __typename: 'CompleteTaskResponse',
          },
        },
      })
    } catch (error) {
      showNotification(
        'The task cannot be undone at the moment. Please try again in a few moments.',
        'is-danger',
      )
    }
  }

  function onAddTask() {
    const taskTitle = `Task ${tasks.length + 1}`

    return addTaskMutation({
      variables: {
        phaseId,
        input: {
          title: taskTitle,
        },
      },
      update: (store, result) => {
        const { projectById } = store.readQuery({
          query: GET_PROJECT_DETAILS,
          variables: {
            id: projectId,
          },
        })

        store.writeQuery({
          query: GET_PROJECT_DETAILS,
          variables: {
            id: projectId,
          },
          data: {
            projectById: {
              ...projectById,
              phases: projectById.phases.map(phase => {
                if (phase.id === phaseId) {
                  return {
                    ...phase,
                    tasks: [
                      ...phase.tasks,
                      {
                        ...result.data.addProjectPhaseTask.task,
                        notification: null,
                      },
                    ],
                  }
                }

                return phase
              }),
            },
          },
        })
      },

      optimisticResponse: {
        __typename: 'Mutation',
        addProjectPhaseTask: {
          successful: true,
          userErrors: null,
          task: {
            id: -Math.random(),
            title: taskTitle,
            completed: false,
            notification: null,
            __typename: 'ProjectPhaseTask',
          },
          __typename: 'AddProjectPhaseTaskResponse',
        },
      },
    })
  }

  function onDeleteTask({ id }) {
    return deleteTaskMutation({
      variables: {
        id,
      },
      update: store => {
        const { projectById } = store.readQuery({
          query: GET_PROJECT_DETAILS,
          variables: {
            id: projectId,
          },
        })

        store.writeQuery({
          query: GET_PROJECT_DETAILS,
          variables: {
            id: projectId,
          },
          data: {
            projectById: {
              ...projectById,
              phases: projectById.phases.map(phase => {
                if (phase.id === phaseId) {
                  return {
                    ...phase,
                    tasks: phase.tasks.filter(task => task.id !== id),
                  }
                }

                return phase
              }),
            },
          },
        })
      },

      optimisticResponse: {
        __typename: 'Mutation',
        deleteProjectPhaseTaskById: {
          successful: true,
          userErrors: null,
          task: {
            id,
            __typename: 'ProjectPhaseTask',
          },
          __typename: 'DeleteProjectPhaseTaskByIdResponse',
        },
      },
    })
  }

  function onMoveTask(task, newPosition) {
    return moveTaskMutation({
      variables: {
        id: task.id,
        newPosition,
      },
      update: store => {
        const { projectById } = store.readQuery({
          query: GET_PROJECT_DETAILS,
          variables: {
            id: projectId,
          },
        })

        store.writeQuery({
          query: GET_PROJECT_DETAILS,
          variables: {
            id: projectId,
          },
          data: {
            projectById: {
              ...projectById,
              phases: projectById.phases.map(phase => {
                if (phase.id === phaseId) {
                  const newTasks = [...phase.tasks]

                  const currentMovedTaskPosition = phase.tasks.findIndex(
                    ({ id }) => task.id === id,
                  )

                  newTasks[currentMovedTaskPosition] = newTasks[newPosition]
                  newTasks[newPosition] = task

                  return {
                    ...phase,
                    tasks: newTasks,
                  }
                }

                return phase
              }),
            },
          },
        })
      },

      optimisticResponse: {
        __typename: 'Mutation',
        moveProjectPhaseTaskById: {
          successful: true,
          userErrors: null,
          task: {
            id: task.id,
            __typename: 'ProjectPhaseTask',
          },
          __typename: 'MoveProjectPhaseTaskByIdResponse',
        },
      },
    })
  }

  function onUpdateTask(id, taskDto) {
    return updateTaskMutation({
      variables: {
        id,
        input: taskDto,
      },
    })
  }

  const { id } = useParams()
  const isEditing = !!id

  async function onConfigureNotifications(id, notificationsConfig) {
    if (isEditing) {
      await updateTaskMutation({
        variables: {
          id,
          input: {
            notification: notificationsConfig,
          },
        },
      })
    }

    return onUpdateTask(id, {
      notification: {
        recipients: notificationsConfig.recipients,
        emailTemplateId: notificationsConfig.emailTemplateId,
        extraRecipients: notificationsConfig.extraRecipients,
      },
    })
  }

  const debouncedOnUpdateTask = debounce(onUpdateTask, 700)

  return (
    <TasksView
      tasks={tasks}
      onCompleteTask={onCompleteTask}
      onUndoTask={onUndoTask}
      onAddTask={onAddTask}
      onUpdateTask={debouncedOnUpdateTask}
      onDeleteTask={onDeleteTask}
      onMoveTask={onMoveTask}
      onConfigureNotifications={onConfigureNotifications}
      readOnly={readOnly}
    />
  )
}

export default TasksContainer
