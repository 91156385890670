import Select from 'react-select'
import styles from './PhaseDependency.module.scss'

function PhaseDependsOnSelect({ options, onChange, value }) {
  return (
    <div className={styles['container']}>
      <label className="label">Depends on</label>
      <div className="control">
        <Select options={options} onChange={onChange} value={value} />
      </div>
    </div>
  )
}

export default PhaseDependsOnSelect
