import { useMutation } from '@apollo/client'
import { faSlack } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useState } from 'react'

import { showNotification } from '../../../../constants/utils'
import { UPDATE_COMPANY_BY_ID } from '../../../../graphql/mutations'
import { GET_COMPANY_SETTINGS } from '../../../../graphql/queries'
import { useAuth } from '../../../../hooks/use-auth'
import useCompanyPermissions from '../../../../hooks/useCompanySettings'

import styles from '../settings.module.scss'

const Integrations = () => {
  const settings = useCompanyPermissions()

  const chatProviderSettings = settings.chatProvider
  const notificationsSettings = settings.notifications

  const initialValues = {
    notificationsChannelId:
      notificationsSettings?.slack?.notificationsChannelId || '',
  }

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { companyId } = useAuth()

  const [updateCompanyMutation] = useMutation(UPDATE_COMPANY_BY_ID, {
    refetchQueries: [GET_COMPANY_SETTINGS],
  })

  const onSubmit = async updatedSettings => {
    setIsSubmitting(true)

    try {
      await updateCompanyMutation({
        variables: {
          id: companyId,
          input: {
            settings: {
              notifications: {
                slack: {
                  ...(notificationsSettings?.slack || {}),
                  ...updatedSettings,
                },
              },
            },
          },
        },
      })

      showNotification('Settings saved', 'is-success')
    } catch (e) {
      showNotification(
        'Settings could not be updated. Please try again.',
        'is-danger',
      )
    }

    setIsSubmitting(false)
  }

  const slackAppInstalled = chatProviderSettings?.provider === 'slack'

  return (
    <div>
      <h3>Integrations</h3>

      <section>
        <h5>Notifications</h5>
        {!slackAppInstalled && (
          <a
            href={`${process.env.REACT_APP_GRAPHQL_HOST}/slack/init-install?companyId=${companyId}`}
          >
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </a>
        )}
        {slackAppInstalled && (
          <>
            <Formik
              validateOnBlur={false}
              enableReinitialize
              initialValues={initialValues}
              validate={values => {
                const errors = {}
                if (!values.notificationsChannelId) {
                  errors.notificationsChannelId = 'Required'
                }
                return errors
              }}
              onSubmit={onSubmit}
            >
              {() => (
                <Form>
                  <div className={cx('field', styles['field-container'])}>
                    <label className="label">
                      <FontAwesomeIcon icon={faSlack} /> Slack notification
                      channel ID
                    </label>
                    <div className="control">
                      <Field
                        className="input"
                        type="text"
                        name="notificationsChannelId"
                      />
                      <ErrorMessage
                        className="error"
                        name="notificationsChannelId"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className={cx('field', styles['field-container'])}>
                    <button
                      className={cx(
                        'button',
                        'is-info',
                        styles['action-button'],
                        isSubmitting ? 'is-loading' : '',
                      )}
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
      </section>
    </div>
  )
}

export default Integrations
