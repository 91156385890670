import cx from 'classnames'
import { Field, ErrorMessage } from 'formik'
import SelectField from '../../../../../../components/select'

import styles from '../../CreateProjectTemplateForm.module.scss'

const TemplateDetailsForm = ({ engineers, surveyors, consultants }) => {
  return (
    <>
      <div className={cx('field', styles['field-container'])}>
        <label className="label">Template's name *</label>
        <div className="control">
          <Field className="input" type="text" name="name" />
          <ErrorMessage
            className="error-message "
            name="name"
            component="div"
          />
        </div>
      </div>

      <div className={cx('field', styles['field-container'])}>
        <label className="label">Select Engineers</label>
        <div className="control is-expanded">
          <Field
            name="engineers"
            component={SelectField}
            options={engineers}
            multiple
          />
          <ErrorMessage
            className="error-message "
            name="engineers"
            component="div"
          />
        </div>
      </div>

      <div className={cx('field', styles['field-container'])}>
        <label className="label">Select Consultants</label>
        <div className="control is-expanded">
          <Field
            name="consultants"
            component={SelectField}
            options={consultants}
            multiple
          />
          <ErrorMessage
            className="error-message "
            name="consultants"
            component="div"
          />
        </div>
      </div>

      <div className={cx('field', styles['field-container'])}>
        <label className="label">Select Surveyors</label>
        <div className="control is-expanded">
          <Field
            name="surveyors"
            component={SelectField}
            options={surveyors}
            multiple
          />
          <ErrorMessage
            className="error-message "
            name="surveyors"
            component="div"
          />
        </div>
      </div>
    </>
  )
}

export default TemplateDetailsForm
