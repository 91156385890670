import groupBy from 'lodash.groupby'
import pluckValues from 'lodash.values'
import mapKeys from 'lodash.mapkeys'
import mapValues from 'lodash.mapvalues'
import sortBy from 'lodash.sortby'

function formatMinutes(totalMinutes = 0) {
  if (!totalMinutes) return ''

  const minutes = totalMinutes % 60
  const hours = Math.floor(totalMinutes / 60)

  return `${hours}:${minutes >= 10 ? minutes : `0${minutes}` || '00'}`
}

function groupTimesheetDataByProjectAndDate(apiData = []) {
  const groupedByProjectIdList = pluckValues(groupBy(apiData, 'projectId'))

  const totalWeekTimeByProjectIdDict = groupedByProjectIdList.reduce(
    (acc, projectTimeRecordList) => {
      const totalWeekTime = projectTimeRecordList.reduce((acc, timeRecord) => {
        return acc + timeRecord.time
      }, 0)

      return {
        ...acc,
        [projectTimeRecordList[0].projectId]: totalWeekTime,
      }
    },
    {},
  )

  return groupedByProjectIdList.map(projectTimeRecordList => {
    const groupedByDateDict = groupBy(projectTimeRecordList, 'date')

    const groupedByDateWithFormattedDateDict = mapKeys(
      groupedByDateDict,
      (_, date) => date,
    )

    const groupByDateWithFormattedTimeDict = mapValues(
      groupedByDateWithFormattedDateDict,
      records => {
        const totalDayTime = records.reduce((acc, record) => {
          return acc + record.time
        }, 0)

        return totalDayTime
      },
    )

    const isHidden = !!projectTimeRecordList.find(record => record.hidden)

    return {
      projectName: projectTimeRecordList[0].projectName,
      projectId: projectTimeRecordList[0].projectId,
      ...groupByDateWithFormattedTimeDict,
      totalWeekTime:
        totalWeekTimeByProjectIdDict[projectTimeRecordList[0].projectId],
      hidden: isHidden,
    }
  })
}

function getDatesBetween(startDate, endDate) {
  const dates = []

  let currentDate = new Date(
    startDate?.getFullYear(),
    startDate?.getMonth(),
    startDate?.getDate(),
  )

  while (currentDate <= endDate) {
    dates.push(currentDate)

    currentDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1,
    )
  }

  return dates
}

function generateDataForTimesheetPhases(project, phaseRecordList) {
  return sortBy(
    phaseRecordList.map(record => {
      const phase = project.phases.find(phase => phase.id === record.phaseId)

      return {
        ...record,
        phaseName: phase?.title || 'Unallocated',
        time: formatMinutes(record.time),
      }
    }),
    record => record.createdAt,
  ).reverse()
}

function generatePhasesWithTotalTime(project, phaseHoursRecordList) {
  return mapValues(
    phaseHoursRecordList.reduce((acc, record) => {
      const phase = project.phases.find(phase => phase.id === record.phaseId)
      const phaseName = phase?.title || 'Unallocated'

      if (!acc[phaseName]) {
        return { ...acc, [phaseName]: record.time }
      }

      return { ...acc, [phaseName]: record.time + acc[phaseName] }
    }, {}),
    formatMinutes,
  )
}

export {
  formatMinutes,
  groupTimesheetDataByProjectAndDate,
  getDatesBetween,
  generateDataForTimesheetPhases,
  generatePhasesWithTotalTime,
}
