import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPen, faCopy } from '@fortawesome/free-solid-svg-icons'

import { showNotification } from 'constants/utils'

import { GET_PROJECT_TEMPLATES_IDS, GET_EMAIL_TEMPLATES } from 'graphql/queries'
import {
  DELETE_PROJECT_TEMPLATE,
  DELETE_EMAIL_TEMPLATE_BY_ID,
} from 'graphql/mutations'

import DeleteTemplateModal from '../delete-template'
import Table from 'components/table'

function TemplatesTable({
  type,
  templates,
  openEditModal,
  openCloneModal,
  onToggleCreateModal,
}) {
  const [selectedTemplate, setSelectedTemplate] = useState('')
  const [currentPageNumber, setCurrentPageNumber] = useState(0)

  const [deleteProjectTemplateMutation] = useMutation(DELETE_PROJECT_TEMPLATE)
  const [deleteEmailTemplateMutation] = useMutation(DELETE_EMAIL_TEMPLATE_BY_ID)

  const columns = [
    {
      Header:
        type === 'project' ? 'Project template name' : 'Email template ID',
      accessor: type === 'project' ? 'name' : 'name',
      sortType: 'basic',
    },
    {
      Header: ' ',
      Cell: props => {
        return (
          <div className="columns is-vcentered">
            <div className="column is-2">
              <button
                className="button is-small is-light"
                onClick={() => openEditModal(props.row.original.id)}
              >
                <span className="icon">
                  <FontAwesomeIcon icon={faPen} size="lg" />
                </span>
              </button>
            </div>

            {type === 'project' && (
              <div className="column is-2">
                <button
                  className="button is-small is-light"
                  onClick={() => openCloneModal(props.row.original.id)}
                >
                  <span className="icon">
                    <FontAwesomeIcon icon={faCopy} size="lg" />
                  </span>
                </button>
              </div>
            )}

            <div className="column is-2">
              <button
                className="button is-small is-light"
                onClick={() => onOpenDeleteTemplateModal(props.row.original)}
              >
                <span className="icon is-medium">
                  <FontAwesomeIcon icon={faTrash} size="lg" />
                </span>
              </button>
            </div>
          </div>
        )
      },
    },
  ]

  function onOpenDeleteTemplateModal(template) {
    setSelectedTemplate(template)
  }

  function onCloseDeleteTemplateModal() {
    setSelectedTemplate(null)
  }

  const deleteProjectTemplate = projectTemplate => {
    return deleteProjectTemplateMutation({
      variables: { id: projectTemplate.id },
      refetchQueries: [
        {
          query: GET_PROJECT_TEMPLATES_IDS,
        },
      ],
    })
      .then(() =>
        showNotification(
          `Project template "${projectTemplate.name}" deleted correctly`,
          'is-success',
        ),
      )
      .catch(error => showNotification(error.message, 'is-danger'))
  }

  const deleteEmailTemplate = emailTemplate => {
    return deleteEmailTemplateMutation({
      variables: { id: emailTemplate.id },
      refetchQueries: [
        {
          query: GET_EMAIL_TEMPLATES,
        },
      ],
    })
      .then(() =>
        showNotification(
          `Email template "${emailTemplate.name}" deleted correctly`,
          'is-success',
        ),
      )
      .catch(error => showNotification(error.message, 'is-danger'))
  }

  return (
    <>
      <Table
        data={templates}
        columns={columns}
        pageSize={7}
        onToggleCreateModal={onToggleCreateModal}
        buttonText={
          type === 'project' ? 'New project template' : 'New email templates'
        }
        currentPageNumber={currentPageNumber}
        onChangePage={pageNumber => setCurrentPageNumber(pageNumber)}
      />

      {selectedTemplate && (
        <DeleteTemplateModal
          onClose={onCloseDeleteTemplateModal}
          deleteTemplate={
            type === 'project' ? deleteProjectTemplate : deleteEmailTemplate
          }
          selectedTemplate={selectedTemplate}
        />
      )}
    </>
  )
}

export default TemplatesTable
