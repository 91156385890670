import { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks'

import { useParams, Redirect, useLocation } from 'react-router-dom'

import { GET_PROJECT_DETAILS } from '../../graphql/queries'

import ProjectDescriptionComponent from '../shared/project/project-description'
import Loader from '../../components/loader'
import ProjectPhases from '../shared/project/project-phases'

import hooks from '../../hooks'

import styles from './client-view.module.scss'

function ClientView() {
  const location = useLocation()
  const searchQuery = location.search
  const params = new URLSearchParams(searchQuery)
  const shareToken = params.get('shareToken')

  const { id } = useParams()

  const auth = hooks.useAuth()

  useEffect(() => {
    if (id) auth.setAnonymousUser()
    // eslint-disable-next-line
  }, [id])

  const { data: projectResponse, loading: fetchingProjectDetails } = useQuery(
    GET_PROJECT_DETAILS,
    {
      fetchPolicy: 'network-only',
      variables: { id },
      context: {
        headers: {
          sharetoken: shareToken,
        },
      },
    },
  )

  if (!id) {
    return <Redirect to="/" />
  }

  if (fetchingProjectDetails) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  if (!fetchingProjectDetails && !projectResponse) {
    return <Redirect to="/404" />
  }

  let { projectById: project } = projectResponse

  return (
    <div className={styles['client-view-wrapper']}>
      <h3 className={styles['client-view-title']}>{project.name}</h3>
      <div className={styles['client-view-project-wrapper']}>
        <div className={styles['client-view-project-details']}>
          <ProjectDescriptionComponent clientView project={project} readOnly />
        </div>
        <div className={styles['client-view-phases']}>
          <ProjectPhases
            readOnly
            phases={project.phases}
            projectId={project.id}
          />
        </div>
      </div>
    </div>
  )
}

export default ClientView
