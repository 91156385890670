import { useEffect } from 'react';
import cx from 'classnames'

import styles from './confirmation-modal.module.scss'

const ConfirmationModal = ({ children, open, onClose }) => {
  useEffect(() => {
    if (open) {
      window.document.body.style.overflow = 'hidden'
    } else {
      window.document.body.style.overflow = 'auto'
    }

    return () => (window.document.body.style.overflow = 'auto')
  }, [open])

  return (
    <div className={`modal ${styles.confirmation} ${open ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className={cx('modal-content', styles['confirmation-content'])}>
        {children}
      </div>
    </div>
  )
}

export default ConfirmationModal
