import { useState, useEffect } from 'react';
import { ViewMode, Gantt } from '@yoopknows/timeline'

import Loader from 'components/loader'

import useTimelineData from '../hooks/useProjectsTimelineData'

import {
  createPhaseDetailsUrl,
  createProjectDetailsUrl,
} from 'constants/routes'

import '@yoopknows/timeline/dist/index.css'
import TaskTableItem from '../components/TaskTableItem'
import classNames from 'classnames'
import styles from '../Timeline.module.scss'

function ProjectsTimeline({ selectedScale, maxProjects }) {
  const {
    rows,
    handleExpanderClick,
    handlePhaseChange,
    loading,
    searchQuery,
    setSearchQuery,
  } = useTimelineData()

  const [selectedItemId, setSelectedItemId] = useState(null)
  const [initialScrollXDate, setInitialScrollXDate] = useState(null)

  useEffect(() => {
    if (!selectedItemId) setInitialScrollXDate(null)
  }, [selectedScale])

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  function onProjectClick({ id, startDate }) {
    if (selectedItemId === id) {
      setSelectedItemId(null)
    } else {
      setSelectedItemId(id)
      setInitialScrollXDate(startDate)
    }
  }

  return (
    <div data-test="timeline-container">
      {rows.length ? (
        <>
          <input
            className={classNames('input', styles['search-input'])}
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
          <Gantt
            tasks={rows}
            viewMode={ViewMode[selectedScale]}
            onDateChange={handlePhaseChange}
            onExpanderClick={handleExpanderClick}
            listCellWidth={'300px'}
            columnWidth={65}
            nameCellComponent={props => {
              const { type, id, project } = props

              const url =
                type === 'project'
                  ? createProjectDetailsUrl(id)
                  : createPhaseDetailsUrl({
                      phaseId: id,
                      projectId: project,
                    })
              return (
                <TaskTableItem
                  {...props}
                  url={url}
                  onProjectClick={onProjectClick}
                  isSelected={selectedItemId === props.id}
                />
              )
            }}
            ganttHeight={maxProjects * 50}
            hasScrollbars={{ bottom: true }}
            initialScrollXDate={initialScrollXDate}
          />
        </>
      ) : (
        <p>No projects to show...</p>
      )}
    </div>
  )
}

export default ProjectsTimeline
