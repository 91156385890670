import cx from 'classnames'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './drop-down.module.scss'
function DropDown(props) {
  return (
    <>
      <div className={styles.container}>
        {props.icon && (
          <FontAwesomeIcon className={styles.icon} icon={props.icon} />
        )}

        {props.async ? (
          <AsyncSelect
            {...props.field}
            {...props}
            className={cx(styles['select'], props.className)}
            value={props.field.value && props.field.value}
            onChange={option =>
              props.form.setFieldValue(props.field.name, option.value)
            }
            isClearable
          />
        ) : (
          <Select
            className={cx(styles['select'], props.className)}
            options={props.options}
            name={props.field.name}
            value={
              props.options
                ? props.options.find(
                    option => option.value === props.field.value,
                  )
                : ''
            }
            onChange={option =>
              option?.value
                ? props.form.setFieldValue(props.field.name, option.value)
                : props.form.setFieldValue(props.field.name, '')
            }
            onBlur={props.field.onBlur}
            styles={props.styles}
            placeholder={props.placeholder}
            isDisabled={props.disabled}
            instanceId={props.iid}
            components={props.components}
            isClearable
          />
        )}
      </div>
    </>
  )
}

export default DropDown
