import { useState, useEffect } from 'react';

import { Container, Draggable } from 'react-smooth-dnd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCaretSquareDown } from '@fortawesome/free-solid-svg-icons'
import cx from 'classnames'

import styles from './TasksForm.module.scss'

import ExpansionPanel from 'components/expansion-panel'

import DeleteModal from '../../../../../../../shared/project/project-phases/delete-modal'

import { reorder } from '../../../../../../../../constants/utils'

function onReorderTasks(tasks, onTasksChange) {
  return ({ removedIndex: from, addedIndex: to }) => {
    if (from === to) return

    const currentTasks = [...tasks]

    const newTasks = reorder(currentTasks, from, to)

    return onTasksChange(newTasks)
  }
}

function renderTaskHeading(task, toggleModal, i) {
  return (
    <div className={cx('level', styles['wrapper'])}>
      <div className={cx('level-left', styles['container'])}>
        <div className={cx('level-item', styles['icons'])}>
          <button
            type="button"
            className="button is-small is-light"
            onClick={event => {
              event.stopPropagation()
              toggleModal(task, i)
            }}
          >
            <span className="icon is-medium">
              <FontAwesomeIcon icon={faTrash} size="lg" />
            </span>
          </button>
        </div>
        <div>{task.title || `Task ${i + 1}`}</div>
      </div>

      <div className="level-right">
        <div
          className={cx('level-item', styles['drop-down-icon'])}
          style={{ marginLeft: '14px' }}
        >
          <FontAwesomeIcon icon={faCaretSquareDown} size="2x" />
        </div>
      </div>
    </div>
  )
}

const TasksForm = ({ tasks, onPhaseUpdate, phaseIndex, noButton }) => {
  const [tasksCache, setTasksCache] = useState(tasks)
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(null)
  const [selectedTask, setSelectedTask] = useState(null)
  const [openedModal, setOpenedModal] = useState()

  function onAddTask() {
    setTasksCache([
      ...tasksCache,
      {
        title: '',
      },
    ])
  }

  function toggleModal(task, taskIndex) {
    setSelectedTask(task)
    setSelectedTaskIndex(taskIndex)
    setOpenedModal('delete')
  }

  function closeModal() {
    setSelectedTask(null)
    setSelectedTaskIndex(null)
    setOpenedModal(null)
  }

  function updateReorderedTasks(newTasks) {
    setTasksCache(newTasks)
    onPhaseUpdate(phaseIndex, 'tasks', newTasks)
  }

  function onRemoveTask(taskIndex) {
    const newTasks = tasksCache.filter((_, i) => i !== taskIndex)
    setTasksCache(newTasks)
    onPhaseUpdate(phaseIndex, 'tasks', newTasks)
  }

  function onTaskUpdate(i, field, value) {
    const newTasks = [...tasksCache]
    newTasks[i][field] = value

    setTasksCache(newTasks)
    onPhaseUpdate(phaseIndex, 'tasks', newTasks)
  }

  useEffect(() => {
    if (tasks.length > 0) {
      setTasksCache(tasks)
    }
  }, [tasks])

  return (
    <div>
      {' '}
      {!noButton && (
        <button type="button" className="button is-info" onClick={onAddTask}>
          Add task
        </button>
      )}
      <Container onDrop={onReorderTasks(tasksCache, updateReorderedTasks)}>
        {tasksCache.map((task, i) => {
          return (
            <Draggable key={`form-task-${i}`} style={{ overflow: 'visible' }}>
              <div>
                <ExpansionPanel
                  title={renderTaskHeading(task, toggleModal, i)}
                  className="no-outline"
                >
                  <div className="no-drag" style={{ padding: '14px' }}>
                    <div className="field">
                      <label className="label">Title*</label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          value={tasksCache[i].title}
                          onChange={e =>
                            onTaskUpdate(i, 'title', e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </ExpansionPanel>
              </div>
            </Draggable>
          )
        })}
      </Container>
      {selectedTask && (
        <DeleteModal
          title={selectedTask.title}
          onClose={closeModal}
          deleteAction={() => onRemoveTask(selectedTaskIndex)}
          elemType="task"
          open={selectedTask}
        />
      )}
    </div>
  )
}

export default TasksForm
