import { faBook } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ProjectSectionTitle from './components/project-section-title'

import styles from './project-description.module.scss'

function ProjectDescriptionDocs({ projectDocsUrl }) {
  return (
    <div className={styles['project-description-status']}>
      <ProjectSectionTitle title="Project Docs" />
      <div className={styles['project-description-section-text']}>
        <div className={styles['client-detail']}>
          <span className="icon is-small is-left" style={{ marginRight: 5 }}>
            <FontAwesomeIcon icon={faBook} />
          </span>
          {projectDocsUrl ? (
            <a href={projectDocsUrl} target="_blank" rel="noreferrer">
              View project docs
            </a>
          ) : (
            'No docs available for this project'
          )}
        </div>
      </div>
    </div>
  )
}

export default ProjectDescriptionDocs
