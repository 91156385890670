import { useMutation } from '@apollo/react-hooks'
import { showNotification } from '../../../constants/utils'

import { GET_COMPANY_SETTINGS } from '../../../graphql/queries'
import { UPDATE_CARD_CATEGORY } from '../../../graphql/mutations'

const makeMutationOptions = ({ categoryId, category }) => {
  return {
    variables: { categoryId, category },
    refetchQueries: [
      {
        query: GET_COMPANY_SETTINGS,
      },
    ],
  }
}

export const useConfigureUpdateCardCategoryMutation = () => {
  const [updateCardCategory] = useMutation(UPDATE_CARD_CATEGORY)

  return newConfig => {
    const options = makeMutationOptions(newConfig)

    return updateCardCategory(options)
      .then(res => {
        const {
          data: {
            updateCardCategory: { success, error = '' },
          },
        } = res

        if (!success)
          showNotification(
            error || "It's not possible to update the category",
            'is-danger',
          )
      })
      .catch(error => showNotification(error.message, 'is-danger'))
  }
}
