import { useMutation } from '@apollo/react-hooks'
import { showNotification } from '../../../constants/utils'

import { GET_COMPANY_SETTINGS } from '../../../graphql/queries'
import { SET_SETTINGS } from '../../../graphql/mutations'

const makeMutationOptions = newConfig => {
  return {
    variables: { configuration: newConfig },
    refetchQueries: [
      {
        query: GET_COMPANY_SETTINGS,
      },
    ],
  }
}

export const useConfigureWeeklyUpdatesMutation = () => {
  const [configureWeeklyUpdates] = useMutation(SET_SETTINGS)

  return newConfig => {
    const options = makeMutationOptions(newConfig)

    return configureWeeklyUpdates(options)
      .then(
        ({
          data: {
            configureWeeklyUpdates: { success },
          },
        }) =>
          success
            ? showNotification('Configuration updated correctly', 'is-success')
            : showNotification(
                "It's not possible to create the job",
                'is-danger',
              ),
      )
      .catch(error => showNotification(error.message, 'is-danger'))
  }
}
