import { useState } from 'react';
import cx from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import styles from './documents.module.scss'

function Documents({ documents, onPostDocument }) {
  const [name, setName] = useState('')
  const [link, setLink] = useState('')

  function onNameChange(e) {
    setName(e.target.value)
  }

  function onLinkChange(e) {
    setLink(e.target.value)
  }

  if (!documents) return ''

  return (
    <>
      <div className={documents.length > 0 ? styles.documents : ''}>
        {documents.map(document => {
          return (
            <div
              className={cx(
                'is-flex is-justify-content-space-between is-align-items-center	p-4',
                styles.document,
              )}
              key={document.text}
            >
              <div>
                <a
                  className={styles['document-text']}
                  rel="noopener noreferrer"
                  href={document.text}
                  target="_blank"
                >
                  {document.name}
                </a>
              </div>

              <div>
                <button
                  disabled
                  className={cx('button', 'is-small', 'is-light')}
                >
                  <span className="icon is-medium">
                    <FontAwesomeIcon icon={faTrash} size="lg" />
                  </span>
                </button>
              </div>
            </div>
          )
        })}
      </div>

      <div className={cx('field is-grouped', styles['document-form'])}>
        <p className={cx('control is-expanded')}>
          <input
            className={'input is-small is-hovered'}
            type="text"
            placeholder="Document name..."
            name="document_name"
            value={name}
            onChange={onNameChange}
          />
        </p>

        <p className={cx('control is-expanded', styles['document-input'])}>
          <input
            className={'input is-small is-hovered'}
            type="text"
            placeholder="Document link..."
            name="document_link"
            value={link}
            onChange={onLinkChange}
          />
        </p>

        <p className={cx('control', styles['document-button'])}>
          <button
            disabled={!name || !link}
            className="button is-info"
            onClick={() => onPostDocument({ name, link })}
          >
            Post Document
          </button>
        </p>
      </div>
    </>
  )
}

export default Documents
