import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { Link } from 'react-router-dom'
import styles from './sign-up.module.scss'

const SignUpView = ({ onSignUp, loading, error }) => {
  const initialValues = {
    password: '',
    repeatPassword: '',
  }

  return (
    <div className={styles['sign-up-page']}>
      <div className={styles['sign-up-wrapper']}>
        <div className={styles['sign-up-header']}>
          <span className="icon is-medium is-left">
            <FontAwesomeIcon size="lg" icon={faSignInAlt} />
          </span>
          <span>Complete your registration</span>
        </div>

        <div className="p-5">
          <Formik
            enableReinitialize
            validateOnBlur={false}
            initialValues={initialValues}
            validate={values => {
              const errors = {}

              if (!values.password) {
                errors.password = 'Required'
              } else if (values.password.length < 6) {
                errors.password = 'Password must contain minimum 6 characters'
              } else if (
                !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(
                  values.password,
                )
              ) {
                errors.password =
                  'Use at least a number and a special character'
              }

              if (!values.repeatPassword) {
                errors.repeatPassword = 'Required'
              } else if (values.password !== values.repeatPassword) {
                errors.repeatPassword = "The two passwords don't match!"
              }

              return errors
            }}
            onSubmit={({ password }) => onSignUp(password)}
          >
            {() => (
              <Form>
                <div style={{ padding: '1.5rem' }}>
                  <div className="field" style={{ marginTop: '1.5rem' }}>
                    <label className={styles['form-label']}>
                      New Password *
                    </label>
                    <div className="control">
                      <Field
                        className="input"
                        type="password"
                        name="password"
                      />
                      <ErrorMessage
                        className="error"
                        name="password"
                        component="div"
                      />
                    </div>
                  </div>

                  <div className="field" style={{ marginTop: '1.5rem' }}>
                    <label className={styles['form-label']}>
                      Repeat New Password *
                    </label>
                    <div className="control">
                      <Field
                        className="input"
                        type="password"
                        name="repeatPassword"
                      />
                      <ErrorMessage
                        className="error"
                        name="repeatPassword"
                        component="div"
                      />
                    </div>
                  </div>
                </div>

                <div style={{ padding: '1.5rem' }}>
                  After changing the password, you'll need to log in again
                </div>

                <div className={cx(styles['footer-wrapper'])}>
                  {error && (
                    <span className={styles.error}>
                      An error has occured, please try again later
                    </span>
                  )}

                  <button
                    className={cx(
                      'button',
                      'is-info',
                      styles['action-button'],
                      loading ? 'is-loading' : '',
                    )}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className={styles['sign-in-text']}>
        Have an account?{' '}
        <Link to="sign-in" className={cx(styles['link-style'], styles['bold'])}>
          Sign in
        </Link>
      </div>
    </div>
  )
}

export default SignUpView
