import { useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import cx from 'classnames'

import CreateFormModal from 'components/create-form-modal'
import SelectField from 'components/select'

import { showNotification } from 'constants/utils'
import {
  clientsAndPartnersFilterOptions,
  teamMembersRolesOptions,
} from 'constants/constants'

import styles from './create-user-form.module.scss'

function CreateUserFormView({
  open,
  onClose,
  onCreateUser,
  onCreateTeamMember,
  onUpdate,
  selectedUser,
  isTeamPage,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false)

  let initialValues

  if (selectedUser) {
    initialValues = {
      firstName: selectedUser.firstName,
      lastName: selectedUser.lastName,
      email: selectedUser.email,
      address: selectedUser.address,
      password: selectedUser.password,
      phoneNumber: selectedUser.phoneNumber,
      role: {
        value: selectedUser.role,
        label:
          selectedUser.role.charAt(0).toUpperCase() +
          selectedUser.role.slice(1),
      },
      ...(isTeamPage && {
        chatProviderId: selectedUser.chatProviderId,
      }),
      ...(!isTeamPage && {
        organization: selectedUser.organization,
      }),
    }
  } else {
    initialValues = {
      firstName: '',
      lastName: '',
      email: '',
      address: '',
      phoneNumber: '',
      password: '',
      role: '',
      chatProviderId: '',
    }
  }

  const formRef = useRef()

  function onCreate() {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  function onReset() {
    if (formRef.current) {
      const form = formRef.current
      form.resetForm()
    }
  }

  function submitForm(userData) {
    let postBody

    setIsSubmitting(true)

    if (selectedUser) {
      postBody = {
        id: selectedUser.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        address: userData.address,
        role: userData.role.value,
        phoneNumber: userData.phoneNumber,
        chatProviderId: userData.chatProviderId,
        organization: userData.organization,
      }

      return onUpdate(postBody)
        .then(() => {
          onReset()
          onClose()
        })
        .then(() => showNotification('User updated correctly', 'is-success'))
        .catch(error => showNotification(error.message, 'is-danger'))
        .finally(() => setIsSubmitting(false))
    } else {
      postBody = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        address: userData.address,
        role: userData.role.value,
        phoneNumber: userData.phoneNumber,
        ...(!isTeamPage && {
          organization: userData.organization,
        }),
        ...(isTeamPage && {
          createAccount: true,
        }),
        ...(isTeamPage && { password: userData.password }),
        ...(isTeamPage && { chatProviderId: userData.chatProviderId }),
      }

      const createFn = isTeamPage ? onCreateTeamMember : onCreateUser

      return createFn(postBody)
        .then(() => {
          showNotification('User created correctly', 'is-success')
          onReset()
          onClose()
        })
        .catch(error => showNotification(error.message, 'is-danger'))
        .finally(() => {
          setIsSubmitting(false)
        })
    }
  }

  return (
    <CreateFormModal
      open={open}
      onClose={onClose}
      title={selectedUser ? 'Update user' : 'Create a new user'}
      submitButtonText={selectedUser ? 'Update' : 'Create'}
      resetButtonText={selectedUser ? 'Cancel' : 'Reset'}
      onCreate={onCreate}
      onReset={onReset}
      submitting={isSubmitting}
    >
      <Formik
        enableReinitialize
        innerRef={formRef}
        validateOnBlur={false}
        initialValues={initialValues}
        validate={values => {
          const errors = {}

          if (!values.firstName) {
            errors.firstName = 'Required'
          }

          if (!values.lastName) {
            errors.lastName = 'Required'
          }

          if (!values.email) {
            errors.email = 'Required'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address'
          }

          if (values.password && values.password.length < 6) {
            errors.password = 'Password must contain minimum 6 characters'
          }

          if (values.role.length === 0) {
            errors.role = 'Required'
          }

          return errors
        }}
        onSubmit={user => submitForm(user)}
      >
        <Form>
          <div className={cx('field', styles['field-container'])}>
            <label className="label">First Name *</label>
            <div className="control">
              <Field className="input" type="text" name="firstName" />
              <ErrorMessage
                className="error"
                name="firstName"
                component="div"
              />
            </div>
          </div>
          <div className={cx('field', styles['field-container'])}>
            <label className="label">Last Name *</label>
            <div className="control">
              <Field className="input" type="text" name="lastName" />
              <ErrorMessage className="error" name="lastName" component="div" />
            </div>
          </div>
          <div className={cx('field', styles['field-container'])}>
            <label className="label">Email *</label>
            <div className="control">
              <Field className="input" type="email" name="email" />
              <ErrorMessage className="error" name="email" component="div" />
            </div>
          </div>
          {isTeamPage && (
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Password *</label>
              <div className="control">
                <Field className="input" type="password" name="password" />
                <ErrorMessage
                  className="error"
                  name="password"
                  component="div"
                />
              </div>
            </div>
          )}
          <div className={cx('field', styles['field-container'])}>
            <label className="label">Role *</label>
            <div className="control is-expanded">
              <Field
                name="role"
                component={SelectField}
                options={
                  isTeamPage
                    ? teamMembersRolesOptions
                    : clientsAndPartnersFilterOptions
                }
                placeholder={selectedUser && selectedUser.role}
              />
              <ErrorMessage className="error" name="role" component="div" />
            </div>
          </div>
          {isTeamPage && (
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Slack User ID</label>
              <div className="control">
                <Field className="input" type="text" name="chatProviderId" />
                <ErrorMessage
                  className="error"
                  name="chatProviderId"
                  component="div"
                />
              </div>
            </div>
          )}
          {/* Team Members do not have the organization field */}
          {!isTeamPage && (
            <div className={cx('field', styles['field-container'])}>
              <label className="label">Company</label>
              <div className="control">
                <Field className="input" type="text" name="organization.name" />
                <ErrorMessage
                  className="error"
                  name="organization.name"
                  component="div"
                />
              </div>
            </div>
          )}
          <div className={cx('field', styles['field-container'])}>
            <label className="label">Address</label>
            <div className="control">
              <Field className="input" type="text" name="address" />
              <ErrorMessage className="error" name="address" component="div" />
            </div>
          </div>
          <div className={cx('field', styles['field-container'])}>
            <label className="label">Phone Number</label>
            <div className="control">
              <Field className="input" type="phoneNumber" name="phoneNumber" />
              <ErrorMessage
                className="error"
                name="phoneNumber"
                component="div"
              />
            </div>
          </div>
        </Form>
      </Formik>
    </CreateFormModal>
  )
}

export default CreateUserFormView
