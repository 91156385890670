import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './avatar.module.scss'

import AvatarImg from './avatar.svg'

const Avatar = ({ isSmall, isMedium, hasCounter }) => {
  return (
    <div className={styles['container']}>
      <img
        alt="avatar"
        src={AvatarImg}
        className={cx(
          styles['avatar'],
          isSmall && styles['is-small'],
          isMedium && styles['is-medium'],
          hasCounter && styles['has-counter'],
        )}
      />
    </div>
  )
}

export default Avatar

Avatar.defaultProps = {
  isSmall: false,
  hasCounter: false,
}

Avatar.propTypes = {
  isSmall: PropTypes.bool,
  hasCounter: PropTypes.bool,
}
