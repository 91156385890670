import cx from 'classnames'

import styles from './create-form-modal.module.scss'

const FooterView = ({
  onReset,
  onCreate,
  submitting,
  submitButtonText,
  resetButtonText,
  dataTestSubmitButton,
}) => {
  return (
    <footer className={styles.footer}>
      <div className="buttons is-right">
        <button
          className={cx('button', 'is-light', styles['action-button'])}
          onClick={() => onReset()}
          disabled={submitting}
        >
          {resetButtonText}
        </button>

        <button
          className={cx(
            'button',
            'is-info',
            styles['action-button'],
            submitting ? 'is-loading' : '',
          )}
          onClick={onCreate}
          type="submit"
          data-test={dataTestSubmitButton}
        >
          {submitButtonText}
        </button>
      </div>
    </footer>
  )
}

export default FooterView
