import { Switch, Route, useParams } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/react-hooks'
import debounce from 'lodash.debounce'

import * as U from 'routes/shared/tools/timesheet/timesheet-utils'
import { showNotification } from 'constants/utils'

import { UPDATE_PROJECT_BY_ID } from 'graphql/mutations'
import {
  GET_PROJECT_DETAILS,
  GET_PROJECT_NAMES,
  GET_TIMESHEET_BY_PROJECT_ID,
} from 'graphql/queries'

import Sidebar from 'components/sidebar'
import ProjectHeader from './project-header'
import ProjectPhases from 'routes/shared/project/project-phases'
import ProjectDetailsComponent from 'routes/shared/project/project-description'
import TimesheetPhasesTotalTime from './timesheet-phases-total-time'

import styles from './project-modal.module.scss'
import {
  createPhasesUrl,
  createProjectDetailsUrl,
  createProjectHoursUrl,
  PROJECT_DETAILS,
  PROJECT_HOURS,
  PROJECT_PHASES,
} from 'constants/routes'
import { TabLink, TabsContainer } from 'components/Tabs'
import hooks from 'hooks'

function ProjectModal({ onClose, user }) {
  const { id: projectId } = useParams()
  const subscription = hooks.useSubscription()

  const [updateProjectMutation, { error: updateProjectError }] = useMutation(
    UPDATE_PROJECT_BY_ID,
    { refetchQueries: [{ query: GET_PROJECT_NAMES }] },
  )

  function onUpdateProjectDetails({ target: { name, value } }) {
    return updateProjectMutation({
      variables: { id: projectId, input: { [name]: value } },
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.log(error)
      showNotification(
        'There was an error while saving the project details',
        'is-danger',
      )
    })
  }

  const {
    data: projectResponse,
    loading: fetchingProjectDetails,
    error: fetchingProjectDetailsError,
  } = useQuery(GET_PROJECT_DETAILS, {
    variables: { id: projectId },
  })

  const {
    data: timesheetResponse,
    loading: fetchingTimesheet,
    error: fetchingTimesheetError,
  } = useQuery(GET_TIMESHEET_BY_PROJECT_ID, {
    variables: { projectId },
    fetchPolicy: 'network-only',
  })

  if (fetchingTimesheetError) {
    showNotification(
      'There was an error while fetching the project hours',
      'is-danger',
    )
    return <></>
  }

  if (fetchingProjectDetailsError) {
    showNotification(
      'There was an error while fetching the project',
      'is-danger',
    )
    return <></>
  }

  if (fetchingProjectDetails || fetchingTimesheet) {
    return <></>
  }

  if (updateProjectError) {
    showNotification(
      'There was an error while saving the project details',
      'is-danger',
    )
    return <></>
  }

  const { projectById: project } = projectResponse
  const { timesheetByProjectId } = timesheetResponse

  const debouncedUpdateProject = debounce(onUpdateProjectDetails, 700)

  return (
    <Sidebar open={!fetchingProjectDetails} onClose={onClose}>
      <section className={styles['grey-background']}>
        <ProjectHeader
          selectedProject={project}
          user={user}
          onClose={onClose}
        />

        <div className={styles['tabs']}>
          <TabsContainer className={styles['tabs-items']}>
            <TabLink
              to={createProjectDetailsUrl(projectId)}
              dataTest="tab-project-description"
            >
              Project description
            </TabLink>
            <TabLink to={createPhasesUrl(projectId)} dataTest="tab-phases">
              Phases
            </TabLink>
            {subscription.canUseTimesheet() && (
              <TabLink
                to={createProjectHoursUrl(projectId)}
                dataTest="tab-hours"
              >
                Hours
              </TabLink>
            )}
          </TabsContainer>
        </div>

        <Switch>
          <Route path={PROJECT_PHASES}>
            <ProjectPhases
              phases={project.phases}
              projectId={project.id}
              projectStartDate={new Date(project.startDate)}
            />
          </Route>

          <Route path={PROJECT_HOURS}>
            <TimesheetPhasesTotalTime
              data={U.generatePhasesWithTotalTime(
                project,
                timesheetByProjectId,
              )}
            />
          </Route>

          <Route path={PROJECT_DETAILS}>
            <ProjectDetailsComponent
              project={project}
              onUpdate={debouncedUpdateProject}
            />
          </Route>
        </Switch>
      </section>
    </Sidebar>
  )
}

export default ProjectModal
