import Select from 'react-select'

const SelectField = ({
  options,
  field,
  form,
  multiple,
  placeholder,
  disabled,
}) => {
  return (
    <Select
      data-test="select"
      className="react-select-container"
      classNamePrefix="react-select"
      options={options}
      name={field.name}
      value={field.value}
      onChange={option => form.setFieldValue(field.name, option)}
      onBlur={field.onBlur}
      closeMenuOnSelect={!multiple}
      isMulti={multiple}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: 'rgba(101, 138, 255, 0.35)',
          primary: '#658aff',
        },
      })}
      placeholder={placeholder ? placeholder : 'Select...'}
      isDisabled={disabled}
    />
  )
}

export default SelectField
