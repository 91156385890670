import { useEffect, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks'

import { COMPANIES } from 'constants/routes'
import { showNotification } from 'constants/utils'

import CreateFormModal from 'components/create-form-modal/create-form-modal'
import FormInput from 'components/FormInput'
import SelectField from 'components/select'
import FormToggle from 'components/FormToggle'
import Loader from 'components/loader'

import { CREATE_COMPANY, UPDATE_COMPANY_BY_ID } from 'graphql/mutations'
import {
  GET_ALL_COMPANIES,
  GET_COMPANY_BY_ID,
  GET_ALL_VIRGIN_PROJECT_TEMPLATES,
  GET_ALL_VIRGIN_EMAIL_TEMPLATES,
} from 'graphql/queries'

import styles from './CompanyEditor.module.scss'

function CompanyEditor() {
  const history = useHistory()
  const formRef = useRef()
  const { id: companyId } = useParams()

  const [
    getCompanyById,
    { loading: fetchingCompanyById, data: getCompanyByIdResponse },
  ] = useLazyQuery(GET_COMPANY_BY_ID, { fetchPolicy: 'network-only' })

  const [createCompanyMutation, { loading: creatingCompany }] =
    useMutation(CREATE_COMPANY)

  const [updateCompanyByIdMutation, { loading: updatingCompanyById }] =
    useMutation(UPDATE_COMPANY_BY_ID)

  useEffect(() => {
    if (companyId) {
      getCompanyById({
        variables: { id: companyId },
      })
    }
  }, [companyId])

  const {
    data: getAllVirginProjectTemplatesResponse,
    loading: fetchingAllVirginProjectTemplates,
  } = useQuery(GET_ALL_VIRGIN_PROJECT_TEMPLATES)

  const {
    data: getAllVirginEmailTemplatesResponse,
    loading: fetchingAllVirginEmailTemplates,
  } = useQuery(GET_ALL_VIRGIN_EMAIL_TEMPLATES)

  function onCreate() {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  function onReset() {
    if (formRef.current) {
      const form = formRef.current
      form.resetForm()
    }
  }

  // eslint-disable-next-line no-unused-vars
  async function onSubmit({ id, ...companyDto }) {
    try {
      if (companyId) {
        await updateCompanyByIdMutation({
          variables: {
            id: companyId,
            input: {
              name: companyDto.name,
              settings: {
                subscription: {
                  allowedExtraUsers: companyDto.allowedExtraUsers,
                  maxNumberOfProjects: companyDto.maxNumberOfProjects,
                  enableProjectNotifications:
                    companyDto.enableProjectNotifications,
                  enableTimesheet: companyDto.enableTimesheet,
                  enableTimeline: companyDto.enableTimeline,
                  enableCardsTimeline: companyDto.enableCardsTimeline,
                  enableStickyCards: companyDto.enableStickyCards,
                },
              },
            },
          },
        })

        showNotification('Company updated correctly', 'is-success')
      } else {
        await createCompanyMutation({
          variables: {
            input: {
              name: companyDto.name,
              subscriber: {
                firstName: companyDto.adminFirstName,
                lastName: companyDto.adminLastName,
                email: companyDto.adminEmail,
                password: companyDto.adminPassword.trim(),
              },
              settings: {
                subscription: {
                  allowedExtraUsers: parseInt(companyDto.allowedExtraUsers),
                  maxNumberOfProjects: parseInt(companyDto.maxNumberOfProjects),
                  enableProjectNotifications:
                    companyDto.enableProjectNotifications,
                  enableTimesheet: companyDto.enableTimesheet,
                  enableTimeline: companyDto.enableTimeline,
                  enableCardsTimeline: companyDto.enableCardsimeline,
                  enableStickyCards: companyDto.enableStickyCards,
                },
              },
              virginProjectTemplateIds: companyDto.virginProjectTemplateIds.map(
                ({ value }) => value,
              ),
              virginEmailTemplateIds: companyDto.virginEmailTemplateIds
                ? companyDto.virginEmailTemplateIds.map(({ value }) => value)
                : [],
            },
          },
          refetchQueries: [
            {
              query: GET_ALL_COMPANIES,
            },
          ],
        })

        showNotification('Company created correctly', 'is-success')
      }
    } catch (error) {
      showNotification(
        `Couldn't ${
          companyId ? 'update' : 'create'
        } the company. Please try later`,
        'is-danger',
      )
    }

    onClose()
  }

  function onClose() {
    history.push(COMPANIES)
  }

  if (
    fetchingAllVirginProjectTemplates ||
    fetchingAllVirginEmailTemplates ||
    fetchingCompanyById ||
    updatingCompanyById
  ) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  const initialValues =
    companyId && getCompanyByIdResponse?.companyById
      ? {
          name: getCompanyByIdResponse.companyById.name,
          allowedExtraUsers:
            getCompanyByIdResponse.companyById.settings.subscription
              .allowedExtraUsers,
          maxNumberOfProjects:
            getCompanyByIdResponse.companyById.settings.subscription
              .maxNumberOfProjects,
          enableProjectNotifications:
            getCompanyByIdResponse.companyById.settings.subscription
              .enableProjectNotifications,
          enableTimesheet:
            getCompanyByIdResponse.companyById.settings.subscription
              .enableTimesheet,
          enableTimeline:
            getCompanyByIdResponse.companyById.settings.subscription
              .enableTimeline,
          enableCardsTimeline:
            getCompanyByIdResponse.companyById.settings.subscription
              .enableCardsTimeline,
          enableStickyCards:
            getCompanyByIdResponse.companyById.settings.subscription
              .enableStickyCards,
        }
      : {
          name: '',
          adminFirstName: '',
          adminLastName: '',
          adminEmail: '',
          adminPassword: '',
          allowedExtraUsers: 3,
          maxNumberOfProjects: 10,
          enableProjectNotifications: false,
          enableTimesheet: false,
          enableTimeline: false,
          enableCardsTimeline: false,
          enableStickyCards: false,
          virginProjectTemplateIds: [],
        }

  return (
    <CreateFormModal
      title={`${companyId ? 'Update' : 'Create'} company`}
      submitButtonText={companyId ? 'Update' : 'Create'}
      resetButtonText={companyId ? 'Cancel' : 'Reset'}
      open
      onCreate={onCreate}
      onReset={onReset}
      onClose={onClose}
      submitting={creatingCompany}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur
        validate={values => {
          const errors = {}

          if (!values.name) {
            errors.name = 'Required'
          }

          if (companyId) return errors

          if (values.virginProjectTemplateIds.length === 0) {
            errors.virginProjectTemplateIds = 'Required'
          }

          if (!values.adminFirstName) {
            errors.adminFirstName = 'Required'
          }

          if (!values.adminLastName) {
            errors.adminLastName = 'Required'
          }

          if (!values.adminEmail) {
            errors.adminEmail = 'Required'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.adminEmail)
          ) {
            errors.adminEmail = 'Invalid email address'
          }

          if (!values.adminPassword) {
            errors.adminPassword = 'Required'
          } else if (values.adminPassword.length < 6) {
            errors.adminPassword = 'Password must contain minimum 6 characters'
          }

          return errors
        }}
      >
        {() => (
          <Form>
            <FormInput name="name" label="Company Name" mandatory />
            {!companyId ? (
              <div className={styles['company-form-section']}>
                <h2>Subscriber</h2>
                <FormInput name="adminFirstName" label="First Name" mandatory />
                <FormInput name="adminLastName" label="Last Name" mandatory />
                <FormInput name="adminEmail" label="Email" mandatory />

                <div className="control">
                  <FormInput name="adminPassword" label="Password" mandatory />
                </div>
              </div>
            ) : (
              <></>
            )}

            {!companyId ? (
              <div className={styles['company-form-section']}>
                <h2>Virgin Project Templates</h2>
                <Field
                  name="virginProjectTemplateIds"
                  component={SelectField}
                  options={getAllVirginProjectTemplatesResponse?.virginProjectTemplates.map(
                    template =>
                      ({
                        value: template.id,
                        label: template.name,
                      } || []),
                  )}
                  multiple
                />
                <ErrorMessage
                  className="error"
                  name="virginProjectTemplateIds"
                  component="div"
                />
              </div>
            ) : (
              <></>
            )}

            {!companyId ? (
              <div className={styles['company-form-section']}>
                <h2>Virgin Email Templates</h2>
                <Field
                  name="virginEmailTemplateIds"
                  component={SelectField}
                  options={getAllVirginEmailTemplatesResponse?.virginEmailTemplates.map(
                    template =>
                      ({
                        value: template.id,
                        label: template.name,
                      } || []),
                  )}
                  multiple
                />
                <ErrorMessage
                  className="error"
                  name="virginEmailTemplateIds"
                  component="div"
                />
              </div>
            ) : (
              <></>
            )}

            <div className={styles['company-form-section']}>
              <h2>Settings</h2>
              <FormInput
                name="allowedExtraUsers"
                label="Maximum users"
                type="number"
                mandatory
              />

              <FormInput
                name="maxNumberOfProjects"
                label="Maximum projects"
                type="number"
                mandatory
              />

              <FormToggle
                label="Allow Email Notifications"
                name="enableProjectNotifications"
                style={{ marginRight: '1rem' }}
              />

              <FormToggle label="Enable Timesheet" name="enableTimesheet" />

              <FormToggle
                label="Enable Projects Timeline"
                name="enableTimeline"
              />

              <FormToggle
                label="Enable Cards Timeline"
                name="enableCardsTimeline"
              />

              <FormToggle
                label="Enable Sticky Cards"
                name="enableStickyCards"
              />
            </div>
          </Form>
        )}
      </Formik>
    </CreateFormModal>
  )
}

export default CompanyEditor
