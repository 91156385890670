import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { Link } from 'react-router-dom'

import { Logo } from 'assets'
import styles from './login.module.scss'

const LoginView = ({ onLogin, loading, apiError }) => {
  const initialValues = {
    email: '',
    password: '',
  }

  return (
    <div className={styles['login-page']}>
      <div className={styles['logo-container']}>
        <Logo />
      </div>
      <div className={styles['login-wrapper']}>
        <div className={styles['login-header']}>
          <span className="icon is-medium is-left">
            <FontAwesomeIcon size="lg" icon={faSignInAlt} />
          </span>
          <span>Sign in</span>
        </div>

        <div className={styles['login-form-wrapper']}>
          <Formik
            enableReinitialize
            validateOnBlur={false}
            initialValues={initialValues}
            validate={values => {
              const errors = {}

              if (!values.email) {
                errors.email = 'Required'
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address'
              }

              if (!values.password) {
                errors.password = 'Required'
              } else if (values.password.length < 6) {
                errors.password = 'Password must contain minimum 6 characters'
              }

              return errors
            }}
            onSubmit={({ email, password }) => onLogin(email, password)}
          >
            {() => (
              <Form>
                <div className={styles['align-center']}>
                  <div className={cx('field', styles['field-container'])}>
                    <label className="label">Email *</label>
                    <div className="control">
                      <Field className="input" type="email" name="email" />
                      <ErrorMessage
                        className="error"
                        name="email"
                        component="div"
                      />
                    </div>
                  </div>

                  <div className={cx('field', styles['field-container'])}>
                    <label className="label">Password *</label>
                    <div className="control">
                      <Field
                        className="input"
                        type="password"
                        name="password"
                      />
                      <ErrorMessage
                        className="error"
                        name="password"
                        component="div"
                      />
                    </div>
                  </div>
                </div>

                {apiError && (
                  <p className="error" data-test="error-message">
                    {apiError}
                  </p>
                )}

                <div
                  className={cx(
                    styles['footer-wrapper'],
                    styles['align-center'],
                  )}
                >
                  <Link
                    className={styles['link-style']}
                    to="/password-recovery"
                  >
                    Forgot password
                  </Link>
                  <div className={styles['right-section']}>
                    <button
                      className={cx(
                        'button',
                        'is-info',
                        styles['action-button'],
                        loading ? 'is-loading' : '',
                      )}
                      type="submit"
                    >
                      Sign in
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className={styles['login-text']}>
        Don’t have an account yet?{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://yoopknows.com/#home-pricing-section"
          className={cx(styles['link-style'], styles['bold'])}
        >
          Sign Up
        </a>{' '}
        now!
      </div>
    </div>
  )
}

export default LoginView
