import Select from 'react-select'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLongArrowAltDown,
  faLongArrowAltUp,
} from '@fortawesome/free-solid-svg-icons'

import styles from './SortingSelect.module.scss'

function SortingSelect({ sortingOptions, sort, setSort }) {
  const toggleSortingDirection = () =>
    setSort(currSort => ({
      ...currSort,
      direction: currSort?.direction === 'asc' ? 'desc' : 'asc',
    }))

  return (
    <div className={styles['container']}>
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        isClearable
        options={sortingOptions}
        value={sortingOptions.find(o => o?.value === sort?.field)}
        onChange={option =>
          setSort(currSort => ({
            field: option?.value,
            direction: currSort?.direction ?? 'asc',
          }))
        }
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: 'rgba(101, 138, 255, 0.35)',
            primary: '#658aff',
          },
        })}
        styles={{
          container: provided => ({
            ...provided,
            width: '70%',
          }),
        }}
        placeholder={'Sort by...'}
      />

      {sort?.field && (
        <button
          onClick={toggleSortingDirection}
          className={styles['direction-button']}
        >
          <FontAwesomeIcon
            icon={
              sort?.direction === 'asc' ? faLongArrowAltDown : faLongArrowAltUp
            }
          />
        </button>
      )}
    </div>
  )
}

export default SortingSelect
