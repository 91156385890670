import styles from './TimelineToolbar.module.scss'

function TimelineToolbar({
  selectedScale,
  setSelectedScale,
  maxProjects,
  setMaxProjects,
}) {
  return (
    <div className={styles['container']}>
      <ul className={styles['scale-selectors']}>
        {[
          { label: 'S', value: 5 },
          { label: 'M', value: 10 },
          { label: 'L', value: 20 },
          { label: 'Full', value: null },
        ].map(({ value: projectsNumber, label }) => (
          <li
            key={projectsNumber}
            className={
              (projectsNumber === maxProjects && styles['selected-scale']) || ''
            }
          >
            <button onClick={() => setMaxProjects(projectsNumber)}>
              {label}
            </button>
          </li>
        ))}
      </ul>

      <ul className={styles['scale-selectors']}>
        {['Day', 'Week', 'Month'].map(scale => (
          <li
            key={scale}
            className={
              (scale === selectedScale && styles['selected-scale']) || ''
            }
          >
            <button onClick={() => setSelectedScale(scale)}>{scale}</button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default TimelineToolbar
