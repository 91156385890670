import { useState } from 'react';

import { Container, Draggable } from 'react-smooth-dnd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCaretSquareDown } from '@fortawesome/free-solid-svg-icons'
import cx from 'classnames'

import styles from './TasksForm.module.scss'

import ExpansionPanel from '../../../../../../components/expansion-panel'
import SmartNotificationsModal from '../../../../../shared/project/project-phases/tasks/smart-notifications-modal'

import DeleteModal from '../../../../../shared/project/project-phases/delete-modal'

import { reorder } from '../../../../../../constants/utils'
import { useMutation } from '@apollo/client'
import {
  CREATE_PROJECT_TEMPLATE_PHASE_TASK,
  DELETE_PROJECT_TEMPLATE_PHASE_TASK_BY_ID,
  MOVE_PROJECT_TEMPLATE_PHASE_TASK_BY_ID,
  UPDATE_PROJECT_TEMPLATE_PHASE_TASK_BY_ID,
} from 'graphql/mutations'
import { toast } from 'bulma-toast'

function renderTaskHeading(task, toggleModal, i) {
  return (
    <div className={cx('level', styles['wrapper'])}>
      <div className={cx('level-left', styles['container'])}>
        <div className={cx('level-item', styles['icons'])}>
          <button
            type="button"
            className="button is-ghost mr-4 p-0"
            style={{ color: '#555c72' }}
            onClick={() => toggleModal(task, i)}
          >
            <FontAwesomeIcon icon={faTrash} size="1x" />
          </button>
        </div>
        <div>{task.title || `Task ${i + 1}`}</div>
      </div>

      <div className="level-right">
        <div
          className={cx('level-item', styles['drop-down-icon'])}
          style={{ marginLeft: '14px' }}
        >
          <FontAwesomeIcon icon={faCaretSquareDown} size="2x" />
        </div>
      </div>
    </div>
  )
}

function TasksForm({ tasks, onTasksUpdated, noButton, isEditing, phaseId }) {
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(null)
  const [selectedTask, setSelectedTask] = useState(null)
  const [openedModal, setOpenedModal] = useState()

  const [addTask] = useMutation(CREATE_PROJECT_TEMPLATE_PHASE_TASK)
  const [moveTask] = useMutation(MOVE_PROJECT_TEMPLATE_PHASE_TASK_BY_ID)
  const [deleteTask] = useMutation(DELETE_PROJECT_TEMPLATE_PHASE_TASK_BY_ID)

  function onReorderTasks(tasks, onTasksChange) {
    return async ({ removedIndex: from, addedIndex: to }) => {
      if (from === to) return

      const currentTasks = [...tasks]

      const newTasks = reorder(currentTasks, from, to)

      if (isEditing) {
        const movedTask = tasks[from]
        const { data } = await moveTask({
          variables: { id: movedTask.id, newPosition: to },
        })

        if (data.moveProjectTemplatePhaseTask.successful)
          return onTasksChange(newTasks)
      } else {
        return onTasksChange(newTasks)
      }
    }
  }

  async function onAddTask() {
    if (isEditing) {
      const resp = await addTask({
        variables: {
          phaseId,
          input: {
            title: `Task ${tasks?.length ? tasks.length + 1 : 1}`,
            private: false,
          },
        },
      })

      if (resp.data.createProjectTemplatePhaseTask.successful) {
        const newTasks = [
          ...(tasks ?? []),
          resp.data.createProjectTemplatePhaseTask.projectTemplatePhaseTask,
        ]
        onTasksUpdated(newTasks)
      }
    } else {
      const newTasks = [
        ...(tasks ?? []),
        {
          title: `Task ${tasks?.length ? tasks.length + 1 : 1}`,
          private: false,
        },
      ]
      onTasksUpdated(newTasks)
    }
  }

  function toggleModal(task, taskIndex) {
    setSelectedTask(task)
    setSelectedTaskIndex(taskIndex)
    setOpenedModal('delete')
  }

  function closeModal() {
    setSelectedTask(null)
    setSelectedTaskIndex(null)
    setOpenedModal(null)
  }

  function updateReorderedTasks(newTasks) {
    onTasksUpdated(newTasks)
  }

  async function onRemoveTask(taskIndex) {
    if (isEditing) {
      const { data } = await deleteTask({
        variables: { id: tasks[taskIndex].id },
      })

      if (data.deleteProjectTemplatePhaseTask.successful) {
        const newTasks = tasks.filter((_, i) => i !== taskIndex)
        onTasksUpdated(newTasks)
      }
    } else {
      const newTasks = tasks.filter((_, i) => i !== taskIndex)
      onTasksUpdated(newTasks)
    }
  }

  const [updateTask] = useMutation(UPDATE_PROJECT_TEMPLATE_PHASE_TASK_BY_ID)

  async function onTaskUpdate(i, field, value) {
    if (isEditing) {
      const { id } = tasks[i]

      const newTasks = tasks.map(task => ({ ...task }))
      newTasks[i][field] = value

      onTasksUpdated(newTasks)

      try {
        await updateTask({
          variables: {
            id,
            input: {
              [field]: value,
            },
          },
        })
      } catch (e) {
        toast({
          message: `Could not update task`,
          type: 'is-danger',
          dismissible: true,
          position: 'bottom-right',
          duration: 3000,
          closeOnClick: true,
        })
      }
    }
  }

  return (
    <div>
      {' '}
      {!noButton && (
        <button type="button" className="button is-info" onClick={onAddTask}>
          Add task
        </button>
      )}
      <Container onDrop={onReorderTasks(tasks, updateReorderedTasks)}>
        {tasks?.map((task, i) => {
          return (
            <Draggable key={`form-task-${i}`} style={{ overflow: 'visible' }}>
              <div>
                <ExpansionPanel
                  title={renderTaskHeading(task, toggleModal, i)}
                  className="no-outline"
                >
                  <div className="no-drag" style={{ padding: '14px' }}>
                    <div className="field">
                      <label className="label">Title*</label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          value={tasks[i].title}
                          onChange={e =>
                            onTaskUpdate(i, 'title', e.target.value)
                          }
                        />
                      </div>
                    </div>

                    <div className="level">
                      <div className="level-left">
                        <div className="field">
                          <div className="control">
                            <label
                              className="checkbox"
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <input
                                type="checkbox"
                                value={tasks[i].private}
                                checked={tasks[i].private}
                                onChange={() =>
                                  onTaskUpdate(i, 'private', !tasks[i].private)
                                }
                              />
                              Set as private
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="level-right">
                        <button
                          type="button"
                          className="button is-info"
                          onClick={() => setSelectedTaskIndex(i)}
                        >
                          Smart Notifications
                        </button>
                      </div>
                    </div>
                  </div>
                </ExpansionPanel>
              </div>
            </Draggable>
          )
        })}
      </Container>
      {selectedTaskIndex !== null && !openedModal ? (
        <SmartNotificationsModal
          open={selectedTaskIndex !== null}
          onClose={() => setSelectedTaskIndex(null)}
          closeOnSubmit
          currentConfiguration={tasks[selectedTaskIndex].notification}
          onConfigureNotifications={config => {
            onTaskUpdate(selectedTaskIndex, 'notification', config)
            setSelectedTaskIndex(null)
          }}
          taskName={tasks[selectedTaskIndex].title}
        />
      ) : (
        <></>
      )}
      {selectedTask && openedModal && (
        <DeleteModal
          onClose={closeModal}
          deleteAction={() => onRemoveTask(selectedTaskIndex)}
          elemType="Task"
          title={selectedTask.title}
          open={!!selectedTask}
        />
      )}
    </div>
  )
}

export default TasksForm
