import gql from 'graphql-tag'

export const virginProjectTemplateFragment = gql`
  fragment VirginProjectTemplateFragment on VirginProjectTemplate {
    id
    name
    phases {
      id
      title
      description
      duration
      tasks {
        id
        title
      }
    }
  }
`

export const virginEmailTemplateFragment = gql`
  fragment VirginEmailTemplateFragment on VirginEmailTemplate {
    id
    name
    emailText
  }
`

export const companyFragment = gql`
  fragment CompanyFragment on Company {
    id
    name
    settings
    subscriberEmail
  }
`

export const projectPhaseFragment = /* GraphQL */ `
  fragment ProjectPhaseFragment on ProjectPhase {
    id
    title
    description
    dependsOn
    estimatedDurationDateRange {
      estimatedStartingDate
      estimatedCompletionDate
    }
    documents {
      name
      text
      uploadedBy
      createdAt
    }
    tasks {
      id
      title
      notification {
        emailTemplate {
          emailText
          id
        }
        recipients
        extraRecipients
      }
      completed
    }
  }
`
