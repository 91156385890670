import { useMutation } from '@apollo/react-hooks'
import debounce from 'lodash.debounce'

import { GET_PROJECT_DETAILS } from 'graphql/queries'

import {
  POST_DOCUMENT,
  ADD_PROJECT_PHASE,
  UPDATE_PROJECT_PHASE_BY_ID,
  MOVE_PROJECT_PHASE_BY_ID,
  DELETE_PROJECT_PHASE_BY_ID,
} from 'graphql/mutations'

import ProjectPhasesView from './project-phases-view'

function ProjectPhasesContainer({
  phases,
  projectId,
  projectStartDate,
  readOnly = false,
}) {
  const [addProjectPhaseMutation] = useMutation(ADD_PROJECT_PHASE)
  const [movePhaseMutation] = useMutation(MOVE_PROJECT_PHASE_BY_ID)
  const [updatePhaseByIdMutation] = useMutation(UPDATE_PROJECT_PHASE_BY_ID)
  const [deleteProjectPhaseByIdMutation] = useMutation(
    DELETE_PROJECT_PHASE_BY_ID,
  )
  const [postDocumentMutation] = useMutation(POST_DOCUMENT)

  function onAddPhase(phaseDto) {
    return addProjectPhaseMutation({
      variables: {
        projectId,
        input: phaseDto,
      },

      update: (store, result) => {
        const { projectById } = store.readQuery({
          query: GET_PROJECT_DETAILS,
          variables: {
            id: projectId,
          },
        })

        store.writeQuery({
          query: GET_PROJECT_DETAILS,
          variables: {
            id: projectId,
          },
          data: {
            projectById: {
              ...projectById,
              phases: [
                ...projectById.phases,
                result.data.addProjectPhase.phase,
              ],
            },
          },
        })
      },
    })
  }

  function onUpdatePhase(phase, phaseDto) {
    return updatePhaseByIdMutation({
      variables: { id: phase.id, input: phaseDto },
    })
  }

  function onMovePhase(phase, newPosition) {
    return movePhaseMutation({
      variables: {
        id: phase.id,
        newPosition,
      },

      update: store => {
        const { projectById } = store.readQuery({
          query: GET_PROJECT_DETAILS,
          variables: {
            id: projectId,
          },
        })

        const newPhases = [...projectById.phases]
        const currentMovedPhasePosition = projectById.phases.findIndex(
          ({ id }) => phase.id === id,
        )
        newPhases[currentMovedPhasePosition] = newPhases[newPosition]
        newPhases[newPosition] = phase

        store.writeQuery({
          query: GET_PROJECT_DETAILS,
          variables: {
            id: projectId,
          },
          data: {
            projectById: {
              ...projectById,
              phases: newPhases,
            },
          },
        })
      },

      optimisticResponse: {
        __typename: 'Mutation',
        moveProjectPhaseById: {
          phase: {
            id: phase.id,
            __typename: 'ProjectPhase',
          },
          successful: true,
          userErrors: null,
          __typename: 'MoveProjectPhaseByIdResponse',
        },
      },
    })
  }

  function onDeletePhase(phase) {
    return deleteProjectPhaseByIdMutation({
      variables: {
        id: phase.id,
      },

      update: store => {
        const data = store.readQuery({
          query: GET_PROJECT_DETAILS,
          variables: {
            id: projectId,
          },
        })

        store.writeQuery({
          query: GET_PROJECT_DETAILS,
          variables: {
            id: projectId,
          },
          data: {
            projectById: {
              ...data.projectById,
              phases: data.projectById.phases.filter(
                ({ id }) => phase.id !== id,
              ),
            },
          },
        })
      },
    })
  }

  function onPostDocument(phaseIndex, document) {
    const documentPayload = {
      name: document.name,
      text: document.link,
    }

    return postDocumentMutation({
      variables: { projectId, phaseIndex, document: documentPayload },
    })
  }

  const debouncedOnUpdatePhase = debounce(onUpdatePhase, 700)

  return (
    <ProjectPhasesView
      projectStartDate={projectStartDate}
      phases={phases}
      projectId={projectId}
      readOnly={readOnly}
      onAddPhase={onAddPhase}
      onUpdatePhase={debouncedOnUpdatePhase}
      onUpdatePhaseNotDebounced={onUpdatePhase}
      onMovePhase={onMovePhase}
      onDeletePhase={onDeletePhase}
      onPostDocument={onPostDocument}
    />
  )
}

export default ProjectPhasesContainer
