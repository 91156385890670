import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ProjectSectionTitle from '../project-section-title'

import {
  faPhone,
  faEnvelope,
  faUser,
  faHouse,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons'

import styles from './ProjectDescriptionUsers.module.scss'

const ProjectDescriptionUsers = ({
  type = 'Users',
  users = [],
  redactPhoneNumber = false,
}) => {
  const usersToRender = users?.map(user => ({
    firstName: user && user.firstName ? user.firstName : '',
    lastName: user && user.lastName ? user.lastName : '',
    email: user && user.email ? user.email : '',
    address: user && user.address ? user.address : '',
    phoneNumber:
      !redactPhoneNumber && user && user.phoneNumber?.trim()
        ? user.phoneNumber
        : '',
    organization: user.organization?.name ?? '',
  }))

  return !users?.length ? (
    <></>
  ) : (
    <div className={styles['project-description-users']}>
      <ProjectSectionTitle title={type} icon="icon-friends" />
      <div
        className={styles['project-description-section-text']}
        data-test={`users-container-${type}`}
      >
        {usersToRender?.map(
          ({
            firstName,
            lastName,
            email,
            phoneNumber,
            address,
            organization,
          }) => (
            <div key={email} style={{ marginTop: '1em' }}>
              {firstName || lastName ? (
                <div className={styles['client-detail']}>
                  <span
                    className="icon is-small is-left"
                    style={{ marginRight: 5 }}
                  >
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  {firstName || ''} {lastName || ''}
                </div>
              ) : (
                ''
              )}

              {email ? (
                <div className={styles['client-detail']}>
                  <span
                    className="icon is-small is-left"
                    style={{ marginRight: '0.5em' }}
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
              ) : (
                ''
              )}

              {phoneNumber ? (
                <div className={styles['client-detail']}>
                  <span
                    className="icon is-small is-left"
                    style={{ marginRight: 5 }}
                  >
                    <FontAwesomeIcon icon={faPhone} />
                  </span>
                  <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                </div>
              ) : (
                ''
              )}

              {address ? (
                <div className={styles['client-detail']}>
                  <span
                    className="icon is-small is-left"
                    style={{ marginRight: 5 }}
                  >
                    <FontAwesomeIcon icon={faHouse} />
                  </span>
                  {address}
                </div>
              ) : (
                ''
              )}

              {!!organization && (
                <div className={styles['client-detail']}>
                  <span
                    className="icon is-small is-left"
                    style={{ marginRight: 5 }}
                  >
                    <FontAwesomeIcon icon={faBuilding} />
                  </span>
                  {organization}
                </div>
              )}
            </div>
          ),
        )}
      </div>
    </div>
  )
}

export default ProjectDescriptionUsers
