import cx from 'classnames'

import ConfirmationModal from '../../../../components/confirmation-modal'

import styles from './delete-project.module.scss'

const DeleteProjectModal = ({
  onClose,
  deleteProject,
  submitting,
  selectedProject,
}) => {
  return (
    <ConfirmationModal open onClose={onClose}>
      <div style={{ padding: '24px' }}>
        <p>
          Are you sure you want to delete project{' '}
          <span style={{ fontWeight: 'bold' }}>{selectedProject.name}</span> ?
        </p>
        <div className="buttons is-right">
          <button
            className={cx('button', 'is-light', styles['action-button'])}
            onClick={onClose}
            disabled={submitting}
          >
            Cancel
          </button>

          <button
            className={cx(
              'button',
              'is-info',
              styles['action-button'],
              submitting ? 'is-loading' : '',
            )}
            onClick={() => {
              deleteProject(selectedProject)
              onClose()
            }}
            type="submit"
            data-test="confirm-button"
          >
            Delete
          </button>
        </div>
      </div>
    </ConfirmationModal>
  )
}

export default DeleteProjectModal
