import { useState } from 'react';
import cx from 'classnames'

import ProjectSectionTitle from './components/project-section-title'

import styles from './project-description.module.scss'

const ProjectDescriptionField = ({
  isAnonymous,
  readOnly,
  value,
  onUpdate,
}) => {
  const [description, setDescription] = useState(value)
  const [disabled, setDisabled] = useState(true)

  function onUpdateDescription(e) {
    const newDescription = e.target.value

    setDescription(newDescription)
    onUpdate({ target: { name: 'description', value: newDescription } })
  }

  const iOS = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent)

  function showInput() {
    return (
      <div className="field">
        <div className="control">
          <textarea
            name="description"
            value={description}
            placeholder="Project description..."
            className={cx(
              styles['textarea-input'],
              'textarea is-borderless with-shadow',
            )}
            onChange={onUpdateDescription}
            readOnly={iOS && !isAnonymous ? false : disabled}
            onClick={() => (!readOnly ? setDisabled(false) : '')}
            onBlur={() => (!readOnly ? setDisabled(true) : '')}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={styles['project-description-field']}>
      <ProjectSectionTitle
        title="Project description/directory"
        icon="icon-list"
      />
      {showInput()}
    </div>
  )
}

export default ProjectDescriptionField
