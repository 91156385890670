import { useState } from 'react';

import { useQuery, useMutation } from '@apollo/react-hooks'
import hooks from 'hooks'
import {
  GET_MY_FAVOURITE_PROJECTS,
  GET_PROJECTS,
  GET_MY_TEAM,
  GET_USER_BY_ID,
} from 'graphql/queries'
import { SNOOZE_PROJECT_BY_ID } from 'graphql/mutations'

import isThisMonth from 'date-fns/isThisMonth'
import isToday from 'date-fns/isToday'
import subDays from 'date-fns/subDays'
import getTime from 'date-fns/getTime'
import { toast as notify } from 'bulma-toast'

import Loader from 'components/loader'
import DashboardView from './dashboard-view'

function DashboardContainer() {
  const auth = hooks.useAuth()

  const [progressToShow, setProgressToShow] = useState(['live'])
  const [filterByFavourite, setFilterByFavourite] = useState(false)
  const [searchBy, setSearchBy] = useState('')
  const [assegneeFilter, setAssegneeFilter] = useState([])

  const { data: userResponse, loading: fetchingUserDetails } = useQuery(
    GET_USER_BY_ID,
    {
      variables: { id: auth.user.uid },
      fetchPolicy: 'network-only',
    },
  )

  const [snoozeMutation] = useMutation(SNOOZE_PROJECT_BY_ID)

  // This logic needs to be moved into a reducer
  function snoozeProject(projectId, period) {
    return snoozeMutation({
      variables: { id: projectId, period },
    })
      .then(({ data }) =>
        notify({
          message: `Project ${data.snoozeProjectById.project.name} snoozed for ${period}`,
          type: 'is-success',
          dismissible: true,
          position: 'bottom-right',
          duration: 3000,
          closeOnClick: true,
        }),
      )
      .catch(() =>
        notify({
          message: 'There was an error while snoozing the project',
          type: 'is-danger',
          dismissible: true,
          position: 'bottom-right',
          duration: 3000,
          closeOnClick: true,
        }),
      )
  }

  function onSelectProgress(value) {
    const values = value ? value.map(item => item.value) : []
    setProgressToShow(values)
  }

  function onSelectAssegnee(value) {
    const assegneeIds = value?.map(user => user.value) ?? []

    setAssegneeFilter(assegneeIds)
  }

  function onSearchBy(value) {
    setSearchBy(value)
  }

  const {
    data,
    loading: fetchingProjects,
    error: fetchingProjectsError,
  } = useQuery(GET_PROJECTS)

  const {
    data: myFavouriteProjectsResponse,
    loading: fetchingMyFavouritegProjects,
  } = useQuery(GET_MY_FAVOURITE_PROJECTS)

  const { projects: allProjects = [] } = data ?? {}

  const favouriteProjectsIds = new Set(
    myFavouriteProjectsResponse?.myFavouriteProjects?.map(p => p.id),
  )

  const myFavouriteProjects = allProjects.filter(p =>
    favouriteProjectsIds.has(p.id),
  )
  const { data: team } = useQuery(GET_MY_TEAM)

  //Improve how we show errors
  if (fetchingProjectsError) {
    // eslint-disable-next-line no-console
    console.error(fetchingProjectsError)
    return <p>ERROR</p>
  }

  if (fetchingProjects || fetchingMyFavouritegProjects || fetchingUserDetails) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  let projects = filterByFavourite ? myFavouriteProjects : allProjects

  const statistics = {
    currentLiveProjects: projects.filter(
      project => !project.archived && !project.onHold,
    ).length,
    onHoldProjects: projects.filter(project => project.onHold).length,
    actionRequired: projects
      .filter(project => !project.archived)
      .filter(project => project.snoozeType === 'expired').length,
    snoozedProjectsToday: projects
      .filter(project => project.snoozed)
      .filter(project => isToday(new Date(project.snoozed.createdAt))).length,
    thisMonthNewProjects: projects.filter(project =>
      isThisMonth(new Date(project.createdAt)),
    ).length,
    thisMonthFinishedProjects: projects.filter(project =>
      isThisMonth(new Date(project.archived)),
    ).length,
  }

  // // Filter by progress

  if (progressToShow.length > 0) {
    const colorsFilters = ['today', 'ongoing', 'expired', 'oneday']
    const completionFilters = ['completed', 'uncompleted']
    const statusFilter = ['archived', 'onHold', 'live']

    const filterByColor = colorsFilters.reduce((acc, value) => {
      if (progressToShow.includes(value)) return acc || true

      return acc
    }, false)

    const filterByCompletion = completionFilters.reduce((acc, value) => {
      if (progressToShow.includes(value)) return acc || true

      return acc
    }, false)

    const filterByStatus = statusFilter.reduce((acc, value) => {
      if (progressToShow.includes(value)) return acc || true

      return acc
    }, false)

    const filterByDetermination = progressToShow.includes(
      'excludeOlderThan14Days',
    )

    if (filterByColor) {
      projects = projects.filter(project => {
        if (progressToShow.includes('today')) {
          if (project.snoozeType === 'today') {
            return true
          }
        }

        if (progressToShow.includes('ongoing')) {
          if (project.snoozeType === 'ongoing') {
            return true
          }
        }

        if (progressToShow.includes('expired')) {
          if (project.snoozeType === 'expired') {
            return true
          }
        }

        if (progressToShow.includes('oneday')) {
          if (project.snoozeType === 'oneday') {
            return true
          }
        }

        return false
      })
    }

    if (filterByCompletion) {
      projects = projects.filter(project => {
        if (progressToShow.includes('completed')) {
          if (parseInt(project.progress) === 100) {
            return true
          }
        }

        if (progressToShow.includes('uncompleted')) {
          if (parseInt(project.progress) < 100) {
            return true
          }
        }

        return false
      })
    }

    if (filterByStatus) {
      // eslint-disable-next-line
      projects = projects.filter(project => {
        if (progressToShow.includes('archived')) {
          if (project.archived) {
            return true
          }
        }

        if (progressToShow.includes('onHold')) {
          if (project.onHold) {
            return true
          }
        }

        if (progressToShow.includes('live')) {
          if (!project.archived && !project.onHold) {
            return true
          }
        }
      })
    }

    if (filterByDetermination) {
      projects = [
        ...projects.filter(
          project => project.endDate > getTime(subDays(Date.now(), 14)),
        ),
      ]
    }

    if (assegneeFilter?.length) {
      projects = [
        ...projects.filter(project => {
          const assegneesIds = new Set(
            project.assignees?.map(({ teamMember }) => teamMember.id),
          )

          return assegneeFilter.some(selectedAssegnee => {
            return assegneesIds.has(selectedAssegnee)
          })
        }),
      ]
    }
  }

  if (searchBy) {
    projects = projects.filter(project => {
      return (
        // eslint-disable-next-line
        project.clients.filter(client => {
          if (client) {
            return (
              client.firstName.toLowerCase() +
              ' ' +
              client.lastName.toLowerCase()
            ).includes(searchBy.toLowerCase())
          }
        }).length > 0 ||
        project.name.toLowerCase().includes(searchBy.toLowerCase())
      )
    })
  }

  return (
    <DashboardView
      projects={projects}
      user={userResponse.userById}
      seeFavoriteProjects={setFilterByFavourite}
      showingFavoriteProjects={filterByFavourite}
      projectsStatistics={statistics}
      snoozeProject={snoozeProject}
      onSearchBy={onSearchBy}
      searchBy={searchBy}
      onSelectProgress={onSelectProgress}
      team={team?.team}
      onSelectAssegnee={onSelectAssegnee}
    />
  )
}

export default DashboardContainer
