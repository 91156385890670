import { Link, useLocation } from 'react-router-dom'
import cx from 'classnames'

import styles from './TabLink.module.scss'

function TabLink({ to, children, externalIsActive, dataTest }) {
  const { pathname } = useLocation()

  const internalIsActive = pathname === to

  const isActive = externalIsActive || internalIsActive

  return (
    <li
      data-test={dataTest}
      className={cx(styles['container'], isActive && styles['active'])}
    >
      <Link to={to} className={styles['content']}>
        {children}
      </Link>
    </li>
  )
}

export default TabLink
