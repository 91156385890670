import DatePickerView from './DatePickerView'
import useDatepicker from './hooks/useDatepicker'

export default function DatePickerContainer({
  value,
  startValue,
  endValue,
  onChange,
  onChangeStartValue,
  onChangeEndValue,
  minDate,
  maxDate,
  maxRange = null,
  isRange,
  disabled,
  top,
  className,
  containerClassName,
  singleOnChange,
}) {
  const {
    calendarVisible,
    setCalendarVisible,
    minRangeDate,
    maxRangeDate,
    label,
    handleChange,
    handleClickDay,
  } = useDatepicker({
    maxRange,
    startValue,
    endValue,
    isRange,
    value,
    onChangeStartValue,
    onChangeEndValue,
    onChange,
    singleOnChange,
  })

  return (
    <DatePickerView
      minDate={minDate || (isRange ? minRangeDate : minDate)}
      maxDate={maxDate || (isRange ? maxRangeDate : maxDate)}
      value={value}
      startValue={startValue}
      endValue={endValue}
      isRange={isRange}
      calendarVisible={calendarVisible}
      setCalendarVisible={setCalendarVisible}
      label={label}
      handleChange={handleChange}
      handleClickDay={handleClickDay}
      disabled={disabled}
      top={top}
      className={className}
      containerClassName={containerClassName}
    />
  )
}
