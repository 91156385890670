import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'

import Users from './users'
import Projects from './projects'
import Settings from './settings-page'
import Templates from './templates-page'
import Subscription from './subscription'
import Metrics from './metrics'
import { TabsContainer, TabLink } from 'components/Tabs'

import tabStyles from '../dashboard/project-modal/project-modal.module.scss'
import { TEMPLATES } from 'constants/routes'
import { GET_CLIENTS_PARTNERS, GET_TEAM } from 'graphql/queries'
import hooks from '../../hooks'

function AdministrationView() {
  const { user } = hooks.useAuth()

  let { path, url } = useRouteMatch()

  return (
    <>
      <TabsContainer className={tabStyles['tabs-items']}>
        <TabLink to={`${url}/projects`}>Projects</TabLink>
        <TabLink to={TEMPLATES} dataTest="tab-templates">
          Templates
        </TabLink>
        <TabLink
          to={`${url}/clients-and-partners`}
          dataTest="tab-clients-and-partners"
        >
          Clients & Partners
        </TabLink>
        <TabLink to={`${url}/team`} dataTest="tab-team">
          Team
        </TabLink>
        <TabLink to={`${url}/settings`} dataTest="tab-settings">
          Settings
        </TabLink>
        <TabLink to={`${url}/subscription`} dataTest="tab-subscription">
          Subscription
        </TabLink>
        {user.role === 'admin' && (
          <TabLink to={`${url}/metrics`} dataTest="metrics">
            Metrics
          </TabLink>
        )}
      </TabsContainer>

      <Switch>
        <Route path={`${path}/projects`}>
          <Projects />
        </Route>

        <Route path={TEMPLATES}>
          <Templates />
        </Route>

        <Route path={`${path}/clients-and-partners`}>
          <Users
            title="Clients & Partners"
            role="clients_and_partners"
            usersQuery={GET_CLIENTS_PARTNERS}
            dataTest="administration-clients-and-partners-container"
          />
        </Route>

        <Route path={`${path}/team`}>
          <Users
            title="Team"
            usersQuery={GET_TEAM}
            isTeamPage
            dataTest="administration-team-container"
          />
        </Route>

        <Route path={`${path}/settings`}>
          <Settings />
        </Route>

        <Route path={`${path}/subscription`}>
          <Subscription />
        </Route>

        <Route exact path={`${path}`}>
          <Redirect to={`${url}/projects`} />
        </Route>

        <Route path={`${path}/metrics`}>
          {user.role === 'admin' ? <Metrics /> : <Redirect to="/" />}
        </Route>
      </Switch>
    </>
  )
}

export default AdministrationView
