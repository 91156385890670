import { useQuery, useMutation } from '@apollo/react-hooks'
import SettingsView from './settings-view'
import Loader from 'components/loader'
import hooks from 'hooks'
import { showNotification } from 'constants/utils'

import {
  GET_COMPANY_SETTINGS,
  GET_PROJECT_TEMPLATES_IDS,
  GET_USER_BY_ID,
} from 'graphql/queries'
import { ENABLE_UPDATES, UPDATE_USER_SETTINGS } from 'graphql/mutations'

import { useConfigureWeeklyUpdatesMutation } from './configureWeeklyUpdatesMutation'
import { useConfigureCreateCardCategoryMutation } from './configureCreateCardCategoryMutation'
import { useConfigureDeleteCardCategoryMutation } from './configureDeleteCardCategoryMutation'
import { useConfigureUpdateCardCategoryMutation } from './configureUpdateCardCategoryMutation'

const SettingsContainer = () => {
  const configureWeeklyUpdates = useConfigureWeeklyUpdatesMutation()
  const createCardCategory = useConfigureCreateCardCategoryMutation()
  const deleteCardCategory = useConfigureDeleteCardCategoryMutation()
  const updateCardCategory = useConfigureUpdateCardCategoryMutation()

  const [enableWeeklyUpdates] = useMutation(ENABLE_UPDATES)
  const [updateUserSettingsMutation] = useMutation(UPDATE_USER_SETTINGS)

  const { ...auth } = hooks.useAuth()

  const { data: settingsResponse, loading: settingsLoading } = useQuery(
    GET_COMPANY_SETTINGS,
    { fetchPolicy: 'network-only' },
  )

  const { data: projectTemplatesResponse, loading: projectTemplatesLoading } =
    useQuery(GET_PROJECT_TEMPLATES_IDS, { fetchPolicy: 'network-only' })

  const { data: userResponse, loading: fetchingUserDetails } = useQuery(
    GET_USER_BY_ID,
    { variables: { id: auth.user.uid }, fetchPolicy: 'network-only' },
  )

  function handleEnabledToggle() {
    return enableWeeklyUpdates({
      refetchQueries: [{ query: GET_COMPANY_SETTINGS }],
    })
      .then(
        ({
          data: {
            enableWeeklyUpdates: { success },
          },
        }) =>
          success
            ? showNotification('Configuration updated correctly', 'is-success')
            : showNotification(
                "It's not possible to create the job",
                'is-danger',
              ),
      )
      .catch(error => showNotification(error.message, 'is-danger'))
  }

  function updateUserSettings(settings = {}) {
    return updateUserSettingsMutation({
      variables: { settings },
      refetchQueries: [
        { query: GET_USER_BY_ID, variables: { id: auth.user.uid } },
      ],
    })
      .then(() => {
        showNotification('User settings update correctly!', 'is-success')
      })
      .catch(() => {
        showNotification(
          'User settings could not be updated! Please try again in a few moments.',
          'is-danger',
        )
      })
  }

  if (settingsLoading || projectTemplatesLoading || fetchingUserDetails) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  return (
    <SettingsView
      settings={settingsResponse.settings}
      user={userResponse.userById}
      projectTemplates={projectTemplatesResponse.projectTemplates}
      setWeeklyUpdates={handleEnabledToggle}
      updateUserSettings={updateUserSettings}
      onConfigureWeeklyUpdates={configureWeeklyUpdates}
      onCreateCardCategory={createCardCategory}
      onDeleteCardCategory={deleteCardCategory}
      onUpdateCardCategory={updateCardCategory}
    />
  )
}

export default SettingsContainer
