import { Formik, Form, Field, ErrorMessage } from 'formik'
import cx from 'classnames'

import SelectField from 'components/select'

import HeaderView from 'components/create-form-modal/header'

import styles from './smart-notifications.module.scss'
import Modal from 'components/Modal'

function SmartNotificationsView({
  onClose,
  open,
  onConfigureNotifications,
  emailTemplatesOptions,
  initialValues,
  taskName,
}) {
  function onSubmit(notificationsConfig) {
    let recipients = []

    if (notificationsConfig.client) recipients.push('client')
    if (notificationsConfig.engineers) recipients.push('engineers')
    if (notificationsConfig.consultants) recipients.push('consultants')
    if (notificationsConfig.surveyors) recipients.push('surveyors')

    onConfigureNotifications({
      recipients,
      emailTemplateId: notificationsConfig.emailTemplateId.value,
      extraRecipients: notificationsConfig.extraRecipients,
    })

    onClose()
  }

  return (
    <Modal isOpen={open}>
      <HeaderView
        title={`Configure smart notifications for the task '${taskName}'`}
        onClose={onClose}
      />
      <div className={styles['form-wrapper']}>
        <Formik
          enableReinitialize
          validateOnBlur={false}
          initialValues={initialValues}
          validate={values => {
            const errors = {}

            if (!values.emailTemplateId) {
              errors.emailTemplateId = 'Required'
            }

            return errors
          }}
          onSubmit={onSubmit}
        >
          {({ isValid, isSubmitting }) => (
            <Form>
              <p className={styles['text-style']}>
                Please select who will receive the notification when the task
                will be completed
              </p>
              <div className={styles['checkbox-wrapper']}>
                <div className={cx('field', styles['field-container'])}>
                  <div className={cx(styles['checkbox-control'], 'control')}>
                    <Field
                      className="checkbox"
                      type="checkbox"
                      name="engineers"
                    />
                    <span className={styles['text-style']}>Engineers</span>
                  </div>
                </div>

                <div className={cx('field', styles['field-container'])}>
                  <div className={cx(styles['checkbox-control'], 'control')}>
                    <Field
                      className="checkbox"
                      type="checkbox"
                      name="consultants"
                    />
                    <span className={styles['text-style']}>Consultants</span>
                  </div>
                </div>

                <div className={cx('field', styles['field-container'])}>
                  <div className={cx(styles['checkbox-control'], 'control')}>
                    <Field
                      className="checkbox"
                      type="checkbox"
                      name="surveyors"
                    />
                    <span className={styles['text-style']}>Surveyors</span>
                  </div>
                </div>

                <div className={cx('field', styles['field-container'])}>
                  <div className={cx(styles['checkbox-control'], 'control')}>
                    <Field className="checkbox" type="checkbox" name="client" />
                    <span className={styles['text-style']}>Client</span>
                  </div>
                </div>
                <ErrorMessage name="engineers" component="div" />
              </div>

              <div className={cx('field', styles['field-container'])}>
                <label className="label">Email template *</label>
                <div className="control is-expanded">
                  <Field
                    name="emailTemplateId"
                    component={SelectField}
                    options={emailTemplatesOptions}
                  />
                  <ErrorMessage
                    className="error"
                    name="emailTemplateId"
                    component="div"
                  />
                </div>
              </div>

              <div className={cx('field', styles['field-container'])}>
                <label className="label">
                  Extra recipients (Add separated by comma)
                </label>
                <div className="control is-expanded">
                  <Field
                    name="extraRecipients"
                    type="text"
                    className="input"
                    placeholder="t@email.com,t2@email.com"
                  />
                  <ErrorMessage name="extraRecipients" component="div" />
                </div>
              </div>

              <div className="buttons is-right">
                <button
                  className={cx('button', 'is-light', styles['action-button'])}
                  onClick={() => onClose()}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>

                <button
                  className={cx(
                    'button',
                    'is-info',
                    styles['action-button'],
                    isSubmitting ? 'is-loading' : '',
                  )}
                  disabled={!isValid}
                  type="submit"
                >
                  Confirm
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default SmartNotificationsView
