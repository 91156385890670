import DashboardRoute from './dashboard'
import ToolsRoute from './tools'
import AdministrationRoute from './administration'
import ClientRoute from './client-view'
import LoginRoute from './login'
import SignUpRoute from './sign-up'
import NotFound from './404'
import PasswordRequestRoute from './password-recovery/password-request'
import PasswordResetRoute from './password-recovery/password-reset'
import TimesheetAdministration from './timesheet-administration'
import BackOffice from './back-office'
import Timeline from './shared/tools/timeline'

export default {
  DashboardRoute,
  ToolsRoute,
  AdministrationRoute,
  ClientRoute,
  LoginRoute,
  SignUpRoute,
  NotFound,
  PasswordRequestRoute,
  PasswordResetRoute,
  TimesheetAdministration,
  BackOffice,
  Timeline,
}
