import cx from 'classnames'
import { Field, ErrorMessage } from 'formik'

import styles from '../../VirginProjectTemplateEditor.module.scss'

function TemplateDetailsForm() {
  return (
    <>
      <div className={cx('field', styles['field-container'])}>
        <label className="label">Template's name *</label>
        <div className="control">
          <Field className="input" type="text" name="name" />
          <ErrorMessage name="name" component="div" />
        </div>
      </div>
    </>
  )
}

export default TemplateDetailsForm
