import CardsBoard from './cards-board'
import CardsTable from './cards-table'

export default function CreateView(props) {
  /*   useEffect(() => {
    props.subscribeToMoreCards()
  }, []) */

  return (
    <div data-test="create-container">
      {props.cardsView ? <CardsBoard {...props} /> : <CardsTable {...props} />}
    </div>
  )
}
