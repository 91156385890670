import { useQuery } from '@apollo/react-hooks'
import SubscriptionView from './subscription-view'
import Loader from 'components/loader'
import hooks from 'hooks'

import { GET_COMPANY_SETTINGS, GET_USER_BY_ID } from 'graphql/queries'

function SettingsContainer() {
  const auth = hooks.useAuth()

  const { data: settingsResponse, loading: settingsLoading } = useQuery(
    GET_COMPANY_SETTINGS,
    { fetchPolicy: 'network-only' },
  )

  const { data: userResponse, loading: fetchingUserDetails } = useQuery(
    GET_USER_BY_ID,
    { variables: { id: auth.user.uid }, fetchPolicy: 'network-only' },
  )

  if (settingsLoading || fetchingUserDetails) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  return (
    <SubscriptionView
      subscription={settingsResponse.settings.subscription}
      user={userResponse.userById}
    />
  )
}

export default SettingsContainer
