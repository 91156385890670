import '@yoopknows/timeline/dist/index.css'
import ProjectsTimeline from './components/ProjectsTimeline'

import styles from './Timeline.module.scss'
import classnames from 'classnames'
import CardsTimeline from './components/CardsTimeline'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { CARDS_TIMELINE, PROJECTS_TIMELINE, TIMELINE } from 'constants/routes'
import { useSubscription } from 'hooks/use-subscription'

const modes = [
  {
    label: 'Projects',
    route: PROJECTS_TIMELINE,
  },
  {
    label: 'Cards',
    route: CARDS_TIMELINE,
  },
]

function Timeline({ selectedScale, maxProjects, fullPage }) {
  const history = useHistory()
  const { pathname } = useLocation()

  const { canUseCardsTimeline } = useSubscription()

  const baseUrl = fullPage ? '/tools' : ''

  return (
    <>
      <ul className={styles['data-selectors']}>
        {canUseCardsTimeline() &&
          modes.map(mode => (
            <li
              key={mode.label}
              className={classnames(
                pathname.includes(mode.route) && styles['selected-data'],
              )}
            >
              <button onClick={() => history.push(`${baseUrl}${mode.route}`)}>
                {mode.label}
              </button>
            </li>
          ))}
      </ul>
      <Switch>
        <Route path={`${baseUrl}${PROJECTS_TIMELINE}`}>
          <ProjectsTimeline
            selectedScale={selectedScale}
            maxProjects={maxProjects}
          />
        </Route>
        {canUseCardsTimeline() && (
          <Route path={`${baseUrl}${CARDS_TIMELINE}`}>
            <CardsTimeline
              selectedScale={selectedScale}
              maxProjects={maxProjects}
            />
          </Route>
        )}

        <Route path={`${baseUrl}${TIMELINE}`}>
          <Redirect to={`${baseUrl}${PROJECTS_TIMELINE}`} />
        </Route>
      </Switch>
    </>
  )
}

export default Timeline
