import UserSettings from './user-settings'
import Title from '../../../components/title'

import WeeklyUpdatesForm from './company-settings/weekly-updates-form'
import CategoriesForm from './company-settings/categories-form'

import styles from './settings.module.scss'
import Preferences from './company-settings/preferences'
import Integrations from './company-settings/integrations'

function SettingsView({
  settings,
  setWeeklyUpdates,
  projectTemplates,
  onConfigureWeeklyUpdates,
  onCreateCardCategory,
  onDeleteCardCategory,
  onUpdateCardCategory,
  updateUserSettings,
  updateCompanySettings,
  user,
}) {
  return (
    <div
      className={styles['settings-wrapper']}
      data-test="administration-settings-container"
    >
      <UserSettings
        user={user}
        projectTemplates={projectTemplates}
        updateUserSettings={updateUserSettings}
      />

      <div className={styles['settings-column']}>
        <div className={styles['settings-title-wrapper']}>
          <Title title="Company Settings" />
        </div>

        <div className={styles['settings-container']}>
          <Integrations />
        </div>

        <div className={styles['settings-container']}>
          {/* TODO: Think updateCompanySettings prop can be removed from here */}
          <Preferences updateCompanySettings={updateCompanySettings} />
        </div>

        <div className={styles['settings-container']}>
          <WeeklyUpdatesForm
            settings={settings.weeklyUpdate}
            setWeeklyUpdates={setWeeklyUpdates}
            onConfigureWeeklyUpdates={onConfigureWeeklyUpdates}
            user={user}
          />
        </div>

        <div className={styles['settings-container']}>
          <CategoriesForm
            companyCategories={settings.create?.customCategories || []}
            onSaveCategory={onCreateCardCategory}
            onDeleteCategory={onDeleteCardCategory}
            onUpdateCardCategory={onUpdateCardCategory}
          />
        </div>
      </div>
    </div>
  )
}

export default SettingsView
