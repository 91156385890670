export const ROUTE_ENTITY_ID = ':id'

export const HOME = '/'
export const NOT_FOUND = '/404'
export const CLIENT_VIEW = '/client-view/:id'
export const ADMINISTRATION = '/administration'
export const TOOLS = '/tools'
export const DASHBOARD = '/dashboard'
export const HOURS_ADMINISTRATION = '/hours-administration'
export const SIGN_UP = '/sign-up'
export const PASSWORD_RECOVERY = '/password-recovery'
export const AUTH = '/auth'

export const TIMELINE = '/timeline'
export const PROJECTS_TIMELINE = `${TIMELINE}/projects`
export const CARDS_TIMELINE = `${TIMELINE}/cards`

export const BACKOFFICE = '/back-office'
export const SUPPORT = `${BACKOFFICE}/support`
export const COMPANIES = `${BACKOFFICE}/companies`
export const COMPANY_NEW = `${COMPANIES}/new`
export const COMPANIES_DETAILS = `${COMPANIES}/:id`

export const VIRGIN_PROJECT_TEMPLATES = `${BACKOFFICE}/virgin-project-templates`
export const VIRGIN_PROJECT_TEMPLATE_NEW = `${VIRGIN_PROJECT_TEMPLATES}/new`
export const VIRGIN_PROJECT_TEMPLATE_DETAILS = `${VIRGIN_PROJECT_TEMPLATES}/:id`
export const VIRGIN_EMAIL_TEMPLATES = `${BACKOFFICE}/virgin-email-templates`
export const VIRGIN_EMAIL_TEMPLATE_NEW = `${VIRGIN_EMAIL_TEMPLATES}/new`
export const VIRGIN_EMAIL_TEMPLATE_DETAILS = `${VIRGIN_EMAIL_TEMPLATES}/:id`

export const PROJECTS = '/projects'
export const PROJECTS_ADMINISTRATION = `${ADMINISTRATION}${PROJECTS}`
export const PROJECT_DETAILS = `${PROJECTS}/:id`
export const PROJECT_HOURS = `${PROJECT_DETAILS}/hours`
export const PROJECT_PHASES = `${PROJECT_DETAILS}/phases`
export const PHASE_DETAILS = `${PROJECT_PHASES}/:phaseId`
export const PROJECT_EDIT = `${ADMINISTRATION}${PROJECTS}/:id`

export const createProjectDetailsUrl = projectId => `${PROJECTS}/${projectId}`

export const createPhasesUrl = projectId =>
  `${createProjectDetailsUrl(projectId)}/phases`

export const createPhaseDetailsUrl = ({ projectId, phaseId }) => {
  return `${createProjectDetailsUrl(projectId)}/phases/${phaseId}`
}

export const createProjectHoursUrl = projectId =>
  `${createProjectDetailsUrl(projectId)}/hours`

export const TEMPLATES = `${ADMINISTRATION}/templates`

export const PROJECT_TEMPLATES = `${TEMPLATES}/project-templates`
export const PROJECT_TEMPLATE_CREATE = `${PROJECT_TEMPLATES}/new`
export const PROJECT_TEMPLATE_EDIT = `${PROJECT_TEMPLATES}/edit/${ROUTE_ENTITY_ID}`
export const PROJECT_TEMPLATE_CLONE = `${PROJECT_TEMPLATES}/clone/${ROUTE_ENTITY_ID}`

export const createProjectTemplateEditUrl = templateId =>
  PROJECT_TEMPLATE_EDIT.replace(ROUTE_ENTITY_ID, templateId)
export const createProjectTemplateCloneUrl = templateId =>
  PROJECT_TEMPLATE_CLONE.replace(ROUTE_ENTITY_ID, templateId)

export const EMAIL_TEMPLATES = `${TEMPLATES}/email-templates`
export const EMAIL_TEMPLATE_CREATE = `${EMAIL_TEMPLATES}/new`
export const EMAIL_TEMPLATE_EDIT = `${EMAIL_TEMPLATES}/edit/${ROUTE_ENTITY_ID}`

export const createEmailTemplateEditUrl = templateId =>
  EMAIL_TEMPLATE_EDIT.replace(ROUTE_ENTITY_ID, templateId)

export const TEAM = `${ADMINISTRATION}/team`
export const TEAM_NEW_USER = `${TEAM}/new`
export const TEAM_EDIT_USER = `${TEAM}/edit/${ROUTE_ENTITY_ID}`

export const createTeamEditUserUrl = id =>
  TEAM_EDIT_USER.replace(ROUTE_ENTITY_ID, id)

export const CLIENTS_PARTNERS = `${ADMINISTRATION}/clients-and-partners`
export const CLIENTS_PARTNERS_NEW_USER = `${CLIENTS_PARTNERS}/new`
export const CLIENTS_PARTNERS_EDIT_USER = `${CLIENTS_PARTNERS}/edit/${ROUTE_ENTITY_ID}`

export const createClientPartnerEditUserUrl = id =>
  CLIENTS_PARTNERS_EDIT_USER.replace(ROUTE_ENTITY_ID, id)

export const CARDS = `/dashboard/create`
export const CARDS_EDIT = `${CARDS}/${ROUTE_ENTITY_ID}`
export const CARDS_NEW = `${CARDS}/new`

export const createCardDetailsUrl = cardId =>
  CARDS_EDIT.replace(ROUTE_ENTITY_ID, cardId)
