import styles from './PhaseDurationInput.module.scss'

function PhaseDurationInput({ value, setValue }) {
  function handleDurationInput(e) {
    const inputRegExp = /^[0-9]*$/

    const input = e.target.value

    if (inputRegExp.test(input)) {
      const inputNumber = Number(input)
      const inputInSeconds = inputNumber * 24 * 60 * 60

      setValue(inputInSeconds)
    }
  }

  const displayValue = value / 60 / 60 / 24

  const tooSmall = value <= 0

  return (
    <div className="field">
      <label className="label">Duration (days)</label>
      <div className="control">
        <input
          className="input"
          type="text"
          value={displayValue}
          onChange={handleDurationInput}
        />
      </div>
      {tooSmall && (
        <p className={`${styles['error']}`}>
          Duration needs to be at least 1 day
        </p>
      )}
    </div>
  )
}

export default PhaseDurationInput
