import { useState } from 'react';
import Select, { components } from 'react-select'
import { useQuery } from '@apollo/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
} from 'recharts'

import Loader from 'components/loader'
import Title from 'components/title'
import styles from './metrics.module.scss'

import { GET_METRICS } from 'graphql/queries'

const metricsToChart = [
  {
    name: 'New projects',
    metricKey: 'newProjects',
    stroke: '#8884d8',
  },
  {
    name: 'Completed projects',
    metricKey: 'completedProjects',
    stroke: '#82ca9d',
  },
  {
    name: 'Value of projects',
    metricKey: 'valueOfProjects',
    stroke: '#ffc658',
    formatValue: value => {
      return value.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },
  },
  {
    name: 'Created cards',
    metricKey: 'createdCards',
    stroke: '#ff0000',
  },
  {
    name: 'Completed cards',
    metricKey: 'completedCards',
    stroke: '#00ff00',
  },
]

const filterPeriodValues = [
  { value: 'allTime', label: 'All Time' },
  { value: 'last12Months', label: 'Last 12 months' },
  { value: 'yearToDate', label: 'Year to Date' },
  { value: 'monthToDate', label: 'Month to Date' },
]

const filterPeriodAllTime = filterPeriodValues[1]

function MetricsView() {
  const [filterPeriod, setFilterPeriod] = useState(filterPeriodAllTime)

  const { data, loading } = useQuery(GET_METRICS, {
    variables: {
      period: filterPeriod.value,
    },
    fetchPolicy: 'network-only',
  })

  const ColorValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && (
            <FontAwesomeIcon
              icon={faCalendar}
              style={{ position: 'absolute', left: 16 }}
            />
          )}
          {children}
        </components.ValueContainer>
      )
    )
  }

  const XAxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    )
  }

  const YAxisTick = ({ x, y, stroke, payload, formatValue }) => {
    const value =
      typeof formatValue === 'function'
        ? formatValue(payload.value)
        : payload.value

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={6} textAnchor="end" fill="#666">
          {value}
        </text>
      </g>
    )
  }

  const formatTooltipValue = formatValue => {
    if (typeof formatValue !== 'function') {
      return (value, name) => value
    }

    return (value, name) => formatValue(value)
  }

  return (
    <div
      className={styles['root']}
      data-test="administration-metrics-container"
    >
      <div className={styles['metrics-title-wrapper']}>
        {/* TODO: Remove this hacky block of markup/inline CSS when Metrics is out of beta */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Title title="Metrics" fitTitle={true} />
          <div
            style={{ marginLeft: 16 }}
            className="field is-grouped is-grouped-multiline"
          >
            <div className="control">
              <div className="tags has-addons">
                <span
                  className="tag is-primary is-dark is-medium"
                  style={{ fontWeight: 'bold' }}
                >
                  Beta
                </span>
                <span className="tag is-primary is-light is-medium">
                  What do you think? Which metrics would help you?{' '}
                  <a
                    href="mailto:believe@yoopknows.io"
                    style={{
                      textDecoration: 'underline',
                      paddingLeft: 4,
                    }}
                  >
                    Let us know!
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles['metrics-filter-wrapper']}>
          <label>View metrics for period</label>
          <Select
            closeMenuOnSelect
            components={{ ValueContainer: ColorValueContainer }}
            className="react-select-container-progress"
            classNamePrefix="react-select-progress"
            styles={{ valueContainer: base => ({ ...base, paddingLeft: 32 }) }}
            options={filterPeriodValues}
            name="progressFilter"
            onChange={value => setFilterPeriod(value)}
            defaultValue={filterPeriod}
            isMulti={false}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: 'rgba(101, 138, 255, 0.35)',
                primary: '#658aff',
              },
            })}
            placeholder="Filter by"
            isSearchable={false}
          />
        </div>
      </div>

      {loading ? (
        <div className={styles['loader']}>
          <Loader />
        </div>
      ) : (
        <div className={styles['metrics-wrapper']}>
          {data?.metrics &&
            metricsToChart
              .filter(metric => data.metrics[metric.metricKey])
              .map(metric => (
                <div key={metric.metricKey}>
                  <h4>
                    {metric.name} ({filterPeriod.label})
                  </h4>
                  <div className={styles['chart-wrapper']}>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart
                        data={data.metrics[metric.metricKey]}
                        margin={{ top: 5, right: 20, bottom: 50, left: 50 }}
                      >
                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                        <Line
                          type="monotone"
                          dataKey="value"
                          name={metric.name}
                          connectNulls={true}
                          stroke={metric.stroke}
                          strokeWidth={4}
                          dot={{ r: 4 }}
                        />
                        <XAxis
                          dataKey="period"
                          type="category"
                          tick={<XAxisTick />}
                        />
                        <YAxis
                          tick={<YAxisTick formatValue={metric.formatValue} />}
                        />
                        <Tooltip
                          formatter={formatTooltipValue(metric.formatValue)}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              ))}
        </div>
      )}
    </div>
  )
}

export default MetricsView
