import { useState, useEffect } from 'react';

import PropTypes from 'prop-types'
import cx from 'classnames'
import { useLocation, useRouteMatch, Route, Switch } from 'react-router-dom'

import { useSubscription } from 'hooks/use-subscription'

import Overview from './overview'
import OverviewToolbar from './overview/overview-toolbar'

import Create from './create'
import CreateToolbar from './create/create-toolbar'

import Timesheet from './timesheet'
import TimesheetToolbar from './timesheet/timesheet-toolbar'

import styles from './tools.module.scss'
import Timeline from 'routes/shared/tools/timeline'
import TimelineToolbar from 'components/TimelineToolbar'
import { TabsContainer, TabLink } from 'components/Tabs'
import hooks from 'hooks'
import { TIMELINE } from 'constants/routes'

export default function ToolsView({ user, fullPage }) {
  const { canUseTimeline, canUseTimesheet } = useSubscription()

  const { url } = useRouteMatch()
  const { pathname } = useLocation()
  const subscription = hooks.useSubscription()

  const baseUrl = url?.length > 1 ? url : ''

  const showTimeline = canUseTimeline()

  const [selectedScale, setSelectedScale] = useState('Month')

  const [maxProjects, setMaxProjects] = useState(10)

  const [toolsToolbarSettings, setOverviewToolbarSettings] = useState({
    isCondensed: true,
    defaultTemplateId: '',
  })

  const [createToolbarSettings, setCreateToolbarSettings] = useState({
    cardsView: true,
    addCardModalOpen: false,
  })

  const [timesheetToolbarSettings, setTimesheetToolbarSettings] = useState({
    addHoursModalOpen: false,
  })

  useEffect(() => {
    setOverviewToolbarSettings({
      ...toolsToolbarSettings,
      defaultTemplateId: user.defaultTemplateId,
    })
  }, [user])

  return (
    <div className={cx(styles['root'], fullPage && styles['full-page'])}>
      <div className={styles['tabs-container']}>
        <div className={styles['header']}>
          <div className="level-left" data-test="tools-tabs">
            <TabsContainer className={styles['tabs-items']}>
              <TabLink
                dataTest="create-tab"
                to={`${baseUrl}/create`}
                externalIsActive={['/', '/tools'].includes(pathname)}
              >
                Create
              </TabLink>
              <TabLink
                externalIsActive={
                  pathname === `${baseUrl}/kanban` || pathname === `/dashboard`
                }
                dataTest="kanban-tab"
                to={`${baseUrl}/kanban`}
              >
                Kanban
              </TabLink>
              {subscription.canUseTimesheet() && (
                <TabLink dataTest="timesheet-tab" to={`${baseUrl}/timesheet`}>
                  Hours
                </TabLink>
              )}
              {showTimeline && (
                <TabLink dataTest="timeline-tab" to={`${baseUrl}${TIMELINE}`}>
                  Timeline
                </TabLink>
              )}
            </TabsContainer>
          </div>

          {/* Toolbar */}
          <div className={`level-right ${styles['toolbar-container']}`}>
            <Switch>
              <Route path={`${baseUrl}/create`}>
                <CreateToolbar
                  settings={createToolbarSettings}
                  setSetting={(name, value) =>
                    setCreateToolbarSettings({
                      ...createToolbarSettings,
                      [name]: value,
                    })
                  }
                />
              </Route>
              <Route path={`${baseUrl}/kanban`}>
                <OverviewToolbar
                  settings={toolsToolbarSettings}
                  setSetting={(name, value) =>
                    setOverviewToolbarSettings({
                      ...toolsToolbarSettings,
                      [name]: value,
                    })
                  }
                />
              </Route>
              <Route path={`${baseUrl}/timesheet`}>
                <TimesheetToolbar
                  settings={timesheetToolbarSettings}
                  setSetting={(name, value) =>
                    setTimesheetToolbarSettings({
                      ...timesheetToolbarSettings,
                      [name]: value,
                    })
                  }
                ></TimesheetToolbar>
              </Route>
              {showTimeline && (
                <Route path={`${baseUrl}/timeline`}>
                  <TimelineToolbar
                    selectedScale={selectedScale}
                    setSelectedScale={setSelectedScale}
                    maxProjects={maxProjects}
                    setMaxProjects={setMaxProjects}
                  />
                </Route>
              )}
              {/* Default Route */}
              <Route path={`${baseUrl}`}>
                <CreateToolbar
                  settings={createToolbarSettings}
                  setSetting={(name, value) =>
                    setCreateToolbarSettings({
                      ...createToolbarSettings,
                      [name]: value,
                    })
                  }
                />
              </Route>
            </Switch>
          </div>
        </div>
        <Switch>
          <Route path={`${baseUrl}/create`}>
            <Create {...createToolbarSettings} />
          </Route>
          <Route path={`${baseUrl}/kanban`}>
            <Overview user={user} {...toolsToolbarSettings} />
          </Route>
          {canUseTimesheet() && (
            <Route path={`${baseUrl}/timesheet`}>
              <Timesheet
                {...timesheetToolbarSettings}
                closeAddHoursModal={() =>
                  setTimesheetToolbarSettings({
                    ...timesheetToolbarSettings,
                    addHoursModalOpen: false,
                  })
                }
                openAddHouseModal={() =>
                  setTimesheetToolbarSettings({
                    ...timesheetToolbarSettings,
                    addHoursModalOpen: true,
                  })
                }
              />
            </Route>
          )}
          {showTimeline && (
            <Route path={`${baseUrl}/timeline`}>
              <Timeline
                fullPage={fullPage}
                selectedScale={selectedScale}
                maxProjects={maxProjects}
              />
            </Route>
          )}
          {/* Default Route */}
          <Route path={`${baseUrl}`}>
            <Create {...createToolbarSettings} />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

ToolsView.propTypes = {
  user: PropTypes.object,
  fullPage: PropTypes.bool,
}
