import { ThreeDots } from 'react-loader-spinner'

import styles from './loader.module.scss'

const Loader = () => {
  return (
    <div className={styles.loader_wrapper}>
      <ThreeDots color="#fecb37" height={80} width={80} ariaLabel="loading" />
    </div>
  )
}

export default Loader
