import styles from './unstyled-button.module.scss'

function UnstyledButton({ onClick, children }) {
  return (
    <button onClick={onClick} className={styles.button}>
      {children}
    </button>
  )
}

export default UnstyledButton
