import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPen, faDownload } from '@fortawesome/free-solid-svg-icons'
import { CSVLink } from 'react-csv'

import { useSubscription } from 'hooks/use-subscription'

import Table from 'components/table'
import Title from 'components/title'

import DeleteUserModal from './delete-user'

import {
  clientsAndPartnersFilterOptions,
  teamMembersRolesOptions,
} from 'constants/constants'

import styles from './users.module.scss'

function UsersView({
  title,
  users,
  onToggleCreateUserModal,
  deleteUser,
  openEditModal,
  isTeamPage = false,
  dataTest,
  role,
  generateUsersCsv,
}) {
  const [selectedUser, setSelectedUser] = useState('')
  const [currentPageNumber, setCurrentPageNumber] = useState(0)
  const subscription = useSubscription()

  const columns = [
    {
      Header: 'First name',
      accessor: 'firstName',
      sortType: 'basic',
    },
    {
      Header: 'Last name',
      accessor: 'lastName',
      sortType: 'basic',
    },
    {
      Header: 'Email',
      accessor: 'email',
      sortType: 'basic',
    },
    {
      Header: 'Company',
      accessor: 'organization.name',
      sortType: 'basic',
    },
    {
      Header: 'Phone',
      accessor: 'phoneNumber',
      sortType: 'basic',
    },
    {
      Header: 'Address',
      accessor: 'address',
      sortType: 'basic',
    },
    {
      Header: 'Role',
      accessor: 'role',
      sortType: 'basic',
    },
    {
      Header: 'Projects',
      accessor: 'projects',
      sortType: 'basic',
    },
    {
      Header: 'Interaction count',
      accessor: 'interaction',
      sortType: 'basic',
    },
    {
      Header: ' ',
      Cell: props => {
        return (
          <div className="columns is-vcentered">
            <div className="column is-2">
              <button
                className="button is-small is-light"
                onClick={() => openEditModal(props.row.original)}
              >
                <span className="icon">
                  <FontAwesomeIcon icon={faPen} size="lg" />
                </span>
              </button>
            </div>

            <div className="column is-2">
              <button
                className="button is-small is-light"
                onClick={() => onOpenDeleteUserModal(props.row.original)}
              >
                <span className="icon is-medium">
                  <FontAwesomeIcon icon={faTrash} size="lg" />
                </span>
              </button>
            </div>
          </div>
        )
      },
    },
  ]

  function onOpenDeleteUserModal(user) {
    setSelectedUser(user)
  }

  function onCloseDeleteUserModal() {
    setSelectedUser(null)
  }

  return (
    <div className={styles['users-wrapper']} data-test={dataTest}>
      <div className={styles['users-title-wrapper']}>
        <div className={styles['users-title-header']}>
          <Title title={title} />
          {role === 'clients_and_partners' && (
            <>
              <CSVLink data={generateUsersCsv('client')} filename="clients.csv">
                <button className="button is-info">
                  <FontAwesomeIcon icon={faDownload} />
                  <span className={styles['download-text']}>
                    Download Clients CSV
                  </span>
                </button>
              </CSVLink>
              <CSVLink
                data={generateUsersCsv('consultant')}
                filename="consultants.csv"
              >
                <button className="button is-info">
                  <FontAwesomeIcon icon={faDownload} />
                  <span className={styles['download-text']}>
                    Download Consultants CSV
                  </span>
                </button>
              </CSVLink>
            </>
          )}
        </div>

        {isTeamPage ? (
          <p>
            <strong>NOTE</strong>: You can still create{' '}
            <strong>
              {subscription.getNumberOfRemainingTeamMembers()} users
            </strong>{' '}
            with an account
          </p>
        ) : (
          <></>
        )}
      </div>

      <Table
        data={users}
        columns={columns}
        onToggleCreateModal={onToggleCreateUserModal}
        buttonText={isTeamPage ? 'New Staff' : 'New Contact'}
        buttonDisabled={isTeamPage && !subscription.canAddNewTeamMembers()}
        pageSize={7}
        filterOptions={
          isTeamPage ? teamMembersRolesOptions : clientsAndPartnersFilterOptions
        }
        currentPageNumber={currentPageNumber}
        onChangePage={pageNumber => setCurrentPageNumber(pageNumber)}
      />
      {selectedUser && (
        <DeleteUserModal
          onClose={onCloseDeleteUserModal}
          deleteUser={deleteUser}
          selectedUser={selectedUser}
        />
      )}
    </div>
  )
}

export default UsersView
