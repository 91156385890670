import { Fragment, useState } from 'react';
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Droppable } from 'react-beautiful-dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faHourglass,
  faBriefcase,
  faInbox,
  faBars,
} from '@fortawesome/free-solid-svg-icons'

import styles from '../create.module.scss'
import Card from './card'
import isAfter from 'date-fns/isAfter'
import { convertSecondsToTimeComponents } from 'constants/utils'

const icons = {
  Incoming: faInbox,
  Waiting: faHourglass,
  Doing: faBriefcase,
  Done: faCheckCircle,
  Overflow: faBars,
}

function makeDroppableId(columnLabel, assigneeId) {
  const id = assigneeId ?? 'company'
  const droppableId = `${columnLabel}-${id}`

  return droppableId
}

function Column({
  label,
  description = '',
  cards,
  loggedUserId,
  className,
  colors,
  allowStickyCards,
}) {
  return (
    <div className={cx(className, styles['cards-column'])}>
      <div className={styles['column-header']}>
        <div className={styles['column-title-wrapper']}>
          <FontAwesomeIcon icon={icons[label]} />
          <h4 className={styles['column-title']}>{label}</h4>
          {description && <span>{description}</span>}
        </div>
        {['Waiting', 'Doing'].includes(label) && (
          <div className={cx(styles['column-total-duration-wrapper'])}>
            {calculateTotalCardsHours(cards)}
          </div>
        )}
      </div>

      <Droppable
        isDropDisabled={!allowStickyCards}
        droppableId={makeDroppableId(label, loggedUserId)}
      >
        {(provided, snapshot) => {
          const isDraggingOver = snapshot.isDraggingOver

          return (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={cx(
                styles['card-list'],
                isDraggingOver ? styles['dragging-over'] : '',
              )}
            >
              {cards?.length < 1 && (
                <div className={styles['column-content-placeholder']}>
                  <h3>Start by adding a card</h3>
                </div>
              )}
              {cards?.map((card, index) => {
                return (
                  <Fragment key={card.id}>
                    {card && (
                      <Card
                        index={index}
                        card={card}
                        status={label}
                        colors={colors}
                        allowStickyCards={allowStickyCards}
                      />
                    )}
                  </Fragment>
                )
              })}
              {provided.placeholder}
            </div>
          )
        }}
      </Droppable>
    </div>
  )
}

function calculateTotalCardsHours(cards) {
  const totalDuration = cards.reduce((acc, card) => {
    if (!card.duration) return acc
    return acc + card.duration
  }, 0)

  const timeComponents = convertSecondsToTimeComponents(totalDuration)

  let durationString = ''

  if (timeComponents.days > 0) {
    durationString += `${timeComponents.days}d `
  }

  if (timeComponents.hours > 0) {
    durationString += `${timeComponents.hours}h `
  }

  return durationString
}

export default Column

Column.propTypes = {
  label: PropTypes.string.isRequired,
  cards: PropTypes.array,
}
