import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { Formik, Form, Field, ErrorMessage } from 'formik'
import cx from 'classnames'
import styles from '../password-recovery.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'

const SuccessMessage = () => {
  return (
    <div className={cx('container', styles['success-container'])}>
      <h1>All done!</h1>
      <h2>{'Enjoy your new password! :)'}</h2>
      <Link to="/sign-in" className={cx('button', 'is-info', 'link-button')}>
        Go to Login
      </Link>
    </div>
  )
}

const ResetErrorMessage = () => {
  return (
    <div className={cx('container', styles['success-container'])}>
      <h1>Ups... something went wrong!</h1>
      <Link
        to="/password-recovery"
        className={cx('button', 'is-info', 'link-button')}
      >
        Try again
      </Link>
    </div>
  )
}

const PasswordResetView = ({ loading, onSubmit, success, error }) => {
  const location = useLocation()
  const searchQuery = location.search
  const params = new URLSearchParams(searchQuery)
  const oobCode = params.get('oobCode')

  const initialValues = {
    password: '',
    confirmPassword: '',
  }

  if (success) return <SuccessMessage />

  if (error) return <ResetErrorMessage />

  return (
    <div className={styles['password-recovery-page']}>
      <div className={styles['password-recovery-wrapper']}>
        <div className={styles['password-recovery-header']}>
          <span className="icon is-medium is-left">
            <FontAwesomeIcon size="lg" icon={faSignInAlt} />
          </span>
          <span>Reset Your Password</span>
        </div>

        <div className={styles['password-recovery-form-wrapper']}>
          <Formik
            enableReinitialize
            validateOnBlur={false}
            initialValues={initialValues}
            validate={values => {
              const errors = {}

              if (!values.password) {
                errors.password = 'Required'
              } else if (values.password.length < 6) {
                errors.password = 'Password must contain minimum 6 characters'
              } else if (
                !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(
                  values.password,
                )
              ) {
                errors.password =
                  'Use at least a number and a special character'
              }

              if (!values.confirmPassword) {
                errors.confirmPassword = 'Required'
              } else if (values.password !== values.confirmPassword) {
                errors.confirmPassword = "The two passwords don't match"
              }

              return errors
            }}
            onSubmit={({ password }) => onSubmit(password, oobCode)}
          >
            {() => (
              <Form>
                <div className={styles['field-group']}>
                  <div className={cx('field', styles['field-container'])}>
                    <label className="label">Password *</label>
                    <div className="control">
                      <Field
                        className="input"
                        type="password"
                        name="password"
                      />
                      <ErrorMessage
                        className="error"
                        name="password"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className={cx('field', styles['field-container'])}>
                    <label className="label">Repeat Password *</label>
                    <div className="control">
                      <Field
                        className="input"
                        type="password"
                        name="confirmPassword"
                      />
                      <ErrorMessage
                        className="error"
                        name="confirmPassword"
                        component="div"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={cx(
                    styles['footer-wrapper'],
                    styles['align-right'],
                  )}
                >
                  <button
                    className={cx(
                      'button',
                      'is-info',
                      styles['action-button'],
                      loading ? 'is-loading' : '',
                    )}
                    type="submit"
                  >
                    Reset Password
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default PasswordResetView
