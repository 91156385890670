import { useState } from 'react';
import { Container, Draggable } from 'react-smooth-dnd'

import SmartNotificationsModal from './smart-notifications-modal'
import DeleteModal from '../delete-modal'

import styles from './tasks.module.scss'
import Task from './task'

function TasksView({
  tasks,
  onCompleteTask,
  onUndoTask,
  onAddTask,
  onUpdateTask,
  onDeleteTask,
  onMoveTask,
  onConfigureNotifications,
  readOnly = false,
}) {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState([])
  const [isOpenSmartNotificationModal, setIsOpenSmartNotificationModal] =
    useState([])

  if (!tasks) return <></>

  function onReorderTasks({ removedIndex: from, addedIndex: to }) {
    if (from === to) return
    onMoveTask(tasks[from], to)
  }

  function openSmartNotificationModal(taskId) {
    setIsOpenSmartNotificationModal(taskIds => {
      return [...taskIds, taskId]
    })
  }

  function closeSmartNotificationModal(taskId) {
    setIsOpenSmartNotificationModal(taskIds => {
      return taskIds.filter(id => id !== taskId)
    })
  }

  function openDeleteModal(taskId) {
    setIsOpenDeleteModal(taskIds => {
      return [...taskIds, taskId]
    })
  }

  function closeDeleteModal(taskId) {
    setIsOpenDeleteModal(taskIds => {
      return taskIds.filter(id => id !== taskId)
    })
  }

  return (
    <div className={styles.root}>
      {readOnly ? (
        <></>
      ) : (
        <button
          type="button"
          className={`${styles.button} button is-info`}
          onClick={onAddTask}
        >
          Add task
        </button>
      )}

      <Container onDrop={onReorderTasks} shouldAcceptDrop={() => true}>
        {tasks.map(task => {
          return (
            <Draggable key={task.id} style={{ overflow: 'visible' }}>
              <SmartNotificationsModal
                open={isOpenSmartNotificationModal.includes(task.id)}
                onClose={() => closeSmartNotificationModal(task.id)}
                onConfigureNotifications={notifications =>
                  onConfigureNotifications(task.id, notifications)
                }
                currentConfiguration={task.notification}
                taskName={task.title}
              />
              <DeleteModal
                open={isOpenDeleteModal.includes(task.id)}
                onClose={() => closeDeleteModal(task.id)}
                deleteAction={() => onDeleteTask(task)}
                title={task.title}
                elemType="task"
              />
              <Task
                task={task}
                canBeDeleted={tasks.length > 1}
                onCompleteTask={onCompleteTask}
                onUndoTask={onUndoTask}
                readOnly={readOnly}
                onUpdateTask={onUpdateTask}
                openSmartNotificationForm={() =>
                  openSmartNotificationModal(task.id)
                }
                openDeleteModal={() => openDeleteModal(task.id)}
              />
            </Draggable>
          )
        })}
      </Container>
    </div>
  )
}

export default TasksView
