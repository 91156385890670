import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useState } from 'react'

import Table from 'components/table'
import Title from 'components/title'

import styles from './support.module.scss'

function AllUsersView({ users, impersonateUser }) {
  const [currentPageNumber, setCurrentPageNumber] = useState(0)

  const columns = [
    {
      Header: 'First name',
      accessor: 'firstName',
      sortType: 'basic',
    },
    {
      Header: 'Last name',
      accessor: 'lastName',
      sortType: 'basic',
    },
    {
      Header: 'Email',
      accessor: 'email',
      sortType: 'basic',
    },
    {
      Header: 'Company name',
      accessor: 'company.name',
      sortType: 'basic',
    },
    {
      Header: ' ',
      Cell: props => {
        return (
          <button
            disabled
            className={cx('button is-light is-small')}
            onClick={() => impersonateUser(props.row.original)}
          >
            Impersonate
            <FontAwesomeIcon
              style={{ marginLeft: '10px', color: '#555c72' }}
              icon={faUserSecret}
              size="lg"
            />
          </button>
        )
      },
    },
  ]

  const options = users.map(user => ({
    value: user.company.name,
    label: user.company.name,
  }))

  // eslint-disable-next-line no-undef
  const unq_options = [...new Map(options.map(o => [o['label'], o])).values()]

  return (
    <div
      className={styles['projects-wrapper']}
      data-test="bo-support-container"
    >
      <div className={styles['projects-title-wrapper']}>
        <Title title="Support" />
      </div>

      <Table
        data={users}
        columns={columns}
        pageSize={7}
        filterOptions={unq_options}
        currentPageNumber={currentPageNumber}
        onChangePage={pageNumber => setCurrentPageNumber(pageNumber)}
      />
    </div>
  )
}

AllUsersView.propTypes = {
  impersonateUser: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
}

export default AllUsersView
