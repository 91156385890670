import { Switch, Route, useHistory, useLocation } from 'react-router-dom'

import TemplateColumn from './template-column'
import CreateEmailTemplateForm from './create-email-template-form'
import CreateProjectTemplateForm from './create-project-template-form'

import styles from './templates.module.scss'
import {
  createEmailTemplateEditUrl,
  createProjectTemplateEditUrl,
  createProjectTemplateCloneUrl,
  EMAIL_TEMPLATE_CREATE,
  EMAIL_TEMPLATE_EDIT,
  PROJECT_TEMPLATE_CLONE,
  PROJECT_TEMPLATE_CREATE,
  PROJECT_TEMPLATE_EDIT,
  TEMPLATES,
} from 'constants/routes'

function TemplatesView({ projectTemplates, emailTemplates }) {
  const history = useHistory()
  const { pathname } = useLocation()

  function openProjectTemplateEditModal(templateId) {
    history.push(createProjectTemplateEditUrl(templateId))
  }

  function openEmailTemplateEditModal(templateId) {
    history.push(createEmailTemplateEditUrl(templateId))
  }

  function closeTemplateModal() {
    history.push(TEMPLATES)
  }

  return (
    <div className={styles['templates-wrapper']}>
      <div className={styles['templates-wrapper-column']}>
        <TemplateColumn
          type="project"
          templates={projectTemplates}
          toggleCreateTemplateModal={() =>
            history.push(
              pathname === TEMPLATES ? PROJECT_TEMPLATE_CREATE : TEMPLATES,
            )
          }
          openEditModal={openProjectTemplateEditModal}
          openCloneModal={templateId =>
            history.push(createProjectTemplateCloneUrl(templateId))
          }
        />
      </div>
      <div className={styles['templates-wrapper-column']}>
        <TemplateColumn
          type="email"
          templates={emailTemplates}
          toggleCreateTemplateModal={() =>
            history.push(
              pathname === TEMPLATES ? EMAIL_TEMPLATE_CREATE : TEMPLATES,
            )
          }
          openEditModal={openEmailTemplateEditModal}
        />
      </div>

      <Switch>
        <Route path={PROJECT_TEMPLATE_CREATE}>
          <CreateProjectTemplateForm onClose={() => closeTemplateModal()} />
        </Route>
        <Route path={PROJECT_TEMPLATE_EDIT}>
          <CreateProjectTemplateForm onClose={() => closeTemplateModal()} />
        </Route>
        <Route path={PROJECT_TEMPLATE_CLONE}>
          <CreateProjectTemplateForm
            projectTemplates={projectTemplates}
            cloning
            onClose={() => closeTemplateModal()}
          />
        </Route>
        <Route path={EMAIL_TEMPLATE_CREATE}>
          <CreateEmailTemplateForm onClose={() => closeTemplateModal()} />
        </Route>
        <Route path={EMAIL_TEMPLATE_EDIT}>
          <CreateEmailTemplateForm onClose={() => closeTemplateModal()} />
        </Route>
      </Switch>
    </div>
  )
}

export default TemplatesView
