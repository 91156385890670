import { useState } from 'react';
import { useHistory } from 'react-router-dom'

import cx from 'classnames'
import { useMutation } from '@apollo/react-hooks'
import CopyToClipboard from 'react-copy-to-clipboard'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCopy,
  faStar,
  faShareAlt,
  faTimes,
  faPencilAlt,
  faBell,
  faBook,
} from '@fortawesome/free-solid-svg-icons'

import {
  SAVE_NOTIFICATION_CONFIGURATION,
  SAVE_UPDATES_CONFIGURATION,
  CREATE_SHAREABLE_LINK,
  ADD_PROJECT_TO_FAVOURITES,
  REMOVE_PROJECT_FROM_FAVOURITES,
} from 'graphql/mutations'
import {
  GET_PROJECT_DETAILS,
  GET_USER_BY_ID,
  GET_MY_FAVOURITE_PROJECTS,
} from 'graphql/queries'
import { PROJECT_EDIT, ROUTE_ENTITY_ID } from 'constants/routes'

import Toggle from './toggle'
import IconButton from 'components/icon-button'
import Title from 'components/title'

import { showNotification } from 'constants/utils'

import styles from './project-modal.module.scss'

function ProjectDescriptionHeader({ selectedProject, user, onClose }) {
  const history = useHistory()
  const projectId = selectedProject.id
  const [generatedLink, setGeneratedLink] = useState('')

  const [saveNotificationConfigurationMutation] = useMutation(
    SAVE_NOTIFICATION_CONFIGURATION,
  )
  const [saveUpdatesConfigurationMutation] = useMutation(
    SAVE_UPDATES_CONFIGURATION,
  )
  const [createShareableLinkMutation] = useMutation(CREATE_SHAREABLE_LINK)
  const [addProjectToFavouritesMutation] = useMutation(
    ADD_PROJECT_TO_FAVOURITES,
  )
  const [removeProjectFromFavouritesMutation] = useMutation(
    REMOVE_PROJECT_FROM_FAVOURITES,
  )

  function saveNotificationConfiguration(updatedNotifications) {
    return saveNotificationConfigurationMutation({
      variables: { projectId, value: updatedNotifications },
      update: store => {
        const { projectById } = store.readQuery({
          query: GET_PROJECT_DETAILS,
          variables: {
            id: projectId,
          },
        })

        store.writeQuery({
          query: GET_PROJECT_DETAILS,
          variables: {
            id: projectId,
          },
          data: {
            projectById: {
              ...projectById,
              notifications: updatedNotifications,
            },
          },
        })
      },

      optimisticResponse: {
        __typename: 'Mutation',
        saveNotificationConfiguration: {
          id: projectId,
          notifications: updatedNotifications,
          __typename: 'SaveNotificationConfigurationResponse',
        },
      },
    }).catch(() =>
      showNotification(
        "It's not possible to save the settings at the moment. Please try again in a few moments.",
        'is-danger',
      ),
    )
  }

  function saveUpdatesConfiguration() {
    const newUpdatesValue = !selectedProject.update
    return saveUpdatesConfigurationMutation({
      variables: { projectId, value: newUpdatesValue },
      update: store => {
        const { projectById } = store.readQuery({
          query: GET_PROJECT_DETAILS,
          variables: {
            id: projectId,
          },
        })

        store.writeQuery({
          query: GET_PROJECT_DETAILS,
          variables: {
            id: projectId,
          },
          data: {
            projectById: {
              ...projectById,
              update: newUpdatesValue,
            },
          },
        })
      },

      optimisticResponse: {
        __typename: 'Mutation',
        saveUpdatesConfiguration: {
          id: projectId,
          update: newUpdatesValue,
          __typename: 'SaveUpdatesConfigurationResponse',
        },
      },
    }).catch(() =>
      showNotification(
        "It's not possible to save the settings at the moment. Please try again in a few moments.",
        'is-danger',
      ),
    )
  }

  function createShareableLink() {
    return createShareableLinkMutation({ variables: { id: projectId } })
      .then(result => {
        setGeneratedLink(result.data.createProjectShareableLinkById.link)
      })
      .then(() =>
        showNotification('Share link created successfully!', 'is-success'),
      )
      .catch(() =>
        showNotification('Share link could not be generated!', 'is-danger'),
      )
  }

  function addProjectToFavourites() {
    return addProjectToFavouritesMutation({
      variables: { projectId },
      // refetchQueries: [{ query: GET_USER_BY_ID, variables: { id: user.id } }],
      update: store => {
        // Update myFavouriteProjects cache
        const { myFavouriteProjects = [] } = store.readQuery({
          query: GET_MY_FAVOURITE_PROJECTS,
        })

        store.writeQuery({
          query: GET_MY_FAVOURITE_PROJECTS,
          data: {
            myFavouriteProjects: [
              ...myFavouriteProjects,
              {
                id: projectId,
                __typename: 'Project',
              },
            ],
          },
        })

        // Update userById cache
        const { userById } = store.readQuery({
          query: GET_USER_BY_ID,
          variables: { id: user.id },
        })

        store.writeQuery({
          query: GET_USER_BY_ID,
          data: {
            userById: {
              ...userById,
              favoriteProjectsIds: [
                ...(userById.favoriteProjectsIds ?? []),
                projectId,
              ],
            },
          },
        })
      },
    }).catch(() => {
      showNotification(
        'Project could not be added to favorite projects',
        'is-danger',
      )
    })
  }

  function removeProjectFromFavourites() {
    return removeProjectFromFavouritesMutation({
      variables: { projectId },
      refetchQueries: [{ query: GET_USER_BY_ID, variables: { id: user.id } }],
      update: store => {
        // Update myFavouriteProjects cache
        const { myFavouriteProjects = [] } = store.readQuery({
          query: GET_MY_FAVOURITE_PROJECTS,
          variables: { id: user.id },
        })

        store.writeQuery({
          query: GET_MY_FAVOURITE_PROJECTS,
          data: {
            myFavouriteProjects: myFavouriteProjects.filter(
              project => project.id !== projectId,
            ),
          },
        })

        // Update userById cache
        const { userById } = store.readQuery({
          query: GET_USER_BY_ID,
          variables: { id: user.id },
        })

        store.writeQuery({
          query: GET_USER_BY_ID,
          data: {
            userById: {
              ...userById,
              favoriteProjectsIds: userById.favoriteProjectsIds.filter(
                favProjId => favProjId !== projectId,
              ),
            },
          },
        })
      },
    }).catch(() =>
      showNotification(
        'Project could not be removed from favorite projects',
        'is-danger',
      ),
    )
  }

  const isProjectInFavorite = user.favoriteProjectsIds
    ? user.favoriteProjectsIds.includes(selectedProject.id)
    : false

  return (
    <div className={cx(styles['header-wrapper'])}>
      <>
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <Title title={selectedProject.name} dataTest="project-title" />
            </div>
          </div>
          <div className="level-right">
            <div className={cx(styles['header-buttons'], 'level-item')}>
              {isProjectInFavorite ? (
                <IconButton
                  dataTooltip="Remove project from favorites"
                  icon={faStar}
                  color="#ffa34a"
                  action={removeProjectFromFavourites}
                  className={cx(
                    styles['toolbar-item'],
                    styles['toolbar-button'],
                    'with-shadow button is-medium is-light',
                  )}
                />
              ) : (
                <IconButton
                  dataTooltip="Add project to favorites"
                  icon={faStar}
                  action={addProjectToFavourites}
                  className={cx(
                    styles['toolbar-item'],
                    styles['toolbar-button'],
                    'with-shadow button is-medium is-light',
                  )}
                />
              )}
              <IconButton
                icon={faBook}
                action={() => {
                  const fullUrl =
                    selectedProject?.meta?.projectDocsUrl.startsWith('http')
                      ? selectedProject?.meta?.projectDocsUrl
                      : `https://${selectedProject?.meta?.projectDocsUrl}`

                  window.open(fullUrl, '_blank', 'noopener,noreferrer', true)
                }}
                className={cx(
                  styles['toolbar-item'],
                  styles['toolbar-button'],
                  'with-shadow button is-medium is-light',
                )}
                disabled={!selectedProject?.meta?.projectDocsUrl}
                dataTooltip={
                  !selectedProject?.meta?.projectDocsUrl
                    ? 'No docs available for this project'
                    : 'View project docs'
                }
              />
              <IconButton
                icon={faShareAlt}
                action={createShareableLink}
                className={cx(
                  styles['toolbar-item'],
                  styles['toolbar-button'],
                  'with-shadow button is-medium is-light',
                )}
              />
              <IconButton
                icon={faPencilAlt}
                action={() =>
                  history.push(PROJECT_EDIT.replace(ROUTE_ENTITY_ID, projectId))
                }
                className={cx(
                  styles['toolbar-item'],
                  styles['toolbar-button'],
                  'with-shadow button is-medium is-light',
                )}
                dataTest="edit-button"
              />
              <IconButton
                icon={faTimes}
                action={onClose}
                dataTest="close-project-modal"
                className={cx(
                  styles['toolbar-item'],
                  styles['toolbar-button'],
                  'with-shadow button is-medium is-light',
                )}
              />
              {generatedLink ? (
                <CopyToClipboard
                  text={generatedLink}
                  onCopy={() =>
                    showNotification(
                      'Generated link was copied to clipboard!',
                      'is-success',
                    )
                  }
                >
                  <FontAwesomeIcon
                    className={cx(
                      styles['toolbar-item'],
                      styles['toolbar-button'],
                      'with-shadow level-left',
                    )}
                    icon={faCopy}
                    size="lg"
                  />
                </CopyToClipboard>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>

        <div className="mb-2">
          <Toggle
            icon={faBell}
            text="Allows smart notifications to be sent"
            isActive={selectedProject.notifications}
            setIsActive={saveNotificationConfiguration}
            dataTest="notifications"
          />
        </div>

        <div>
          <Toggle
            icon={faBell}
            text="Sync project structure with template structure"
            isActive={!!selectedProject.update}
            setIsActive={saveUpdatesConfiguration}
            dataTest="sync-structure"
          />
        </div>
      </>
    </div>
  )
}

export default ProjectDescriptionHeader
