import cx from 'classnames'
import { Switch, Route, useHistory } from 'react-router-dom'

import ProjectsTable from './projects-table'
import Statistics from './statistics'
import Tools from '../shared/tools'
import ProjectModal from './project-modal'
import { DASHBOARD, PROJECT_DETAILS } from 'constants/routes'

import styles from './dashboard.module.scss'

function DashboardView(props) {
  const history = useHistory()

  function onCloseModal() {
    if (history.length > 2) {
      history.goBack()
    } else {
      history.push(DASHBOARD)
    }
  }

  return (
    <div className={styles.root} data-test="dashboard-root">
      <section className={cx('section', styles['dashboard-section'])}>
        <h1 className={styles.title}>Dashboard</h1>

        <div className="columns">
          <div className="column is-9">
            <ProjectsTable
              projects={props.projects}
              user={props.user}
              seeFavoriteProjects={props.seeFavoriteProjects}
              showingFavoriteProjects={props.showingFavoriteProjects}
              onSearchBy={props.onSearchBy}
              onSelectProgress={props.onSelectProgress}
              searchBy={props.searchBy}
              snoozeProject={props.snoozeProject}
              team={props.team}
              onSelectAssegnee={props.onSelectAssegnee}
            />
          </div>

          <div className="column">
            <Statistics statistics={props.projectsStatistics} />
          </div>
        </div>
      </section>

      <section className="section">
        <Tools user={props.user} />
      </section>

      <Switch>
        <Route path={PROJECT_DETAILS}>
          <ProjectModal onClose={onCloseModal} user={props.user} />
        </Route>
      </Switch>
    </div>
  )
}

export default DashboardView
