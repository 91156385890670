import { Route, Switch, useHistory } from 'react-router-dom'
import {
  VIRGIN_PROJECT_TEMPLATE_NEW,
  VIRGIN_PROJECT_TEMPLATE_DETAILS,
} from 'constants/routes'

import TabContainer from 'components/TabContainer'
import TemplateTable from './components/VirginProjectTemplatesTable'
import TemplateEditor from './components/VirginProjectTemplateEditor'

const Templates = () => {
  const history = useHistory()
  return (
    <TabContainer
      title="Virgin Project Templates"
      dataTest="bo-virgin-project-templates"
    >
      <button
        className="button is-info is-normal"
        onClick={() => history.push(VIRGIN_PROJECT_TEMPLATE_NEW)}
      >
        Add Template
      </button>

      <TemplateTable />

      <Switch>
        <Route exact path={VIRGIN_PROJECT_TEMPLATE_NEW}>
          <TemplateEditor />
        </Route>

        <Route exact path={VIRGIN_PROJECT_TEMPLATE_DETAILS}>
          <TemplateEditor />
        </Route>
      </Switch>
    </TabContainer>
  )
}

export default Templates
