import { useState } from 'react';
import { useQuery, useApolloClient } from '@apollo/react-hooks'
import { Route, Switch, useLocation } from 'react-router-dom'
import { parseISO, compareAsc, compareDesc } from 'date-fns'

import { GET_TASK_LIST, GET_COMPANY_SETTINGS } from 'graphql/queries'
import {
  TASK_ADDED,
  TASK_UPDATED,
  TASK_DELETED,
  TASK_POSITIONS_UPDATED,
} from 'graphql/subscriptions'
import hooks from 'hooks'

import CreateView from './create-view'
import CreateCardModal from './create-card-form-modal'
import CreateSubToolbar from './components/CreateSubToolbar'
import Loader from 'components/loader'
import { CARDS_EDIT, CARDS_NEW } from 'constants/routes'

const filterOptions = []
const sortingOptions = []

export default function CreateContainer({ type, cardsView }) {
  const loggedUser = hooks.useAuth().user
  const isTeam = useLocation().pathname.includes('/tools')
  // eslint-disable-next-line no-unused-vars
  const [filter, setFilter] = useState({ key: '', value: '' })
  const [searchQuery, setSearchQuery] = useState('')

  // Sorting. Accept an object of the following shape:
  // {
  //   field: string (the name of the key on the object that you would like to filter on),
  //   direction: 'asc' | 'desc' (wether you would like to sort your list ascending or descending)
  // }
  const [sort, setSort] = useState(null)

  const client = useApolloClient()

  const queryOptions = {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  }

  const {
    loading: loadingCards,
    error,
    data,
    refetch,
    subscribeToMore,
  } = useQuery(GET_TASK_LIST, {
    variables: {
      uid: isTeam ? undefined : loggedUser.uid,
      limitDoneTasks: Boolean(cardsView),
    },
    ...queryOptions,
  })

  const { loading: loadingSettings, data: companySettings } =
    useQuery(GET_COMPANY_SETTINGS)

  const colors =
    companySettings?.settings?.create?.customCategories.reduce(
      (acc, category) => {
        return {
          ...acc,
          [category.id]: category.color,
        }
      },
      {},
    ) || {}

  function subscribeToMoreCards() {
    return [
      subscribeToMore({
        document: TASK_ADDED,
        updateQuery: (prev, { subscriptionData }) => {
          refetch()
          return prev
        },
      }),
      subscribeToMore({
        document: TASK_UPDATED,
      }),
      subscribeToMore({
        document: TASK_DELETED,
        updateQuery: (prev, { subscriptionData }) => {
          refetch()
          return prev
        },
      }),
      subscribeToMore({
        document: TASK_POSITIONS_UPDATED,
        updateQuery: (prev, { subscriptionData }) => {
          refetch()
          return prev
        },
      }),
    ]
  }

  function compareCards(a, b) {
    const dateA = parseISO(a.dueDate)
    const dateB = parseISO(b.dueDate)

    if (sort.direction === 'asc') {
      return compareAsc(dateA, dateB)
    } else {
      return compareDesc(dateA, dateB)
    }
  }

  const searchIsActive = Boolean(searchQuery?.length)

  let searchedCards = searchIsActive
    ? data.tasks.filter(c => {
        const authorFullName = `${c.author.firstName} ${c.author.lastName}`
        return (
          c?.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          c?.content?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          // Search by author
          authorFullName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          // Search by project
          c.project?.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      })
    : data?.tasks ?? []

  const sortingIsActive = sort?.field === 'dueDate'
  if (sortingIsActive) {
    const sortedCards = [...searchedCards]
    sortedCards.sort(compareCards)
    searchedCards = sortedCards
  }

  let allowStickyCards = true
  if (searchIsActive || sortingIsActive) {
    allowStickyCards = false
  }

  if (loadingCards || loadingSettings) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return <p>Error fetching cards...</p>
  }

  return (
    <>
      <CreateSubToolbar
        hasSearchBar
        filterOptions={filterOptions}
        setFilter={setFilter}
        sortingOptions={sortingOptions}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        sort={sort}
        setSort={setSort}
        isTeam={isTeam}
      />
      <CreateView
        colors={colors}
        cardsView={cardsView}
        cards={searchedCards}
        // onOpen={onAddCardModalOpen}
        type={type}
        searchQuery={searchQuery}
        loggedUserId={loggedUser.uid}
        isTeam={isTeam}
        subscribeToMoreCards={subscribeToMoreCards}
        allowStickyCards={allowStickyCards}
      />
      <Switch>
        <Route path={CARDS_NEW}>
          <CreateCardModal
            open
            isTeam={isTeam}
            cardsView={cardsView}
            loggedUserId={loggedUser.uid}
          />
        </Route>
        <Route path={CARDS_EDIT}>
          <CreateCardModal
            open
            isTeam={isTeam}
            cardsView={cardsView}
            loggedUserId={loggedUser.uid}
          />
        </Route>
      </Switch>
    </>
  )
}
