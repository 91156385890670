import { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faEye } from '@fortawesome/free-solid-svg-icons'
import cx from 'classnames'
import groupBy from 'lodash.groupby'
import mapValues from 'lodash.mapvalues'
import isAfter from 'date-fns/isAfter'
import subDays from 'date-fns/subDays'

import Column from './column'
import Avatar from '../../../../../components/avatar'
import ExpansionPanel from '../../../../../components/expansion-panel'

import styles from '../create.module.scss'

const COLUMNS_HEADERS = [
  {
    header: 'Waiting',
  },
  {
    header: 'Doing',
  },
  {
    header: 'Done',
    description: 'Last 7 days',
  },
]
const INCOMING = 'Incoming'
const OVERFLOW = 'Overflow'

function calculateCardsNumber(cards) {
  return Object.keys(cards).reduce((acc, status) => {
    if (status === 'Done') {
      return (
        acc +
        cards[status].filter(card => {
          if (!card.completedAt) return true
          return isAfter(new Date(card.completedAt), subDays(new Date(), 7))
        }).length
      )
    }

    return acc + cards[status].length
  }, 0)
}

function formatCardsForColumns(cards, teamMembers) {
  const incomingCards = cards.filter(card => card.status === INCOMING)
  const overflowCards = cards.filter(card => card.status === OVERFLOW)

  const teamCards = cards
    .filter(card => ![INCOMING, OVERFLOW].includes(card.status))
    .filter(card => card.assignee)
  const teamCardsGroupedByAssigneeId = groupBy(
    teamCards,
    card => card.assignee.id,
  )
  const groupedTeamCards = mapValues(teamCardsGroupedByAssigneeId, cards =>
    groupBy(cards, card => card.status),
  )

  const teamMembersGroupedById = teamMembers.reduce(
    (acc, member) => ({ ...acc, [member.id]: [] }),
    {},
  )

  return {
    incomingCards,
    overflowCards,
    teamCards: { ...teamMembersGroupedById, ...groupedTeamCards },
  }
}

export function CreateView({
  cards,
  colors,
  onCardDropped,
  onOpen,
  loggedUserId,
  teamMembers,
  isTeam,
  allowStickyCards,
}) {
  const [focusTeamMemberIds, setFocusTeamMemberIds] = useState([])

  const focusSpaceEnabled = teamMembers.length > 3

  useEffect(() => {
    if (!focusSpaceEnabled) {
      return
    }

    try {
      const savedFocusTeamMemberIds = JSON.parse(
        window.localStorage.getItem('createBoardFocusTeamMemberIds'),
      )
      if (savedFocusTeamMemberIds) {
        setFocusTeamMemberIds(savedFocusTeamMemberIds)
      }
    } catch (error) {
      // This feature is not essential — ignore any errors
    }
  }, [])

  const formattedCards = formatCardsForColumns(cards, teamMembers)
  const teamMemberIds = Object.keys(formattedCards.teamCards)

  const otherTeamMemberIds = !focusSpaceEnabled
    ? teamMemberIds
    : teamMemberIds.filter(teamMemberId => {
        return !focusTeamMemberIds.includes(teamMemberId)
      })

  function toggleTeamMemberFocus(teamMemberId) {
    setFocusTeamMemberIds(prevState => {
      let newState
      if (prevState.includes(teamMemberId)) {
        newState = prevState.filter(id => id !== teamMemberId)
      } else {
        newState = [...prevState, teamMemberId]
      }

      try {
        window.localStorage.setItem(
          'createBoardFocusTeamMemberIds',
          JSON.stringify(newState),
        )
      } catch (error) {
        // This feature is not essential — ignore any errors
      }

      return newState
    })
  }

  function renderCardsColumns(cardsGroupedByColumn, teamMemberId) {
    return COLUMNS_HEADERS.map(({ header, description }, i) => {
      return (
        <Column
          className={!isTeam && styles['column-dashboard']}
          key={`${header}-${i}`}
          label={header}
          description={description}
          cards={cardsGroupedByColumn[header] || []}
          onOpen={onOpen}
          loggedUserId={isTeam ? teamMemberId : loggedUserId}
          colors={colors}
          allowStickyCards={allowStickyCards}
        />
      )
    })
  }

  function renderUserCards(teamMemberId) {
    const teamMember = teamMembers.find(member => member.id === teamMemberId)
    if (!teamMember) return <div key={teamMemberId}></div>

    const inFocus = focusTeamMemberIds.includes(teamMemberId)

    return (
      <ExpansionPanel
        key={teamMemberId}
        title={renderUserHeading(
          teamMember,
          calculateCardsNumber(formattedCards.teamCards[teamMemberId]),
          inFocus,
        )}
        className={styles['user-accordion']}
      >
        <div className={styles['user-cards-container']}>
          <div className={styles['columns-container']}>
            {renderCardsColumns(
              formattedCards.teamCards[teamMemberId],
              teamMemberId,
            )}
          </div>
        </div>
      </ExpansionPanel>
    )
  }

  function renderUserHeading(teamMember, numberOfCards, inFocus) {
    return (
      <div key={teamMember.id} className={styles['user-heading-container']}>
        <div className={styles['user']}>
          <Avatar isMedium />
          <div className={styles['user-info']}>
            <h4>
              {teamMember.firstName} {teamMember.lastName}
            </h4>
            <span className={styles['cards-number']}>{`${numberOfCards} card${
              numberOfCards === 1 ? '' : 's'
            }`}</span>
          </div>
        </div>
        <div>
          {focusSpaceEnabled && (
            <FontAwesomeIcon
              icon={faEye}
              size="lg"
              onClick={() => toggleTeamMemberFocus(teamMember.id)}
              className={cx(
                styles['user-focus-toggle'],
                inFocus && styles['in-focus'],
              )}
              title={inFocus ? 'Remove from focus space' : 'Add to focus space'}
            />
          )}
          <FontAwesomeIcon icon={faCaretDown} size="lg" />
        </div>
      </div>
    )
  }

  return (
    <>
      {cards && (
        <DragDropContext onDragEnd={onCardDropped}>
          <div
            className={cx(
              isTeam
                ? styles['columns-wrap']
                : styles['columns-wrap-dashboard'],
            )}
          >
            <Column
              className={
                isTeam
                  ? styles['practice-column-full-page']
                  : styles['incoming-column']
              }
              label="Incoming"
              cards={formattedCards.incomingCards}
              onOpen={onOpen}
              colors={colors}
              allowStickyCards={allowStickyCards}
            />

            {isTeam && (
              <div className={cx('column', styles['users-container'])}>
                {focusSpaceEnabled && (
                  <>
                    <div className={cx('divider', styles['custom-divider'])}>
                      Focus space
                    </div>
                    {!focusTeamMemberIds.length && (
                      <p className={cx(styles['tip'])}>
                        Move team members you want to focus on into this space
                        by clicking on their <FontAwesomeIcon icon={faEye} />{' '}
                        icon.
                      </p>
                    )}
                    {focusTeamMemberIds.map(renderUserCards)}
                    <div className={cx('divider', styles['custom-divider'])}>
                      Team Members
                    </div>
                  </>
                )}

                {otherTeamMemberIds.map(renderUserCards)}
              </div>
            )}

            {!isTeam && (
              <div className={styles['user-columns-dashboard']}>
                {renderCardsColumns(formattedCards.teamCards[loggedUserId])}
              </div>
            )}

            <Column
              className={isTeam && styles['practice-column-full-page']}
              label="Overflow"
              cards={formattedCards.overflowCards}
              onOpen={onOpen}
              colors={colors}
              allowStickyCards={allowStickyCards}
            />
          </div>
        </DragDropContext>
      )}
    </>
  )
}

export default CreateView
