import { addDays } from 'date-fns'

export default function calculateUserDates(userTasks) {
  const allDates =
    !!userTasks &&
    userTasks
      .reduce(
        (curr, task) => [
          ...curr,
          ...[
            task.estimatedDurationDateRange?.estimatedStartingDate,
            task.estimatedDurationDateRange?.estimatedCompletionDate,
          ].filter(d => !!d),
        ],
        [],
      )
      .sort((a, b) => (a > b ? 1 : -1))

  const earliestDate = allDates[0] ? new Date(allDates[0]) : new Date() // Defaulting to today in case no cards have a starting or ending date
  const latestDate = allDates.slice(-1)[0]
    ? new Date(allDates.slice(-1)[0])
    : addDays(new Date(), 1) // Defaulting to today in case no cards have a starting or ending date

  let start = earliestDate
  let end = latestDate === earliestDate ? addDays(earliestDate, 1) : latestDate

  // Should not have dates in the past
  if (start < new Date()) start = new Date()
  if (end < new Date()) end = addDays(new Date(), 1)

  return { start, end }
}
