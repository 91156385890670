import { useState } from 'react';
import PropTypes from 'prop-types'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import cx from 'classnames'
import { parse } from 'date-fns'

import * as U from '../timesheet-utils'

import DropDown from 'components/drop-down'
import FormDatePicker from 'components/form-datepicker'
import CreateFormModal from 'components/create-form-modal'
import TimesheetPhases from './timesheet-phases'

import styles from './add-hours-form.module.scss'
import { formatDateFromCalendar } from 'constants/utils'

const dropdownStyles = {
  option: provided => ({
    ...provided,
    color: '#555c72',
    fontSize: '12px',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#555c72',
    fontSize: '12px',
  }),
  singleValue: provided => ({
    ...provided,
    color: '#555c72',
    fontSize: '12px',
  }),
}

function AddHoursFormView(props) {
  const initialValues = {
    projectId: props?.selectedRecord?.projectId || '',
    phaseId: '',
    hours: '',
    minutes: '',
    date: props?.selectedRecord?.date
      ? parse(props?.selectedRecord?.date, 'yyyy-MM-dd', new Date())
      : null,
  }

  const [isAddingRecord, setIsAddingRecord] = useState(false)

  const projectListOptions = props.projectList
    ? props.projectList.map(project => {
        return { label: project.name, value: project.id, raw: project }
      })
    : null

  function generateProjectPhaseListOptions(selectedProjectId) {
    if (!props.projectList) return []

    const project = props.projectList.find(
      project => project.id === selectedProjectId,
    )
    if (!project) return []

    return [
      ...project.phases.map(phase => {
        return { label: phase.title, value: phase.id, raw: phase }
      }),
      { label: 'Unallocated', value: 'unallocated' },
    ]
  }

  function generateDataForTimesheetPhases() {
    if (!props.projectList || !props.selectedRecord) return []

    const project = props.projectList.find(
      project => project.id === props.selectedRecord.projectId,
    )

    return U.generateDataForTimesheetPhases(
      project,
      props.selectedRecord.records,
    )
  }

  return (
    <CreateFormModal
      open={props.open}
      onClose={props.onClose}
      title="Add Hours"
      submitButtonText="Add"
      resetButtonText="Reset"
      hideFooter
    >
      <Formik
        validateOnBlur={false}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          setIsAddingRecord(true)

          return props
            .onAddHours({
              ...values,
              date: formatDateFromCalendar(values.date),
              hours: values.hours || 0,
              minutes: values.minutes || 0,
            })
            .finally(() => {
              setIsAddingRecord(false)
              resetForm()
            })
        }}
      >
        {form => {
          return (
            <Form className={styles['form-wrap']} autoComplete="off">
              <div style={{ width: '100%' }}>
                <Field
                  name={'projectId'}
                  component={DropDown}
                  options={projectListOptions}
                  styles={dropdownStyles}
                  placeholder="Project"
                  disabled={!!props?.selectedRecord?.projectId}
                />
                <ErrorMessage
                  className="error"
                  name="projectId"
                  component="div"
                />
              </div>

              <div style={{ width: '100%' }} className="mt-2">
                <Field
                  name={'phaseId'}
                  component={DropDown}
                  options={generateProjectPhaseListOptions(
                    form.values.projectId,
                  )}
                  styles={dropdownStyles}
                  disabled={!form.values.projectId}
                  placeholder="Phase"
                />
                <ErrorMessage
                  className="error"
                  name="phaseId"
                  component="div"
                />
              </div>

              <div style={{ width: '100%' }} className="mt-2">
                <Field
                  name="description"
                  className="input"
                  type="text"
                  placeholder="What did you do?"
                />
                <ErrorMessage
                  className="error"
                  name="phaseId"
                  component="div"
                />
              </div>

              <div className={cx('mt-2', styles['time-inputs-wrapper'])}>
                <div className={styles['time-input-wrapper']}>
                  <FormDatePicker
                    name="date"
                    maxDate={new Date()}
                    className={cx(styles['datepicker'], 'input')}
                    containerClassName={styles['time-datepicker-container']}
                  />
                  <ErrorMessage className="error" name="date" component="div" />
                </div>

                <div className={styles['time-input-wrapper']}>
                  <Field
                    className="input"
                    type="number"
                    name="hours"
                    placeholder="hours"
                    min="0"
                  />
                  <ErrorMessage
                    className="error"
                    name="hours"
                    component="div"
                  />
                </div>

                <div className={styles['time-input-wrapper']}>
                  <Field
                    className="input"
                    type="number"
                    name="minutes"
                    placeholder="minutes"
                    min="0"
                    max="59"
                  />
                  <ErrorMessage
                    className="error"
                    name="minutes"
                    component="div"
                  />
                </div>

                <>
                  <button
                    className={cx(
                      'button',
                      'is-info',
                      isAddingRecord ? 'is-loading' : '',
                    )}
                    type="submit"
                    disabled={
                      !(
                        form.values.date &&
                        form.values.projectId &&
                        form.values.phaseId &&
                        (form.values.minutes || form.values.hours)
                      )
                    }
                  >
                    Add
                  </button>
                </>
              </div>
            </Form>
          )
        }}
      </Formik>

      {props.selectedRecord ? (
        <>
          <hr className="divider" />
          <TimesheetPhases
            phaseRecordList={generateDataForTimesheetPhases()}
            onDeleteRecord={props.onDeleteRecord}
          />
        </>
      ) : (
        <></>
      )}
    </CreateFormModal>
  )
}

AddHoursFormView.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAddHours: PropTypes.func,
  onDeleteRecord: PropTypes.func,
  onSubmit: PropTypes.func,
  selectedRecord: PropTypes.object,
}

export default AddHoursFormView
