import Toggle from '../../../dashboard/project-modal/toggle'

import styles from './summary-subscribe-form.module.scss'

function SummarySubscribeForm({
  subscribedToDailySummary = false,
  subscribedToWeeklySummary = false,
  subscribedToMonthlySummary = false,
  updateUserSettings,
}) {
  return (
    <>
      <div className="subtitle is-5">
        Subscribe to daily or weekly company's summary
      </div>

      <div>
        <div>
          <Toggle
            text="Subscribe to daily summary"
            isActive={subscribedToDailySummary}
            setIsActive={() =>
              updateUserSettings({
                subscribedToDailySummary: !subscribedToDailySummary,
              })
            }
            textContainerClass={styles.textContainerClass}
          />
        </div>

        <hr></hr>

        <div>
          <Toggle
            text="Subscribe to weekly summary"
            isActive={subscribedToWeeklySummary}
            setIsActive={() =>
              updateUserSettings({
                subscribedToWeeklySummary: !subscribedToWeeklySummary,
              })
            }
            textContainerClass={styles.textContainerClass}
          />
        </div>

        <hr></hr>

        <div>
          <Toggle
            text="Subscribe to monthly summary"
            isActive={subscribedToMonthlySummary}
            setIsActive={() =>
              updateUserSettings({
                subscribedToMonthlySummary: !subscribedToMonthlySummary,
              })
            }
            textContainerClass={styles.textContainerClass}
          />
        </div>
      </div>
    </>
  )
}

export default SummarySubscribeForm
