import cx from 'classnames'
import { useState } from 'react'

import { Logo } from 'assets'
import { Link } from 'react-router-dom'

import { faPlusSquare, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useMutation } from '@apollo/client'
import {
  ADMINISTRATION,
  BACKOFFICE,
  HOME,
  HOURS_ADMINISTRATION,
  TOOLS,
} from 'constants/routes'
import { SIGN_OUT } from 'graphql/mutations'
import hooks from '../hooks'
import styles from './header.module.scss'

const Header = ({ onToggleCreateProjectModal }) => {
  const auth = hooks.useAuth()
  const subscription = hooks.useSubscription()
  const [signOut] = useMutation(SIGN_OUT)

  const [openBurger, setOpenBurger] = useState(false)

  const oldSession = JSON.parse(window.localStorage.getItem('old-session'))

  function onStopImpersonateUser() {
    let oldUser = JSON.parse(window.localStorage.getItem('old-session'))
    window.localStorage.removeItem('session')
    window.localStorage.setItem('session', JSON.stringify(oldUser))
    window.localStorage.removeItem('old-session')
    auth.setNewUser(oldUser)
    window.location.reload(false)
  }

  const isDevelopment = process.env.NODE_ENV === 'development'

  async function onSignOut() {
    try {
      await signOut()
      auth.signOut()
    } catch (error) {
      console.log(error.message)
    }
  }

  return (
    <>
      {isDevelopment && (
        <div className={styles['dev-environment-banner']}>
          <strong>Development environment</strong>
        </div>
      )}
      <nav
        className="navbar"
        role="navigation"
        aria-label="main navigation"
        style={{ marginTop: auth.isImpersonating() ? '50px' : '0px' }}
      >
        <div className="navbar-brand">
          <div className={styles['header-left']}>
            <a className="navbar-item" href="/">
              <Logo style={{ height: '40px' }} />
            </a>

            <div className="tags are-medium">
              <span className="tag">{auth.user.company}</span>
            </div>
          </div>

          <button
            className={cx(
              openBurger
                ? 'navbar-burger burger is-active'
                : 'navbar-burger burger',
              styles['burger'],
            )}
            onClick={() => setOpenBurger(!openBurger)}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>

        <div className={openBurger ? 'navbar-menu is-active' : 'navbar-menu'}>
          <div
            className="navbar-end move-right-touch"
            onClick={() => openBurger && setOpenBurger(false)}
          >
            <Link
              to={HOME}
              className={cx('navbar-item', styles['link'])}
              data-test="nav-dashboard"
            >
              Dashboard
            </Link>

            <Link
              to={TOOLS}
              className={cx('navbar-item', styles['link'])}
              data-test="nav-kanban"
            >
              Kanban
            </Link>
            {subscription.canUseTimesheet() &&
              ['staff', 'admin'].includes(auth.user?.role) && (
                <Link
                  to={HOURS_ADMINISTRATION}
                  className={cx('navbar-item', styles['link'])}
                >
                  Hours
                </Link>
              )}
            <Link
              to={ADMINISTRATION}
              className={cx('navbar-item', styles['link'])}
              data-test="nav-administration"
            >
              Administration
            </Link>

            {auth.isSupport && (
              <Link
                to={BACKOFFICE}
                className={cx('navbar-item', styles['link'])}
                data-test="nav-back-office"
              >
                Back Office
              </Link>
            )}

            {!oldSession && (
              <a
                className={cx(
                  'navbar-item is-hidden-desktop',
                  '',
                  styles['link'],
                )}
                onClick={onSignOut}
              >
                Logout
              </a>
            )}

            {oldSession && (
              <a
                className={cx(
                  'navbar-item is-hidden-desktop',
                  '',
                  styles['link'],
                )}
                onClick={() => onStopImpersonateUser()}
              >
                Stop impersonating
              </a>
            )}

            <div className="navbar-item has-dropdown is-hoverable is-hidden-touch">
              {/* eslint-disable-next-line */}
              <a className="navbar-link">
                <div className={cx('level', styles['user-button'])}>
                  <div className="level-left" style={{ marginRight: 7 }}>
                    <FontAwesomeIcon icon={faUserCircle} size="lg" />
                  </div>
                  <div className="level-right">
                    {auth.user.firstName} {auth.user.lastName}
                  </div>
                </div>
              </a>

              <div className="navbar-dropdown">
                {!oldSession && (
                  <a className="navbar-item" onClick={onSignOut}>
                    Logout
                  </a>
                )}
                {oldSession && (
                  <a
                    className="navbar-item"
                    onClick={() => onStopImpersonateUser()}
                  >
                    Stop impersonating
                  </a>
                )}
              </div>
            </div>

            <div className="navbar-item">
              <button
                className={cx('button is-info', styles['new-project-button'])}
                onClick={() => onToggleCreateProjectModal(true)}
                data-test="new-project-button"
                disabled={!subscription.canAddNewProjects()}
              >
                New Project
                <FontAwesomeIcon
                  style={{ marginLeft: '27px' }}
                  icon={faPlusSquare}
                  size="lg"
                />
              </button>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Header
