import PropTypes from 'prop-types'
import cx from 'classnames'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'

import styles from './timesheet-phases.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function TimesheetPhases(props) {
  const enableDelete = props?.onDeleteRecord

  return (
    <table
      className={cx('table is-fullwidth', styles['timesheet-phases-table'])}
    >
      <thead>
        <tr>
          <th>Phase</th>
          <th>Hours</th>
          <th>What did you do?</th>
          {enableDelete ? <th></th> : <></>}
        </tr>
      </thead>
      <tbody>
        {props.phaseRecordList.map(record => {
          return (
            <tr key={record.id}>
              <td>{record.phaseName}</td>
              <td>{record.time}</td>
              <td>{record.description}</td>
              {enableDelete ? (
                <td>
                  <FontAwesomeIcon
                    className={styles['delete-icon']}
                    title="Delete phase time"
                    icon={faTrash}
                    size="1x"
                    onClick={() => {
                      if (confirm('Are you sure to delete this record?')) {
                        props.onDeleteRecord(record.id)
                      }
                    }}
                  />
                </td>
              ) : (
                <></>
              )}
            </tr>
          )
        })}
        <tr></tr>
      </tbody>
    </table>
  )
}

TimesheetPhases.propTypes = {
  phaseRecordList: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.number,
      description: PropTypes.string,
      id: PropTypes.string,
      phaseId: PropTypes.string,
      phaseName: PropTypes.string,
      time: PropTypes.number,
    }),
  ),
  onDeleteRecord: PropTypes.func,
}

export default TimesheetPhases
