import { useEffect, useState } from 'react';
import styles from './styles.module.scss'
import { useField } from 'formik'
import {
  convertSecondsToTimeComponents,
  convertTimeComponentsToSeconds,
} from 'constants/utils'

function DurationField(props) {
  const [duration, setDuration] = useState({
    days: 0,
    hours: 0,
  })

  const [field, , helpers] = useField(props.field.name)

  useEffect(() => {
    helpers.setValue(convertTimeComponentsToSeconds(duration), false)
  }, [duration])

  useEffect(() => {
    if (!field.value) {
      return
    }

    const { days, hours } = convertSecondsToTimeComponents(props.field.value)

    setDuration({
      ['days']: days,
      ['hours']: hours,
    })
  }, [field.value])

  const handleInputChange = (event, unit) => {
    const { value } = event.target

    if (isNaN(value) || !value) {
      return setDuration(prevDuration => ({
        ...prevDuration,
        [unit]: 0,
      }))
    }

    setDuration(prevDuration => ({
      ...prevDuration,
      [unit]: parseInt(value, 10),
    }))
  }

  return (
    <div className={styles['duration-field']}>
      <div className={styles['input-fields']}>
        <div className={styles['input-field']}>
          <label>Days:</label>
          <input
            className="input"
            value={duration.days}
            onChange={e => handleInputChange(e, 'days')}
            onKeyPress={event => {
              if (!/[1-9][0-9]?/.test(event.key)) {
                event.preventDefault()
              }
            }}
          />
        </div>
        <div className={styles['input-field']}>
          <label>Hours:</label>
          <input
            className="input"
            value={duration.hours}
            onChange={e => handleInputChange(e, 'hours')}
            onKeyPress={event => {
              if (!/[1-9][0-9]?/.test(event.key)) {
                event.preventDefault()
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default DurationField
