import { useState, useMemo } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { toast as notify } from 'bulma-toast'
import { CSVLink } from 'react-csv'
import parseDate from 'date-fns/parse'
import format from 'date-fns/format'
import { startOfDay, addDays } from 'date-fns'
import Select, { components } from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

import { GENERATE_DATA_FOR_TIMESHEET_CSV, GET_MY_TEAM } from 'graphql/queries'
import { TOGGLE_INVOICE_STATUS_BY_RECORD_ID } from 'graphql/mutations'

import Loader from 'components/loader'
import Table from 'components/table'
import DatePicker from 'components/datepicker'

import styles from './timesheet-administration.module.scss'
import cx from 'classnames'
import useCompanySettings from '../../hooks/useCompanySettings'

function formatMinutes(totalMinutes = 0) {
  if (!totalMinutes) return ''

  const minutes = totalMinutes % 60
  const hours = Math.floor(totalMinutes / 60)

  return `${hours}:${minutes || '00'}`
}

function TimesheetAdminView() {
  const [isLoading, setIsLoading] = useState(false)
  const [filterUsers, setFilterUsers] = useState([])
  const [datesRange, setDatesRange] = useState({})
  const [csvData, setCsvData] = useState(null)
  const [currentPageNumber, setCurrentPageNumber] = useState(0)

  const { data: teamData } = useQuery(GET_MY_TEAM)

  const teamList = useMemo(
    () =>
      teamData?.team?.map(user => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      })),
    [teamData],
  )

  const apolloClient = useApolloClient()

  const { preferences } = useCompanySettings()
  const { dateFormat } = preferences ?? {}

  function onSelectUser(value) {
    const userIds = value?.map(user => user.value) ?? []

    setFilterUsers(userIds)
  }

  function fetchTimesheetDataForCsv() {
    setIsLoading(true)

    return apolloClient
      .query({
        query: GENERATE_DATA_FOR_TIMESHEET_CSV,
        variables: {
          filterUsers,
          datesRange:
            datesRange.start && datesRange.end
              ? {
                  start: format(datesRange.start, 'yyyy-MM-dd'),
                  end: format(datesRange.end, 'yyyy-MM-dd'),
                }
              : null,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data: { generateDataForTimesheetCsv } }) => {
        const headers = [
          'invoiced',
          'project',
          'phase',
          'date',
          'time',
          'description',
          'user',
        ]

        const data = generateDataForTimesheetCsv.map(record => ({
          ...record,
          time: formatMinutes(record.time),
          date: format(
            parseDate(record.date, 'yyyy-MM-dd', new Date()),
            dateFormat ?? 'dd/MM/yyyy',
          ),
        }))

        setCsvData({ headers, data })
      })
      .catch(() => {
        notify({
          message: 'There was an error generating the timesheet CSV',
          type: 'is-danger',
          dismissible: true,
          position: 'bottom-right',
          duration: 3000,
          closeOnClick: true,
        })
      })
      .finally(() => setIsLoading(false))
  }

  function handleSelectRange([start, end]) {
    setDatesRange({ start, end })
  }

  const handleInvoicedChange = id => {
    const recordIndex = csvData.data.findIndex(record => record.id === id)
    if (recordIndex === -1) {
      return
    }

    const newDataArray = [...csvData.data]

    newDataArray[recordIndex] = {
      ...newDataArray[recordIndex],
      invoiced: !csvData.data[recordIndex].invoiced,
    }

    setCsvData({
      ...csvData,
      data: newDataArray,
    })

    apolloClient
      .mutate({
        mutation: TOGGLE_INVOICE_STATUS_BY_RECORD_ID,
        variables: {
          recordId: id,
        },
      })
      .then(mutationResult => {
        if (!mutationResult.data.toggleInvoiceStatusByRecordId.successful) {
          throw new Error()
        }

        notify({
          message: 'Invoiced status updated',
          type: 'is-success',
          dismissible: true,
          position: 'bottom-right',
          duration: 3000,
          closeOnClick: true,
        })
      })
      .catch(() => {
        notify({
          message: 'Error updating invoiced status',
          type: 'is-danger',
          dismissible: true,
          position: 'bottom-right',
          duration: 3000,
          closeOnClick: true,
        })
      })
  }

  const UserValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && (
            <FontAwesomeIcon
              icon={faUser}
              style={{ position: 'absolute', left: 8 }}
            />
          )}
          {children}
        </components.ValueContainer>
      )
    )
  }

  const selectStyles = {
    valueContainer: base => ({
      ...base,
      paddingLeft: 24,
    }),
  }

  const columns = [
    {
      Header: 'Invoiced',
      accessor: 'invoiced',
      Cell: ({ row }) => (
        <input
          type="checkbox"
          checked={row.original.invoiced}
          onChange={() => handleInvoicedChange(row.original.id)}
        />
      ),
    },
    { Header: 'Date', accessor: 'date' },
    { Header: 'Time', accessor: 'time' },
    { Header: 'Project', accessor: 'project' },
    { Header: 'Phase', accessor: 'phase' },
    { Header: 'Description', accessor: 'description' },
    { Header: 'User', accessor: 'user' },
  ]

  return (
    <div className={styles['root']}>
      <h3>Timesheet reporting</h3>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <span style={{ marginRight: '0.5rem' }}>
            <strong>All team members</strong> or
          </span>
          <Select
            closeMenuOnSelect
            components={{ ValueContainer: UserValueContainer }}
            className="react-select-container-progress"
            classNamePrefix="react-select-progress"
            styles={selectStyles}
            options={teamList}
            onChange={onSelectUser}
            defaultValue={null}
            isMulti={true}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: 'rgba(101, 138, 255, 0.35)',
                primary: 'red',
              },
            })}
            placeholder="Select team members"
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '0.5rem' }}>
            <strong>All time</strong> or
          </span>
          <DatePicker
            isRange
            singleOnChange
            startValue={datesRange.start && new Date(datesRange.start)}
            endValue={datesRange.end && new Date(datesRange.end)}
            onChange={handleSelectRange}
          />
        </div>
      </div>
      <button
        className={cx('button is-normal', styles['view-records-button'])}
        onClick={() => fetchTimesheetDataForCsv()}
      >
        View records
      </button>
      <div className={styles['download-csv-button']}>
        {isLoading && (
          <div style={{ textAlign: 'center' }}>
            <Loader />
          </div>
        )}
        {csvData && (
          <div>
            <span className={styles['download-button']}>
              <CSVLink
                data={csvData.data}
                headers={csvData.headers}
                filename="hours.csv"
                className="button is-info"
              >
                Export all records to CSV file
              </CSVLink>
            </span>
            <Table
              columns={columns}
              data={csvData.data}
              pageSize={50}
              currentPageNumber={currentPageNumber}
              onChangePage={pageNumber => setCurrentPageNumber(pageNumber)}
              hightlightRowField="invoiced"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default TimesheetAdminView
