import { useState, useEffect } from 'react';
import { ViewMode } from '@yoopknows/timeline'

import Loader from 'components/loader'

import '@yoopknows/timeline/dist/index.css'
import TaskTableItem from './TaskTableItem'
import useCardsTimelineData from '../hooks/useCardsTimelineData'
import { createCardDetailsUrl } from 'constants/routes'
import GanttTimeline from 'components/GanttTimeline'

function CardsTimeline({ selectedScale }) {
  const {
    rows,

    loading,
    handleExpanderClick,
    handleTaskChange,
  } = useCardsTimelineData()

  const [selectedItemId, setSelectedItemId] = useState(null)

  const [initialScrollXDate, setInitialScrollXDate] = useState(null)

  useEffect(() => {
    if (!selectedItemId) setInitialScrollXDate(null)
  }, [selectedScale])

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    )
  }

  function onUserClick({ id, startDate }) {
    if (selectedItemId === id) {
      setSelectedItemId(null)
    } else {
      setSelectedItemId(id)
      setInitialScrollXDate(startDate)
    }
  }

  return (
    <div data-test="timeline-container">
      {rows.length ? (
        <GanttTimeline
          tasks={rows}
          viewMode={ViewMode[selectedScale]}
          onDateChange={handleTaskChange}
          onExpanderClick={user => handleExpanderClick(user.id)}
          listCellWidth={'300px'}
          nameCellComponent={props => (
            <TaskTableItem
              {...props}
              onProjectClick={onUserClick}
              isSelected={selectedItemId === props.id}
              hideParentLink
              url={props.type === 'task' && createCardDetailsUrl(props.id)}
            />
          )}
          hasScrollbars={{ bottom: true }}
          initialScrollXDate={initialScrollXDate}
        />
      ) : (
        <p>No cards to show...</p>
      )}
    </div>
  )
}

export default CardsTimeline
