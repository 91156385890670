import cx from 'classnames'

import Search from '../search'
import Filter from '../filter'

import styles from './table.module.scss'

const TableHeader = ({
  globalFilter,
  setGlobalFilter,
  filterOptions,
  buttonText,
  onToggleCreateModal,
  favoriteFilter,
  search,
  buttonDisabled,
}) => {
  function renderFavoriteFilter() {
    return (
      <div className="column is-2">
        <div className="buttons no-flex-wrap">
          <button
            className={`button is-fullwidth ${styles['favorite-project-switch']}`}
            style={{ marginRight: '2px' }}
          >
            <span>Favorite</span>
          </button>
          <button
            className={`button is-fullwidth ${styles['favorite-project-switch']}`}
          >
            All
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="columns is-vcentered">
      {search && (
        <div className={`column ${filterOptions && 'is-7'}`}>
          <Search
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      )}

      {favoriteFilter && renderFavoriteFilter()}

      {filterOptions && (
        <div className="column">
          <Filter
            onChange={setGlobalFilter}
            options={filterOptions}
            onReset={() => setGlobalFilter('')}
          />
        </div>
      )}

      {buttonText && (
        <div>
          <button
            className={cx('column', styles['button-custom'], 'button is-info')}
            onClick={() => onToggleCreateModal(true)}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        </div>
      )}
    </div>
  )
}

export default TableHeader
